import _ from "lodash"
import UserFilterType from "../../models/UserFilterType"
import { extractResponseBody } from "./apiActionHelpers"
import { collapseGroupTypes } from "./teamHelpers"
import SteadyfootAgent from "./SteadyfootAgent"

/**
 * @param {Object<string, string>} urls - from endpointsDeprecated.json. Object that
 *    maps `UserFilterType` values to the endpoints for loading filter options. E.g. the
 *    `ADMIN_PERF_REVIEW_FILTER_URLS` entry.
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} cycleIds - Self Reflection/Evaluation/Survey cycle ID(s)
 * @param {string} cycleParamName - name of the cycle IDs param in the API, e.g.,
 *    `performance_cycle_ids`
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
export async function loadUserFilterOptions({
  urls,
  userFilterType,
  filter,
  cycleIds,
  ecId,
  cycleParamName,
  currentFilters,
  query,
  ids,
  distinct_by = "title",
  page = 1,
  agent = SteadyfootAgent.defaultInstance,
  managers_only = false,
}) {
  const url = urls[userFilterType]

  let searchParamName
  if (userFilterType === UserFilterType.Levels) {
    searchParamName = "level_q"
  } else if (userFilterType === UserFilterType.DemographicValues) {
    searchParamName = "value_q"
  } else {
    searchParamName = "q"
  }

  let extraParams = {}
  switch (userFilterType) {
    case UserFilterType.Users:
      extraParams = {
        department_ids: currentFilters?.departmentIds,
        manager_ids: currentFilters?.managerIds,
        job_title_ids: currentFilters?.jobTitleIds,
        group_type: collapseGroupTypes(currentFilters?.groupTypes),
        per_page: 100,
      }
      break
    case UserFilterType.DemographicValues:
      extraParams = {
        department_ids: currentFilters?.departmentIds,
        manager_ids: currentFilters?.managerIds,
        group_type: collapseGroupTypes(currentFilters?.groupTypes),
        attribute_type: filter,
      }
      break
    case UserFilterType.Levels:
      extraParams = {
        job_title_ids: currentFilters?.jobTitleIds,
        department_ids: currentFilters?.departmentIds,
      }
      break
    case UserFilterType.Departments:
      extraParams = {
        distinct_by: distinct_by,
      }
      break
    case UserFilterType.Managers:
      if (ecId) {
        extraParams = {
          evaluation_cycle_id: ecId,
        }
      }
      break
    default:
      extraParams = {
        department_ids: currentFilters?.departmentIds,
        distinct_by: distinct_by,
      }
  }

  const rawParams = {
    [cycleParamName]: cycleIds,
    [searchParamName]: query,
    ids,
    page,
    per_page: 20,
    managers_only,
    ...extraParams,
  }

  // Remove blank params and convert arrays to comma-separated strings
  // TODO: move to util?
  const params = _(rawParams)
    .pickBy((v) => v || v === 0)
    .mapValues((v) => (Array.isArray(v) ? v.join(",") : v))
    .value()

  let responseBody

  try {
    responseBody = await extractResponseBody(agent.get(url).query(params))
  } catch (error) {
    if (_.get(error, "status") !== 404) {
      throw error
    }
    return { results: [], meta: {} }
  }

  // Since SF wraps the array of results in a key specific to the endpoint, like
  // "performance_managers", just look for the first entry other than "meta"
  const results = _(responseBody).omit("meta").values().head()

  return { results, meta: responseBody.meta }
}
