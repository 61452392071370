import React from "react"
import surveysIcon from "@kaizen/component-library/icons/surveys-white.icon.svg"
import { Icon } from "@kaizen/component-library"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { Tooltip } from "@Components/Tooltip"
import {
  Employee,
  ManagerReviewCycle,
  ManagerReview,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { UserInformation } from "@Calibrations/v2/components/TableCells/EmployeeCell/UserInformation"
import { EmployeeToggleMenu } from "./EmployeeToggleMenu"
import styles from "./EmployeeCell.scss"

type EmployeeCellProps = {
  managerReviewCycle: ManagerReviewCycle
  managerReview: ManagerReview
  employee: Employee
  showKebabMenu?: boolean
}

export const HasNotesIcon = ({
  managerReview,
}: {
  managerReview: ManagerReview
}) => {
  const { formatMessage } = useIntl()

  if (!managerReview.note.note) {
    return null
  }

  return (
    <div className={styles.notesTooltip} data-automation-id={"hasNotesTooltip"}>
      <Tooltip
        renderLocation={"root"}
        text={formatMessage(
          strings.pages.AdminCalibrationViewShowPage.EmployeeCell
            .hasCalibrationNoteTooltip
        )}
      >
        <Icon
          icon={surveysIcon}
          role="img"
          title={formatMessage(
            strings.pages.AdminCalibrationViewShowPage.AdminCalibrationViewTable
              .hasNotesIconTitle
          )}
        />
      </Tooltip>
    </div>
  )
}

export const EmployeeCell: React.FC<EmployeeCellProps> = ({
  managerReviewCycle,
  managerReview,
  employee,
  showKebabMenu,
}) => {
  return (
    <div className={styles.employeeCell}>
      <UserInformation employee={employee} />
      {showKebabMenu ? (
        <>
          <EmployeeToggleMenu
            employee={employee}
            managerReviewCycle={managerReviewCycle}
            managerReview={managerReview}
          />
          <HasNotesIcon managerReview={managerReview} />
        </>
      ) : null}
    </div>
  )
}
