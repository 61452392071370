import AdminEmployeeGrouping from "../admin/refluxStores/AdminEmployeeGroupingStore"
import AdminFeedbackStats from "../admin/refluxStores/AdminFeedbackStatsStore"
import AdminGoal from "../admin/refluxStores/AdminGoalStore"
import AdminIncompleteSurveyResponse from "../admin/refluxStores/AdminIncompleteSurveyResponseStore"
import AdminManagerFeedbackStats from "../admin/refluxStores/AdminManagerFeedbackStatsStore"
import AdminSurveyResponse from "../admin/refluxStores/AdminSurveyResponseStore"
import AdminSurvey from "../admin/refluxStores/AdminSurveyStore"
import AdminTemplate from "../admin/refluxStores/AdminTemplateStore"
import Directory from "../admin/refluxStores/DirectoryStore"
import ManageUsers from "../admin/refluxStores/ManageUsersStore"
import PerformanceReviewAdmin from "../admin/refluxStores/PerformanceReviewAdminStore"
import BaseEmployeeGrouping from "./BaseEmployeeGroupingStore"
import BaseIncompleteSurveyResponse from "./BaseIncompleteSurveyResponseStore"
import BaseSurveyResponse from "./BaseSurveyResponseStore"
import BaseSurvey from "./BaseSurveyStore"
import Contact from "./ContactStore"
import Conversation from "./ConversationStore"
import DevelopmentResource from "./DevelopmentResourceStore"
import Draft from "./DraftStore"
import EmployeeGrouping from "./EmployeeGroupingStore"
import FeedbackReply from "./FeedbackReplyStore"
import FeedbackRequests from "./FeedbackRequestsStore"
import Feedback from "./FeedbackStore"
import Filters from "./FiltersStore"
import GivenFeedback from "./GivenFeedbackStore"
import Goal from "./GoalStore"
import IncompleteSurveyResponse from "./IncompleteSurveyResponseStore"
import LoadingState from "./LoadingStateStore"
import Notification from "./NotificationStore"
import Ontology from "./OntologyStore"
import PerformanceReviewHack from "./PerformanceReviewHackStore"
import PerformanceReview from "./PerformanceReviewStore"
import PreviousUser from "./PreviousUserStore"
import PublicPraise from "./PublicPraiseStore"
import Review from "./ReviewStore"
import SavedView from "./SavedViewStore"
import Search from "./SearchStore"
import SurveyResponse from "./SurveyResponseStore"
import Survey from "./SurveyStore"
import Team from "./TeamStore"
import Template from "./TemplateStore"
import User from "./UserStore"
import UserSummary from "./UserSummaryStore"

// Includes a list of every reflux store available.
// It's sole purpose is for debugging purposes, so we can log store changes
// in the console.
export const refluxStores = {
  AdminEmployeeGrouping,
  AdminFeedbackStats,
  AdminGoal,
  AdminIncompleteSurveyResponse,
  AdminManagerFeedbackStats,
  AdminSurveyResponse,
  AdminSurvey,
  AdminTemplate,
  Directory,
  ManageUsers,
  PerformanceReviewAdmin,
  BaseEmployeeGrouping,
  BaseIncompleteSurveyResponse,
  BaseSurveyResponse,
  BaseSurvey,
  Contact,
  Conversation,
  DevelopmentResource,
  Draft,
  EmployeeGrouping,
  FeedbackReply,
  FeedbackRequests,
  Feedback,
  Filters,
  GivenFeedback,
  Goal,
  IncompleteSurveyResponse,
  LoadingState,
  Notification,
  Ontology,
  PerformanceReviewHack,
  PerformanceReview,
  PreviousUser,
  PublicPraise,
  Review,
  SavedView,
  Search,
  SurveyResponse,
  Survey,
  Team,
  Template,
  User,
  UserSummary,
}
