// This function takes an event and some router settings and
// Overrides the event with the router if the user has clicked
import React from "react"
import { RouteChildrenProps } from "react-router-dom"
import { isRegularMouseClick } from "./domEvent"
import { buildUrl } from "./url"
import R from "../constants/routes"

type QueryObject = { [q: string]: string }

export const createRouterClickCallback =
  (
    history: RouteChildrenProps["history"],
    route: R | string,
    settings?: {
      query?: QueryObject
      replace?: boolean
      onClick?: (e: React.MouseEvent) => void
    }
  ) =>
  (event: React.MouseEvent) => {
    const { replace = false, query, onClick } = settings || {}

    // Helpful code was copy/pasted from react-router:
    //  https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
    try {
      if (onClick) onClick(event)
    } catch (ex) {
      event.preventDefault()
      throw ex
    }

    if (
      !event.defaultPrevented && // ie. the passed in onClick event already called `event.preventDefault()`
      isRegularMouseClick(event)
    ) {
      event.preventDefault()
      history[replace ? "replace" : "push"](
        query ? buildUrl(route, query) : route
      )
    }
  }
