import React, { useEffect } from "react"
import {
  Route,
  Switch,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom"

export const basePath = "/performance"
export const conversationUIBasePath = "/app/conversations"

/**
 * A list of every route used in the perform website.
 *
 * Please do not use strings as routes, or use string concatenation
 * for deriving your route. Treat them as if their values were some random,
 * unknown number. The purpose of this enum is to make it
 * very easy to trace where and how a route is used. If you're asking "Where
 * is this route getting used?", you can simply call "find usages" in your
 * IDE on the enum value, or do a global search for R.myRouteName.
 *
 * Note: this list is not complete yet. There is a tech debt task to go through
 * the existing code here:
 *   https://trello.com/c/wbaKt7ze/27-use-a-constants-file-for-route-addresses
 */
enum R {
  oneOnOne = "/1-1",
  oneOnOneSpace = "/1-1/:oneOnOneUuid",
  adminOneOnOnesUsageReport = "/admin/1-1s_stats",
  error403 = "/403",
  calendarSync = "/1-1/calendar-sync",
  oneOnOneError403 = "/1-1/403",
  oneOnOneConversation = "/1-1/conversation/:conversationUuid",
}

export default R

interface MatchParams {
  conversationUuid: string
  oneOnOneUuid: string
}

interface RedirectProps {
  to: string
}

const PrivateOneOnOneRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return <Route {...rest}>{children}</Route>
}

const ConversationRedirect = ({ to }: RedirectProps) => {
  useEffect(() => {
    window.location.replace(to)
  })

  return null
}

// 1-1s Routes
// You will notice that in 1-1 routes we have our own way of authenticating
// users. This is because we need to allow engagement users to use our area of the
// product, not just performance users.
export const OneOnOneRoutes = (
  <PrivateOneOnOneRoute path={R.oneOnOne}>
    <Switch>
      <Route exact path={R.oneOnOne}>
        <ConversationRedirect to="/app/conversations" />
      </Route>
      <Route exact path={R.calendarSync}>
        <ConversationRedirect to="/app/conversations/calendar-sync" />
      </Route>
      <Route
        path={R.oneOnOneConversation}
        component={(props: RouteComponentProps<MatchParams>) => {
          return (
            <ConversationRedirect
              to={`/app/conversations/${props.match.params.conversationUuid}`}
            />
          )
        }}
      />
      <Route
        path={R.oneOnOneSpace}
        component={() => {
          return <ConversationRedirect to={`/app/conversations`} />
        }}
      />
    </Switch>
  </PrivateOneOnOneRoute>
)

export const OneOnOneAdminRoutes = (
  <PrivateOneOnOneRoute path="/admin/1-1s_stats">
    <Route exact path="/admin/1-1s_stats">
      <ConversationRedirect to="/app/conversations/admin/report" />
    </Route>
  </PrivateOneOnOneRoute>
)
