import { perfApiGet } from "@API/utils"
import { FetchReview } from "@Calibrations/v2/queries/useGetReviewResults"
import { useQuery } from "@tanstack/react-query"
import { QueryKeys } from "./keys"

export const fetchReviewResult = async ({
  calibrationViewId,
  managerReviewId,
}: {
  calibrationViewId: number
  managerReviewId: number
}) => {
  const url = `dashboard/calibration/:calibrationViewId/snapshot_manager_review/:managerReviewId`
  const res = await perfApiGet<{ managerReview: FetchReview }>(url, {
    params: {
      calibrationViewId,
      managerReviewId,
    },
  })
  return res.data
}

export const useGetReviewResult = ({
  calibrationViewId,
  managerReviewId,
}: {
  calibrationViewId: number
  managerReviewId: number
}) => {
  return useQuery(
    [
      QueryKeys.AdminCalibrationViewReviewResult,
      calibrationViewId,
      managerReviewId,
    ],
    () => fetchReviewResult({ calibrationViewId, managerReviewId })
  )
}

export const fetchNonAdminReviewResult = async ({
  calibrationViewId,
  managerReviewId,
}: {
  calibrationViewId: number
  managerReviewId: number
}) => {
  const url = `calibrations/:calibrationViewId/manager_reviews/:managerReviewId`
  const res = await perfApiGet<{ managerReview: FetchReview }>(url, {
    params: {
      calibrationViewId,
      managerReviewId,
    },
  })
  return res.data
}

export const useGetNonAdminReviewResult = ({
  calibrationViewId,
  managerReviewId,
}: {
  calibrationViewId: number
  managerReviewId: number
}) => {
  return useQuery(
    [
      QueryKeys.AdminCalibrationViewReviewResult,
      calibrationViewId,
      managerReviewId,
    ],
    () => fetchNonAdminReviewResult({ calibrationViewId, managerReviewId })
  )
}
