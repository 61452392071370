/**
 * A list of some feature flags used in the app.
 * Note: These are for the new feature flag system that uses flipper.
 * Launch Darkly is now the preferred system to create and manage feature flags.
 *
 * More info: docs/featureFlags.md
 */
enum FeatureFlags {
  /** TODO: Reverse the logic and remove the flag */
  /** enables page on admin dashboard to delete survey responses and reviews */
  allowAdminFeedbackDelete = "allow_admin_feedback_delete",
  /** TODO: Investigate if this is required. Only turned on for Culture Amp Employees */
  redirectNotificationsPageToUnifiedHome = "redirect_notifications_page_to_unified_home",
  /** Demo feature flag for jest tests */
  testFlag = "test_flag",
  /** TODO: Investigate if this is required. Only turned on for Unilever */
  useNewSkillsAssignmentModal = "use_new_skills_assignment_modal",
}

export default FeatureFlags
