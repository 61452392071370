import { ConfirmationModal } from "@kaizen/draft-modal"
import strings from "@Calibrations/locale/strings"
import { Paragraph } from "@kaizen/typography"
import React from "react"
import { useIntl } from "react-intl"

export const SuccessModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { formatMessage } = useIntl()

  return (
    <ConfirmationModal
      automationId={"publish-success-modal"}
      isOpen={isOpen}
      mood="positive"
      isProminent={true}
      title={formatMessage(
        strings.pages.AdminCalibrationViewShowPage.PageHeader
          .publishSuccessModal.title
      )}
      dismissLabel={formatMessage(
        strings.pages.AdminCalibrationViewShowPage.PageHeader
          .publishSuccessModal.dismissLabel
      )}
      onDismiss={() => setIsOpen(false)}
    >
      <Paragraph variant="body">
        {formatMessage(
          strings.pages.AdminCalibrationViewShowPage.PageHeader
            .publishSuccessModal.paragraph
        )}
      </Paragraph>
    </ConfirmationModal>
  )
}
