import { ConfirmationModal } from "@kaizen/draft-modal"
import strings from "@Calibrations/locale/strings"
import { Box } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import styles from "@Calibrations/v2/components/PublishCalibrationModals/PageHeader.scss"
import { CheckboxField } from "@kaizen/draft-form"
import React from "react"
import { useIntl } from "react-intl"
import Avo from "@Avo/Avo"
import { usePublishCalibrationView } from "@Calibrations/v2/queries/usePublishCalibrationView"
import { SuccessModal } from "@Calibrations/v2/components/PublishCalibrationModals/SuccessModal"
import { ErrorModal } from "@Calibrations/v2/components/PublishCalibrationModals/ErrorModal"

export const PublishCalibrationModal = ({
  calibrationViewId,
  isPublishModalOpen,
  setIsPublishModalOpen,
  setIsPublishSuccessModalOpen,
  setIsPublishErrorModalOpen,
}: {
  calibrationViewId: number
  isPublishModalOpen: boolean
  setIsPublishModalOpen: (e: boolean) => void
  setIsPublishSuccessModalOpen: (e: boolean) => void
  setIsPublishErrorModalOpen: (e: boolean) => void
}) => {
  const { formatMessage } = useIntl()

  const publishCalibrationViewMutation =
    usePublishCalibrationView(calibrationViewId)

  const [shouldSendNotifications, setShouldSendNotifications] =
    React.useState(true)

  return (
    <ConfirmationModal
      automationId={"publish-modal"}
      isOpen={isPublishModalOpen}
      mood="informative"
      title={formatMessage(
        strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal.title
      )}
      dismissLabel={formatMessage(
        strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal
          .dismissLabel
      )}
      confirmLabel={formatMessage(
        strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal
          .confirmLabel
      )}
      onConfirm={() => {
        // We always pass the boolean, but check for the flag status where the boolean is implemented
        publishCalibrationViewMutation.mutate(shouldSendNotifications, {
          onSuccess: () => {
            setIsPublishSuccessModalOpen(true)
            Avo.calibrationViewPublished({ calibrationViewId })
          },
          onError: () => {
            setIsPublishErrorModalOpen(true)
          },
        })
        setIsPublishModalOpen(false)
      }}
      onDismiss={() => {
        setIsPublishModalOpen(false)
      }}
    >
      <Box mb={1}>
        <Paragraph variant="body">
          {formatMessage(
            strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal
              .paragraph1
          )}
          :
        </Paragraph>
        <Paragraph variant="body">
          <ul className={styles.publishPointsList}>
            {strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal.points.map(
              (point) => (
                <li key={`${point}`}>{formatMessage(point)}</li>
              )
            )}
          </ul>
        </Paragraph>
      </Box>
      <Box mr={0.5}>
        <CheckboxField
          labelText={formatMessage(
            strings.pages.AdminCalibrationViewShowPage.PageHeader.publishModal
              .shouldSendNotifications
          )}
          onCheck={(event) => {
            setShouldSendNotifications(event.target.checked)
          }}
          checkedStatus={shouldSendNotifications ? "on" : "off"}
        />
      </Box>
    </ConfirmationModal>
  )
}

export const PublishCalibrationModals = ({
  calibrationViewId,
  isPublishModalOpen,
  setIsPublishModalOpen,
}: {
  calibrationViewId: number
  isPublishModalOpen: boolean
  setIsPublishModalOpen: (e: boolean) => void
}) => {
  const [isPublishSuccessModalOpen, setIsPublishSuccessModalOpen] =
    React.useState(false)
  const [isPublishErrorModalOpen, setIsPublishErrorModalOpen] =
    React.useState(false)

  return (
    <>
      <PublishCalibrationModal
        calibrationViewId={calibrationViewId}
        isPublishModalOpen={isPublishModalOpen}
        setIsPublishModalOpen={setIsPublishModalOpen}
        setIsPublishSuccessModalOpen={setIsPublishSuccessModalOpen}
        setIsPublishErrorModalOpen={setIsPublishErrorModalOpen}
      />
      <SuccessModal
        isOpen={isPublishSuccessModalOpen}
        setIsOpen={setIsPublishSuccessModalOpen}
      />
      <ErrorModal
        isOpen={isPublishErrorModalOpen}
        setIsOpen={setIsPublishErrorModalOpen}
      />
    </>
  )
}
