import * as React from "react"
import strings from "@Calibrations/locale/strings"
import { useIntl } from "@Common/locale/useIntl"
import { useGetReviewResults } from "@Calibrations/v2/queries/useGetReviewResults"
import { EmptyState } from "@kaizen/draft-empty-state"
import { TableLoading } from "@Calibrations/containers/PrimitiveTable/PrimitiveTable"
import { CalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { CalibrationTablePagination } from "@Calibrations/v2/components"
import { useGetManagerReviewDetails } from "@Calibrations/v2/queries/useGetManagerReviewDetails"
import { ReadOnlyCalibrationViewTableWrapper } from "@Calibrations/v2/components/CalibrationViewTable/ReadOnlyCalibrationViewTableWrapper"

const {
  components: { CalibrationTable: ComponentStrings },
} = strings

export const CreateResults = ({
  filters,
}: {
  filters: CalibrationViewQueryParams
}) => {
  const { formatMessage } = useIntl()
  const {
    data: results,
    isLoading: isLoadingReviewResults,
    error,
  } = useGetReviewResults()
  const {
    data: managerReviewDetails,
    isLoading: isLoadingManagerReviewDetails,
  } = useGetManagerReviewDetails({
    managerReviewCycleIds: (filters.managerReviewCycleIds as string[]).map(
      (id) => Number(id)
    ),
  })
  const isLoading = isLoadingManagerReviewDetails || isLoadingReviewResults

  if (isLoading) {
    return <TableLoading />
  }

  if (error) {
    return (
      <EmptyState
        illustrationType="negative"
        headingProps={{
          variant: "heading-3",
          children: formatMessage(ComponentStrings.errorState.default.header),
        }}
        isAnimated={false}
        bodyText={formatMessage(ComponentStrings.errorState.default.body)}
      />
    )
  }

  if (results?.length === 0) {
    return (
      <EmptyState
        illustrationType="neutral"
        headingProps={{
          variant: "heading-3",
          children: formatMessage(ComponentStrings.emptyState.header),
        }}
        isAnimated={false}
        bodyText={formatMessage(ComponentStrings.emptyState.body)}
      />
    )
  }
  if (results?.length && managerReviewDetails?.length) {
    return (
      <>
        <ReadOnlyCalibrationViewTableWrapper
          managerReviewDetails={managerReviewDetails}
          managerReviews={results}
          filters={filters}
        />
        <CalibrationTablePagination />
      </>
    )
  }
  return null
}
