import * as React from "react"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { Paragraph } from "@kaizen/typography"
import {
  AssessmentGroup,
  AssessmentRating,
} from "@Calibrations/v2/queries/useGetReviewResults"

type ReadOnlyAssessmentGroupCellProps = {
  assessmentGroup: AssessmentGroup
  assessmentRating: AssessmentRating
}

export const ReadOnlyAssessmentGroupCell: React.FC<
  ReadOnlyAssessmentGroupCellProps
> = ({ assessmentGroup, assessmentRating }) => {
  const { formatMessage } = useIntl()
  const currentRating = assessmentGroup.choices.find(
    (choice) => choice.id === assessmentRating.currentRatingId
  )

  const pendingRating = assessmentGroup.choices.find(
    (choice) => choice.id === assessmentRating.pendingRatingId
  )

  if (!currentRating) {
    return <>-</>
  }

  if (pendingRating && pendingRating.id !== currentRating.id) {
    // Shared and Published calibrations
    return (
      <div className="w-full">
        <div className="justify-around flex flex-col">
          <Paragraph variant="body">{pendingRating?.title}</Paragraph>
          <Paragraph variant="small" tag={"div"}>
            <span className="opacity-70 text-purple-800">
              {formatMessage(
                strings.pages.AdminCalibrationViewShowPage.AssessmentGroupCell
                  .preCalibrationRating
              )}
            </span>{" "}
            {currentRating.title}
          </Paragraph>
        </div>
      </div>
    )
  }

  // Create/edit calibrations
  return (
    <div className="w-full">
      <Paragraph variant="body">{currentRating.title}</Paragraph>
    </div>
  )
}
