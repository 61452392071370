import * as React from "react"
import { useIntl } from "@Common/locale/useIntl"
import { UseInfiniteQueryResult } from "@tanstack/react-query"
import type { Selection } from "@kaizen/select"
import {
  createUseGetDemographicValueFilters,
  useGetFilterable,
  useCachedFilterable,
  useGetSnapshotFilterable,
  UseGetFiltersParams,
  useCachedSnapshotFilterable,
  createUseCachedDemographics,
  createUseGetSnapshotDemographicValueFilters,
  createUseSnapshotCachedDemographics,
} from "@Calibrations/v2/queries/useGetCalibrationFilters"
import {
  FilterQueryParams,
  useCalibrationViewQueryParams,
} from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { QueryKeys } from "@Calibrations/v2/queries/keys"
import strings from "@Calibrations/locale/strings"
import { CalibrationView } from "@Calibrations/v2/queries/useGetCalibrationView"
import { useShouldShowPerformanceRating } from "@Calibrations/v2/hooks/useShouldShowPerformanceRating"
import { snakeCase } from "lodash"

export type Filterable = { label: string; value: string }

type FilterType =
  | "managerReviewCycles"
  | "performanceRatings"
  | "departments"
  | "managers"
  | "groupTypes"
  | "jobTitles"
  | "levels"
  | "userStatus"
  | "reviewStatus"
  | "moreFilters"
  | "demographics"

export type Filter = {
  /** An identifier for the types of filters */
  id: FilterType
  /** The url to be passed to the getFilterable function */
  url: string
  /** The queryKey to be used in the cache */
  queryKey: string
  /** The label rendered in the UI for the filter */
  label: string
  /** An optional override for the trigger label items */
  triggerLabelItems?: string[]
  /** InfiniteQuery hook to fetch Filterable resources */
  useFetch: (
    params: UseGetFiltersParams
  ) => UseInfiniteQueryResult<Filterable, unknown>
  /** Hook for reading Filterable resources from the cache */
  useCache: (queryKey: string) => Filterable[]
  /** Callback passed to the FilterMultiSelect for values changing */
  onChange: (newValues: Selection) => void
  /** The currently selected values */
  values: string[]
  /** The initial values to hydrate on first render */
  initialValues?: string[]
  /** Selected managerReviewCycleIds */
  managerReviewCycleIds?: string[]
} & (RemovableFilter | NonRemovableFilter)

type RemovableFilter = {
  /** Optional demographic for specifying demographic attribute type */
  demographic: string
  /** Callback passed to the FilterMultiSelect for cleaning up values on remove button pressed */
  onRemove: () => void
}

type NonRemovableFilter = {
  demographic?: never
  onRemove?: never
}

const {
  components: {
    CalibrationFilters: { filterLabels },
  },
} = strings

/* We need to set the query param to undefined if there aren't any values
 * to be sure we're removing the empty item from the URL */
function useApplyFilters() {
  const [, setQueryParams] = useCalibrationViewQueryParams()

  function applyFilter({ key, value }: { key: string; value: Selection }) {
    // We want to reset the pagination whenever a filter is applied
    setQueryParams({ page: undefined, perPage: undefined })

    // Remove the filter key from the URL if no filter exists for it
    if (Array.from(value).length < 1) {
      return setQueryParams({ [key]: undefined })
    }

    return setQueryParams({
      [key]: Array.from(value) as string[],
    })
  }

  return { applyFilter }
}

function useClearFilters() {
  const [, setQueryParams] = useCalibrationViewQueryParams()
  const clearFilters = React.useCallback(() => {
    const params = Object.keys(FilterQueryParams).reduce((obj, key) => {
      return { ...obj, [key]: undefined }
    }, {})
    setQueryParams(params)
  }, [setQueryParams])

  return { clearFilters }
}

export const useSnapshotCalibrationViewFilters = (
  calibrationView: CalibrationView
): {
  currentFilters: Filter[]
  clearFilters: () => void
} => {
  const { applyFilter } = useApplyFilters()
  const [, setQueryParams] = useCalibrationViewQueryParams()
  const { clearFilters } = useClearFilters()
  const filterValues = useFilterValues()
  const { formatMessage } = useIntl()
  const shouldShowPerformanceRatingFilter = useShouldShowPerformanceRating(
    filterValues.managerReviewCycleIds,
    calibrationView
  )
  // Store the initial items in a ref
  const initialItems = React.useRef(filterValues)

  const hasGroupType = filterValues.groupTypes.length > 0

  const currentFilters: Filter[] = React.useMemo(() => {
    const shouldShowGroupTypes =
      filterValues.managerIds && filterValues.managerIds.length > 0

    return [
      {
        id: "managerReviewCycles",
        label: formatMessage(filterLabels.managerReviewCycles),
        url: "/dashboard/calibrations/:calibrationId/snapshot_manager_review_cycles",
        queryKey: QueryKeys.AdminFilters.Snapshot.ManagerReviewCycles,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) => {
          clearFilters()
          return applyFilter({
            key: "managerReviewCycleIds",
            value: newValues,
          })
        },
        values: filterValues.managerReviewCycleIds,
        initialValues: initialItems.current.managerReviewCycleIds,
      },
      ...(shouldShowPerformanceRatingFilter
        ? [
            {
              id: "performanceRatings" as const,
              label: "Performance ratings",
              url: "/dashboard/calibrations/:calibrationId/snapshot_performance_ratings",
              queryKey: QueryKeys.AdminFilters.Snapshot.PerformanceRatings,
              useFetch: useGetSnapshotFilterable,
              useCache: useCachedSnapshotFilterable,
              onChange: (newValues: Selection) => {
                applyFilter({
                  key: "performanceBucketInCycleIds",
                  value: newValues,
                })
              },
              values: filterValues.performanceBucketInCycleIds,
              initialValues: initialItems.current.performanceBucketInCycleIds,
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
            },
          ]
        : []),
      {
        id: "departments",
        label: formatMessage(filterLabels.departments),
        url: "/dashboard/calibrations/:calibrationId/snapshot_departments",
        queryKey: QueryKeys.AdminFilters.Snapshot.Departments,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) =>
          applyFilter({ key: "departmentIds", value: newValues }),
        values: filterValues.departmentIds,
        initialValues: initialItems.current.departmentIds,
        managerReviewCycleIds: filterValues.managerReviewCycleIds,
      },
      {
        id: "managers",
        label: formatMessage(filterLabels.managers),
        url: "/dashboard/calibrations/:calibrationId/snapshot_managers",
        queryKey: QueryKeys.AdminFilters.Snapshot.Managers,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) => {
          applyFilter({ key: "managerIds", value: newValues })
          // Default the Groups filter to "Directs" (direct reports) when a manager is selected
          // if no groups are currently selected
          !hasGroupType &&
            applyFilter({
              key: "groupTypes",
              value: new Set<string>(["directs"]),
            })
        },
        values: filterValues.managerIds,
        initialValues: initialItems.current.managerIds,
        managerReviewCycleIds: filterValues.managerReviewCycleIds,
      },
      ...(shouldShowGroupTypes
        ? [
            {
              id: "groupTypes" as const,
              label: formatMessage(filterLabels.groupTypes),
              url: "group_type",
              queryKey: QueryKeys.AdminFilters.Snapshot.GroupTypes,
              useFetch: useGetSnapshotFilterable,
              useCache: useCachedSnapshotFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "groupTypes", value: newValues }),
              values: filterValues.groupTypes,
              initialValues: initialItems.current.groupTypes,
            },
          ]
        : []),
      {
        id: "jobTitles",
        label: formatMessage(filterLabels.jobTitles),
        url: "/dashboard/calibrations/:calibrationId/snapshot_job_titles",
        queryKey: QueryKeys.AdminFilters.Snapshot.JobTitles,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) =>
          applyFilter({ key: "jobTitleIds", value: newValues }),
        values: filterValues.jobTitleIds,
        initialValues: initialItems.current.jobTitleIds,
        managerReviewCycleIds: filterValues.managerReviewCycleIds,
      },
      {
        id: "levels" as const,
        label: formatMessage(filterLabels.levels),
        url: "/dashboard/calibrations/:calibrationId/snapshot_levels",
        queryKey: QueryKeys.AdminFilters.Snapshot.Levels,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) =>
          applyFilter({ key: "levels", value: newValues }),
        values: filterValues.levels,
        initialValues: initialItems.current.levels,
        managerReviewCycleIds: filterValues.managerReviewCycleIds,
      },
      {
        id: "userStatus",
        label: formatMessage(filterLabels.userStatus),
        url: "user_status",
        queryKey: QueryKeys.AdminFilters.Snapshot.UserStatus,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) =>
          applyFilter({ key: "userStatus", value: newValues }),
        values: filterValues.userStatus,
        initialValues: initialItems.current.userStatus,
      },
      {
        id: "reviewStatus",
        label: formatMessage(filterLabels.reviewStatus),
        url: "review_status",
        queryKey: QueryKeys.AdminFilters.Snapshot.ReviewStatus,
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) =>
          applyFilter({ key: "reviewStatus", value: newValues }),
        values: filterValues.reviewStatus,
        initialValues: initialItems.current.reviewStatus,
      },

      ...filterValues.demographicOptions.map(([demographic, values]) => ({
        id: "demographics" as const,
        demographic: demographic,
        url: "/dashboard/calibrations/:calibrationId/snapshot_demographic_values",
        queryKey: QueryKeys.AdminFilters.DemographicValues,
        label: capitaliseFirstLetter(demographic.split("_").join(" ")),
        useFetch: createUseGetSnapshotDemographicValueFilters(demographic),
        useCache: createUseSnapshotCachedDemographics(demographic),
        onChange: (newValues: Selection) => {
          const newDemographics = {
            ...filterValues.demographics,
            [demographic]: Array.from(newValues),
          }

          setQueryParams({ demographics: newDemographics })
        },
        onRemove: () => {
          setQueryParams((params) => {
            const newDemographics = params.demographics ?? {}
            delete newDemographics[demographic]
            return {
              demographics: newDemographics,
            }
          })
        },
        values: (values as string[]) ?? [],
        initialValues:
          (initialItems.current.demographics[demographic] as string[]) ??
          undefined,
      })),
      {
        id: "moreFilters",
        label: formatMessage(filterLabels.moreFilters),
        url: "/dashboard/calibrations/additional_mapped_demographics",
        queryKey: QueryKeys.AdminFilters.More,
        triggerLabelItems: [],
        useFetch: useGetSnapshotFilterable,
        useCache: useCachedSnapshotFilterable,
        onChange: (newValues: Selection) => {
          const newDemographics = Array.from(newValues).reduce(
            (acc, value) => {
              return {
                ...acc,
                [value]: filterValues.demographics?.[value] ?? [],
              }
            },
            {} as { [key: string]: string[] }
          )
          setQueryParams({ demographics: newDemographics })
        },
        values: filterValues.demographicOptions.map(([key]) => key),
        // We do not render initial values in the dropdown label, nor can the endpoint
        // accept initial values (as the response is { id: null, attribute_type: string })
        // so we always have initialValues == undefined for the moreFilters
      },
    ]
  }, [
    shouldShowPerformanceRatingFilter,
    filterValues.managerIds,
    filterValues.managerReviewCycleIds,
    filterValues.performanceBucketInCycleIds,
    filterValues.departmentIds,
    filterValues.groupTypes,
    filterValues.jobTitleIds,
    filterValues.levels,
    filterValues.userStatus,
    filterValues.reviewStatus,
    filterValues.demographicOptions,
    filterValues.demographics,
    formatMessage,
    clearFilters,
    applyFilter,
    hasGroupType,
    setQueryParams,
  ])
  return { currentFilters, clearFilters }
}

export const useCalibrationViewFilters = (): {
  currentFilters: Filter[]
  clearFilters: () => void
} => {
  const [, setQueryParams] = useCalibrationViewQueryParams()
  const filterValues = useFilterValues()
  const { clearFilters } = useClearFilters()
  const { applyFilter } = useApplyFilters()

  // Store the initial items in a ref
  const initialItems = React.useRef(filterValues)
  const { formatMessage } = useIntl()
  const shouldShowPerformanceRatingFilter = useShouldShowPerformanceRating(
    filterValues.managerReviewCycleIds
  )

  const hasGroupType = filterValues.groupTypes.length > 0

  const currentFilters: Filter[] = React.useMemo(() => {
    const shouldShowFilters = filterValues.managerReviewCycleIds.length > 0
    const shouldShowGroupTypes =
      filterValues.managerIds && filterValues.managerIds.length > 0

    return [
      {
        id: "managerReviewCycles",
        label: formatMessage(filterLabels.managerReviewCycles),
        url: "/dashboard/calibration/manager_review_cycles",
        queryKey: QueryKeys.AdminFilters.ManagerReviewCycles,
        useFetch: useGetFilterable,
        useCache: useCachedFilterable,
        onChange: (newValues: Selection) => {
          clearFilters()
          return applyFilter({ key: "managerReviewCycleIds", value: newValues })
        },
        values: filterValues.managerReviewCycleIds,
        initialValues: initialItems.current.managerReviewCycleIds,
        managerReviewCycleIds: filterValues.managerReviewCycleIds,
      },
      ...(shouldShowFilters
        ? [
            ...(shouldShowPerformanceRatingFilter
              ? [
                  {
                    id: "performanceRatings" as const,
                    label: "Performance ratings",
                    url: "/dashboard/calibration/performance_ratings",
                    queryKey: QueryKeys.AdminFilters.PerformanceRatings,
                    useFetch: useGetFilterable,
                    useCache: useCachedFilterable,
                    onChange: (newValues: Selection) => {
                      applyFilter({
                        key: "performanceBucketInCycleIds",
                        value: newValues,
                      })
                    },
                    values: filterValues.performanceBucketInCycleIds,
                    initialValues:
                      initialItems.current.performanceBucketInCycleIds,
                    managerReviewCycleIds: filterValues.managerReviewCycleIds,
                  },
                ]
              : []),
            {
              id: "departments" as const,
              label: formatMessage(filterLabels.departments),
              url: "/dashboard/calibrations/departments",
              queryKey: QueryKeys.AdminFilters.Departments,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "departmentIds", value: newValues }),
              values: filterValues.departmentIds,
              initialValues: initialItems.current.departmentIds,
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
            },
            {
              id: "managers" as const,
              label: formatMessage(filterLabels.managers),
              url: "/dashboard/calibrations/managers",
              queryKey: QueryKeys.AdminFilters.Managers,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) => {
                applyFilter({ key: "managerIds", value: newValues })
                // Default the Groups filter to "Directs" (direct reports) when a manager is selected
                // if no groups are currently selected
                !hasGroupType &&
                  applyFilter({
                    key: "groupTypes",
                    value: new Set<string>(["directs"]),
                  })
              },
              values: filterValues.managerIds,
              initialValues: initialItems.current.managerIds,
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
            },
            ...(shouldShowGroupTypes
              ? [
                  {
                    id: "groupTypes" as const,
                    label: formatMessage(filterLabels.groupTypes),
                    url: "group_type",
                    queryKey: QueryKeys.AdminFilters.GroupTypes,
                    useFetch: useGetFilterable,
                    useCache: useCachedFilterable,
                    onChange: (newValues: Selection) =>
                      applyFilter({ key: "groupTypes", value: newValues }),
                    values: filterValues.groupTypes,
                    initialValues: initialItems.current.groupTypes,
                  },
                ]
              : []),
            {
              id: "jobTitles" as const,
              label: formatMessage(filterLabels.jobTitles),
              url: "/dashboard/calibrations/job_titles",
              queryKey: QueryKeys.AdminFilters.JobTitles,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "jobTitleIds", value: newValues }),

              values: filterValues.jobTitleIds,
              initialValues: initialItems.current.jobTitleIds,
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
            },
            {
              id: "levels" as const,
              label: formatMessage(filterLabels.levels),
              url: "/dashboard/calibrations/levels",
              queryKey: QueryKeys.AdminFilters.Levels,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "levels", value: newValues }),
              values: filterValues.levels,
              initialValues: initialItems.current.levels,
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
            },
            {
              id: "userStatus" as const,
              label: formatMessage(filterLabels.userStatus),
              url: "user_status",
              queryKey: QueryKeys.AdminFilters.UserStatus,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "userStatus", value: newValues }),
              values: filterValues.userStatus,
              initialValues: initialItems.current.userStatus,
            },
            {
              id: "reviewStatus" as const,
              label: formatMessage(filterLabels.reviewStatus),
              url: "review_status",
              queryKey: QueryKeys.AdminFilters.ReviewStatus,
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) =>
                applyFilter({ key: "reviewStatus", value: newValues }),
              values: filterValues.reviewStatus,
              initialValues: initialItems.current.reviewStatus,
            },
            ...filterValues.demographicOptions.map(([demographic, values]) => ({
              id: "demographics" as const,
              demographic: demographic,
              url: "/dashboard/calibrations/demographic_values",
              queryKey: QueryKeys.AdminFilters.DemographicValues,
              label: capitaliseFirstLetter(
                snakeCase(demographic).split("_").join(" ")
              ),
              managerReviewCycleIds: filterValues.managerReviewCycleIds,
              useFetch: createUseGetDemographicValueFilters(
                snakeCase(demographic)
              ),
              useCache: createUseCachedDemographics(snakeCase(demographic)),
              onChange: (newValues: Selection) => {
                const newDemographics = {
                  ...filterValues.demographics,
                  [demographic]: Array.from(newValues),
                }
                setQueryParams({
                  demographics: newDemographics,
                })
              },
              onRemove: () => {
                setQueryParams((params) => {
                  const newDemographics = params.demographics ?? {}
                  delete newDemographics[demographic]
                  return {
                    demographics: newDemographics,
                  }
                })
              },
              values: (values as string[]) ?? [],
              initialValues:
                (initialItems.current.demographics[demographic] as string[]) ??
                undefined,
            })),
            {
              id: "moreFilters" as const,
              label: formatMessage(filterLabels.moreFilters),
              url: "/dashboard/calibrations/additional_mapped_demographics",
              queryKey: QueryKeys.AdminFilters.More,
              triggerLabelItems: [],
              useFetch: useGetFilterable,
              useCache: useCachedFilterable,
              onChange: (newValues: Selection) => {
                const newDemographics = Array.from(newValues).reduce(
                  (acc, value) => {
                    return {
                      ...acc,
                      [value]: filterValues.demographics?.[value] ?? [],
                    }
                  },
                  {} as { [key: string]: string[] }
                )
                setQueryParams({ demographics: newDemographics })
              },
              values: filterValues.demographicOptions.map(([key]) => key),
              // We do not render initial values in the dropdown label, nor can the endpoint
              // accept initial values (as the response is { id: null, attribute_type: string })
              // so we always have initialValues == undefined for the moreFilters
            },
          ]
        : []),
    ]
  }, [
    filterValues.managerReviewCycleIds,
    filterValues.managerIds,
    filterValues.performanceBucketInCycleIds,
    filterValues.departmentIds,
    filterValues.groupTypes,
    filterValues.jobTitleIds,
    filterValues.levels,
    filterValues.userStatus,
    filterValues.reviewStatus,
    filterValues.demographicOptions,
    filterValues.demographics,
    formatMessage,
    clearFilters,
    applyFilter,
    hasGroupType,
    setQueryParams,
    shouldShowPerformanceRatingFilter,
  ])
  return { currentFilters, clearFilters }
}

const useFilterValues = () => {
  const [queryParams] = useCalibrationViewQueryParams()
  const managerReviewCycleIds = React.useMemo(
    () => queryParams.managerReviewCycleIds?.filter(removeNulls) ?? [],
    [queryParams.managerReviewCycleIds]
  )
  const performanceBucketInCycleIds = React.useMemo(
    () => queryParams.performanceBucketInCycleIds?.filter(removeNulls) ?? [],
    [queryParams.performanceBucketInCycleIds]
  )
  const departmentIds = React.useMemo(
    () => queryParams.departmentIds?.filter(removeNulls) ?? [],
    [queryParams.departmentIds]
  )
  const managerIds = React.useMemo(
    () => queryParams.managerIds?.filter(removeNulls) ?? [],
    [queryParams.managerIds]
  )
  const groupTypes = React.useMemo(
    () => queryParams.groupTypes?.filter(removeNulls) ?? [],
    [queryParams.groupTypes]
  )
  const jobTitleIds = React.useMemo(
    () => queryParams.jobTitleIds?.filter(removeNulls) ?? [],
    [queryParams.jobTitleIds]
  )
  const levels = React.useMemo(
    () => queryParams.levels?.filter(removeNulls) ?? [],
    [queryParams.levels]
  )
  const userStatus = React.useMemo(
    () => queryParams.userStatus?.filter(removeNulls) ?? [],
    [queryParams.userStatus]
  )
  const userIds = React.useMemo(
    () => queryParams.userIds ?? {},
    [queryParams.userIds]
  )
  const reviewStatus = React.useMemo(
    () => queryParams.reviewStatus?.filter(removeNulls) ?? [],
    [queryParams.reviewStatus]
  )
  const demographics = React.useMemo(
    () => queryParams.demographics ?? {},
    [queryParams.demographics]
  )
  const demographicOptions = React.useMemo(
    () => Object.entries(demographics),
    [demographics]
  )

  return {
    demographicOptions,
    demographics,
    departmentIds,
    groupTypes,
    jobTitleIds,
    levels,
    managerIds,
    managerReviewCycleIds,
    performanceBucketInCycleIds,
    userStatus,
    userIds,
    reviewStatus,
  }
}

const removeNulls = (element: string | null): element is string => {
  return element !== null
}
const capitaliseFirstLetter = (str: string) =>
  [...str][0].toUpperCase() + str.slice(1)
