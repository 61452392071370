import React, { FC, useCallback } from "react"
import { useLocalStorage } from "@Calibrations/hooks/useLocalStorage"
import { TableInstance } from "react-table"
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@Calibrations/containers/PrimitiveTable/PrimitiveTable"
import cx from "classnames"
import { Icon } from "@kaizen/component-library"
import { TruncatableText } from "@Calibrations/components/TruncatableText/TruncatableText"
import sortDescending from "@kaizen/component-library/icons/sort-descending.icon.svg"
import sortAscending from "@kaizen/component-library/icons/sort-ascending.icon.svg"
import {
  CalibrationColumn,
  CalibrationColumnInstance,
  CalibrationHeaderGroup,
} from "@Calibrations/v2/types/Tables"
import { ReviewResult } from "@Calibrations/v2/queries/useGetReviewResults"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { Card } from "@kaizen/draft-card"
import styles from "./CalibrationViewTable.scss"

const getAriaSortForColumn = (
  column: CalibrationColumnInstance
): "ascending" | "descending" | "none" => {
  if (!column.canSort || !column.isSorted) return "none"
  return column.isSortedDesc ? "descending" : "ascending"
}

type CalibrationViewTableProps = {
  tableInstance: TableInstance<ReviewResult>
}

export const CalibrationViewTable: FC<CalibrationViewTableProps> = ({
  tableInstance,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const [queryParams, setQueryParams] = useCalibrationViewQueryParams()
  const [shouldDisplayFullWidth] = useLocalStorage(
    "calibrations.fullWidthState",
    false
  )

  const onHeaderClick = useCallback(
    (column: CalibrationColumnInstance) => {
      // If false, we are currently 'asc' and sorting this column. Set to 'desc'
      // if we are descending, we are currently sorting this column. Set to 'asc'
      // Alternatively, if undefined, we are currently not sorting this column. Set to 'asc'
      // This is why we need `=== false`
      const sortOrder = column.isSortedDesc === false ? "desc" : "asc"
      const sortBy = column.id

      if (column?.isQuestion) {
        setQueryParams({
          sortBy: "performanceAnswer",
          sortOrder,
          questionId: Number(sortBy),
        })
      } else {
        setQueryParams({ sortBy, sortOrder })
      }

      column.toggleSortBy && column.toggleSortBy()
    },
    [setQueryParams]
  )

  return (
    <Card
      classNameOverride={cx(styles.tableContainer, {
        [styles.tableContainerFullWidth]: shouldDisplayFullWidth,
      })}
    >
      <Table
        {...getTableProps({
          className: "w-full",
        })}
        data-automation-id="calibration-table"
      >
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: CalibrationHeaderGroup, i) => {
                const headerCustomProps = {
                  className: cx({
                    [styles.stickyLeft]: column.isStickyLeft,
                    [styles.flagTableCell]: column.id === "flag",
                    [styles.employeeTableHeaderCell]: column.id === "name",
                    [styles.extraPaddingLeft]: i === 2,
                    [styles.canSort]: column.canSort,
                  }),
                  style: { left: `${column.stickLeft}px` },
                }
                return (
                  <Th
                    {...column.getHeaderProps(headerCustomProps)}
                    onClick={() => column.canSort && onHeaderClick(column)}
                    data-automation-id={`calibration-table-header-${column.id}`}
                    aria-sort={getAriaSortForColumn(column)}
                  >
                    <div className={styles.headerCellContentWrapper}>
                      <TruncatableText>
                        {column.render("Header")}
                      </TruncatableText>
                      {column.canSort ? (
                        <div
                          className={cx(styles.sortIcon, {
                            [styles["sortIcon--sorted"]]: column.isSorted,
                          })}
                        >
                          <Icon
                            icon={
                              column.isSortedDesc
                                ? sortDescending
                                : sortAscending
                            }
                            role="presentation"
                          />
                        </div>
                      ) : null}
                    </div>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  const column = cell.column as CalibrationColumn
                  const isCellHighlighted = [
                    queryParams.dossierNotesReviewId,
                    queryParams.dossierEmployeeId,
                  ].includes(row.original.managerReview.id)
                  const cellCustomProps = {
                    className: cx(
                      {
                        [styles.stickyLeft]: column.isStickyLeft,
                        [styles.employeeTableCell]: column.id === "name",
                        [styles.flagTableCell]: column.id === "flag",
                        // Add an extra padding to the column after the 2 columns that are fixed to the left
                        [styles.extraPaddingLeft]: i === 2,
                        [styles.cellHighlighted]: isCellHighlighted,
                      },
                      styles.cell
                    ),
                    style: { left: `${column.stickLeft}px` },
                  }
                  return (
                    <Td {...cell.getCellProps(cellCustomProps)}>
                      {cell.render("Cell")}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Card>
  )
}

export default CalibrationViewTable
