import * as React from "react"
import { useIntl } from "@Common/locale/useIntl"
import { Card } from "@kaizen/draft-card"
import {
  useGetSnapshotHeroStats,
  useGetHeroStats,
  useGetNonAdminHeroStats,
} from "@Calibrations/v2/queries/useGetHeroStats"
import { CalibrationView } from "@Calibrations/v2/queries/useGetCalibrationView"
import strings from "@Calibrations/locale/strings"
import { CalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { Tabs, TabList, TabPanels, TabPanel, Tab } from "@kaizen/tabs"
import {
  AssessmentGroupsStatsRow,
  AssessmentGroupsTab,
  Loading,
  StatusStatsRow,
} from "./components"

type HeroStatsProps = {
  isFetching: boolean
  data:
    | ReturnType<typeof useGetSnapshotHeroStats>["data"]
    | ReturnType<typeof useGetHeroStats>["data"]
}
const HeroStats = ({ data, isFetching }: HeroStatsProps) => {
  const { formatMessage } = useIntl()

  if (isFetching || !data) {
    return <Loading />
  }

  return (
    <Card>
      <Tabs>
        <TabList
          aria-label={formatMessage(
            strings.components.CalibrationHeroStats.description
          )}
        >
          <div className="flex">
            <AssessmentGroupsTab assessmentGroups={data.assessmentGroups} />
            <Tab>
              {formatMessage(strings.components.CalibrationHeroStats.status)}
            </Tab>
          </div>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AssessmentGroupsStatsRow
              data={data.assessmentGroups ? data.assessmentGroups : []}
            />
          </TabPanel>
          <TabPanel>
            <StatusStatsRow data={data.managerReviewStatuses} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  )
}

export const SnapshotCalibrationHeroStats = ({
  calibrationView,
  filters,
}: {
  calibrationView?: CalibrationView
  filters: CalibrationViewQueryParams
}) => {
  const { data, isFetching } = useGetSnapshotHeroStats({
    calibrationViewId: calibrationView ? Number(calibrationView.id) : undefined,
    filters,
  })
  return <HeroStats data={data} isFetching={isFetching} />
}

export const CalibrationHeroStats = ({
  filters,
}: {
  calibrationView?: CalibrationView
  filters: CalibrationViewQueryParams
}) => {
  const { data, isFetching } = useGetHeroStats({
    filters,
  })

  return <HeroStats data={data} isFetching={isFetching} />
}

export const NonAdminCalibrationHeroStats = ({
  calibrationView,
}: {
  calibrationView: CalibrationView
}) => {
  const { data, isFetching } = useGetNonAdminHeroStats({
    calibrationViewId: calibrationView.id,
  })

  return <HeroStats data={data} isFetching={isFetching} />
}
