import { useQuery } from "@tanstack/react-query"
import { GoalForTree } from "@Goals/types"
import { getGoalForTree } from "@Goals/api"

export const buildPath = (goalId: string) => `/goals/tree/${goalId}/incoming`
export const queryGroup = "goals.tree.incoming"

export const useGoalForTree = (goalId: string) => {
  return useQuery<
    GoalForTree[],
    ReturnType<typeof Error>,
    GoalForTree[],
    string[]
  >([buildPath(goalId)], () => getGoalForTree(goalId), {
    enabled: !!goalId,
    meta: { group: queryGroup },
    /*
     * The two attribute below make the query only refetch when it is
     * intetionally invalidated. refetchOnMount is set to true, it means it
     * would refetch on mount if the data is stale, but staleTime is set to
     * infinity, so unless we set the query as invalid, it wont reftech.
     */
    refetchOnMount: true,
    staleTime: Infinity,
  })
}
