import * as React from "react"
import ErrorPage from "./ErrorPage"

export const ErrorPage404 = () => {
  return (
    <ErrorPage
      code={404}
      title="404 Page Not Found"
      message={
        <>
          Hmm, that&apos;s strange. We can&apos;t seem to find the page
          you&apos;re looking for either.
        </>
      }
      includeCTA
    />
  )
}

export default ErrorPage404
