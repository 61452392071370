import * as React from "react"
import { Tooltip } from "@Components/Tooltip"
import styles from "./TruncatableText.scss"

export const TruncatableText: React.FC = ({ children }) => {
  return (
    <Tooltip text={children} classNameOverride={styles.truncatedText}>
      {children}
    </Tooltip>
  )
}
