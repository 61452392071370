import React from "react"
import { PrivateRoute, ValidatedRoute } from "@Common/routing"
import { Switch } from "react-router-dom"
import { path } from "static-path"

const basePath = path("/feedback")
const feedbackRequests = basePath.path(
  "/requests/:evaluationCycleId/:subjectId"
)
const peerNominations = basePath.path(
  "/peer/nominations/:evaluationCycleId/:peerFeedbackCycleId"
)
const peerFeedbackResponse = basePath.path("/:peerFeedbackCycleId/:responseId")

export const paths = { feedbackRequests, peerFeedbackResponse, peerNominations }

const FeedbackApp = React.lazy(
  () => import(/* webpackChunkName: "Feedback" */ "@Feedback/index")
)
const FeedbackResponse = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackResponse" */ "@Feedback/individual/pages/FeedbackResponse"
    )
)
const FeedbackRequests = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackRequests" */ "@Feedback/manager/pages/RequestFeedback"
    )
)
const FeedbackNominations = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackNominations" */ "@Feedback/individual/pages/FeedbackNominations"
    )
)

export const FeedbackRoutes = (
  <PrivateRoute path={basePath.pattern}>
    <FeedbackApp>
      <Switch>
        <PrivateRoute
          path={feedbackRequests.pattern}
          authorisedRoles={["admin", "manager"]}
        >
          <ValidatedRoute
            exact
            path={feedbackRequests.pattern}
            component={FeedbackRequests}
            validators={{ evaluationCycleId: /^\d+$/, subjectId: /^\d+$/ }}
          />
        </PrivateRoute>
        <ValidatedRoute
          exact
          path={peerNominations.pattern}
          component={FeedbackNominations}
          validators={{
            evaluationCycleId: /^\d+$/,
            peerFeedbackCycleId: /^\d+$/,
          }}
        />
        <ValidatedRoute
          exact
          path={peerFeedbackResponse.pattern}
          component={FeedbackResponse}
          validators={{ peerFeedbackCycleId: /^\d+$/, responseId: /^\d+$/ }}
        />
      </Switch>
    </FeedbackApp>
  </PrivateRoute>
)
