import { UserSearch } from "@Calibrations/v2/components/UserSearch/UserSearch"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { fetchCalibrationUsers } from "@Calibrations/v2/queries/useFetchUsers"
import React from "react"
import { MessageDescriptor } from "react-intl"

export const UserSearchWrapper = ({
  placeholder,
}: {
  placeholder: MessageDescriptor
}) => {
  const [queryParams, setQueryParams] = useCalibrationViewQueryParams()

  const handleFetchUsers = async ({
    searchString,
  }: {
    searchString?: string
  }) =>
    fetchCalibrationUsers({
      searchString,
      managerReviewCycleIds: queryParams.managerReviewCycleIds as string[],
    })

  // pre-select users present in query params
  const handleFetchInitialUsers = async () =>
    queryParams?.userIds && queryParams.userIds.length
      ? fetchCalibrationUsers({
          userIds: queryParams.userIds,
          managerReviewCycleIds: queryParams.managerReviewCycleIds as string[],
        })
      : []

  const handleUserSelection = (userIds: number[]) => {
    setQueryParams({ userIds: userIds.length ? userIds : undefined })
  }

  return (
    <UserSearch
      iconSize="xsmall"
      fetchUsers={handleFetchUsers}
      fetchInitialUsers={handleFetchInitialUsers}
      onChange={handleUserSelection}
      placeholder={placeholder}
    />
  )
}
