import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { perfApiGet } from "@API/utils"
import { Calibrations } from "bff/upstreams"
import { components } from "bff/upstreams/calibrations/calibrations.types"
import { Filterable } from "../components/CalibrationViewFilters/useCalibrationViewFilters"

type FilterItems = Calibrations["filters.FilterItems"]

type FilterableParams = {
  query: string
  page: number
  perPage: number
  initialIds?: string[]
  attribute?: string
  managerReviewCycleIds?: string[]
}

export type UseGetFiltersParams = {
  url: string
  queryKey: string
  enabled: boolean
  query: string
  initialIds?: string[]
  attribute?: string
  managerReviewCycleIds?: string[]
}
const convertQueryDataToFilterItem = (
  filterItem:
    | components["schemas"]["filters.FilterItem"]
    | components["schemas"]["filters.FilterPerformanceRatingItem"]
): Filterable => {
  if ("id" in filterItem) {
    return { label: filterItem.label, value: filterItem.id.toString() }
  } else {
    return { label: filterItem.label, value: filterItem.ids.toString() }
  }
}
const fetchSnapshotFilterable = async (
  url: string,
  calibrationViewId: number,
  params: FilterableParams
) => {
  const lastUrlSegment = url.split("/").pop()

  if (url === "user_status") {
    return fetchUserStatuses(params.query)
  }

  if (url === "review_status") {
    return fetchReviewStatuses(params.query)
  }

  if (url === "group_type") {
    return fetchGroupTypes(params.query)
  }

  const initialIds =
    lastUrlSegment == "snapshot_manager_review_cycles"
      ? { managerReviewCycleIds: params.initialIds?.join(",") }
      : lastUrlSegment == "snapshot_departments"
      ? { departmentIds: params.initialIds?.join(",") }
      : lastUrlSegment == "snapshot_managers"
      ? { managerIds: params.initialIds?.join(",") }
      : lastUrlSegment == "snapshot_job_titles"
      ? { jobTitleIds: params.initialIds?.join(",") }
      : lastUrlSegment == "snapshot_levels"
      ? { levels: params.initialIds?.join(",") }
      : lastUrlSegment == "additional_mapped_demographics"
      ? { attributeTypes: params.initialIds?.join(",") }
      : lastUrlSegment == "demographic_values"
      ? { demographicValueIds: params.initialIds?.join(",") }
      : {}

  const res = await perfApiGet(url, {
    params: {
      calibrationId: calibrationViewId,
      ...(params.managerReviewCycleIds &&
        params.managerReviewCycleIds?.length > 0 && {
          managerReviewCycleIds: params.managerReviewCycleIds?.join(","),
        }),
      attributeType: params.attribute,
      ...(params.query &&
        params.query.length > 0 && { searchString: params.query }),
      page: params.page,
      perPage: params.perPage,
      ...initialIds,
    },
  })

  return res.data as FilterItems
}

export const useGetSnapshotFilterable = ({
  url,
  queryKey,
  enabled,
  query,
  initialIds,
  attribute,
  managerReviewCycleIds,
}: UseGetFiltersParams) => {
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()

  return useInfiniteQuery(
    [queryKey, calibrationViewId, attribute, query, initialIds],
    (context) =>
      fetchSnapshotFilterable(url, Number(calibrationViewId), {
        query,
        page: context.pageParam,
        perPage: 5,
        initialIds,
        attribute,
        managerReviewCycleIds,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.meta.pagination.nextPage,
      enabled,
      select: (data) => ({
        pages: data.pages
          .flatMap((p) => p.filterItems)
          .map((d) => convertQueryDataToFilterItem(d)),
        pageParams: data.pageParams,
      }),
    }
  )
}

export const useCachedSnapshotFilterable = (
  queryKey: string,
  attribute?: string
) => {
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()
  const queryClient = useQueryClient()
  const filterItems = queryClient
    .getQueriesData<{ pages: { filterItems: FilterItems["filterItems"] } }>([
      queryKey,
      calibrationViewId,
      attribute,
    ])
    .flatMap(([, data]) => data?.pages ?? [])
    .flatMap((page) => page.filterItems)
    .map((filterItem) => convertQueryDataToFilterItem(filterItem))
  return filterItems
}

const fetchFilterable = async (url: string, params: FilterableParams) => {
  const lastUrlSegment = url.split("/").pop()

  if (url === "user_status") {
    return fetchUserStatuses(params.query)
  }

  if (url === "review_status") {
    return fetchReviewStatuses(params.query)
  }

  if (url === "group_type") {
    return fetchGroupTypes(params.query)
  }

  const initialIds =
    lastUrlSegment == "manager_review_cycles"
      ? { managerReviewCycleIds: params.initialIds?.join(",") }
      : lastUrlSegment == "departments"
      ? { departmentIds: params.initialIds?.join(",") }
      : lastUrlSegment == "managers"
      ? { managerIds: params.initialIds?.join(",") }
      : lastUrlSegment == "job_titles"
      ? { jobTitleIds: params.initialIds?.join(",") }
      : lastUrlSegment == "levels"
      ? { levels: params.initialIds?.join(",") }
      : lastUrlSegment == "additional_mapped_demographics"
      ? { attributeTypes: params.initialIds?.join(",") }
      : lastUrlSegment == "demographic_values"
      ? { demographicValueIds: params.initialIds?.join(",") }
      : {}

  const res = await perfApiGet(url, {
    params: {
      ...(params.managerReviewCycleIds &&
        params.managerReviewCycleIds?.length > 0 && {
          managerReviewCycleIds: params.managerReviewCycleIds?.join(","),
        }),
      attributeType: params.attribute,
      ...(params.query &&
        params.query.length > 0 && { searchString: params.query }),
      page: params.page,
      perPage: params.perPage,
      ...initialIds,
    },
  })

  return res.data as FilterItems
}

export const useGetFilterable = ({
  url,
  queryKey,
  enabled,
  query,
  initialIds,
  attribute,
  managerReviewCycleIds,
}: UseGetFiltersParams) => {
  return useInfiniteQuery(
    [queryKey, attribute, query, initialIds],
    (context) =>
      fetchFilterable(url, {
        query,
        page: context.pageParam,
        perPage: 5,
        initialIds,
        attribute,
        managerReviewCycleIds,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.meta.pagination.nextPage,
      enabled,
      select: (data) => ({
        pages: data.pages
          .flatMap((p) => p.filterItems)
          .map((filterItem) => convertQueryDataToFilterItem(filterItem)),
        pageParams: data.pageParams,
      }),
    }
  )
}

export const useCachedFilterable = (queryKey: string, attribute?: string) => {
  const queryClient = useQueryClient()
  const filterItems = queryClient
    .getQueriesData<{ pages: { filterItems: FilterItems["filterItems"] } }>([
      queryKey,
      attribute,
    ])
    .flatMap(([, data]) => data?.pages ?? [])
    .flatMap((page) => page.filterItems)
    .map((filterItem) => convertQueryDataToFilterItem(filterItem))
  return filterItems
}

export const createUseGetDemographicValueFilters =
  (attribute: string) =>
  ({
    url,
    queryKey,
    enabled,
    query,
    initialIds,
    managerReviewCycleIds,
  }: UseGetFiltersParams) => {
    return useGetFilterable({
      url,
      queryKey,
      enabled,
      query,
      initialIds,
      attribute,
      managerReviewCycleIds,
    })
  }

export const createUseGetSnapshotDemographicValueFilters =
  (attribute: string) =>
  ({ url, queryKey, enabled, query, initialIds }: UseGetFiltersParams) => {
    return useGetSnapshotFilterable({
      url,
      queryKey,
      enabled,
      query,
      initialIds,
      attribute,
    })
  }

export const createUseSnapshotCachedDemographics =
  (attribute: string) => (queryKey: string) => {
    return useCachedSnapshotFilterable(queryKey, attribute)
  }

export const createUseCachedDemographics =
  (attribute: string) => (queryKey: string) => {
    return useCachedFilterable(queryKey, attribute)
  }

const fetchUserStatuses = async (query: string) => {
  return Promise.resolve({
    filterItems: [
      {
        id: "active",
        label: "Active",
      },
      {
        id: "deactivated",
        label: "Deactivated",
      },
    ].filter((fi) => fi.label.toLowerCase().includes(query.toLowerCase())),
    meta: {
      pagination: {
        currentPage: 1,
        totalCount: 2,
        totalPages: 1,
        nextPage: undefined,
      },
    },
  })
}

const fetchReviewStatuses = async (query: string) => {
  return Promise.resolve({
    filterItems: [
      {
        id: "completed",
        label: "Completed",
      },
      {
        id: "shared",
        label: "Shared",
      },
      {
        id: "dirty",
        label: "Draft",
      },
      {
        id: "incomplete",
        label: "Not started",
      },
    ].filter((fi) => fi.label.toLowerCase().includes(query.toLowerCase())),
    meta: {
      pagination: {
        currentPage: 1,
        totalCount: 2,
        totalPages: 1,
        nextPage: undefined,
      },
    },
  })
}

const fetchGroupTypes = async (query: string) => {
  return Promise.resolve({
    filterItems: [
      {
        id: "directs",
        label: "Directs",
      },
      {
        id: "indirects",
        label: "Indirects",
      },
    ].filter((fi) => fi.label.toLowerCase().includes(query.toLowerCase())),
    meta: {
      pagination: {
        currentPage: 1,
        totalCount: 2,
        totalPages: 1,
        nextPage: undefined,
      },
    },
  })
}
