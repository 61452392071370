import _ from "lodash"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { prependBasePath } from "../../utils/routing"
import { createRouterClickCallback } from "../../utils/createRouterLink"

/**
 * Creates a routable "onClick" callback, and adds the "/performance" base
 * path to the supplied href.
 *
 * Its purpose is to replicate react-router's
 * `Link` component, for instances where we can't use a regular `Link` (eg. with reusable
 * kaizen components).
 *
 * What we want, is when the user clicks that link, it will go to that route,
 * without a page refresh. If they "Cmd & click" the link, it will open up in a
 * new tab.
 *
 * See the `TableHeaderRowCell` component for example usage.
 */
export const useLink = (
  href: string | undefined,
  onClick: ((e: React.MouseEvent) => void) | undefined,
  options?: { replace?: boolean; target?: string }
) => {
  const history = useHistory()

  const basedHref = href != null ? prependBasePath(href) : undefined

  const useOnClickWrapper = href != null && !options?.target

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedClickCallback = useCallback(
    !useOnClickWrapper || href == null // The href == null is there to make ts happy
      ? // See below, but if we don't pass a href, we don't return the `wrappedClickCallback`,
        // so there's no need to create the history callback here.
        // This awkward code exists because we can't have hooks rendered conditionally.
        _.noop
      : createRouterClickCallback(history, href, {
          replace: options?.replace,
          onClick: onClick,
        }),
    [href, onClick, options?.replace, history]
  )

  if (!useOnClickWrapper) {
    return {
      href: basedHref,
      onClick,
    }
  }

  return {
    onClick: wrappedClickCallback,
    href: basedHref,
  }
}
