import { useGetManagerReviewDetails } from "@Calibrations/v2/queries/useGetManagerReviewDetails"
import { shouldDisplayAssessmentGroupColumn } from "@Calibrations/v2/utils/shouldDisplayAssessmentGroupColumn/shouldDisplayAssessmentGroupColumn"
import { CalibrationView } from "@Calibrations/v2/queries/useGetCalibrationView"

export const useShouldShowPerformanceRating = (
  filteredMangerReviewCycleIds?: Array<string | null> | null | undefined,
  calibrationView?: CalibrationView,
  nonAdminView = false
): boolean => {
  const {
    data: managerReviewDetails,
    isLoading: isLoadingManagerReviewDetails,
  } = useGetManagerReviewDetails({
    managerReviewCycleIds: filteredMangerReviewCycleIds
      ? filteredMangerReviewCycleIds?.map((id) => Number(id))
      : [],
    nonAdminView: nonAdminView,
  })

  // if the user has filtered manager review cycles
  if (filteredMangerReviewCycleIds?.length) {
    if (isLoadingManagerReviewDetails || !managerReviewDetails) {
      return false
    }

    return shouldDisplayAssessmentGroupColumn(
      managerReviewDetails.map((detail) => detail.assessmentGroup)
    )
  }

  if (calibrationView) {
    // if the current calibrationView cycles have the same questions
    return shouldDisplayAssessmentGroupColumn(
      calibrationView.managerReviewCycles.map((cycle) => cycle.assessmentGroup)
    )
  }

  return false
}
