import React, { useState } from "react"
import { useIntl } from "@Common/locale/useIntl"
import { useHistory } from "react-router-dom"
import strings from "@Locale/strings"
import { InputEditModal } from "@kaizen/draft-modal"
import { TextFieldValidation } from "@Containers/GoalForm/types"
import { TextField } from "@kaizen/draft-form"
import { Box } from "@kaizen/component-library"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import {
  CreateCalibrationOptions,
  useCreateCalibrationView,
} from "@Calibrations/v2/queries/useCreateCalibrationView"
import omit from "lodash/omit"
import Avo from "@Analytics/avo/Avo"
import useNotifications from "@Hooks/toastNotifications/useNotifications"
import { useLocalStorage } from "@Calibrations/hooks/useLocalStorage"
import { convertKeysCamelToSnakeCase } from "@Calibrations/v2/utils/convertKeysCamelToSnakeCase/convertKeysCamelToSnakeCase"

type Props = {
  isOpen: boolean
  onDismiss: () => void
}

export const CreateCalibrationViewModal = ({ isOpen, onDismiss }: Props) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [viewName, setViewName] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutateAsync } = useCreateCalibrationView()
  const [queryParams] = useCalibrationViewQueryParams()
  const [nameFieldState, setNameFieldState] = useState<TextFieldValidation>({
    status: "default",
    validationMessage: undefined,
  })
  const [viewedInFullWidth] = useLocalStorage(
    "calibrations.fullWidthState",
    false
  )
  const { showNotification } = useNotifications()

  const options = convertKeysCamelToSnakeCase<CreateCalibrationOptions>(
    omit(queryParams, "managerReviewCycleIds")
  )

  const onChangeName = (value: string) => {
    setNameFieldState({
      status: "default",
      validationMessage: undefined,
    })
    setViewName(value)
  }

  const handleSubmit = async () => {
    if (!viewName.length) {
      setNameFieldState({
        status: "error",
        validationMessage: formatMessage(
          strings.savedViews.createModal.validationError
        ),
      })
      return
    }
    setIsSubmitting(true)
    try {
      const calibrationView = await mutateAsync({
        name: viewName,
        type: "performance_review_view",
        options: {
          ...options,
          performance_cycle_ids: queryParams.managerReviewCycleIds?.map((id) =>
            id ? Number(id) : null
          ),
        },
      })
      Avo.calibrationViewCreated({
        calibrationViewId: calibrationView.saved_view.id,
        viewedInFullWidth,
      })
      showNotification({
        title: formatMessage(strings.toast.success),
        message: formatMessage(strings.savedViews.toasts.create.success, {
          name: calibrationView.saved_view.name,
        }),
        type: "positive",
      })
      history.push(`/admin/calibrations/views/${calibrationView.saved_view.id}`)
    } catch (e) {
      showNotification({
        title: formatMessage(strings.toast.error),
        message: formatMessage(strings.savedViews.toasts.create.error),
        type: "negative",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <InputEditModal
      isOpen={isOpen}
      mood="positive"
      title={formatMessage(strings.savedViews.createModal.title)}
      submitLabel={formatMessage(strings.savedViews.createModal.createButton)}
      onSubmit={handleSubmit}
      submitWorking={
        isSubmitting
          ? { label: formatMessage(strings.savedViews.createModal.creating) }
          : undefined
      }
      onDismiss={onDismiss}
    >
      <Box>
        <div>
          <TextField
            id="name"
            inputType="text"
            inputValue={viewName}
            labelText={formatMessage(
              strings.savedViews.createModal.textFieldLabel
            )}
            placeholder=""
            onChange={(evt: React.FormEvent<HTMLInputElement>) => {
              const { value } = evt.target as HTMLInputElement
              onChangeName(value)
            }}
            status={nameFieldState.status}
            validationMessage={nameFieldState.validationMessage}
          />
        </div>
      </Box>
    </InputEditModal>
  )
}
