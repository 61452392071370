import * as React from "react"
import { InputEditModal } from "@kaizen/draft-modal"
import { CheckboxField } from "@kaizen/draft-form"
import { Paragraph } from "@kaizen/typography"
import { Box } from "@kaizen/component-library"

import { ManageableColumn } from "@Calibrations/v2/types/Tables"

import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"

type SafeManageableColumn = Omit<
  ManageableColumn,
  "filterId" | "canToggleVisibility"
> & {
  filterId: string
  canToggleVisibilility: true
}

const CheckboxStates = ["on", "off", "mixed"] as const
const [ON, OFF, MIXED] = CheckboxStates

type CheckboxState = (typeof CheckboxStates)[number]

type ManageColumnsModalProps = {
  columns: ManageableColumn[]
  onCancel: () => void
  onSubmit: () => void
}

type ManageableColumnCheckbox = {
  id: string
  parentId: string | undefined
  title: string | undefined
  initialState: CheckboxState
  position: number
}

type State = Record<
  string,
  { checkboxState: CheckboxState; parentId: string | undefined }
>
type Action = {
  id: string
  parentId: string | undefined
  newCheckboxState: CheckboxState
}

const columnReducer = (
  currentState: State,
  { id, parentId, newCheckboxState }: Action
): State => {
  // If we have a parent, find it
  const parentState = parentId ? currentState[parentId] : undefined
  // Build the new state for our parent
  if (parentState) {
    const childrenStates = new Set([
      ...Object.keys(currentState)
        .filter((key) => currentState[key].parentId === parentId)
        .filter((key) => key !== id)
        .map((key) => currentState[key].checkboxState),
      newCheckboxState,
    ])

    parentState.checkboxState =
      childrenStates.size !== 1 ? MIXED : newCheckboxState
  }

  // If we have children, find them
  const childrenKeys = Object.keys(currentState).filter(
    (key) => currentState[key].parentId === id
  )
  // Build the new state for our children
  const childrenState = childrenKeys.reduce(
    (acc, id) => ({
      ...acc,
      [id]: { ...currentState[id], checkboxState: newCheckboxState },
    }),
    {}
  )

  // Build the state for ourselves
  const ourState = {
    [id]: { ...currentState[id], checkboxState: newCheckboxState },
  }

  return {
    ...currentState,
    ...ourState,
    ...childrenState,
    ...(parentId ? { [parentId]: parentState } : {}),
  }
}

const getInitialState = (items: ManageableColumnCheckbox[]): State => {
  return items.reduce(
    (acc, value) => ({
      ...acc,
      [value.id]: {
        checkboxState: value.initialState,
        parentId: value.parentId,
      },
    }),
    {}
  )
}

const useColumnItems = (columns: ManageableColumn[]) => {
  const { formatMessage } = useIntl()

  return React.useMemo(() => {
    const filteredColumns = columns.filter(
      (column): column is SafeManageableColumn =>
        Boolean(column.canToggleVisibility && column.filterId)
    )

    const questionColumns = filteredColumns.filter(
      (column) => column.isQuestion
    )
    const firstQuestionColumnPosition = filteredColumns.findIndex(
      (column) => column.isQuestion
    )

    const demographicColumns = filteredColumns.filter(
      (column) => column.isDemographic
    )
    const firstDemographicColumnPosition = filteredColumns.findIndex(
      (column) => column.isDemographic
    )

    const topLevelColumns = filteredColumns.filter(
      (column) => !column.isQuestion && !column.isDemographic
    )

    const items: ManageableColumnCheckbox[] = [
      ...topLevelColumns.map((column) => ({
        id: column.filterId,
        parentId: undefined,
        title: column.title,
        initialState: column.isVisible ? ON : OFF,
        position: filteredColumns.findIndex((c) => c.id === column.id),
      })),
      ...(demographicColumns.length > 0
        ? [
            {
              id: "demographics",
              parentId: undefined,
              title: formatMessage(
                strings.components.ManageColumnsModal.demographicsCheckbox
              ),
              initialState: demographicColumns.every(
                (column) => column.isVisible
              )
                ? ON
                : demographicColumns.some((column) => column.isVisible)
                ? MIXED
                : OFF,
              position: firstDemographicColumnPosition,
            },
          ]
        : []),
      ...(questionColumns.length > 0
        ? [
            {
              id: "questions",
              parentId: undefined,
              title: formatMessage(
                strings.components.ManageColumnsModal.questionsCheckbox
              ),
              initialState: questionColumns.every((column) => column.isVisible)
                ? ON
                : questionColumns.some((column) => column.isVisible)
                ? MIXED
                : OFF,
              position: firstQuestionColumnPosition,
            },
          ]
        : []),
      ...questionColumns.map((column) => ({
        id: column.filterId,
        parentId: "questions",
        title: column.title,
        initialState: column.isVisible ? ON : OFF,
        position: -1,
      })),
      ...demographicColumns.map((column) => ({
        id: column.filterId,
        parentId: "demographics",
        title: column.title,
        initialState: column.isVisible ? ON : OFF,
        position: -1,
      })),
    ]
    return items.sort((a, b) =>
      a.position < b.position ? -1 : a.position > b.position ? 1 : 0
    )
  }, [columns, formatMessage])
}

export const ManageColumnsModal: React.FC<ManageColumnsModalProps> = ({
  columns,
  onCancel,
  onSubmit,
}) => {
  const { formatMessage } = useIntl()
  const [, setQueryParams] = useCalibrationViewQueryParams()

  const items = useColumnItems(columns)

  const [toggleState, setToggleState] = React.useReducer(
    columnReducer,
    getInitialState(items)
  )

  const onChecked = (item: ManageableColumnCheckbox) => {
    setToggleState({
      id: item.id,
      parentId: item.parentId,
      newCheckboxState: toggleState[item.id].checkboxState === ON ? OFF : ON,
    })
  }

  const onSave = () => {
    setQueryParams({
      columns: [
        ...Object.keys(toggleState).filter(
          (key) =>
            toggleState[key].checkboxState === ON &&
            key !== "demographics" &&
            key !== "questions"
        ),
      ],
    })
    onSubmit()
  }

  return (
    <InputEditModal
      isOpen={true}
      title={formatMessage(strings.components.ManageColumnsModal.title)}
      mood="positive"
      onDismiss={onCancel}
      onSubmit={onSave}
      submitLabel={formatMessage(
        strings.components.ManageColumnsModal.submitButton
      )}
      dismissLabel={formatMessage(
        strings.components.ManageColumnsModal.cancelButton
      )}
    >
      <Paragraph variant="body">
        {formatMessage(strings.components.ManageColumnsModal.ledeText)}
      </Paragraph>
      <Box pt={0.75}>
        {items
          .filter((item) => !item.parentId)
          .map((item) => (
            <React.Fragment key={item.id}>
              <CheckboxField
                key={item.id}
                labelText={item.title}
                id={item.id}
                onCheck={() => onChecked(item)}
                checkedStatus={toggleState[item.id].checkboxState}
              />
              <Box ml={1.5}>
                {items
                  .filter((i) => i.parentId === item.id)
                  .map((childItem) => (
                    <CheckboxField
                      key={childItem.id}
                      labelText={childItem.title}
                      id={childItem.id}
                      onCheck={() => onChecked(childItem)}
                      checkedStatus={toggleState[childItem.id].checkboxState}
                    />
                  ))}
              </Box>
            </React.Fragment>
          ))}
      </Box>
    </InputEditModal>
  )
}
