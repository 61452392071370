import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiPut } from "@API/utils"
import { Calibrations } from "bff/upstreams"
import { FilterQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { QueryKeys } from "./keys"
import { fetchReviewResult } from "./useGetSingleReviewResult"
import { useCalibrationViewQueryParams } from "../hooks/useCalibrationViewQueryParams"

type FetchResult = Calibrations["managerReviews.ManagerReviewsDetail"]

type UpdateAssessmentRatingParams = {
  calibrationId: number
  managerReviewId: number
  pendingRatingId: number
}

const updateAssessmentRating = async ({
  calibrationId,
  managerReviewId,
  pendingRatingId,
}: UpdateAssessmentRatingParams) => {
  return perfApiPut<Calibrations["Success"]>(
    "/dashboard/calibrations/:calibrationId/manager_reviews/:managerReviewId/assessment_rating",
    {
      params: {
        calibrationId,
        managerReviewId,
      },
      body: {
        pending_rating_id: pendingRatingId,
      },
    }
  )
}

export const updateAssessmentRatingRefetchReview = ({
  calibrationId,
  managerReviewId,
  pendingRatingId,
}: UpdateAssessmentRatingParams) => {
  return updateAssessmentRating({
    calibrationId,
    managerReviewId,
    pendingRatingId,
  }).then(() =>
    fetchReviewResult({ calibrationViewId: calibrationId, managerReviewId })
  )
}

export const useUpdateAssessmentRating = ({
  calibrationId,
}: {
  calibrationId: number
}) => {
  const [searchParams] = useCalibrationViewQueryParams()

  const queryClient = useQueryClient()

  const heroStatsQueryDependencyArray = Object.fromEntries(
    Object.entries(searchParams).filter(([key, val]) =>
      Object.keys(FilterQueryParams).includes(key)
    )
  )

  return useMutation(
    ({ managerReviewId, pendingRatingId }: UpdateAssessmentRatingParams) =>
      updateAssessmentRatingRefetchReview({
        calibrationId,
        managerReviewId,
        pendingRatingId,
      }),
    {
      onMutate: async (updatedReviewResult) => {
        await queryClient.cancelQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        const previousReviewResults = queryClient.getQueryData([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        queryClient.setQueryData<FetchResult | undefined>(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          (previous) => {
            if (!previous) {
              return undefined
            }
            return {
              ...previous,
              managerReviews: previous.managerReviews.map((review) =>
                updatedReviewResult.managerReviewId === review.managerReview.id
                  ? {
                      ...review,
                      assessmentRating: {
                        currentRatingId:
                          review.assessmentRating.currentRatingId,
                        pendingRatingId: updatedReviewResult.pendingRatingId,
                      },
                    }
                  : review
              ),
            }
          }
        )
        return { previousReviewResults }
      },
      onError: (err, updatedReviewResult, context) => {
        queryClient.setQueryData(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          context?.previousReviewResults
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        // When the performance rating (assessment rating) has changed
        // we want to invalidate the query for the hero stats to force
        // hero stats data to be refetched
        queryClient.invalidateQueries([
          QueryKeys.AdminCalibrationViewHeroStats,
          calibrationId,
          heroStatsQueryDependencyArray,
        ])
      },
    }
  )
}
