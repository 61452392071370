import * as React from "react"
import { useCarousel } from "@Calibrations/v2/hooks/useCarousel"
import { AssessmentGroupHeroStat } from "@Calibrations/v2/queries/useGetHeroStats"
import { IconButton } from "@kaizen/button"
import arrowLeftIcon from "@kaizen/component-library/icons/arrow-left.icon.svg"
import arrowRightIcon from "@kaizen/component-library/icons/arrow-right.icon.svg"
import { useIntl } from "react-intl"
import strings from "@Calibrations/locale/strings"
import { Stat, StatEmployeeCount, StatPercentage, StatTitle } from ".."

export const AssessmentGroupsStatsRow = ({
  data,
}: {
  data: Array<AssessmentGroupHeroStat>
}) => {
  const { refCallback, visibleItems, nextPage, prevPage, hasNext, hasPrev } =
    useCarousel({ items: data })
  const { formatMessage } = useIntl()

  return (
    <div className="inline-flex items-center w-full">
      <IconButton
        label={formatMessage(
          strings.components.CalibrationHeroStats.previousPerformanceRatings
        )}
        icon={arrowLeftIcon}
        onClick={() => prevPage()}
        disabled={!hasPrev}
      />
      <div className="flex grow w-full" ref={refCallback}>
        {visibleItems.map((item) => {
          return (
            <Stat key={item.assessmentRating}>
              <StatTitle
                title={item.assessmentRating}
                description={item.description}
              />
              <div className="w-full">
                <StatPercentage percentage={item.percentage} />
                <StatEmployeeCount employeeCount={item.total} />
              </div>
            </Stat>
          )
        })}
      </div>
      <IconButton
        label={formatMessage(
          strings.components.CalibrationHeroStats.nextPerformanceRatings
        )}
        icon={arrowRightIcon}
        onClick={() => nextPage()}
        disabled={!hasNext}
      />
    </div>
  )
}
