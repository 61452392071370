import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { perfApiGet } from "@API/utils"
import {
  useCalibrationViewQueryParams,
  CalibrationViewQueryParams,
} from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { Calibrations } from "bff/upstreams"
import { QueryKeys } from "./keys"

export type FetchResult = Calibrations["managerReviews.ManagerReviewsDetail"]
export type FetchReview = Calibrations["managerReview.ManagerReviewDetail"]
type Meta = Calibrations["calibrations.Meta"]

export type ReviewResult = Calibrations["managerReview.ManagerReviewDetail"]
export type ManagerReview = ReviewResult["managerReview"]
export type Question = Calibrations["calibrations.Question"]
export type QuestionAnswer = Calibrations["managerReview.QuestionAnswer"]
export type QuestionAnswers = Calibrations["managerReview.QuestionAnswers"]
export type Employee = ReviewResult["employee"]
export type ManagerReviewCycle = ReviewResult["managerReviewCycle"]
export type AssessmentRating = ReviewResult["assessmentRating"]
export type AssessmentGroup = Calibrations["calibrations.AssessmentGroup"]

// We need to handle nested objects here
export const transformParams = (searchParams: CalibrationViewQueryParams) => {
  return Object.entries(searchParams).reduce((acc, [key, value]) => {
    /*
      We store demographicId filters as a nested object in our
      searchParams but want to pass them to the API as a single level array of
      values
     */

    if (key === "demographics") {
      return {
        ...acc,
        demographic_value_ids:
          value && Object.entries(value).length > 0
            ? Object.values(value).flat(1).join(",")
            : undefined,
      }
    }
    return {
      ...acc,
      [key]: Array.isArray(value) ? value.join(",") : value,
    }
  }, {})
}

const fetchSnapshotReviewResults = async (
  calibrationViewId: number,
  params: CalibrationViewQueryParams
): Promise<FetchResult> => {
  const transformedFilters = transformParams(params)
  const url =
    "dashboard/calibrations/:calibrationViewId/snapshot_manager_reviews"
  const res = await perfApiGet<FetchResult>(url, {
    params: {
      calibrationViewId,
      ...transformedFilters,
    },
  })
  return res.data
}

export const useGetSnapshotReviews = <T extends unknown = FetchResult>(
  select?: (data: FetchResult) => T
) => {
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()
  const [searchParams] = useCalibrationViewQueryParams()

  return useQuery(
    [QueryKeys.AdminReviewResults, Number(calibrationViewId), searchParams],
    () => fetchSnapshotReviewResults(Number(calibrationViewId), searchParams),
    {
      keepPreviousData: true,
      select,
      staleTime: 5000,
    }
  )
}

export const useGetSnapshotReviewResultsMeta = () => {
  return useGetSnapshotReviews<Meta>((data) => data.meta)
}

export const useGetSnapshotReviewResults = () => {
  return useGetSnapshotReviews<ReviewResult[]>((data) => data.managerReviews)
}

const fetchReviewResults = async (
  params: CalibrationViewQueryParams
): Promise<FetchResult> => {
  const transformedFilters = transformParams(params)
  const url = "dashboard/calibrations/manager_reviews"
  const res = await perfApiGet<FetchResult>(url, {
    params: {
      ...transformedFilters,
    },
  })
  return res.data
}

export const useGetReviews = <T extends unknown = FetchResult>(
  select?: (data: FetchResult) => T
) => {
  const [searchParams] = useCalibrationViewQueryParams()

  return useQuery(
    [QueryKeys.AdminReviewResults, searchParams],
    () => fetchReviewResults(searchParams),
    {
      keepPreviousData: true,
      select,
      staleTime: 5000,
    }
  )
}

export const useGetReviewResultsMeta = () => {
  return useGetReviews<Meta>((data) => data.meta)
}

export const useGetReviewResults = () => {
  return useGetReviews<ReviewResult[]>((data) => data.managerReviews)
}

const fetchNonAdminReviewResults = async (
  calibrationViewId: number,
  params: CalibrationViewQueryParams
): Promise<FetchResult> => {
  const transformedFilters = transformParams(params)
  const url = "calibrations/:calibrationViewId/manager_reviews"
  const res = await perfApiGet<FetchResult>(url, {
    params: {
      calibrationViewId,
      ...transformedFilters,
    },
  })
  return res.data
}

export const useGetNonAdminReviews = <T extends unknown = FetchResult>(
  select?: (data: FetchResult) => T
) => {
  const [searchParams] = useCalibrationViewQueryParams()
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()

  return useQuery(
    [QueryKeys.AdminReviewResults, Number(calibrationViewId), searchParams],
    () => fetchNonAdminReviewResults(Number(calibrationViewId), searchParams),
    {
      keepPreviousData: true,
      select,
      staleTime: 5000,
    }
  )
}

export const useGetNonAdminReviewResultsMeta = () => {
  return useGetNonAdminReviews<Meta>((data) => data.meta)
}

export const useGetNonAdminReviewResults = () => {
  return useGetNonAdminReviews<ReviewResult[]>((data) => data.managerReviews)
}
