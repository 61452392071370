import * as React from "react"
import strings from "@Calibrations/locale/strings"
import { Button } from "@kaizen/button"
import { useIntl } from "react-intl"
import { CalibrationView } from "@Calibrations/v2/queries/useGetCalibrationView"
import { FilterDropdown } from "./FilterDropdown"
import {
  useSnapshotCalibrationViewFilters,
  useCalibrationViewFilters,
} from "./useCalibrationViewFilters"
import { CalibrationViewFiltersLoader } from "./CalibrationViewFiltersLoader"

const {
  components: { CalibrationFilters: FilterStrings },
} = strings

export const SnapshotCalibrationViewFilters = ({
  calibrationView,
}: {
  calibrationView: CalibrationView
}) => {
  const { formatMessage } = useIntl()
  const { currentFilters, clearFilters } =
    useSnapshotCalibrationViewFilters(calibrationView)
  return (
    <CalibrationViewFiltersLoader filters={currentFilters}>
      <Container>
        <FilterItems>
          {currentFilters.map((filter) => {
            return (
              <FilterDropdown
                key={`${filter.id}_${filter.demographic}`}
                filter={filter}
              />
            )
          })}

          <Button
            type="button"
            secondary
            label={formatMessage(FilterStrings.clearButton)}
            onClick={clearFilters}
          />
        </FilterItems>
      </Container>
    </CalibrationViewFiltersLoader>
  )
}

export const CalibrationViewFilters = () => {
  const { formatMessage } = useIntl()
  const { currentFilters, clearFilters } = useCalibrationViewFilters()
  return (
    <CalibrationViewFiltersLoader filters={currentFilters}>
      <Container>
        <FilterItems>
          {currentFilters.map((filter) => {
            return (
              <FilterDropdown
                key={`${filter.id}_${filter.demographic}`}
                filter={filter}
              />
            )
          })}
          <Button
            type="button"
            secondary
            label={formatMessage(FilterStrings.clearButton)}
            onClick={clearFilters}
          />
        </FilterItems>
      </Container>
    </CalibrationViewFiltersLoader>
  )
}

const Container: React.FC = ({ children, ...otherProps }) => (
  <div
    {...otherProps}
    style={{
      display: "inline-grid",
      gridTemplateColumns: "1fr auto",
      justifyContent: "center",
      alignItems: "center",
      border: "solid 1px rgb(244, 244, 245)",
      padding: "10px",
    }}
    className="bg-white rounded-default shadow-sm w-full"
  >
    {children}
  </div>
)

const FilterItems: React.FC = ({ children, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      style={{ gap: "1rem" }}
      className="flex flex-row flex-wrap"
    >
      {children}
    </div>
  )
}
