import React from "react"
import { MessageDescriptor } from "react-intl"
import { Label } from "@kaizen/draft-form"
import { Box } from "@kaizen/component-library"
import { Select } from "@kaizen/draft-select"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Locale/strings"
import { RoutePaths, RoutePathValue } from "@Goals/constants"

import styles from "./ListTreeViewSelect.scss"

export type ViewOption = {
  value: string
  label: MessageDescriptor
  route: RoutePathValue
}

export const OPTIONS: ViewOption[] = [
  {
    value: "list",
    label: strings.goals.listTreeViewSelect.list,
    route: RoutePaths.companyGoalsList,
  },
  {
    value: "tree",
    label: strings.goals.listTreeViewSelect.tree,
    route: RoutePaths.goalsTree,
  },
]

type MessageFormatter = (message: MessageDescriptor) => string

export const makeOptionInternationaliser =
  (messageFormatter: MessageFormatter) => (option: ViewOption) => ({
    ...option,
    label: messageFormatter(option.label),
  })

export type ListTreeViewSelectProps = {
  value: ViewOption
  onChange: (option: ViewOption) => void
}

export const ListTreeViewSelect = ({
  value,
  onChange,
}: ListTreeViewSelectProps) => {
  const { formatMessage } = useIntl()

  const internationaliseOption = makeOptionInternationaliser(formatMessage)
  const internationalisedOptions = OPTIONS.map(internationaliseOption)

  return (
    <div className={styles.viewSelectContainer}>
      <Label reversed>
        {formatMessage(strings.goals.listTreeViewSelect.viewLabel)}
      </Label>
      <Box mb={1}>
        <Select
          className={styles.viewSelect}
          fullWidth
          options={internationalisedOptions}
          value={internationaliseOption(value)}
          onChange={onChange}
        />
      </Box>
    </div>
  )
}
