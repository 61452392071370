/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import paginationDecoder from "../PaginationDecoder/paginationDecoder"
const { boolean, array, object, number, string } = JsonDecoder

const teamDecoder = object(
  {
    team_id: string,
    name: string,
    description: string,
    member_count: number,
    current_user_can_edit: boolean,
  },
  "teamsDecoder team"
)

export const teamsDecoder = object(
  {
    teams: array(teamDecoder, "teamsDecoder teams"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "teamsDecoder"
).map(({ teams, meta }) => ({
  teams: teams.map((team) => ({
    id: team.team_id,
    name: team.name,
    description: team.description,
    currentUserCanEdit: team.current_user_can_edit,
    preview: {
      memberCount: team.member_count,
    },
  })),
  pagination: meta.pagination,
}))

export default teamsDecoder
