import * as React from "react"
import ErrorPage from "./ErrorPage"

export const ErrorPage503Maintenance = () => {
  return (
    <ErrorPage
      code={503}
      title="We're working on some improvements"
      message={
        <>
          Sorry, Culture Amp is not available because we&apos;re doing some
          important maintenance work. Please try again later.
        </>
      }
      includeCTA
    />
  )
}
