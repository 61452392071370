import { perfApiPost } from "@API/utils"
import { CamelToSnakeCase } from "@BFF/types"
import { SavedView } from "@Calibrations/types/SavedView"
import { useMutation } from "@tanstack/react-query"
import { omit } from "lodash"
import { CalibrationViewQueryParams } from "../hooks/useCalibrationViewQueryParams"

export type CreateCalibrationOptions = {
  [Key in keyof CalibrationViewQueryParams as CamelToSnakeCase<Key>]: CalibrationViewQueryParams[Key]
} & {
  performance_cycle_ids: (number | null)[] | null | undefined
}

type CreateCalibrationParams = {
  name: string
  type: string
  options: CreateCalibrationOptions
}
export const createCalibrationView = async ({
  name,
  type,
  options,
}: CreateCalibrationParams) => {
  const res = await perfApiPost("/dashboard/saved_views", {
    body: {
      saved_view: {
        name,
        type,
        options: transformV2optionstoV1(options),
      },
    },
  })
  return res.data as { saved_view: SavedView }
}

export const useCreateCalibrationView = () => {
  return useMutation((params: CreateCalibrationParams) =>
    createCalibrationView(params)
  )
}

const transformV2optionstoV1 = (options: CreateCalibrationOptions) => {
  // old v1 structure of demographic ids
  const demographic_value_ids = options.demographics
    ? Object.values(options.demographics).flat()
    : undefined

  // These come in nested comma separated strings from filters for
  // 'duplicates' across different cycles, they have the same label
  // but different ids. so we just split them up, then flatten into
  // one nice big array.
  const performance_bucket_in_cycle_ids =
    options.performance_bucket_in_cycle_ids
      ?.map((bucket) => bucket?.split(","))
      .flat(1)

  return {
    ...omit(options, "demographic_ids"),
    demographic_value_ids,
    performance_bucket_in_cycle_ids,
    // v1 api requires these to be numbers or it chucks a wobbly
    department_ids: options.department_ids?.map((id) => Number(id)),
    manager_ids: options.manager_ids?.map((id) => Number(id)),
    job_title_ids: options.job_title_ids?.map((id) => Number(id)),
    status: options.review_status?.map((id) => id),
  }
}
