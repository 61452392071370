import React, { useState } from "react"
import { InputEditModal } from "@kaizen/draft-modal"
import { Box } from "@kaizen/component-library"
import { InputStatus, TextField } from "@kaizen/draft-form"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { useUpdateCalibrationViewName } from "@Calibrations/v2/queries/useUpdateCalibrationViewName"
import { addToastNotification } from "@kaizen/notification"

const {
  pages: {
    AdminCalibrationViewsDetail: { RenameModal: PageStrings, Toasts },
  },
} = strings

type TextFieldValidation = {
  status: InputStatus
  validationMessage?: string
}

export type CalibrationRenameModalProps = {
  isOpen: boolean
  closeModal: () => void
  calibrationId: number
  originalCalibrationViewName?: string
}

export const CalibrationRenameModal = ({
  isOpen,
  closeModal,
  originalCalibrationViewName,
  calibrationId,
}: CalibrationRenameModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formatMessage } = useIntl()
  const [calibrationViewName, setCalibrationViewName] = useState<string>(
    originalCalibrationViewName || ""
  )
  const [nameTextField, setNameTextField] = useState<TextFieldValidation>({
    status: "default",
    validationMessage: undefined,
  })

  const onSubmit = () => {
    if (calibrationViewName === "") {
      setNameTextField({
        status: "error",
        validationMessage: formatMessage(PageStrings.validationError),
      })
      return
    }
    setIsSubmitting(true)
    updateCalibrationViewNameMutation
      .mutateAsync(calibrationViewName, {
        onSuccess: () => {
          addToastNotification({
            title: formatMessage(Toasts.rename.success.title),
            message: formatMessage(Toasts.rename.success.message, {
              calibrationViewName: calibrationViewName,
            }),
            type: "positive",
          })
        },
        onError: () => {
          addToastNotification({
            title: formatMessage(Toasts.rename.error.title),
            message: formatMessage(Toasts.rename.error.message),
            type: "negative",
          })
        },
      })
      .finally(() => {
        setIsSubmitting(false)
        closeModal()
      })
  }

  const onChangeName = (value: string) => {
    if (!value.length) {
      setNameTextField({
        status: "error",
        validationMessage: formatMessage(PageStrings.validationError),
      })
    } else {
      setNameTextField({
        status: "default",
        validationMessage: undefined,
      })
    }
    setCalibrationViewName(value)
  }

  const updateCalibrationViewNameMutation = useUpdateCalibrationViewName({
    calibrationId,
  })
  return (
    <InputEditModal
      isOpen={isOpen}
      mood="positive"
      title={formatMessage(PageStrings.title)}
      submitLabel={formatMessage(PageStrings.submitLabel)}
      onSubmit={onSubmit}
      submitWorking={
        isSubmitting
          ? { label: formatMessage(PageStrings.submitWorkingLabel) }
          : undefined
      }
      onDismiss={closeModal}
    >
      <Box>
        <div>
          <TextField
            id="name"
            inputType="text"
            inputValue={calibrationViewName}
            labelText={formatMessage(PageStrings.subtitle)}
            placeholder=""
            onChange={(evt: React.FormEvent<HTMLInputElement>) => {
              const { value } = evt.target as HTMLInputElement
              onChangeName(value)
            }}
            status={nameTextField.status}
            validationMessage={nameTextField.validationMessage}
          />
        </div>
      </Box>
    </InputEditModal>
  )
}
