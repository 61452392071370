import { AsyncSelect } from "@kaizen/draft-select"
import React, { useCallback, useMemo } from "react"
import { MessageDescriptor } from "react-intl"
import { Avatar } from "@kaizen/draft-avatar"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Locale/strings"
import { useModalEscapeLock } from "@Hooks/modals/modals"
import { useAsyncEffect } from "@Hooks/async/async"
import Aid from "@Constants/automationId"
import styles from "./UserSearch.scss"
import { Calibrations } from "../../../../../bff/upstreams"

type OptionValue = {
  value: string
  label: string
}

type IconSizeOptions = "xsmall" | "small" | "medium" | "large" | "xlarge"

const iconSizeToAvatarSize = (
  size: IconSizeOptions
): "small" | "medium" | "large" | "xlarge" => {
  if (size === "xsmall") {
    return "small"
  }
  if (size === "small") {
    return "medium"
  }
  return size
}

type Props = {
  /**
   *  The users that show when no query has been entered into the text field.\
   *  If `true` is passed, simply use the results from `fetchUsers`.\
   *  I know, this is weird, but it matches the behaviour of react-select, which
   *  `AsyncSelect` uses behind the scenes.
   */
  defaultUsers?: Calibrations["calibrations.User"][] | boolean | undefined
  /**
   *  Usually, when we load a page, if the typeahead has an initial value,
   *  we would only have the user ids available, but not the user names and
   *  avatar. Therefore, we need to fetch that data. This prop does this.
   */
  fetchInitialUsers?: () => Promise<Calibrations["calibrations.User"][]>
  fetchUsers: (opts: {
    searchString: string
  }) => Promise<Calibrations["filters.UserFilter"]["filterItems"]>
  excludedUserIds?: number[]
  onChange?: (userIds: number[]) => void
  placeholder?: MessageDescriptor
  iconSize?: IconSizeOptions
}

const usersToAsyncSelectOption = (
  users: Calibrations["calibrations.User"][],
  excludedUserIds: number[]
) => {
  return (
    users
      // Prevents the selection of users who have already been selected
      .filter((user) => {
        // This is only to make typescript happy but there shouldn't be cases where the user id is undefined
        if (!user.id) return false
        return !excludedUserIds.includes(user.id)
      })
      .map((user: Calibrations["calibrations.User"]) => ({
        // This is only to make typescript happy but there shouldn't be cases where user name and/or email are undefined.
        label: user.name || user.email || "",
        value: JSON.stringify({
          id: user.id,
          profile_image_url: user.profileImageUrl,
        }),
      }))
  )
}

export const UserSearch = ({
  defaultUsers = true,
  excludedUserIds = [],
  fetchUsers,
  onChange,
  fetchInitialUsers,
  placeholder = strings.ecPaufRequestFeedback.selectFromDropdown,
  iconSize = "small",
}: Props) => {
  const { formatMessage } = useIntl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data: defaultValue } = useAsyncEffect(async () => {
    if (!fetchInitialUsers) return
    const users = await fetchInitialUsers()
    return usersToAsyncSelectOption(users, excludedUserIds)
    // eslint-disable-next-line
  }, [])

  const loadOptions = async (input: string) => {
    try {
      const users = await fetchUsers({ searchString: input })
      return usersToAsyncSelectOption(users, excludedUserIds)
    } catch (err) {
      return []
    }
  }

  // Prevents the selection of users who have already been selected
  const defaultOptions: OptionValue[] | boolean | undefined = useMemo(
    () =>
      defaultUsers != null && typeof defaultUsers !== "boolean"
        ? usersToAsyncSelectOption(defaultUsers, excludedUserIds)
        : defaultUsers,
    [defaultUsers, excludedUserIds]
  )

  const handleChange = useCallback(
    (items) => {
      if (!onChange) return

      const userIds =
        items?.map(({ value }: { value: string }) => {
          return JSON.parse(value).id
        }) || []

      onChange(userIds)
    },
    [onChange]
  )

  const { enableModalLock, releaseModalLock } = useModalEscapeLock()

  return (
    <div data-automation-id={Aid.userSearchBar}>
      <AsyncSelect
        menuPortalTarget={document.body}
        loadOptions={loadOptions}
        // Forces the component to be recreated, once the defaultValue has been
        // fetched.
        key={defaultValue?.length || "asyncselect"}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        placeholder={formatMessage(placeholder)}
        isMulti={true}
        isClearable={true}
        onChange={onChange ? handleChange : undefined}
        formatOptionLabel={({ label, value }) => {
          const userObject = JSON.parse(value)
          return (
            <div key={userObject.id} className={styles.selectOption}>
              <Avatar
                size={iconSizeToAvatarSize(iconSize)}
                avatarSrc={userObject.profile_image_url}
                fullName={userObject.full_name}
              />
              <div className={styles.labelContainer}>{label}</div>
            </div>
          )
        }}
        styles={{
          multiValue: (provided, state) => ({
            ...provided,
            padding: "0px !important",
            borderRadius: "24px !important",
          }),
          multiValueLabel: (provided, state) => ({
            ...provided,
            padding: "3px !important",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            borderRadius: "12px !important",
            height: "24px !important",
            alignSelf: "center !important",
            marginRight: "6px !important",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        onMenuOpen={enableModalLock}
        onMenuClose={releaseModalLock}
      />
    </div>
  )
}
