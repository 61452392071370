import * as React from "react"
// This is the only instance, where we access the kaizen split button directly
// eslint-disable-next-line no-restricted-imports
import { MenuItem as KaizenMenuItem, MenuItemProps } from "@kaizen/draft-menu"
import { useLink } from "../../hooks/routing/useLink"

// The action prop is deprecated
export type Props = Omit<MenuItemProps, "action">

// Wrapper around the Kaizen MenuItem component.
//   https://cultureamp.design/components/menu/
// This wrapper adds integration with react-router, so if we specify a `href`,
// it will use `router.push`, instead of doing a fresh page load.
const MenuItem = ({ href, onClick, target, ...rest }: Props) => {
  const link = useLink(
    href,
    onClick as ((e: React.MouseEvent) => void) | undefined,
    { target }
  )

  return (
    <KaizenMenuItem
      {...rest}
      target={target}
      onClick={link.onClick}
      href={link.href}
    />
  )
}

export default MenuItem
