import * as React from "react"
import { createContext, useReducer } from "react"
import { GoalPreview } from "../types/Goals"
import { PaginatedResults } from "../types/Pagination"
import { getPaginationState } from "./shared/pagination"
import { GoalsState } from "../types/reduxState/goals"
import { GoalsAction } from "../types/reduxActions/goals"

const INITIAL_STATE: GoalsState = {
  goalsById: {},
  goalsPreviewsById: {},
  goalsIdsByTeamId: {},
  goalsIdsByDepartmentId: {},
  goalsIdsByCompanyId: {},
  personalGoals: {
    totalPages: 0,
    totalCount: 0,
    pages: {},
  },
  goalStats: {
    goalCount: 0,
    overallProgress: 0,
  },
  refreshStaleGoals: false,
}

export const goalsReducer = (
  state: GoalsState,
  action: GoalsAction
): GoalsState => {
  switch (action.type) {
    case "FETCH_GOAL_DETAILS": {
      const fetchedGoal = action.payload

      return {
        ...state,
        goalsById: {
          ...state.goalsById,
          [fetchedGoal.id]: fetchedGoal,
        },
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          [fetchedGoal.id]: { ...fetchedGoal },
        },
        refreshStaleGoals: false,
      }
    }
    case "FETCH_PERSONAL": {
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      const personalGoals: number[] = []
      const { goals, goalStats, pagination } = action.payload
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
        personalGoals.push(goal.id)
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        personalGoals: getPaginationState(
          state.personalGoals,
          pagination,
          personalGoals
        ),
        goalStats: goalStats,
        refreshStaleGoals: false,
      }
    }
    case "FETCH_TEAM": {
      const { teamId, goals } = action.payload
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByTeamId: {
          ...state.goalsIdsByTeamId,
          [teamId]: goals.map((goal) => goal.id),
        },
        refreshStaleGoals: false,
      }
    }
    case "FETCH_DEPARTMENT": {
      const { departmentId, goals } = action.payload
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByDepartmentId: {
          ...state.goalsIdsByTeamId,
          [departmentId]: goals.map((goal) => goal.id),
        },
        refreshStaleGoals: false,
      }
    }

    case "FETCH_COMPANY_GOALS": {
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      const companyGoals: number[] = []
      const { goals, companyId } = action.payload
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
        companyGoals.push(goal.id)
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByCompanyId: {
          ...state.goalsIdsByCompanyId,
          [companyId]: goals.map((goal) => goal.id),
        },
        refreshStaleGoals: false,
      }
    }

    case "DELETE": {
      const goal = action.payload
      const filter = (id: number) => id !== goal.id

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [goal.id]: removedPreview,
        ...goalsPreviewsById
      } = state.goalsPreviewsById
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [goal.id]: removedGoal, ...goalsById } = state.goalsById

      if (goal.type === "team") {
        return {
          ...state,
          goalsById,
          goalsPreviewsById,
          goalsIdsByTeamId: {
            ...state.goalsIdsByTeamId,
            [goal.teamId]: state.goalsIdsByTeamId[goal.teamId].filter(filter),
          },
          refreshStaleGoals: false,
        }
      } else {
        const personalGoals: PaginatedResults<number> = {
          ...state.personalGoals,
          pages: Object.assign(
            {},
            ...Object.keys(state.personalGoals.pages).map((page) => ({
              [Number(page)]:
                state.personalGoals.pages[Number(page)].filter(filter),
            }))
          ),
        }
        return {
          ...state,
          goalsById,
          goalsPreviewsById,
          personalGoals,
          refreshStaleGoals: false,
        }
      }
    }

    case "REFRESH_STALE_GOALS": {
      return {
        ...state,
        refreshStaleGoals: true,
      }
    }
  }
  return { ...state }
}

export const GoalsContext: React.Context<{
  state: GoalsState
  dispatch: React.Dispatch<GoalsAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: GoalsAction) => {},
})

export const GoalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(goalsReducer, INITIAL_STATE)

  return (
    <GoalsContext.Provider value={{ state, dispatch }}>
      {children}
    </GoalsContext.Provider>
  )
}
