import * as React from "react"
import { Tag } from "@kaizen/draft-tag"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { PerformanceReviewStatus } from "@Calibrations/types/PerformanceReview"

type StatusCellProps = {
  status: PerformanceReviewStatus
}
export const generateTagVariant = (status: PerformanceReviewStatus) => {
  switch (status) {
    case "incomplete":
    case "dirty":
      return "default"
    case "completed":
    case "shared":
      return "sentimentPositive"
  }
}
export const StatusCell: React.FC<StatusCellProps> = ({ status }) => {
  const { formatMessage } = useIntl()
  return (
    <div className={"whitespace-nowrap"}>
      {status === "dirty" ? (
        <Tag variant="default">
          {formatMessage(strings.components.TableCells.StatusCell.draft)}
        </Tag>
      ) : (
        <Tag variant={generateTagVariant(status)}>
          {formatMessage(strings.components.TableCells.StatusCell[status])}
        </Tag>
      )}
    </div>
  )
}
