import * as React from "react"
import strings from "@Calibrations/locale/strings"
import { useIntl } from "@Common/locale/useIntl"
import {
  AssessmentRating,
  ReviewResult,
  Employee,
  ManagerReview,
  ManagerReviewCycle,
  useGetReviewResultsMeta,
  Question,
  QuestionAnswers,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { CellProps, useFlexLayout, useSortBy, useTable } from "react-table"
import { CalibrationColumn } from "@Calibrations/v2/types/Tables"
import {
  FlagCell,
  EmployeeCell,
  PrimitiveCell,
  AverageRatingCell,
  QuestionCell,
  StatusCell,
} from "@Calibrations/v2/components/TableCells"
import { CalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { ReadOnlyAssessmentGroupCell } from "@Calibrations/v2/components/TableCells/ReadOnlyAssessmentGroupCell/ReadOnlyAssessmentGroupCell"
import {
  ManageColumns,
  CalibrationViewTable,
} from "@Calibrations/v2/components"
import { ManagerReviewDetails } from "@Calibrations/v2/queries/useGetManagerReviewDetails"
import { Calibrations } from "bff/upstreams"
import { useParams } from "react-router-dom"
import { shouldDisplayAssessmentGroupColumn } from "@Calibrations/v2/utils/shouldDisplayAssessmentGroupColumn/shouldDisplayAssessmentGroupColumn"
import { CalibrationViewToolbar } from "../CalibrationViewToolbar/CalibrationViewToolbar"

const {
  components: { CalibrationTable: ComponentStrings },
} = strings

export const ReadOnlyCalibrationViewTableWrapper = React.forwardRef<
  HTMLDivElement,
  {
    filters: CalibrationViewQueryParams
    managerReviews: Array<ReviewResult>
    managerReviewDetails: ManagerReviewDetails
  }
>(({ filters, managerReviews, managerReviewDetails }, ref) => {
  const { formatMessage } = useIntl()
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()
  const shouldDisplayManagerReviewCycleColumn = React.useMemo(() => {
    return (
      filters.managerReviewCycleIds && filters.managerReviewCycleIds.length > 1
    )
  }, [filters])
  const questions = React.useMemo<Question[]>(
    () => managerReviewDetails.map((details) => details.questions).flat(1),
    [managerReviewDetails]
  )

  const columns = React.useMemo<CalibrationColumn[]>(() => {
    return [
      {
        id: "flag",
        filterId: "flag",
        canToggleVisibility: false,
        isVisible: true,
        accessor: ({ managerReview }) => managerReview.flag,
        Cell: ({ row }: CellProps<ReviewResult, ManagerReview["flag"]>) => (
          <FlagCell reviewResult={row.original} editable={false} />
        ),
        isStickyLeft: true,
        stickLeft: 0,
        minWidth: 50,
        width: 50,
        maxWidth: 50,
      },
      {
        id: "name",
        filterId: "employee",
        Header: formatMessage(ComponentStrings.tableHeaders.employee),
        title: formatMessage(ComponentStrings.tableHeaders.user),
        canToggleVisibility: false,
        isVisible: true,
        accessor: ({ employee }) => employee,
        Cell: ({ value: employee, row }: CellProps<ReviewResult, Employee>) => {
          return (
            <EmployeeCell
              employee={employee}
              managerReview={row.original.managerReview}
              managerReviewCycle={row.original.managerReviewCycle}
              showKebabMenu={!!calibrationViewId}
            />
          )
        },
        isStickyLeft: true,
        // Care that we need to leave space for the flag column
        stickLeft: 50,
        width: 220,
        minWidth: 220,
        maxWidth: 220,
      },
      ...(shouldDisplayManagerReviewCycleColumn
        ? [
            {
              id: "managerReviewCycleName",
              filterId: "managerReviewCycleName",
              Header: formatMessage(
                ComponentStrings.tableHeaders.performanceReviewCycle
              ),
              title: formatMessage(
                ComponentStrings.tableHeaders.performanceReviewCycle
              ),
              canToggleVisibility: true,
              disableSortBy: true,
              accessor: ({
                managerReviewCycle,
              }: {
                managerReviewCycle: ManagerReviewCycle
              }) => managerReviewCycle.name,
              Cell: ({
                value: managerReviewCycle,
              }: CellProps<ReviewResult, ManagerReviewCycle["name"]>) => (
                <PrimitiveCell value={managerReviewCycle} />
              ),
            },
          ]
        : []),
      {
        id: "managerName",
        filterId: "manager",
        Header: formatMessage(ComponentStrings.tableHeaders.reportsTo),
        title: formatMessage(ComponentStrings.tableHeaders.reportsTo),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.managerName,
        Cell: ({
          value: employee,
        }: CellProps<ReviewResult, Employee["managerName"]>) => (
          <PrimitiveCell value={employee} />
        ),
      },
      {
        id: "status",
        filterId: "status",
        Header: formatMessage(ComponentStrings.tableHeaders.reviewStatus),
        title: formatMessage(ComponentStrings.tableHeaders.reviewStatus),
        canToggleVisibility: true,
        accessor: ({ managerReview }) => managerReview.status,
        Cell: ({
          value: status,
        }: CellProps<ReviewResult, ManagerReview["status"]>) => (
          <StatusCell status={status} />
        ),
      },
      {
        id: "department",
        filterId: "department",
        Header: formatMessage(ComponentStrings.tableHeaders.department),
        title: formatMessage(ComponentStrings.tableHeaders.department),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.department,
        Cell: ({
          value: department,
        }: CellProps<ReviewResult, Employee["department"]>) => (
          <PrimitiveCell value={department} />
        ),
        isDemographic: true,
      },
      {
        id: "jobTitle",
        filterId: "JobTitle",
        Header: formatMessage(ComponentStrings.tableHeaders.jobTitle),
        title: formatMessage(ComponentStrings.tableHeaders.jobTitle),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.jobTitle,
        Cell: ({
          value: jobTitle,
        }: CellProps<ReviewResult, Employee["jobTitle"]>) => (
          <PrimitiveCell value={jobTitle} />
        ),
        isDemographic: true,
      },
      {
        id: "level",
        filterId: "level",
        Header: formatMessage(ComponentStrings.tableHeaders.jobTitleLevel),
        title: formatMessage(ComponentStrings.tableHeaders.jobTitleLevel),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.level,
        Cell: ({
          value: level,
        }: CellProps<ReviewResult, Employee["level"]>) => (
          <PrimitiveCell value={level} />
        ),
        isDemographic: true,
      },
      {
        id: "businessUnit",
        filterId: "businessUnit",
        Header: formatMessage(ComponentStrings.tableHeaders.businessUnit),
        title: formatMessage(ComponentStrings.tableHeaders.businessUnit),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.businessUnit,
        Cell: ({
          value: businessUnit,
        }: CellProps<ReviewResult, Employee["businessUnit"]>) => (
          <PrimitiveCell value={businessUnit} />
        ),
        isDemographic: true,
      },
      {
        id: "country",
        filterId: "country",
        Header: formatMessage(ComponentStrings.tableHeaders.country),
        title: formatMessage(ComponentStrings.tableHeaders.country),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.country,
        Cell: ({
          value: country,
        }: CellProps<ReviewResult, Employee["country"]>) => (
          <PrimitiveCell value={country} />
        ),
        isDemographic: true,
      },
      {
        id: "division",
        filterId: "division",
        Header: formatMessage(ComponentStrings.tableHeaders.division),
        title: formatMessage(ComponentStrings.tableHeaders.division),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.division,
        Cell: ({
          value: division,
        }: CellProps<ReviewResult, Employee["division"]>) => (
          <PrimitiveCell value={division} />
        ),
        isDemographic: true,
      },
      {
        id: "employmentType",
        filterId: "employmentType",
        Header: formatMessage(ComponentStrings.tableHeaders.employmentType),
        title: formatMessage(ComponentStrings.tableHeaders.employmentType),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.employmentType,
        Cell: ({
          value: employmentType,
        }: CellProps<ReviewResult, Employee["employmentType"]>) => (
          <PrimitiveCell value={employmentType} />
        ),
        isDemographic: true,
      },
      {
        id: "location",
        filterId: "location",
        Header: formatMessage(ComponentStrings.tableHeaders.location),
        title: formatMessage(ComponentStrings.tableHeaders.location),
        canToggleVisibility: true,
        accessor: ({ employee }) => employee.location,
        Cell: ({
          value: location,
        }: CellProps<ReviewResult, Employee["location"]>) => (
          <PrimitiveCell value={location} />
        ),
        isDemographic: true,
      },
      {
        id: "average",
        filterId: "average",
        Header: formatMessage(
          ComponentStrings.tableHeaders.averageOfAllRatingsAbbreviated
        ),
        title: formatMessage(ComponentStrings.tableHeaders.averageOfAllRatings),
        canToggleVisibility: true,
        accessor: ({ managerReview }) => managerReview.averageQuestionRating,
        Cell: ({
          value: averageQuestionRating,
        }: CellProps<ReviewResult, ManagerReview["averageQuestionRating"]>) => (
          <AverageRatingCell value={averageQuestionRating} />
        ),
      },
      ...(shouldDisplayAssessmentGroupColumn(
        managerReviewDetails.map((detail) => detail.assessmentGroup)
      )
        ? [
            {
              id: "assessmentRating",
              filterId: "bucket",
              Header: formatMessage(
                ComponentStrings.tableHeaders.performanceRating
              ),
              title: formatMessage(
                ComponentStrings.tableHeaders.performanceRating
              ),
              canToggleVisibility: true,
              accessor: ({
                assessmentRating,
              }: {
                assessmentRating: AssessmentRating
              }) => assessmentRating,
              Cell: ({
                value: assessmentRating,
                row,
              }: CellProps<
                ReviewResult & {
                  managerReviewCycle: Calibrations["calibrations.ManagerReviewCycle"]
                },
                AssessmentRating
              >) => {
                if (!row.original.managerReview.managerCompletedAt) {
                  return <></>
                }

                const assessmentGroup = managerReviewDetails.find(
                  (detail) => detail.id === row.original.managerReviewCycle.id
                )?.assessmentGroup

                if (!assessmentGroup) return <></>

                return (
                  <ReadOnlyAssessmentGroupCell
                    assessmentGroup={assessmentGroup}
                    assessmentRating={assessmentRating}
                  />
                )
              },
              width: 250,
              minWidth: 250,
              maxWidth: 250,
            },
          ]
        : []),
      ...questions.map((question) => {
        return {
          id: question.id.toString(),
          filterId: question.id.toString(),
          Header: question.title,
          title: question.title,
          canToggleVisibility: true,
          isQuestion: true,
          accessor: ({
            questionAnswers,
          }: {
            questionAnswers: QuestionAnswers
          }) => {
            return questionAnswers.find(
              (answer) => answer.questionId === question.id
            )
          },
          Cell: ({ value: answer, row }: CellProps<ReviewResult>) => (
            <QuestionCell
              review={row.original}
              question={question}
              answer={answer}
              editable={false}
            />
          ),
        }
      }),
    ]
  }, [
    questions,
    formatMessage,
    shouldDisplayManagerReviewCycleColumn,
    calibrationViewId,
    managerReviewDetails,
  ])

  const hiddenColumns = React.useMemo(() => {
    const defaultColumns = [
      "flag",
      "employee",
      "cycleName",
      "manager",
      "status",
      "bucket",
    ]
    return columns.reduce((cols, column) => {
      if (column.canToggleVisibility) {
        const queryColumns = filters.columns
        if (
          (queryColumns && !queryColumns.includes(String(column.filterId))) ||
          (!queryColumns && !defaultColumns?.includes(String(column.filterId)))
        ) {
          cols.push(String(column.id))
        }
      }
      return cols
    }, [] as string[])
  }, [columns, filters.columns])

  const initialStateSortBy = React.useMemo(
    () => [
      filters.sortBy
        ? { id: filters.sortBy, desc: filters.sortOrder === "desc" }
        : { id: "manager_name", desc: true },
    ],
    [filters.sortBy, filters.sortOrder]
  )

  const initialState = {
    hiddenColumns: hiddenColumns,
    sortBy: initialStateSortBy,
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 200,
      width: 200,
      maxWidth: 300,
    }),
    []
  )

  const data = React.useMemo(() => managerReviews, [managerReviews])

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState,
      defaultColumn,
      manualSortBy: true,
      disableSortRemove: true,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            hiddenColumns: hiddenColumns,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, hiddenColumns]
        )
      },
    },
    useSortBy,
    useFlexLayout
  )

  return (
    <div ref={ref}>
      <CalibrationViewToolbar>
        <EmployeeCount />
        <ManageColumns columns={tableInstance.allColumns} />
      </CalibrationViewToolbar>
      <CalibrationViewTable tableInstance={tableInstance} />
    </div>
  )
})

const EmployeeCount = () => {
  const { formatMessage } = useIntl()
  const { data: meta } = useGetReviewResultsMeta()

  if (!meta) return null

  return (
    <div>
      {meta.pagination.totalCount &&
        formatMessage(ComponentStrings.managerReviewCount, {
          count: meta.pagination.totalCount,
        })}
    </div>
  )
}
