/**
 * @file Extra types for use with use-query-params
 */
import {
  decodeArray,
  decodeDelimitedArray,
  encodeArray,
  encodeString,
} from "use-query-params"

function encodeDateTime(date: Date) {
  return date ? date.toISOString() : undefined
}

function decodeDateTime(dateString: string | null, defaultValue: Date) {
  return dateString ? new Date(dateString) : defaultValue
}

function decodeNumberOrString(
  rawString: string | null,
  defaultValue: number | string
) {
  if (!rawString) {
    return defaultValue
  }
  const numValue = Number(rawString)
  if (Number.isNaN(numValue)) {
    return rawString
  } else {
    return numValue
  }
}

function decodeNumericOrStringArray(
  arrayString: string | null,
  defaultValue: (number | string)[]
) {
  const decodeArrayOutput = decodeDelimitedArray(arrayString)
  if (!arrayString || !decodeArrayOutput) {
    return defaultValue
  }
  return decodeArrayOutput.map(decodeNumberOrString)
}

export const dateTimeQueryParamType = {
  encode: encodeDateTime,
  decode: decodeDateTime,
}

export const numberOrStringQueryParamType = {
  encode: encodeString,
  decode: decodeNumberOrString,
}

export const numericOrStringArrayQueryParamType = {
  encode: encodeArray,
  decode: decodeNumericOrStringArray,
}

export const commaArrayType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  encode: (value: any[]) => encodeArray(value),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decode: (value: string | null, defaultValue: any[]) =>
    value ? decodeArray(value) : defaultValue,
}
