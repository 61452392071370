import { useIntl } from "@Common/locale/useIntl"
import { Box, Icon } from "@kaizen/component-library"
import { FormattedMessage } from "react-intl"
import strings from "@Locale/strings"
import moment from "moment"
import { Tooltip } from "@Components/Tooltip"
import * as React from "react"
import userDeleteIcon from "@kaizen/component-library/icons/user-delete.icon.svg"
import { TruncatableText } from "@Calibrations/components/TruncatableText/TruncatableText"
import { Employee } from "@Calibrations/v2/queries/useGetReviewResults"
import styles from "./UserInformation.scss"

type UserInformationProps = {
  employee: Employee
}

export const UserInformation: React.FC<UserInformationProps> = ({
  employee,
}) => {
  const { formatMessage } = useIntl()

  if (!employee) {
    return (
      <div>
        <FormattedMessage {...strings.user.deleted} />
      </div>
    )
  }

  return (
    <div className={styles.userInformationWrapper}>
      <div className={styles.userNameWrapper}>
        <div className={styles.userName}>
          <TruncatableText>{employee.name}</TruncatableText>
        </div>
        {moment().isAfter(employee.deactivatedAt) ? (
          <Box ml={0.5}>
            <Tooltip text={formatMessage(strings.user.deactivatedTag)}>
              <div>
                <Icon
                  icon={userDeleteIcon}
                  title={formatMessage(strings.user.deactivatedTag)}
                />
              </div>
            </Tooltip>
          </Box>
        ) : null}
      </div>
      <div className={styles.jobTitle}>
        <TruncatableText>{employee.jobTitle}</TruncatableText>
      </div>
    </div>
  )
}
