import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import SearchAgent from "./lib/SearchAgent"
import { addLoadingState } from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS, PEER_FEEDBACK_URLS } = endpoints
const { PEER_FB_FEEDBACK_FROM_URL } = PEER_FEEDBACK_URLS
const { FEEDBACK_INIVITATIONS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const requestHistorySearchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: FEEDBACK_INIVITATIONS_URL,
  resourceName: "feedback_invitations",
  pageSize: 25,
})

const FeedbackRequestActions = Reflux.createActions({
  loadReviewers: { asyncResult: true },
  filterDirects: {},
  reset: {},
  setReviewers: {},
  setCustomQuestion: {},
  loadRequestHistory: { asyncResult: true },
  pageRequestHistory: { asyncResult: true },
})

FeedbackRequestActions.loadReviewers.listenAndPromise(
  ({ query, revieweeId, excludeDirectsFor, directs }) => {
    const params = {
      q: query,
      reviewee_id: revieweeId,
      exclude_directs_for: excludeDirectsFor,
      directs: directs,
    }

    return agent
      .get(PEER_FB_FEEDBACK_FROM_URL)
      .query(params)
      .then((result) => ({
        ...result,
        users: result.body.users,
      }))
  }
)

function searchRequestHistory({ page, query, type }) {
  return requestHistorySearchAgent.search({
    searchParams: {
      q: query,
      type,
    },
    page,
  })
}

FeedbackRequestActions.loadRequestHistory.listenAndPromise(
  ({ query, type, page }) =>
    addLoadingState(
      [true, { light: true }],
      searchRequestHistory({ page, query, type })
    )
)

FeedbackRequestActions.pageRequestHistory.listenAndPromise(
  ({ query, type, page }) => searchRequestHistory({ page, query, type })
)

export default FeedbackRequestActions
