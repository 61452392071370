import * as React from "react"
import { Tooltip } from "@Components/Tooltip"

export const StatTitle = ({
  title,
  description,
}: {
  title: string
  description: string | null
}) => {
  if (description) {
    return (
      <Tooltip
        text={description}
        classNameOverride={"w-full"}
        position={"above"}
        animationDuration={0.3}
        renderLocation="root"
      >
        <div className="text-center justify-center text-heading-5 leading-heading-5 font-family-paragraph font-weight-paragraph-bold text-ellipsis overflow-hidden">
          {title}
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="text-center justify-center text-heading-5 leading-heading-5 font-family-paragraph font-weight-paragraph-bold text-ellipsis overflow-hidden">
      {title}
    </div>
  )
}
