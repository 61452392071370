import { KeyResult } from "@Goals/types"
import React from "react"
import Aid from "@Constants/automationId"
import { EmptyKeyResultFormItem, KeyResultFormItem } from "@Goals/components"

const EMPTY_KEY_RESULT_ID = "empty-key-result-id"

export type KeyResultFormListProps = {
  currentKeyResultId: string | null
  keyResults: KeyResult[]
  onSelect: (id: string) => void
  onCancel: () => void
  onUpdate: (keyResults: KeyResult[]) => void
  disableEditFields?: boolean
}

export const KeyResultFormList = ({
  currentKeyResultId,
  keyResults,
  onSelect,
  onUpdate,
  onCancel,
  disableEditFields = false,
}: KeyResultFormListProps) => {
  const addKeyResult = (keyResult: KeyResult) => {
    const updatedKeyResults = [...keyResults, keyResult]
    onUpdate(updatedKeyResults)
  }

  const removeKeyResult = (id: string) => {
    const updatedKeyResults = keyResults.filter((kr) => kr.id !== id)
    onUpdate(updatedKeyResults)
  }

  const updateKeyResult = (keyResult: KeyResult) => {
    const updatedKeyResults = keyResults.map((kr) =>
      keyResult.id === kr.id ? keyResult : kr
    )
    onUpdate(updatedKeyResults)
  }

  return (
    <div data-automation-id={Aid.goalsKeyResultFormList}>
      {keyResults.map((keyResult) => (
        <KeyResultFormItem
          automationId={Aid.newGoalCreateKeyResultItem}
          key={keyResult.id}
          keyResult={keyResult}
          isEditing={currentKeyResultId === keyResult.id}
          onUpdate={updateKeyResult}
          onSelect={(kr) => onSelect(kr.id)}
          onCancelSelect={onCancel}
          onRemove={(kr) => removeKeyResult(kr.id)}
          disableEditFields={disableEditFields}
        />
      ))}
      <EmptyKeyResultFormItem
        automationId={Aid.newGoalCreateKeyResultButton}
        isEditing={currentKeyResultId === EMPTY_KEY_RESULT_ID}
        onSubmit={addKeyResult}
        onSelect={() => onSelect(EMPTY_KEY_RESULT_ID)}
        onCancelSelect={onCancel}
        disableEditFields={disableEditFields}
      />
    </div>
  )
}
