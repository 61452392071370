import * as React from "react"
import { Select } from "@kaizen/draft-select"
import _ from "lodash"
import { TruncatableText } from "@Calibrations/components/TruncatableText/TruncatableText"
import {
  Question,
  QuestionAnswer,
  ReviewResult,
} from "@Calibrations/v2/queries/useGetReviewResults"
import Avo from "@Analytics/avo/Avo"
import { useUpdateAnswer } from "@Calibrations/v2/queries/useUpdateAnswer"
import styles from "./QuestionCell.scss"

type RatingQuestionCellProps = {
  answer: QuestionAnswer
  question: Question
  review: ReviewResult
  editable: boolean
  calibrationId: number
}

export const RatingScaleQuestionCell: React.FC<RatingQuestionCellProps> = ({
  answer,
  question,
  review,
  editable,
  calibrationId,
}) => {
  const options = _(question.choices)
    .map((title, value) => ({
      label: title,
      value: value,
    }))
    .sortBy("value")
    .value()

  const mutation = useUpdateAnswer({
    calibrationId: calibrationId,
  })

  const currentAnswer = {
    value: answer.answer,
    label: answer.answer ? question.choices[answer.answer] : "-",
  }

  if (!editable) {
    return (
      <div data-automation-id="non-editable-question">
        {answer.answer && question.choices ? (
          <TruncatableText>{question.choices[answer.answer]}</TruncatableText>
        ) : (
          "-"
        )}
      </div>
    )
  }

  return (
    <div
      data-automation-id="editable-question"
      className={styles.answerSelectMenu}
    >
      <Select
        value={{ value: currentAnswer.value, label: currentAnswer.label }}
        options={options}
        onChange={(newValue) => {
          mutation
            .mutateAsync({
              calibrationId,
              managerReviewId: review.managerReview.id,
              answer: { ...answer, answer: newValue.value },
            })
            .then(() => {
              Avo.calibrationViewManagerRatingChanged({
                calibrationViewId: calibrationId,
                performanceQuestionType: "Rating",
                managerReviewCycleId: review.managerReviewCycle.id,
                managerReviewId: review.managerReview.id,
              })
            })
        }}
      />
    </div>
  )
}
