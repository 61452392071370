import "./MarkdownText.scss"
import React from "react"
// @ts-expect-error - We are importing a .js file that doesn't have a declaration
import remarkPing from "remark-ping"
import cx from "classnames"
import { Paragraph, Heading } from "@kaizen/typography"
import { shortnameToUnicode } from "./emoji"
import ReactMarkdown from "./react-markdown"
import remarkSyntaxWhitelist from "./remarkSyntaxWhitelist"
import {
  REMARK_PING_CONFIG,
  SYNTAX_WHITELIST,
  SYNTAX_WHITELIST_INLINE,
} from "./remarkConstants"

type HeadingVariant = "heading-4" | "heading-5"

export type Props = {
  className?: string
  text: string | undefined | null
  inline?: boolean
  isTitle?: boolean
  headingVariant?: HeadingVariant
}

/** @deprecated
 * Handle rich text as ProseMirror using:
 * @external kaizen/rich-text-editor
 * @see {@link https://github.com/cultureamp/kaizen-design-system/tree/main/packages/rich-text-editor}
 *
 * Before attempting to delete this file, or replace it with our
 * prosemirror-to-markdown encoder/decoder to utilise Kaizen's RTE,
 * We will need to figure out backwards compatability, as Kaizen's RTE is based
 * on [Markdown-IT]{@link https://github.com/markdown-it/markdown-it}, which uses
 * a different markdown spec to draft-js.

 * Rendering some legacy markdown data with our new RTE component will result in
 * formatting errors.
 */
export const MARKDOWN_NODE_RENDERERS = {
  // eslint-disable-next-line react/display-name
  paragraph: ({ children }: { children: React.ReactNode }) => (
    <Paragraph variant="body">{children}</Paragraph>
  ),

  // eslint-disable-next-line react/display-name
  link: ({
    href,
    title,
    children,
  }: {
    title: string
    href: string
    children: React.ReactNode
  }) => (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ),

  // eslint-disable-next-line react/display-name
  ping: ({ children }: { children: React.ReactNode }) => (
    <span className="MarkdownText--mention">{children}</span>
  ),

  // Unfortunately, the remark parser parses both 'image' and 'link' from the 'link' tokenizer. So
  // we have to block images at the render phase rather than the parse phase. Also, using
  // react-markdown's disallowedTypes + unwrapDisallowed for this seems to throw an error (as of
  // react-markdown 3.1.4).
  // eslint-disable-next-line react/display-name
  image: ({ alt }: { alt: string }) => <span>{alt}</span>,

  // eslint-disable-next-line react/display-name
  list: ({
    ordered,
    start,
    children,
  }: {
    start: string
    ordered: boolean
    children: React.ReactChild[]
  }) =>
    React.createElement(
      ordered ? "ol" : "ul",
      {
        className: "MarkdownText--content-block",
        start,
      },
      ...children
    ),
}

export const MARKDOWN_NODE_RENDERERS_INLINE = {
  ...MARKDOWN_NODE_RENDERERS,

  // For the inline case, lists are blocked at the syntax level in SYNTAX_WHITELIST_INLINE. But
  // the remark parser doesn't seem to work if you disable the paragraph syntax, so they're rendered
  // as inline elements here
  // eslint-disable-next-line react/display-name
  paragraph: ({ children }: { children: React.ReactNode }) => (
    <span className="MarkdownText--inline-paragraph">{children}</span>
  ),
}

const MARKDOWN_NODE_RENDERERS_TITLE = (headingVariant: HeadingVariant) => {
  return {
    ...MARKDOWN_NODE_RENDERERS,
    // eslint-disable-next-line react/display-name
    paragraph: ({ children }: { children: React.ReactNode }) => (
      <Heading variant={headingVariant}>{children}</Heading>
    ),
  }
}
const MarkdownText = (props: Props) => {
  const {
    className,
    text,
    inline,
    isTitle = false,
    headingVariant = "heading-4",
  } = props

  return (
    <ReactMarkdown
      source={shortnameToUnicode(text || "")}
      className={cx("MarkdownText", className, {
        "MarkdownText--inline": inline,
      })}
      plugins={[
        [
          remarkSyntaxWhitelist,
          inline ? SYNTAX_WHITELIST_INLINE : SYNTAX_WHITELIST,
        ],
        [remarkPing, REMARK_PING_CONFIG],
      ]}
      escapeHtml={true}
      renderers={
        inline
          ? MARKDOWN_NODE_RENDERERS_INLINE
          : isTitle
          ? MARKDOWN_NODE_RENDERERS_TITLE(headingVariant)
          : MARKDOWN_NODE_RENDERERS
      }
    />
  )
}

export default MarkdownText
