import * as React from "react"
import { Tooltip } from "@Components/Tooltip"
import { useIntl } from "react-intl"
import strings from "@Calibrations/locale/strings"
import { HeroStatsResponse } from "@Calibrations/v2/queries/useGetHeroStats"
import { Tab } from "@kaizen/tabs"

type Props = {
  assessmentGroups: HeroStatsResponse["statistics"]["assessmentGroups"]
}
const {
  components: { CalibrationHeroStats },
} = strings

export const AssessmentGroupsTab = ({ assessmentGroups }: Props) => {
  const { formatMessage } = useIntl()
  if (!assessmentGroups) {
    return (
      <Tooltip
        text={formatMessage(
          strings.components.CalibrationHeroStats
            .conflictingPerformanceRatingsTooltip
        )}
        renderLocation="root"
        animationDuration={0.3}
      >
        <Tab disabled>
          {formatMessage(CalibrationHeroStats.performanceRating)}
        </Tab>
      </Tooltip>
    )
  }

  if (assessmentGroups.length === 0) {
    return (
      <Tooltip
        text={formatMessage(CalibrationHeroStats.noPerformanceRatingsTooltip)}
        renderLocation="root"
        animationDuration={0.3}
      >
        <Tab disabled>
          {formatMessage(CalibrationHeroStats.performanceRating)}
        </Tab>
      </Tooltip>
    )
  }

  return (
    <Tab>
      {formatMessage(strings.components.CalibrationHeroStats.performanceRating)}
    </Tab>
  )
}
