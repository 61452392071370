import React, { useEffect } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { useFlags } from "@cultureamp/ca-launchdarkly/react"
import { getPathQueryHash, logRedirectWarning } from "@Utils/routing"
import { EnumFlagValues } from "@Constants/launchDarklyFlags"

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface LDFeatureToggleProps<T extends keyof EnumFlagValues> {
  launchDarklyFlagKey: T
  On?: React.FunctionComponent<any>
  Off?: React.FunctionComponent<any>
  onRedirect?: string
  offRedirect?: string
  /**
   * A function to run to evaluate whether the flag should be set to true
   * Example:
   * predicate: (val) => value === 'V2'
   * */
  predicate?: (flagValue: EnumFlagValues[T]) => boolean
}

/**
 * A hoc version of launch darkly feature flagging. Handy to use with the routes.jsx config.
 *
 * More info: docs/featureFlags.md
 */
export function ldFeatureToggle<T extends keyof EnumFlagValues>({
  launchDarklyFlagKey,
  On,
  Off,
  onRedirect,
  offRedirect,
  predicate = (val) => !!val,
}: LDFeatureToggleProps<T>): React.ComponentType {
  function LDFeatureToggleWrapper(props: RouteComponentProps): JSX.Element {
    // TODO: can we have below validation as part of type definitions
    if (!On && !onRedirect) {
      throw new Error("Either a On or onRedirect is required")
    }
    if (!Off && !offRedirect) {
      throw new Error("Either a Off or offRedirect is required")
    }
    const isLDFlagEnabled = predicate(useFlags()[launchDarklyFlagKey])
    const { location, history } = props

    useEffect(() => {
      if (isLDFlagEnabled) {
        if (onRedirect) {
          logRedirectWarning(
            getPathQueryHash(location),
            onRedirect,
            `the "${launchDarklyFlagKey}" feature flag is turned on`
          )
          history.replace(onRedirect)
        }
      } else {
        if (offRedirect) {
          logRedirectWarning(
            getPathQueryHash(location),
            offRedirect,
            `the "${launchDarklyFlagKey}" feature flag is turned off`
          )
          history.replace(offRedirect)
        }
      }
      // If 'location' and 'history' are added to the deps, the infinite loop occurs as history deps is getting updated
      // in useEffect to redirect to either onRedirect/offRedirect.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLDFlagEnabled])

    if (isLDFlagEnabled) {
      return On ? <On {...props} /> : <></>
    } else {
      return Off ? <Off {...props} /> : <></>
    }
  }

  return withRouter(LDFeatureToggleWrapper)
}
