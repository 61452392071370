import { PrivateRoute } from "@Common/routing"
import { GoalsApp } from "@Goals/containers/GoalsApp/GoalsApp"
import * as React from "react"
import { hot } from "react-hot-loader/root"
import { Redirect, Route, Switch } from "react-router-dom"
import { ConfigurationOptions } from "@Constants/configurationOptions"
import { RoutePaths } from "@Goals/constants"
import { GoalStateProvider } from "@Goals/context/goalState"
import { GoalTreeStateProvider } from "@Goals/context/goalTreeState"

const EditPersonalGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/EditPersonalGoal/EditPersonalGoal"
    )
)
const EditDepartmentGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/EditDepartmentGoal/EditDepartmentGoal"
    )
)
const EditCompanyGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/EditCompanyGoal/EditCompanyGoal"
    )
)
const GoalsList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/GoalsList/GoalsList"
    )
)
const NewPersonalGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/NewPersonalGoal/NewPersonalGoal"
    )
)
const NewDepartmentGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/NewDepartmentGoal/NewDepartmentGoal"
    )
)
const NewCompanyGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/NewCompanyGoal/NewCompanyGoal"
    )
)
const TeamSummariesList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/TeamSummariesList/TeamSummariesList"
    )
)
const DepartmentSummariesList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/DepartmentSummariesList/DepartmentSummariesList"
    )
)
const TeamSummaryDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/TeamSummaryDetail/TeamSummaryDetail"
    )
)
const NewTeamGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/NewTeamGoal/NewTeamGoal"
    )
)
const PersonalGoalsList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/PersonalGoalsList/PersonalGoalsList"
    )
)
const GoalDirectReportList = React.lazy(
  () =>
    import(
      // @ts-expect-error - This should be typed
      /* webpackChunkName: "goals" */ "@Goals/containers/GoalDirectReport/GoalDirectReport"
    )
)
const EditTeamGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/EditTeamGoal/EditTeamGoal"
    )
)
const CompanySummariesList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/CompanySummariesList/CompanySummariesList"
    )
)
const GoalsTree = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/GoalsTree/GoalsTree"
    )
)

const CopyPersonalGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/CopyPersonalGoal/CopyPersonalGoal"
    )
)

const CopyTeamGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/CopyTeamGoal/CopyTeamGoal"
    )
)

const CopyDepartmentGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/CopyDepartmentGoal/CopyDepartmentGoal"
    )
)

const CopyCompanyGoal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "goals" */ "@Goals/containers/CopyCompanyGoal/CopyCompanyGoal"
    )
)

export const GoalsRoutes = (
  <PrivateRoute
    path="/new_goals"
    hasConfigurationOption="allow_new_goals_interface"
  >
    <GoalStateProvider>
      <GoalTreeStateProvider>
        <GoalsApp>
          <Switch>
            <Redirect exact from="/new_goals" to="/new_goals/personal" />
            <Route
              exact
              path="/new_goals/personal/new"
              component={hot(NewPersonalGoal)}
            />
            <Route
              exact
              path="/new_goals/personal/copy/:goalId"
              component={hot(CopyPersonalGoal)}
            />
            <Route
              exact
              path="/new_goals/team/:teamId/copy/:goalId"
              component={hot(CopyTeamGoal)}
            />
            <Route
              exact
              path="/new_goals/department/:departmentId/copy/:goalId"
              component={hot(CopyDepartmentGoal)}
            />
            <Route
              exact
              path="/new_goals/company/copy/:goalId"
              component={hot(CopyCompanyGoal)}
            />
            <PrivateRoute
              exact
              path="/new_goals/team/new"
              hasConfigurationOption={ConfigurationOptions.teamGoals}
              component={hot(NewTeamGoal)}
            />
            <PrivateRoute
              exact
              path="/new_goals/team/:teamId/new"
              hasConfigurationOption={ConfigurationOptions.teamGoals}
              component={hot(NewTeamGoal)}
            />
            <Route
              exact
              path="/new_goals/department/new"
              component={hot(NewDepartmentGoal)}
            />
            <Route
              exact
              path="/new_goals/company/new"
              component={hot(NewCompanyGoal)}
            />

            <Route
              exact
              path="/new_goals/personal/edit/:goalId"
              component={hot(EditPersonalGoal)}
            />
            <PrivateRoute
              path="/new_goals/team/:teamId/edit/:goalId"
              hasConfigurationOption={ConfigurationOptions.teamGoals}
              component={hot(EditTeamGoal)}
            />
            <Route
              exact
              path="/new_goals/company/edit/:goalId"
              component={hot(EditCompanyGoal)}
            />
            <Route
              path="/new_goals/department/:departmentId/edit/:goalId"
              component={hot(EditDepartmentGoal)}
            />
            <GoalsList>
              <Switch>
                <Route
                  exact
                  path="/new_goals/personal"
                  component={hot(PersonalGoalsList)}
                />
                <Route
                  exact
                  path="/new_goals/directReport"
                  component={hot(GoalDirectReportList)}
                />
                <PrivateRoute
                  exact
                  path="/new_goals/team"
                  hasConfigurationOption={ConfigurationOptions.teamGoals}
                  component={hot(TeamSummariesList)}
                />
                <PrivateRoute
                  exact
                  path="/new_goals/team/:teamId"
                  hasConfigurationOption={ConfigurationOptions.teamGoals}
                  component={hot(TeamSummaryDetail)}
                />
                <Route
                  exact
                  path="/new_goals/department"
                  component={hot(DepartmentSummariesList)}
                />
                <Route
                  exact
                  path={RoutePaths.companyGoalsList}
                  component={hot(CompanySummariesList)}
                />
                <Route
                  exact
                  path={RoutePaths.goalsTree}
                  component={hot(GoalsTree)}
                />
              </Switch>
            </GoalsList>
          </Switch>
        </GoalsApp>
      </GoalTreeStateProvider>
    </GoalStateProvider>
  </PrivateRoute>
)
