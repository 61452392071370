import React, { useEffect, useRef } from "react"
import {
  GoalDirectReportFilter,
  GoalFilter,
  GoalType,
  IndividualGoalStatus,
  IndividualGoalSubtype,
} from "@Goals/types"
import { useLocalStorageState } from "@Goals/hooks/useLocalStorageState"
import { useIntl } from "@Common/locale/useIntl"
import useCurrentUser from "@Domain/auth/useCurrentUser"
import { generateGoalCycleOptions } from "@Components/FilterContainer/FilterContainer"

type GoalFilterKey = GoalType | "directReport"
export type GoalFilterValue = GoalFilter & GoalDirectReportFilter

interface GoalState {
  filters: Record<GoalFilterKey, GoalFilterValue>
  updateFilters: (
    type: GoalFilterKey,
    filters: Partial<GoalFilterValue>
  ) => void
}

export const GoalStateContext = React.createContext<GoalState | undefined>(
  undefined
)

const GOAL_FILTERS_STORAGE_KEY = "CA.goals.filters"

type GoalFilterRecords = Record<GoalFilterKey, GoalFilterValue>

export const defaultFilters: GoalFilterRecords = {
  personal: {},
  team: {
    all_teams: false,
    q: "",
  },
  department: {
    my_department: true,
    q: "",
  },
  company: {},
  directReport: {},
}

interface GoalStateProviderProps {
  children: React.ReactNode
}

export const statusesValues = Object.values(IndividualGoalStatus)
export const subtypesValues = Object.values(IndividualGoalSubtype)

export function GoalStateProvider({ children }: GoalStateProviderProps) {
  const { formatMessage, formatDate } = useIntl()
  const currentUser = useCurrentUser()

  const goalCyclesCompanyOptions = React.useMemo(() => {
    return generateGoalCycleOptions(
      currentUser.company,
      formatMessage,
      formatDate
    )
  }, [currentUser.company, formatMessage, formatDate])

  const [filters, setFilters] = useLocalStorageState<GoalFilterRecords>(
    GOAL_FILTERS_STORAGE_KEY,
    defaultFilters
  )

  const filterValueSet = useRef(false)

  useEffect(() => {
    if (!filterValueSet.current && goalCyclesCompanyOptions.length > 0) {
      const defaultFilters = {
        from: goalCyclesCompanyOptions[0].values[0].startOf("day").toDate(),
        to: goalCyclesCompanyOptions[0].values[1].endOf("day").toDate(),
        "statuses[]": statusesValues,
      }
      setFilters((previousFilters: GoalFilterRecords) => {
        return {
          ...previousFilters,
          personal: {
            ...defaultFilters,
            ...{ "sub_types[]": ["", "development", "business"] },
            ...previousFilters["personal"],
          },
          team: { ...defaultFilters, ...previousFilters["team"] },
          department: {
            ...defaultFilters,
            ...previousFilters["department"],
          },
          company: { ...defaultFilters, ...previousFilters["company"] },
        }
      })
      filterValueSet.current = true
    }
  }, [goalCyclesCompanyOptions, goalCyclesCompanyOptions.length, setFilters])

  const updateFilters = React.useCallback(
    (type: GoalFilterKey, updatedFilters: Partial<GoalFilterValue>) => {
      setFilters((prevFilters: GoalFilterRecords) => ({
        ...prevFilters,
        [type]: {
          ...prevFilters[type],
          ...updatedFilters,
        },
      }))
    },
    [setFilters]
  )

  return (
    <GoalStateContext.Provider
      value={{
        filters,
        updateFilters,
      }}
    >
      {children}
    </GoalStateContext.Provider>
  )
}

export function useGoalState() {
  const context = React.useContext(GoalStateContext)

  if (context === undefined) {
    throw new Error("useGoalState must be used within GoalStateProvider")
  }

  return context
}
