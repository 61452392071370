import { Button } from "@kaizen/button"
import { Card } from "@kaizen/draft-card"
import { MeetingVoices } from "@kaizen/draft-illustration"
import { Heading, Paragraph } from "@kaizen/typography"
import React from "react"
import { basePath } from "@Constants/routes"
import strings from "@Locale/strings"
import { FormattedMessage, useIntl } from "react-intl"
import { useFlags } from "@cultureamp/ca-launchdarkly/react"

type ContinuousFeedbackBannerProps = {
  context: string
  reviewee: {
    id: string
    best_name?: string
    full_name: string
  }
  text: string
  classNameOverride?: string
  dossierLayout?: boolean
}

export const ContinuousFeedbackBanner: React.FunctionComponent<
  ContinuousFeedbackBannerProps
> = ({ context, reviewee, text, classNameOverride, dossierLayout = false }) => {
  const { formatMessage } = useIntl()
  const { continuousPerformanceAnytimeFeedbackEnable } = useFlags()

  const giveFeedbackUrl = continuousPerformanceAnytimeFeedbackEnable
    ? `/app/anytime-feedback/give?subjectId=${encodeURIComponent(
        reviewee.id
      )}&context=${encodeURIComponent(context)}`
    : `${basePath}/quick_comment?revieweeId=${encodeURIComponent(
        reviewee.id
      )}&context=${encodeURIComponent(context)}`

  return (
    <Card
      tag="div"
      variant="highlight"
      classNameOverride={`flex items-center p-12 pl-[1.25rem] ${classNameOverride}`}
    >
      {!dossierLayout && (
        <div className="w-80 h-80 flex-grow-0 flex-shrink-0 mr-12">
          <MeetingVoices alt="" />
        </div>
      )}

      <div
        className={`flex flex-grow items-center ${
          dossierLayout && `flex-col text-center`
        }`}
      >
        <div className="flex-grow-0 flex-shrink w-3/4">
          <Heading
            color="dark"
            variant="heading-6"
            tag="span"
            classNameOverride={dossierLayout ? `block mb-[0.45rem]` : ""}
          >
            <FormattedMessage
              {...strings.general.giveRevieweeFeedback}
              values={{ reviewee: reviewee.best_name ?? reviewee.full_name }}
            />
          </Heading>{" "}
          <Paragraph tag="span" variant="small">
            {text}
          </Paragraph>
        </div>
        <div className={`flex-grow text-center ${dossierLayout && `mt-16`}`}>
          <Button
            label={formatMessage(strings.general.giveFeedback)}
            primary
            onClick={() => {
              window.open(giveFeedbackUrl, "_blank")
            }}
          />
        </div>
      </div>
    </Card>
  )
}
