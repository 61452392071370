import * as React from "react"
import { OpenEndedQuestionCell } from "@Calibrations/v2/components/TableCells/QuestionCell/OpenEndedQuestionCell"
import { RatingScaleQuestionCell } from "@Calibrations/v2/components/TableCells/QuestionCell/RatingScaleQuestionCell"
import {
  Question,
  QuestionAnswer,
  ReviewResult,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { useParams } from "react-router-dom"

const isQuestionMultipleChoice = ({ question }: { question: Question }) => {
  return question.questionType === "multiple-choice"
}

const isQuestionOpenEnded = ({ question }: { question: Question }) => {
  return question.questionType === "open-ended"
}

type QuestionCellProps = {
  review: ReviewResult
  answer: QuestionAnswer
  question: Question
  editable: boolean
}

export const QuestionCell: React.FC<QuestionCellProps> = ({
  review,
  answer,
  question,
  editable,
}) => {
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()

  if (!review.managerReview.managerCompletedAt) {
    return <>-</>
  }

  if (!answer) {
    return <>-</>
  }

  if (isQuestionOpenEnded({ question }) && answer.answer) {
    return <OpenEndedQuestionCell answerText={answer.answer} />
  }

  if (isQuestionMultipleChoice({ question }) && answer.answer) {
    return (
      <RatingScaleQuestionCell
        answer={answer}
        review={review}
        question={question}
        editable={editable}
        calibrationId={Number(calibrationViewId)}
      />
    )
  }
  return <>-</>
}
