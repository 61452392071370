import { MessageDescriptor } from "react-intl"
import {
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  JsonParam,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
} from "use-query-params"
import {
  EvaluationState,
  ModuleMap,
  PaufReviewKind,
  QuestionsListType,
} from "../types/EvaluationCycle"
import strings from "../locale/strings"
import { numericOrStringArrayQueryParamType } from "../utils/urlQueryTypes"

type ModuleToStringMapping = {
  [K in keyof ModuleMap]: MessageDescriptor
}

export const CYCLE_SLUG = "cycle"

export const moduleTitles: ModuleToStringMapping = {
  peer_and_upward_feedback: strings.adminEc.peerAndUpwardFeedback,
  manager_evaluation: strings.adminEc.managerEvaluation,
  self_review: strings.adminEc.selfReview,
}

type QuestionToStringMapping = {
  [K in QuestionsListType]: MessageDescriptor
}

export const questionTitles: QuestionToStringMapping = {
  self_review: strings.adminEc.selfReview,
  peer_feedback: strings.adminEc.peerFeedback,
  upward_feedback: strings.adminEc.upwardFeedback,
  manager_evaluation: strings.adminEc.managerEvaluation,
}

export const statusTitles: {
  [state in EvaluationState]: MessageDescriptor
} = strings.adminEc.state

export const paufReviewKindTitles: {
  [kind in PaufReviewKind]: MessageDescriptor
} = {
  PeerFeedback: strings.ecPaufRequestFeedback.peer,
  UpwardFeedback: strings.ecPaufRequestFeedback.upward,
}

export const evaluationCycleFiltersQueryConfig: QueryParamConfigMap = {
  managerIds: DelimitedNumericArrayParam,
  jobTitleIds: DelimitedNumericArrayParam,
  departmentIds: DelimitedNumericArrayParam,
  // We need to make this a json object, rather than an array, because
  // the arrays in use-query-params are underscore (_) delimited.
  // Any values with an underscore are not escaped when calling `encode` 🙄.
  // eg. ["business_unit", "country"] would encode to "business_unit_country",
  // which would decode back to ["business", "unit", "country"].
  // The quick fix, was to change this from an array, to json.
  // An alternative would have been to change the delimiter character
  // to something less likely to fail, like a pipe (|), but this setting
  // can only be made globally (🙄) via the `configureUrlQuery` call with
  // an `entrySeparator` value. So I didn't want to risk a regression.
  moreFiltersIds: JsonParam,
  demographicValueIds: DelimitedArrayParam,
  levelIds: JsonParam,
  groupTypes: DelimitedArrayParam,
  userStatus: DelimitedArrayParam,
  columns: DelimitedArrayParam,
  questionId: StringParam,
  cycleIds: DelimitedNumericArrayParam,
  // @ts-expect-error - This should be typed
  bucketIds: numericOrStringArrayQueryParamType,
  panelReviewId: NumberParam,
}

export const evaluationCycleSortingQueryConfig: QueryParamConfigMap = {
  sortBy: StringParam,
  sortOrder: StringParam,
}

export const evaluationCycleUserSearchQueryConfig: QueryParamConfigMap = {
  userIds: DelimitedArrayParam,
}
