import { Box, Icon } from "@kaizen/component-library"
import { Paragraph, Heading } from "@kaizen/typography"
import { Select } from "@kaizen/select"
import moment from "moment"
import * as React from "react"
import { useCallback, useEffect, useState, useContext, useMemo } from "react"
import { useIntl } from "@Common/locale/useIntl"
import { useHistory, useLocation } from "react-router-dom"
import { Divider } from "@kaizen/draft-divider"
import DateStartIcon from "@kaizen/component-library/icons/date-start-white.icon.svg"
import FlagOnIcon from "@kaizen/component-library/icons/flag-on.icon.svg"
import VisibileIcon from "@kaizen/component-library/icons/visible.icon.svg"
import { ProgressSliderFacade } from "@Goals/components"
import { KeyResult, RawGoalStatus } from "@Goals/types"
import { isDevelopModuleGoal } from "@Goals/domain/goal"
import { Menu, MenuList, MenuItem } from "@kaizen/draft-menu"
import meatballsIcon from "@kaizen/component-library/icons/meatballs.icon.svg"
import { IconButton } from "@kaizen/button"
import useGoalsTreeCacheInvalidator from "@Goals/hooks/useGoalsTreeCacheInvalidator"
import { Goals } from "@Goals/containers/GoalsApp/GoalsConfiguration"
import { useTrackOnView } from "@cultureamp/amplitude"
import { logGoalViewed } from "@Goals/analytics"
import { ConfigurationOptions } from "@Constants/configurationOptions"
import {
  fullAndManagerEditLevel,
  fullEditLevel,
  managerEditLevel,
} from "@Constants/goal"
import { GOAL_ICON } from "@Containers/GoalForm/constant"
import { translatedGoalSubtype } from "@Containers/GoalForm/utils"
import { Goal, GoalAction, UpdatingGoal } from "@Types/Goals"
import { hasConfigOption } from "@Domain/user/user"
import { CommentsProvider } from "@Redux/reducers/comments"
import { TeamSummariesContext } from "@Redux/reducers/teamSummaries"
import { MessageDescriptor } from "react-intl"
import { ContinuousFeedbackBanner } from "@Components/ContinuousFeedbackBanner/ContinuousFeedbackBanner"
import { useFlags } from "@cultureamp/ca-launchdarkly/react"
import ProgressBar from "../../components/ProgressBar/ProgressBar"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import useTeam from "../../domainHooks/teams/useTeam"
import useUpdateGoal from "../../domainHooks/goals/useUpdateGoal"
import GoalKeyResults from "./GoalKeyResults"
import GoalDeletionModal from "./GoalDeletionModal"
import GoalOwnersDetails from "./GoalOwnersDetails"
import OutgoingAlignedGoals from "../AlignedGoals/OutgoingAlignedGoals"
import IncomingAlignedGoals from "../AlignedGoals/IncomingAlignedGoals"
import GoalComments from "./GoalComments"
import styles from "./GoalDetails.scss"

interface GoalDetailsProps {
  goal: Goal
  userCannotEdit?: boolean
  effectiveOwnerId?: string
}

const statusLabels: Record<RawGoalStatus, MessageDescriptor> = {
  // Goals with a status of 'Created' should be displayed as being 'In
  // progress' in the UI
  ongoing: strings.goals.status.ongoing,
  created: strings.goals.status.ongoing,
  blocked: strings.goals.status.blocked,
  accomplished: strings.goals.status.accomplished,
}

const GoalDetails = ({
  goal,
  userCannotEdit,
  effectiveOwnerId,
}: GoalDetailsProps) => {
  const { moduleTogglesContinuousFeedback = true } = useFlags()
  const { formatMessage } = useIntl()

  const goalId = goal.id
  const user = useCurrentUser()
  const history = useHistory()
  const location = useLocation()
  const { team } = useTeam((goal.type === "team" && goal.teamId) || undefined)
  const {
    updateGoal,
    success: updateSuccess,
    error: updateError,
  } = useUpdateGoal(goalId)
  const { close: closeDossier } = useGoalDossier()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { showNotification } = useNotifications()
  const { keyResults } = goal

  const { invalidateOnGoalUpdated, invalidateOnGoalDeleted } =
    useGoalsTreeCacheInvalidator()

  const { dispatch } = useContext(TeamSummariesContext)

  const shouldShowGoalComments = (goal: Goal) => {
    if (goal.type === "department" || goal.type === "company") {
      return false
    } else if (goal.visibility === "owner") {
      return false
    } else {
      return true
    }
  }

  const selectedStatus = goal.status === "created" ? "ongoing" : goal.status

  // As part of the #cont_per_fast_launch feature, we need to know which user to link the "Give Feedback" button to.
  // Since goals can have multiple owners, the effectiveOwnerId can be used to determine the appropriate goal owner from
  // goal.owner array.
  const selected_goal_owner = goal.owners.find(
    (owner) => owner.aggregateId === effectiveOwnerId
  )

  useEffect(() => {
    if (updateSuccess) {
      showNotification({
        type: "positive",
        title: formatMessage(strings.goalsPage.goalsDossier.successTitle),
        message: formatMessage(
          strings.goalsPage.goalsDossier.successNotification
        ),
      })
      invalidateOnGoalUpdated(goal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess])

  useEffect(() => {
    if (updateError) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.goalsPage.goalsDossier.errorTitle),
        message: formatMessage(
          strings.goalsPage.goalsDossier.errorNotification
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError])

  useTrackOnView(() => {
    logGoalViewed({ goal, history })
  })

  const handleDeleteGoal = useCallback(() => {
    setShowDeleteConfirmation(true)
  }, [])

  const handleKeyResultChange = (keyResults: KeyResult[]) => {
    const updatedGoal: UpdatingGoal = {
      keyResults: keyResults,
    }
    updateGoal(updatedGoal)
  }

  const handleOnProgressChange = (val: number) => {
    const updatedGoal: UpdatingGoal = {
      completion: val / 100,
    }
    updateGoal(updatedGoal)
  }

  const renderVisibilityText = (goal: Goal) => {
    switch (goal.type) {
      case "personal":
        if (goal.visibility === "individuals") {
          return goal.individuals.map((val) => val.name).join(", ")
        }
        if (goal.visibility === "manager") {
          return formatMessage(strings.goals.visibilityLabel.myManager)
        }

        if (goal.visibility === "owner") {
          return formatMessage(strings.goals.visibilityLabel.onlyMe)
        }

        return formatMessage(strings.goals.visibilityLabel.everyone)
      case "team":
        if (goal.visibility === "team_only") {
          return formatMessage(strings.goals.visibilityLabel.teamOnly)
        }
        return formatMessage(strings.goals.visibilityLabel.everyone)
      default:
        return formatMessage(strings.general.everyone)
    }
  }

  const renderSubTypeText = () => {
    if (goal.type === "personal") {
      const subTypeKey = translatedGoalSubtype(goal.subType)
      return subTypeKey ? (
        <Paragraph variant="small">{`: ${formatMessage(
          subTypeKey
        )}`}</Paragraph>
      ) : null
    }

    return null
  }

  const userCanComment = () => {
    if (userCannotEdit) {
      return false
    }
    return goal.visibility !== "owner"
  }

  const userCanEdit = useMemo(() => {
    const editableLevels = [fullEditLevel, fullAndManagerEditLevel]
    if (!editableLevels.includes(goal.editLevel as string)) {
      return false
    }

    if (userCannotEdit) {
      return false
    }

    return true
  }, [goal.editLevel, userCannotEdit])

  const userCanCopy = React.useMemo(() => {
    if (isDevelopModuleGoal(goal)) {
      return false
    }
    // check user can create this type of goal
    return !Goals[goal.type].create.disabled(user, true)
  }, [goal, user])

  const managerCanEdit = React.useMemo(() => {
    const editableLevels = [fullAndManagerEditLevel, managerEditLevel]
    const isManager = editableLevels.includes(goal.editLevel as string)

    if (isManager) {
      // manager cannot modify direct report goals
      return goal.type !== "personal"
    }

    return userCanEdit
  }, [goal.type, goal.editLevel, userCanEdit])

  const canUpdateProgress = useMemo(() => {
    const editableLevels = [
      fullEditLevel,
      fullAndManagerEditLevel,
      managerEditLevel,
    ]
    if (!editableLevels.includes(goal.editLevel as string)) {
      return false
    }

    if (userCannotEdit) {
      return false
    }

    return true
  }, [goal.editLevel, userCannotEdit])

  const deptOrTeamName =
    goal.type === "team" && goal.teamName
      ? `: ${goal.teamName}`
      : goal.type === "department" && goal.departmentName
      ? `: ${goal.departmentName}`
      : null

  const renderGoalDetailSection = (goal: Goal) => {
    const goalTypeLabel = goal.type
      ? formatMessage(strings.goalsPage.goalsDossier.goalType[goal.type])
      : ""
    const goalDueDate = moment(goal.dueDate).format("MMMM DD, YYYY")
    return (
      <div>
        <Heading variant="heading-5">
          {formatMessage(strings.goalsPage.goalsDossier.details)}
        </Heading>
        <div className={styles.detailContent}>
          <div className={styles.detailContentIcon}>
            <Icon
              icon={GOAL_ICON[goal.type]}
              role="presentation"
              inheritSize={true}
            />
          </div>
          <Paragraph variant="small">
            {goalTypeLabel}
            {deptOrTeamName}
          </Paragraph>
          {renderSubTypeText()}
        </div>
        <div className={styles.detailContent}>
          <div className={styles.detailContentIcon}>
            <Icon icon={DateStartIcon} role="presentation" inheritSize={true} />
          </div>
          <Paragraph variant="small">{goalDueDate}</Paragraph>
        </div>
        <div className={styles.detailContent}>
          <div className={styles.detailContentIcon}>
            <Icon icon={FlagOnIcon} role="presentation" inheritSize={true} />
          </div>
          <Paragraph variant="small">
            {`${formatMessage(
              strings.general.priority[goal.priority]
            )} ${formatMessage(strings.prioritySelector.title)}`}
          </Paragraph>
        </div>
        <div className={styles.detailContent}>
          <div className={styles.detailContentIcon}>
            <Icon icon={VisibileIcon} role="presentation" inheritSize={true} />
          </div>
          <Paragraph variant="small">{renderVisibilityText(goal)}</Paragraph>
        </div>
      </div>
    )
  }

  const getGoalUrlForAction = useCallback(() => {
    return (action: GoalAction): string => {
      const isTreePage = location.pathname.includes("/tree")
      const returnToGoalTreeUrl = "/new_goals/company/tree"
      const returnUrl = isTreePage ? returnToGoalTreeUrl : Goals[goal.type].path

      switch (goal.type) {
        case "personal":
          return `/new_goals/personal/${action}/${goal.id}?returnUrl=${returnUrl}`
        case "team":
          return `/new_goals/team/${goal.teamId}/${action}/${goal.id}?returnUrl=${returnUrl}`
        case "department":
          return `/new_goals/department/${goal.departmentId}/${action}/${goal.id}?returnUrl=${returnUrl}`
        case "company":
          return `/new_goals/company/${action}/${goal.id}?returnUrl=${returnUrl}`
        default:
          return ""
      }
    }
  }, [goal, location.pathname])

  const editGoalUrl = React.useMemo(() => {
    return getGoalUrlForAction()("edit")
  }, [getGoalUrlForAction])

  const copyGoalUrl = React.useMemo(() => {
    return getGoalUrlForAction()("copy")
  }, [getGoalUrlForAction])

  const handleEditGoal = () => {
    history.push(editGoalUrl)
  }

  const handleCopyGoal = () => {
    history.push(copyGoalUrl)
  }

  const renderEditableKeyResultsSection = (
    keyResults?: KeyResult[],
    userCanEdit?: boolean
  ) => {
    if (keyResults && keyResults.length > 0) {
      return (
        <div
          data-automation-id="goal-details-progress-label"
          className={styles.progress}
        >
          <Heading variant="heading-4">
            {formatMessage(strings.goalsPage.goalsDossier.keyResults)}
          </Heading>
          <Heading variant="heading-5">
            {`${Math.round(goal.completion * 100)}% ${formatMessage(
              strings.goalsPage.goalsDossier.complete
            )}`}
          </Heading>
        </div>
      )
    }

    if (userCanEdit) {
      return (
        <div className={styles.progressSlider}>
          <Heading variant="heading-4">
            {formatMessage(strings.goalsPage.goalsDossier.keyResults)}
          </Heading>
          <div
            data-automation-id="goal-details-progress-slider"
            className={styles.slider}
          >
            <ProgressSliderFacade
              progress={Math.round(goal.completion * 100)}
              onProgressChange={handleOnProgressChange}
            />
          </div>
        </div>
      )
    }

    return (
      <div data-automation-id="goal-details-progress-bar">
        <ProgressBar value={goal.completion} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <>
        {showDeleteConfirmation && (
          <GoalDeletionModal
            goalId={goalId}
            onGoalDeleted={() => {
              invalidateOnGoalDeleted(goal)

              if (team && team.id) {
                dispatch({
                  type: "UPDATE_TEAM_SUMMARIES",
                  payload: { teamId: team.id },
                })
              }
              closeDossier()
            }}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
        <div className={styles.goalDetailTitleContainer}>
          <div className={styles.goalDetailTitle}>
            <Heading variant="heading-3">{goal.name}</Heading>
          </div>
          {userCanEdit || userCanCopy ? (
            <Menu
              automationId="goal-details-action-buttons"
              button={<IconButton label="More actions" icon={meatballsIcon} />}
              align="right"
            >
              <MenuList>
                {userCanEdit && (
                  <MenuItem
                    automationId="goal-details-action-button-item"
                    onClick={handleEditGoal}
                    label={formatMessage(strings.goals.edit)}
                  />
                )}
                {userCanCopy && (
                  <MenuItem
                    automationId="goal-details-action-button-item"
                    onClick={handleCopyGoal}
                    label={formatMessage(strings.goals.copy)}
                  />
                )}
                {userCanEdit && (
                  <MenuItem
                    automationId="goal-details-action-button-item"
                    onClick={handleDeleteGoal}
                    label={formatMessage(strings.goals.delete)}
                    destructive
                  />
                )}
              </MenuList>
            </Menu>
          ) : null}
        </div>
        {goal.description && goal.description !== "" ? (
          <Box mb={1} pr={2.5}>
            <Paragraph variant="small">{goal.description}</Paragraph>
          </Box>
        ) : null}

        <div className={styles.statusInfoContainer}>
          {renderGoalDetailSection(goal)}
          <div className={styles.status}>
            {userCanEdit ? (
              <Select
                id="goal-details-status"
                label={formatMessage(strings.goalsPage.goalsDossier.status)}
                key="goal-details-status"
                selectedKey={selectedStatus}
                items={[
                  {
                    value: "ongoing",
                    label: formatMessage(strings.goals.status.ongoing),
                  },
                  {
                    value: "blocked",
                    label: formatMessage(strings.goals.status.blocked),
                  },
                  {
                    value: "accomplished",
                    label: formatMessage(strings.goals.status.accomplished),
                  },
                ]}
                onSelectionChange={(value: React.Key) => {
                  updateGoal({ status: value.toString() })
                }}
                isFullWidth
              />
            ) : (
              <>
                <Heading variant="heading-5">
                  {formatMessage(strings.goalsPage.goalsDossier.status)}
                </Heading>
                <Paragraph variant="body">
                  {formatMessage(statusLabels[selectedStatus as RawGoalStatus])}
                </Paragraph>
              </>
            )}
          </div>
        </div>

        {goal.owners && (
          <div className={styles.owners}>
            <GoalOwnersDetails owners={goal.owners} />
          </div>
        )}

        <Divider variant="content" />

        <Box mt={2} mb={1}>
          {renderEditableKeyResultsSection(keyResults, canUpdateProgress)}
          <GoalKeyResults
            addKeyResultsHandler={handleEditGoal}
            keyResults={keyResults || []}
            onChange={handleKeyResultChange}
            readOnly={!userCanEdit || !managerCanEdit}
            canUpdateProgress={canUpdateProgress}
          />
        </Box>

        {moduleTogglesContinuousFeedback &&
          location.pathname === "/new_goals/directReport" &&
          selected_goal_owner && (
            <ContinuousFeedbackBanner
              dossierLayout
              context="goals"
              classNameOverride={
                keyResults.length > 0 ? "mt-[-1.5rem] mb-24" : "my-24"
              }
              reviewee={{
                id: selected_goal_owner.aggregateId,
                best_name: selected_goal_owner.name,
                full_name: selected_goal_owner.name,
              }}
              text={formatMessage(strings.goals.continuousFeedbackBannerText)}
            />
          )}

        <div className={styles.goalAlignmentsLabel}>
          <Heading variant="heading-4">
            {formatMessage(
              strings.goalsPage.goalsDossier.goalAlignments.goalAlignmentLabel
            )}
          </Heading>
        </div>
        {goal.type !== "company" && (
          <OutgoingAlignedGoals
            goal={goal}
            readOnly={!userCanEdit || !managerCanEdit}
            addAlignedGoalHandler={handleEditGoal}
            hasTeamGoalsEnabled={hasConfigOption(
              user,
              ConfigurationOptions.teamGoals
            )}
          />
        )}
        {goal.type !== "personal" && (
          <IncomingAlignedGoals
            goalType={goal.type}
            incomingAlignedGoalsCounts={goal.incomingAlignedGoalsCount}
            hasTeamGoalsEnabled={hasConfigOption(
              user,
              ConfigurationOptions.teamGoals
            )}
          />
        )}

        {shouldShowGoalComments(goal) && (
          <div className={styles.comments}>
            <Heading variant="heading-4">
              {formatMessage(strings.goalsPage.goalsDossier.comments)}
            </Heading>
            <CommentsProvider>
              <GoalComments
                goalType={goal.type}
                goalId={String(goalId)}
                currentUserCanComment={userCanComment()}
              />
            </CommentsProvider>
          </div>
        )}
      </>
    </div>
  )
}

export default React.memo(GoalDetails)
