import React from "react"
import RemoveIcon from "@kaizen/component-library/icons/clear-white.icon.svg"
import { KeyResult } from "@Goals/types"
import { KeyResultForm } from "@Goals/components"
import ButtonizedDiv from "@Components/ButtonizedDiv"
import cx from "classnames"
import { Paragraph } from "@kaizen/typography"
import strings from "@Locale/strings"
import { IconButton } from "@kaizen/button"
import { useIntl } from "@Common/locale/useIntl"

import styles from "./KeyResultFormItem.scss"

type KeyResultFormItemProps = {
  automationId?: string
  keyResult: KeyResult
  isEditing: boolean
  onUpdate: (keyResult: KeyResult) => void
  onSelect: (keyResult: KeyResult) => void
  onRemove: (keyResult: KeyResult) => void
  onCancelSelect: () => void
  disableEditFields?: boolean
}

export const KeyResultFormItem = ({
  automationId,
  keyResult,
  isEditing,
  onUpdate,
  onSelect,
  onRemove,
  onCancelSelect,
  disableEditFields = false,
}: KeyResultFormItemProps) => {
  const { formatMessage } = useIntl()

  const handleOnSelect = () => {
    onSelect(keyResult)
  }

  const handleOnRemove = () => {
    onRemove(keyResult)
  }

  const renderForm = () => {
    const handleOnSubmit = (kr: KeyResult) => {
      onUpdate(kr)
      onCancelSelect()
    }

    return (
      <KeyResultForm
        id={`${keyResult.id}-key-result-form`}
        keyResult={keyResult}
        onSubmit={handleOnSubmit}
        onCancel={onCancelSelect}
        disableEditFields={disableEditFields}
      />
    )
  }

  const renderItem = () => (
    <div className={styles.item} data-automation-id={automationId}>
      <ButtonizedDiv
        className={cx(styles.textContainer, styles.textContainerEditable)}
        onClick={handleOnSelect}
      >
        <Paragraph variant="body" tag="p">
          {keyResult.title}
        </Paragraph>
        <div className={styles.button}>
          <IconButton
            icon={RemoveIcon}
            label={formatMessage(strings.general.remove)}
            onClick={handleOnRemove}
          />
        </div>
      </ButtonizedDiv>
    </div>
  )

  return isEditing ? renderForm() : renderItem()
}
