import * as React from "react"
import ErrorPage from "./ErrorPage"

export const ErrorPage403 = () => {
  return (
    <ErrorPage
      code={403}
      title="403 Forbidden"
      message={
        <>
          Hmm, you don&apos;t have access to this functionality. Check with your
          account admin if this issue persists.
        </>
      }
      includeCTA
    />
  )
}
