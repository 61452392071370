import Reflux from "reflux-core"
import SurveyResponseActions from "../refluxActions/SurveyResponseActions"
import BaseSurveyResponseStore from "./BaseSurveyResponseStore"

const SurveyResponseStore = Reflux.createStore({
  ...BaseSurveyResponseStore,

  init() {
    BaseSurveyResponseStore.init.call(this, { actions: SurveyResponseActions })

    this.data = {
      ...this.data,
      surveyById: {},
      pastReviewsByUserId: {},
      selfReflectionsByUserId: {},
    }

    this.listenTo(
      SurveyResponseActions.getSurveyResponse.completed,
      (surveyResponse) => {
        this.data = {
          ...this.data,
          surveyById: {
            ...this.data.surveyById,
            [surveyResponse.id]: surveyResponse,
          },
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      SurveyResponseActions.loadCompletedSelfReflectionByUserId.completed,
      ({ userId, selfReflections }) => {
        this.data = {
          ...this.data,
          selfReflectionsByUserId: {
            ...this.data.selfReflectionsByUserId,
            [userId]: selfReflections,
          },
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      SurveyResponseActions.loadCompletedSelfReflectionByUserIdV2.completed,
      ({ author_aggregate_id, selfReflections }) => {
        this.data = {
          ...this.data,
          selfReflectionsByUserId: {
            ...this.data.selfReflectionsByUserId,
            [author_aggregate_id]: selfReflections,
          },
        }
        this.trigger(this.data)
      }
    )
  },
})

export default SurveyResponseStore
