import { FormatMessageType } from "../../types/locale"
import { CurrentUser } from "../../types/User"
import { GoalOwner } from "../../types/Goals"
import strings from "../../locale/strings"
import { BUSINESS_SUBTYPE, DEVELOPMENT_SUBTYPE } from "./constant"

export const constructOwnersLabel = (
  user: CurrentUser,
  owners: GoalOwner[],
  formatMessage: FormatMessageType
) => {
  const currentUserAsOwner = owners.find(
    (owner) => owner.aggregateId === user.employee_aggregate_id
  )
  const otherUsers = owners.filter(
    (owner) => owner.aggregateId !== user.employee_aggregate_id
  )

  if (currentUserAsOwner) {
    if (otherUsers.length === 1) {
      return formatMessage(
        strings.goalFormAdvancedSection.goalOwners.currentUserWithOneOther,
        { otherOwner: otherUsers[0].name }
      )
    }

    if (otherUsers.length > 1) {
      return formatMessage(
        strings.goalFormAdvancedSection.goalOwners.currentUserWithMoreUsers,
        { otherOwnersCount: otherUsers.length }
      )
    }

    return formatMessage(
      strings.goalFormAdvancedSection.goalOwners.onlyCurrentUser
    )
  }

  if (otherUsers.length === 1) {
    return `${otherUsers[0].name}`
  }

  if (otherUsers.length === 2) {
    return formatMessage(
      strings.goalFormAdvancedSection.goalOwners.onlyTwoUsers,
      { firstOwner: otherUsers[0].name, secondOwner: otherUsers[1].name }
    )
  }

  if (otherUsers.length > 2) {
    return formatMessage(
      strings.goalFormAdvancedSection.goalOwners.multipleOwners,
      {
        firstOwner: otherUsers[0].name,
        otherOwnersCount: otherUsers.length - 1,
      }
    )
  }

  return formatMessage(strings.goalFormAdvancedSection.goalOwners.noOwners)
}

export const translatedGoalSubtype = (subtype?: string) => {
  if (subtype === DEVELOPMENT_SUBTYPE) {
    return strings.individualGoalForm.subTypeField.developmentType
  }

  if (subtype === BUSINESS_SUBTYPE) {
    return strings.individualGoalForm.subTypeField.businessType
  }

  return null
}
