// Automation IDs, used to identify dom elements via the cypress e2e tests.
// Abbreviated because of how common these ids are to be used.
// Try to avoid string concatenation for these automation ids, and stick to
// using the `Aid` enum. The purpose of it is to make if very easy to trace
// where an automation id is used. If you're asking "Where is this component
// getting used?", you can simply call "find usages" in your IDE on the enum
// value, or do a global search.
//
// Note: tasks do not currently follow Aid conventions
enum Aid {
  accomplishedBucket = "accomplished-bucket",
  actionsDropdown = "actions-dropdown",
  addCommentButton = "add-comment-button",
  addKeyResult = "add-key-result",
  addQuestionButton = "add-question-button",
  addQuestionMenuOption = "add-question-menu-option",
  adminIndividualManagerRequestHistoryPageHeading = "heading",
  adminSkillDraggableTileCreate = "admin-skill-draggable-tile-create",
  adminSkillDraggableTileSpacer = "admin-skill-draggable-tile-spacer",
  adminSurveyFeedbackRequestSendRequestButton = "admin-survey-feedback-request-send-request-button",
  adminSurveyFeedbackRequestsAdminSurveyFeedbackRequestSendRequestButton = "admin-survey-feedback-request-send-request-button",
  adminSurveyFeedbackRequestsFeedbackRequestsSentConfirmationModal = "feedback-requests-sent-confirmation-modal",
  affirmativeCardButton = "affirmative-card-button",
  alignGoalSearchFieldGroup = "align-goal-search-field-group",
  alignGoalSearchFieldInput = "align-goal-search",
  alignGoalSearchFieldLabel = "align-goal-search-field-label",
  allEvaluationsBucket = "all-evaluations-bucket",
  allEvaluationsSummaryAllEvaluationsSummaryEmptyState = "all-evaluations-summary-empty-state",
  allEvaluationsSummaryEmptyState = "all-evaluations-summary-empty-state",
  applicationNavigation = "applicationNavigation",
  archivedTeamCard = "archived-team-card",
  archivedTeamDeleteButton = "archived-team-delete-button",
  archivedTeamDeleteModal = "archived-team-delete-modal",
  archivedTeamRestoreButton = "archived-team-restore-button",
  archivedTeamsBackButton = "archived-teams-back-button",
  archivedTeamsRestoreTeamButton = "archived-teams-restore-team-button",
  archivedTeamsViewTeamButton = "archived-teams-view-team-button",
  assessmentGroupHeroStat = "assessment-group-hero-stat",
  assessmentGroupHeroStats = "assessment-group-hero-stats",
  assignSkillsModalAssignSkillsModalHeading = "assign-skills-modal-heading",
  assignSkillsModalHeading = "assign-skills-modal-heading",
  authorAvatar = "author-avatar",
  backButton = "back-button",
  baseReviewBaseReviewContainer = "base-review-container",
  baseReviewContainer = "base-review-container",
  baseReviewReminders = "reminders",
  blockedBucket = "blocked-bucket",
  bucketSummaryContainer = "bucket-summary-container",
  bucketSummaryStepsAssessmentGroupsButton = "bucket-summary-steps-assessment-groups-button",
  bucketSummaryStepsStatusButton = "bucket-summary-steps-status-button",
  calendarDayButton = "calendar-day-button",
  calibrationViewGuidanceBlock = "calibration-view-guidance-block",
  calibrationViewTask = "CalibrationViewTask",
  cancelCompanyGoal = "cancel-company-goal",
  cancelDepartmentGoal = "cancel-department-goal",
  cancelIndividualGoalButton = "cancel-individual-goal-button",
  cancelTeamGoal = "cancel-team-goal",
  cardSelectCheckbox = "card-select-checkbox",
  cardSelectAdditionalInfo = "card-select-additional-info",
  cardSelectCheckboxWrapper = "card-select-checkbox-wrapper",
  cardSelectSetting = "card-select-setting",
  cardSelectSettingCheckbox = "card-select-setting-checkbox",
  checkboxShareAllFeedbackWithManagerFieldCheckbox = "checkbox-share-all-feedback-with-manager-field-checkbox",
  clearAllButton = "clear-all-button",
  collaboratorEvaluationPageQuestions = "CollaboratorEvaluationPage-questions",
  companyTab = "company-tab",
  completedBucket = "completed-bucket",
  completedPeerFeedbackModal = "completed-peer-feedback-modal",
  completedSelfReflectionPageHeadingTitle = "completed-self-reflection-page-heading-title",
  completionEndDate = "completion-end-date",
  completionStartDate = "completion-start-date",
  confirmationModal = "confirmation-modal",
  confirmDeleteButton = "confirm-delete-button",
  conversationConversationSendButton = "conversation-send-button",
  conversationMessage = "conversation-message",
  conversationSendButton = "conversation-send-button",
  createCompanyGoal = "create-company-goal",
  createDepartmentGoal = "create-department-goal",
  createIndividualGoalButton = "create-individual-goal-button",
  createTeamGoal = "create-team-goal",
  createdBucket = "created-bucket",
  cycleCreationEndDate = "cycle-creation-end-date",
  cycleCreationName = "cycle-creation-name",
  cycleCreationStartDate = "cycle-creation-start-date",
  cycleCreationTimezone = "cycle-creation-timezone",
  cycleStatusPill = "cycle-status-pill",
  cycleStepManageQuestions = "cycle-step-manage-questions",
  cycleStepPreviewQuestions = "cycle-step-preview-questions",
  dataTableDataTable = "data-table",
  dataTableDataTableHeaderRow = "data-table-header-row",
  dataTableDataTableRow = "data-table-row",
  dataTableRow = "data-table-row",
  datePickerFieldGroup = "date-picker-field-group",
  datePickerFieldInput = "date-picker-field-input",
  datePickerFieldLabel = "date-picker-field-label",
  defaultMrfQuestionsEditorMrfQuestionsEditorCancelButton = "mrf-questions-editor-cancel-button",
  defaultMrfQuestionsEditorMrfQuestionsEditorSaveButton = "mrf-questions-editor-save-button",
  demoButton = "demo-button",
  departmentTab = "department-tab",
  deprecatedHeaderTextAutomationId = "deprecated-header-text-automation-id",
  deprecatedSubTextAutomationId = "deprecated-sub-text-automation-id",
  deprecatedTabsTabFeedback = "deprecated-tabs-tab-feedback",
  deprecatedTabsTabGoals = "deprecated-tabs-tab-goals",
  deprecatedTabsTabNotes = "deprecated-tabs-tab-notes",
  descriptionFieldGroup = "description-field-group",
  descriptionFieldInput = "description-field-input",
  descriptionFieldLabel = "description-field-label",
  dirtyBucket = "dirty-bucket",
  dismissOption = "dismiss-option",
  dismissRequestFeedbackButton = "dismiss-request-feedback-button",
  dismissRequestFeedbackPopover = "dismiss-request-feedback-popover",
  dismissRequestHaveNotWorkedRecentlyButton = "dismiss-request-have-not-worked-recently-button",
  dismissRequestNoFeedbackNowButton = "dismiss-request-no-feedback-now-button",
  dossier = "dossier",
  dossierCloseButton = "dossier-close-button",
  dossierUserOption = "dossier-user-option",
  drawerHandleLableText = "drawer-handle-lable-text",
  ecClosedModal = "ec-closed-modal",
  ecNameFieldInput = "ec-name-field-input",
  ecPaufCompletedCell = "ec-pauf-completed-cell",
  ecPaufDismissedCell = "ec-pauf-dismissed-cell",
  ecPaufFeedbackRequestedCell = "ec-pauf-feedback-requested-cell",
  ecPaufFeedbackShareActionCell = "ec-pauf-feedback-share-action-cell",
  ecPaufFeedbackSharedStatusCell = "ec-pauf-feedback-shared-status-cell",
  ecPaufFeedbackCompleted = "ec-pauf-feedback-completed",
  ecPaufIncompleteCell = "ec-pauf-incomplete-cell",
  ecPaufNominationsReceivedCell = "ec-pauf-nominations-received-cell",
  ecPaufNominationsSubmittedCell = "ec-pauf-nominations-submitted-cell",
  ecPaufRequestsCell = "ec-pauf-requests-cell",
  ecSharingHelpModal = "ec-sharing-help-modal",
  editableAnswerChoice = "editable-answer-choice",
  editableAnswerChoiceTitle = "editable-answer-choice-title",
  eeListingPageActionItemButton = "ee-listing-page-action-item-button",
  employeeEvaluationCycleItem = "employee-evaluation-cycle-item",
  employeeEvaluationCycleTable = "employee-evaluation-cycle-table",
  emptyStateForAdmin = "empty-state-for-admin",
  errorState = "error-state",
  evaluationCycleSharingStepManagerReviewAccordion = "evaluation-cycle-sharing-step-manager-review-accordion",
  evaluationCycleSharingStepPeerFeedbackAccordion = "evaluation-cycle-sharing-step-peer-feedback-accordion",
  evaluationCycleSharingStepUpwardFeedbackAccordion = "evaluation-cycle-sharing-step-upward-feedback-accordion",
  evaluationCycleSummaryStepMetadataSectionEditModules = "evaluation-cycle-summary-step-metadata-section-edit-modules",
  evaluationCycleSummaryStepMetadataSectionEditName = "evaluation-cycle-summary-step-metadata-section-edit-name",
  evaluationCycleSummaryStepMetadataSectionEditParticipants = "evaluation-cycle-summary-step-metadata-section-edit-participants",
  evaluationCyclesTableRow = "evaluation-cycles-table-row",
  evaluationQuestions = "evaluation-questions",
  evaluationQuestionsEvaluationQuestions = "evaluation-questions",
  evaluationQuestionsSubmitReviewButton = "submit-review-button",
  exportLink = "export-link",
  feedbackGivenContainer = "feedback-given-container",
  feedbackGivenFeedbackGivenContainer = "feedback-given-container",
  feedbackListFeedbackReceivedContainer = "feedback-received-container",
  feedbackListNavigationTabGiven = "navigation-tab-given",
  feedbackListNavigationTabReceived = "navigation-tab-received",
  feedbackMessageFeedbackMessageLikeButton = "feedback-message-like-button",
  feedbackMessageFeedbackMessageReactionsButton = "feedback-message-reactions-button",
  feedbackMessageFeedbackMessageShareButton = "feedback-message-share-button",
  feedbackMessageLikeButton = "feedback-message-like-button",
  feedbackMessageReactionsButton = "feedback-message-reactions-button",
  feedbackMessageShareButton = "feedback-message-share-button",
  feedbackModal = "feedback-modal",
  feedbackReactionsStats = "feedback-reactions-stats",
  feedbackReactionsStatsFeedbackReactionsStats = "feedback-reactions-stats",
  feedbackReceivedContainer = "feedback-received-container",
  feedbackRequestHistoryFeedbackRequestHistoryTable = "feedback-request-history-table",
  feedbackRequestHistoryTable = "feedback-request-history-table",
  feedbackRequestsFeedbackRequestsRequestFeedbackContainer = "feedback-requests-request-feedback-container",
  feedbackRequestsRequestFeedbackContainer = "feedback-requests-request-feedback-container",
  feedbackRequestsRequestsSendRequestButton = "requests-send-request-button",
  feedbackRequestsSentConfirmationModal = "feedback-requests-sent-confirmation-modal",
  feedbackStatsChart = "feedback-stats-chart",
  feedbackStatsChartFeedbackStatsChart = "feedback-stats-chart",
  fieldLabel = "field-label",
  followedUpBucket = "followed-up-bucket",
  formCheckboxField = "form-checkbox-field",
  formIntlFlatPickr = "form-intl-flat-pickr",
  goalCommentTextField = "goal-comment-text-field",
  goalDatePickerFieldGroup = "goal-date-picker-field-group",
  goalDatePickerFieldInput = "goal-date-picker",
  goalDatePickerFieldLabel = "goal-date-picker-field-label",
  goalDescriptionFieldGroup = "goal-description-field-group",
  goalDescriptionFieldInput = "goal-description",
  goalDescriptionFieldLabel = "goal-description-field-label",
  goalNameFieldGroup = "goal-name-field-group",
  goalNameFieldInput = "goal-name",
  goalNameFieldLabel = "goal-name-field-label",
  goalStatsOverviewDownloadCsvButton = "goal-stats-overview-download-csv-button",
  goalStatsOverviewGoalStatsOverviewDownloadCsvButton = "goal-stats-overview-download-csv-button",
  goalsDossierCloseButton = "goals-dossier-close-button",
  goalsDossierOpenButton = "goals-dossier-open-button",
  goalsEmptyStateButton = "goals-empty-state-button",
  goalsKeyResultFormList = "goals-key-result-form-list",
  goalsLegacyKeyResultFormList = "goals-legacy-key-result-form-list",
  goalsProgressSlider = "goals-progress-slider",
  goalsTableDeprecatedGoalsTableEmptyState = "goals-table-empty-state",
  goalsTableEmptyState = "goals-table-empty-state",
  incomingGoalAlignmentCounts = "incoming-goal-alignment-counts",
  incompleteBucket = "incomplete-bucket",
  incompleteFeedbackUserStatsDownloadButton = "incomplete-feedback-user-stats-download-button",
  incompleteFeedbackUserStatsIncompleteFeedbackUserStatsDownloadButton = "incomplete-feedback-user-stats-download-button",
  incompleteMrfSurveyPageMrfDossierCloseButton = "mrf-dossier-close-button",
  inputClearButton = "input-clear-button",
  inputFeedback = "input-feedback",
  jobTitle = "job-title",
  likedBucket = "liked-bucket",
  livePill = "live-pill",
  loader = "loader",
  lockedFeedbackCard = "locked-feedback-card",
  mainActionPrimaryMenuItem = "main-action-primary-menu-item",
  manageManagerReviewColumnsButton = "manage-manager-review-columns-button",
  manageManagerReviewColumnsItem = "manage-manager-review-columns-item",
  manageManagerReviewColumnsMenu = "manage-manager-review-columns-menu",
  manageColumnsMenu = "manage-columns-menu",
  managerEvaluationAccordion = "manager-evaluation-accordion",
  managerEvaluationEndDate = "manager-evaluation-end-date",
  managerEvaluationModule = "manager-evaluation-module",
  managerEvaluationStartDate = "manager-evaluation-start-date",
  managerFeedbackRequestHistoryDownloadButton = "manager-feedback-request-history-download-button",
  managerFeedbackRequestHistoryDownloadListManagerFeedbackRequestHistoryDownloadButton = "manager-feedback-request-history-download-button",
  managerRequestHistoryTableAccordionItem = "manager-request-history-table-accordion-item",
  managerReviewContentStatsButton = "manager-review-content-stats-button",
  managerReviewHeroStats = "manager-review-hero-stats",
  managerReviewTableRow = "manager-review-reviews-table-row",
  managerReviewSharingStatusDescription = "manager-review-sharing-status-description",
  managerReviewSharingStatusPill = "manager-review-sharing-status-pill",
  modal = "modal",
  modalCloseButton = "modal-close-button",
  moreButtonsMenuRow = "more-buttons-menu-row",
  moreMenu = "more-menu",
  mrfCompletedEmptyState = "mrf-completed-empty-state",
  mrfQuestionsEditorCancelButton = "mrf-questions-editor-cancel-button",
  mrfQuestionsEditorSaveButton = "mrf-questions-editor-save-button",
  multiSelectFilterBarDropdown = "multi-select-filter-bar-dropdown",
  multiSelectFilterBarMultiSelectFilterBarDropdown = "multi-select-filter-bar-dropdown",
  multiStepHeaderStepTitle = "multi-step-header-step-title",
  multiStepModalExitButton = "multi-step-modal-exit-button",
  multiStepModalNextButton = "multi-step-modal-next-button",
  multiStepModalPreviousButton = "multi-step-modal-previous-button",
  name = "name",
  nameFieldGroup = "name-field-group",
  nameFieldInput = "name-field-input",
  nameFieldLabel = "name-field-label",
  navigationTabGiven = "navigation-tab-given",
  navigationTabReceived = "navigation-tab-received",
  negativeCardButton = "negative-card-button",
  newCompanyGoalDatePickerFieldGroup = "new-company-goal-date-picker-field-group",
  newCompanyGoalDatePickerFieldLabel = "new-company-goal-date-picker-field-label",
  newDepartmentGoalDatePickerFieldGroup = "new-department-goal-date-picker-field-group",
  newDepartmentGoalDatePickerFieldLabel = "new-department-goal-date-picker-field-label",
  newGoalCreateKeyResultButton = "goal-key-result-field-button",
  newGoalCreateKeyResultItem = "goal-key-result-field-item",
  newGoalDescriptionFieldInput = "goal-description-field-input",
  newGoalHideDescriptionFieldButton = "goal-hide-description-field-button",
  newGoalKeyResultMetricTypeFieldInput = "goal-key-result-metric-type-input",
  newGoalNameFieldInput = "goal-name-field-input",
  newGoalShowDescriptionFieldButton = "goal-show-description-field-button",
  newGoalVisibilityFieldInput = "goal-visibility-field-input",
  newTeamGoalDatePickerFieldGroup = "new-team-goal-date-picker-field-group",
  newTeamGoalDatePickerFieldLabel = "new-team-goal-date-picker-field-label",
  nominatedUsers = "nominated-users",
  notificationsInputField = "notifications-input-field",
  notificationsList = "notifications-list",
  notificationsListEmptyState = "notifications-list-empty-state",
  notificationsListItem = "notifications-list-item",
  notificationsListLegacy = "notifications-list-legacy", // The old notifications list that relies on reflux
  notificationsManagerPortal = "toast-notification-manager-portal",
  ongoingBucket = "ongoing-bucket",
  outgoingGoalAlignments = "outgoing-goal-alignments",
  pastFeedbackHeading = "past-feedback-heading",
  pastFeedbackItemTeamLink = "past-feedback-item-team-link",
  paufPreviewQuestionsModal = "pauf-preview-questions-modal",
  paufRequestedReviewRow = "pauf-requested-review-row",
  paufRequestsAboutTableRow = "pauf-requests-about-table-row",
  paufRequestsReceivedByTableRow = "pauf-requests-received-by-table-row",
  pauseScheduleSuccessToast = "pause-schedule-success-toast",
  pauseScheduleWarningMessage = "pause-schedule-cautionary-notification",
  peerAndUpwardFeedbackAccordion = "peer-and-upward-feedback-accordion",
  peerAndUpwardFeedbackModule = "peer-and-upward-feedback-module",
  peerFeedbackAccordion = "peer-feedback-accordion",
  peerFeedbackBackAllowEmployeesToNominate = "peer-feedback-allow-employees-to-nominate",
  peerFeedbackModule = "peer-feedback-module",
  peerFeedbackRequestCancelButton = "pfr-cancel-button",
  peerFeedbackRequestSendRequestButton = "pfr-send-request-button",
  peerFeedbackRequestUndoButton = "pfr-undo-button",
  peerFeedbackResetButton = "pfr-reset-button",
  peerNominationCycleEndDate = "peer-nomination-cycle-end-date",
  peerNominationCycleStartDate = "peer-nomination-cycle-start-date",
  peerNominationTask = "PeerNominationTask-undefined",
  peerNominationTaskUndefined = "peer-nomination-task-undefined",
  percentageBarAndValue = "percentage-bar-and-value",
  performanceReviewQuestion = "performance-review-question",
  performanceReviewSubTask = "PerformanceReviewSubTask",
  performanceReviewTask = "PerformanceReviewTask",
  performanceReviewTaskId = "PerformanceReviewTask-id",
  performanceUnitItem = "performance-unit-item",
  personalGoalsListLookingForReports = "personal-goals-list-looking-for-reports",
  pillListItem = "pill-list_item",
  postSurveyFormModal = "post-survey-foorm-modal",
  postSurveyGuidanceHelpfulRatingGroup = "post-survey-guidance-helpful-rating-group",
  postSurveyTextAreaFieldGroup = "post-survey-text-area-field-group",
  postSurveyTextAreaFieldLabel = "post-survey-text-area-field-label",
  postSurveyTextAreaFieldTextArea = "post-survey-text-area-field-textarea",
  previewButton = "preview-button",
  previewMe = "preview-me",
  prioritySelectorHighFieldLabel = "priority-selector-high-field-label",
  prioritySelectorLowFieldLabel = "priority-selector-low-field-label",
  prioritySelectorMediumFieldLabel = "priority-selector-medium-field-label",
  profileContentHeading = "profile-content-heading",
  profileExportFailure = "profile-export-failure",
  profileExportSuccess = "profile-export-success",
  profileHeaderContainer = "profile-header-container",
  profileHeaderDeactivated = "profile-header-deactivated",
  profileHeaderExtraSubtitle = "profile-header-extra-subtitle",
  profileHeaderJobTitle = "profile-header-job-title",
  profileWidgetHeading = "profile-widget-heading",
  progressBannerStepButton = "step-button",
  provideAdhocFeedbackSubTask = "ProvideAdhocFeedbackSubTask",
  provideAdhocFeedbackTask = "ProvideAdhocFeedbackTask",
  provideAdhocFeedbackTaskId = "ProvideAdhocFeedbackTask-id",
  provideTeamBasedFeedbackTask = "provide-team-based-feedback-task",
  questionClearButton = "question-clear-button",
  questionDescription = "question-description",
  questionField = "question-field",
  questionInputField = "question-input-field",
  questionQuestionInputField = "question-input-field",
  questionShareableBadge = "question-shareable-badge",
  questionTitle = "question-title",
  readOnlyQuestionAnswer = "read-only-question-and-answer",
  removeButton = "remove-button",
  removeKeyResult = "remove-key-result",
  repliedByRecipientBucket = "replied_by_recipient-bucket",
  repositionDown = "reposition-down",
  repositionUp = "reposition-up",
  requestFeedbackSubTask = "RequestFeedbackSubTask",
  requestFeedbackTask = "RequestFeedbackTask",
  requestFeedbackTask1 = "RequestFeedbackTask-1",
  requestFeedbackTask4 = "RequestFeedbackTask-4",
  requestTeamBasedFeedback = "request-team-based-feedback",
  requestsSendRequestButton = "requests-send-request-button",
  reviewInvitationsSent = "review-invitations-sent",
  reviewPostSkillsSendFeedbackButton = "review-post-skills-send-feedback-button",
  reviewReminders = "reminders",
  reviewReviewPostSkillsSendFeedbackButton = "review-post-skills-send-feedback-button",
  reviewAcknowledgmentStatusLivePill = "review-acknowledgment-status-live-pill",
  reviewStatus = "review-status",
  reviewerSelectionEndDate = "reviewer-selection-end-date",
  reviewerSelectionStartDate = "reviewer-selection-start-date",
  approveNominationsStartDate = "auto_approval_date",
  richTextEditorCustomerData = "CUSTOMER DATA",
  savedViewAdminFilterLabelsError = "admin-saved-view-filters-labels--error",
  savedViewAdminFilterLabelsLoading = "admin-saved-view-filters-labels--loading",
  savedViewAdminFilterLabelsMoreItemsButton = "admin-saved-view-filters-labels--more-items-button",
  savedViewAdminFilterLabelsPopover = "admin-saved-view-filters-labels--popover",
  savingIndicatorFailed = "save-indicator-failed",
  savingIndicatorSaved = "save-indicator-saved",
  savingIndicatorSaving = "save-indicator-saving",
  searchEmptyState = "search-empty-state",
  searchFieldDeprecated = "search-field-deprecated",
  searchFieldDeprecatedSearchFieldDeprecated = "search-field-deprecated",
  seenByRecipientBucket = "seen_by_recipient-bucket",
  selectControl = "Select__Control", // looks to be a default Kaizen AID
  selectOption = "Select__Option", // looks to be a default Kaizen AID
  selectedNomination = "selected-nomination",
  selectedNominations = "selected-nominations",
  selfReflectionConversationReplyButton = "sf-conversation-reply-btn",
  selfReflectionConversationSendReplyButton = "sf-conversation-send-reply-btn",
  selfReflectionCreateCycleButton = "self-reflection-create-cycle-button",
  selfReflectionCreationModal = "self-reflection-creation-modal",
  selfReflectionCycleActionMenu = "self-reflection-cycle-action-menu",
  selfReflectionCycleItem = "self-reflection-cycle-item",
  selfReflectionCycleManageUsersModal = "self-reflection-cycle-manage-users-modal",
  selfReflectionCycleNameAndDateModal = "self-reflection-cycle-name-and-date-modal",
  selfReflectionCycleQuestionsModal = "self-reflection-cycle-questions-modal",
  selfReflectionCyclesTable = "self-reflection-cycles-table",
  selfReflectionEmptyState = "self-reflection-empty-state",
  selfReflectionEmptyStateCta = "self-reflection-empty-state-cta",
  selfReflectionEndCycleModal = "self-reflection-end-cycle-modal",
  selfReflectionSummaryEmptyState = "self-reflection-summary-empty-state",
  selfReflectionSurveySummarySelfReflectionSummaryEmptyState = "self-reflection-summary-empty-state",
  selfReflectionTaskNewReflectionCycle = "self-reflection-task-new-reflection-cycle",
  selfReviewAccordion = "self-review-accordion",
  sendNominationButton = "send-nominations-button",
  setupScheduleSuccessToast = "setup-schedule-success-toast",
  shareFeedbackCheckbox = "share-feedback-checkbox",
  shareModalHeading = "share-modal-heading",
  shareQuestionCheckbox = "share-question-checkbox",
  sharedBucket = "shared-bucket",
  sharedEcDetailFeedbackTab = "shared-ec-detail-feedback-tab",
  sharedEcDetailFeedbackTabLoadingState = "shared-ec-detail-feedback-tab-loading-state",
  sharedEcDetailAcknowledgmentFormLoadingState = "shared-ec-detail-acknowledgment-form-loading-state",
  sharedEcDetailAcknowledgmentFormCheckbox = "shared-ec-detail-acknowledgment-form-checkbox",
  sharedEcDetailAcknowledgmentFormNotes = "shared-ec-detail-acknowledgment-form-notes",
  sharedEcDetailLoadingState = "shared-ec-detail-loading-state",
  sharedEcDetailManagerReviewLoadingState = "shared-ec-detail-manager-review-loading-state",
  sharedEcDetailManagerReviewTab = "shared-ec-detail-manager-review-tab",
  sharedEcDetailManagerReviewCommentsPageContainer = "shared-ec-detail-manager-review-comments-page-container",
  sharedEcDetailManagerReviewAcknowledgmentForm = "shared-ec-detail-manager-review-acknowledgment-form",
  sharedEcEmptyState = "shared-ec-empty-state",
  sharedEcFeedbackEmptyState = "shared-ec-feedback-empty-state",
  sharedEcManagerReviewEmptyState = "shared-ec-manager-review-empty-state",
  sharedEcTableLoadingState = "shared-ec-table-loading-state",
  sharedEcdetailPeerFeedbackSectionv1 = "shared-ec-detail-peer-feedback-sectionv1",
  sharedEcdetailPeerFeedbackSection = "shared-ec-detail-peer-feedback-section",
  sharedEcdetailUpwardFeedbackSection = "shared-ec-detail-upward-feedback-section",
  sharedQuestionListItem = "shared-question-list-item",
  sideNavDrawer = "side-nav-drawer",
  singleCycleEvaluationsSummaryBucket = "single-cycle-evaluations-summary-bucket",
  singleCycleEvaluationsSummaryEmptyState = "single-cycle-evaluations-summary-empty-state",
  skipAllCardsButton = "skip-all-cards-button",
  skipCardButton = "skip-card-button",
  splitButtonButton = "split-button-button",
  splitButtonDropdown = "split-button-dropdown",
  subjectName = "subject-name",
  submitFeedbackForm = "submit-feedback-form",
  submitReviewButton = "submit-review-button",
  successScreen = "success-screen",
  surveyForm = "survey-form",
  surveyQuestion = "survey-question",
  surveyQuestionsManager = "survey-questions-manager",
  tableCard = "table-card",
  tableLoading = "table-loading",
  teamArchiveButton = "ee-listing-page-action-item-button-1",
  teamArchiveModal = "team-archive-modal",
  teamBasedFeedbackAvatar = "tfb-avatar",
  teamBasedFeedbackCancelRequestButton = "tfb-cancel-request-button",
  teamBasedFeedbackCancelRequestModal = "team-based-feedback-cancel-request-modal",
  teamBasedFeedbackConfirmRequestModal = "team-based-feedback-confirm-request-modal",
  teamBasedFeedbackCustomQuestionsButton = "team-based-feedback-custom-questions-button",
  teamBasedFeedbackQuickActionButton = "quick-button-action",
  teamBasedFeedbackReviewee = "tfb-reviewee-name",
  teamBasedFeedbackSendRequestButton = "tfb-send-request-button",
  teamBasedFeedbackTypeAhead = "tfb-reviewers-selector",
  teamCard = "team-card",
  teamDeleteButton = "ee-listing-page-action-item-button-2",
  teamDeleteModal = "team-delete-modal",
  teamDescriptionFieldGroup = "team-description-field-group",
  teamDescriptionFieldInput = "team-description-field-input",
  teamDescriptionFieldLabel = "team-description-field-label",
  teamDescriptionInput = "team-description",
  teamEditButton = "ee-listing-page-action-item-button-0",
  teamFeedbackConfirmationModal = "team-feedback-confirmation-modal",
  teamFeedbackRequestSendButton = "pfr-send-request-button",
  teamFormCreateNewTeamButton = "team-form-create-new-team-button",
  teamFormPrimaryButton = "team-form-primary-team-button",
  teamFormRemoveUserIconButton = "team-form-remove-user-icon-button",
  teamFormSecondaryButton = "team-form-secondary-team-button",
  teamLeadsSearchFieldDescription = "team-leads-search-field-description",
  teamLeadsSearchFieldGroup = "team-leads-search-field-group",
  teamLeadsSearchFieldInput = "team-leads-search-field-input",
  teamLeadsSearchFieldLabel = "team-leads-search-field-label",
  teamLeadsSearchInput = "team-leads-search",
  teamMemberCard = "team-member-card",
  teamMembersSearchFieldDescription = "team-members-search-field-description",
  teamMembersSearchFieldGroup = "team-members-search-field-group",
  teamMembersSearchFieldInput = "team-members-search-field-input",
  teamMembersSearchFieldLabel = "team-members-search-field-label",
  teamMembersSearchInput = "team-members-search",
  teamNameFieldGroup = "team-name-field-group",
  teamNameFieldInput = "team-name-field-input",
  teamNameFieldLabel = "team-name-field-label",
  teamNameInput = "team-name",
  teamTab = "team-tab",
  teamsBackButton = "teams-back-button",
  teamsListCreateNewTeam = "teams-list-create-new-team",
  teamsNavigationTabGoals = "teams-navigation-tab-goals",
  teamsNavigationTabMembers = "teams-navigation-tab-members",
  teamsSummaryTeamTableCard = "teams-summary-team-table-item",
  titleBlockBreadcrumb = "TitleBlock__Breadcrumb",
  titleBlockDefaultActionButton = "title-block-default-action-button",
  titleBlockMainActionsToolbar = "title-block-main-actions-toolbar",
  titleBlockMobileActionsDefaultAction = "title-block-mobile-actions-default-action",
  titleBlockMobileActionsDefaultLink = "title-block-mobile-actions-default-link",
  titleBlockMobileActionsDrawerHandle = "title-block-mobile-actions-drawer-handle",
  titleBlockMobileActionsPrimaryButton = "title-block-mobile-actions-primary-button",
  titleBlockMobileActionsSecondaryAction = "title-block-mobile-actions-secondary-action",
  titleBlockPrimaryActionButton = "title-block-primary-action-button",
  titleBlockSecondaryActionsButton = "title-block-secondary-actions-button",
  titleBlockTitle = "TitleBlock__Title",
  toastNotificationAffirmative = "toast-notification-affirmative",
  toastNotificationNegative = "toast-notification-negative",
  toggleSharing = "toggle-sharing",
  translationQuestionChoiceAndError = "translation-question-choice-and-error",
  translationQuestionDescriptionAndError = "translation-question-desc-and-error",
  translationQuestionTitleAndError = "translation-question-title-and-error",
  upwardFeedbackAccordion = "upward-feedback-accordion",
  upwardFeedbackModule = "upward-feedback-module",
  usUserSelectionName2 = "us-user-selection-name-2",
  usUserSelectionName49 = "us-user-selection-name-49",
  usUserSelectionName59 = "us-user-selection-name-59",
  usUserSelectionName60 = "us-user-selection-name-60",
  userDrawerToggle = "user-drawer-toggle",
  userDrawerUser = "user-drawer-user",
  userGridCard = "user-grid-card",
  userGridUserGridCard = "user-grid-card",
  userItem = "user-item",
  userProfileCell = "user-profile-cell",
  userSearchBar = "user-search-bar",
  userSelectionPeopleIncluded = "user-selection-people-included",
  userSelector = "us-user-selection",
  userSelectorName = "us-user-selection-name",
  viewTeamButton = "view-team-button",
  withdrawRequest = "withdraw-request",
}

export default Aid
