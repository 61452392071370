export type KeyResultMetricType = "percentage" | "numeric"

export type KeyResult = {
  id: string
  start: number
  current: number
  target: number
  title: string
  metricType: KeyResultMetricType
  completion: number
}

export type RawGoalStatus = "ongoing" | "created" | "blocked" | "accomplished"
export type GoalStatus = "Blocked" | "In progress" | "Accomplished"

export type GoalType = "personal" | "team" | "department" | "company"

export type GoalTreeNode = {
  id: string
  data: GoalForTree
}

export type GoalOwner = {
  employeeAggregateId: string
  fullName: string
  userProfileImageUrl: string
}

export type GoalForTree = {
  id: number
  subTitle: string
  uuid: string
  name: string
  // TODO: there is a inconsistency when referring to "individual | personal" goal
  // type, some endpoint returns "personal" some other "individual". A ticket
  // was created to address this specific problem:
  // https://cultureamp.atlassian.net/browse/PATH-715
  goalType: GoalType | "individual"
  dueAt: Date
  status: GoalStatus
  owners: GoalOwner[]
  incomingCount: number
}

export type AccessPermission = {
  notes: string | null
}

export const VisibilityOptionEveryone = "everyone"

export const VisibilityOptionManager = "manager"

export const VisibilityOptionOwner = "owner"

export const VisibilityOptionIndividuals = "individuals"

export const VisibilityOptionTeam = "team_only"

export type VisibilityOption =
  | typeof VisibilityOptionEveryone
  | typeof VisibilityOptionManager
  | typeof VisibilityOptionOwner
  | typeof VisibilityOptionIndividuals
  | typeof VisibilityOptionTeam

export enum IndividualGoalSubtype {
  "NoType" = "",
  "Development" = "development",
  "Business" = "business",
}

export enum IndividualGoalStatus {
  created = "created",
  ongoing = "ongoing",
  blocked = "blocked",
  accomplished = "accomplished",
}

export type GoalFilter = {
  from?: Date
  to?: Date
  q?: string
  all_teams?: boolean
  my_department?: boolean
  statuses?: string[]
  "sub_types[]"?: IndividualGoalSubtype[]
  "statuses[]"?: string[]
}

export type GoalDirectReportFilter = {
  query?: string
  managerId?: number
  departmentId?: number
  status?: string
  statuses?: string[]
  sortBy?: string
  sortOrder?: string
  groupType?: string
  startDate?: Date
  endDate?: Date
}
