import * as React from "react"
import { useLocation, useHistory } from "react-router-dom"

/**
 * Copied from react-router-v6 at:
 * https://github.com/remix-run/react-router/blob/334589beb3aeedb48ea2f3f05c14b48c5d439b2f/packages/react-router-dom/index.tsx
 * Modifications:
 *   * Removed warning (Who needs 'em!?)
 *   * Replaced navigate hook with history hook
 *   * Replaced navigate call with history[method] call
 *   * Minor fixes for our linting rules (e.g let to const)
 */

export function useSearchParams(defaultInit?: URLSearchParamsInit) {
  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit))

  const location = useLocation()
  const searchParams = React.useMemo(() => {
    const searchParams = createSearchParams(location.search)

    for (const key of defaultSearchParamsRef.current.keys()) {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          searchParams.append(key, value)
        })
      }
    }

    return searchParams
  }, [location.search])

  const history = useHistory()
  const setSearchParams = React.useCallback(
    (
      nextInit: URLSearchParamsInit,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      navigateOptions?: { replace?: boolean; state?: any }
    ) => {
      const navigationMethod = navigateOptions?.replace ? "replace" : "push"
      history[navigationMethod]("?" + createSearchParams(nextInit), {
        ...(navigateOptions && { ...navigateOptions?.state }),
      })
    },
    [history]
  )

  return [searchParams, setSearchParams] as const
}

export type ParamKeyValuePair = [string, string]

export type URLSearchParamsInit =
  | string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams

/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ]);
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   });
 *
 * @see https://reactrouter.com/docs/en/v6/utils/create-search-params
 */
export function createSearchParams(
  init: URLSearchParamsInit = ""
): URLSearchParams {
  return new URLSearchParams(
    typeof init === "string" ||
    Array.isArray(init) ||
    init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key]
          return memo.concat(
            Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
          )
        }, [] as ParamKeyValuePair[])
  )
}
