import React from "react"
import cx from "classnames"
import { LoadingParagraph } from "@kaizen/loading-skeleton"
import styles from "./PrimitiveTable.scss"

export const Table: React.FC<JSX.IntrinsicElements["table"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <table className={cx(styles.primitiveTable, className)} {...rest}>
      {children}
    </table>
  )
}

export const Thead: React.FC<JSX.IntrinsicElements["thead"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <thead className={cx(styles.primitiveThead, className)} {...rest}>
      {children}
    </thead>
  )
}

export const Tbody: React.FC<JSX.IntrinsicElements["tbody"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <tbody className={cx(styles.primitiveTbody, className)} {...rest}>
      {children}
    </tbody>
  )
}

export const Tr: React.FC<JSX.IntrinsicElements["tr"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <tr className={cx(styles.primitiveTr, className)} {...rest}>
      {children}
    </tr>
  )
}

export const Th: React.FC<JSX.IntrinsicElements["th"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <th className={cx(styles.primitiveTh, className)} {...rest}>
      {children}
    </th>
  )
}

export const Td: React.FC<JSX.IntrinsicElements["td"]> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <td className={cx(styles.primitiveTd, className)} {...rest}>
      {children}
    </td>
  )
}

export const TableLoading = ({ rowsCount = 10 }) => {
  return (
    <div data-automation-id="table-loading">
      <Table>
        <Thead>
          <Tr>
            <Th>
              <LoadingParagraph isInline width={30} />
            </Th>
            <Th>
              <LoadingParagraph isInline width={30} />
            </Th>
            <Th>
              <LoadingParagraph isInline width={30} />
            </Th>
            <Th>
              <LoadingParagraph isInline width={30} />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {[...Array(rowsCount)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tr key={`tr-${index}`}>
              <Td>
                <LoadingParagraph isInline width={30} />
              </Td>
              <Td>
                <LoadingParagraph isInline width={30} />
              </Td>
              <Td>
                <LoadingParagraph isInline width={30} />
              </Td>
              <Td>
                <LoadingParagraph isInline width={30} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}
