import * as React from "react"
import { Redirect } from "react-router-dom"
import * as Sentry from "@sentry/browser"
import { calibrationsPaths } from "@Calibrations/paths"
import { sendErrorToDatadog } from "@Common/utils/datadog"

type Props = { children: React.ReactNode }
type State = { error: boolean }

export class RouteErrorBoundary extends React.Component<Props, State> {
  state = { error: false }

  constructor(props: Props) {
    super(props)
  }

  static getDerivedStateFromError(error: unknown) {
    return { error: true }
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error)
    sendErrorToDatadog(error)
  }

  render() {
    if (this.state.error) {
      return <Redirect to={calibrationsPaths.admin.error({})} />
    }

    return this.props.children
  }
}
