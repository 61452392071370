import _ from "lodash"
import { determineEnvironment } from "@cultureamp/frontend-env"
import * as logging from "./constants/logging"

const CHROME_EXTENSION_ID_DEVELOPMENT = "beldojngkknecoijbmeeboebiolebiag"
const CHROME_EXTENSION_ID = "pjlfgiceggnffgieobmgpmibpcidmadf"

const CONSTANTS = {
  // Colors for arbitrary values are determined by scaling this scale proportionally
  // and interpolating values
  RATING_COLOR_SCALE: {
    0: "#c4c5d4",
    1: "#B2B3C4",
    2: "#a7a9c1",
    3: "#898ba9",
    4: "#6b6e94",
  },

  // Keep in sync with CSS
  MOBILE_WIDTH: 500,
  SMALL_SCREEN_WIDTH: 800,

  COLORS: {
    MANATEE: "#9898AD",
    LIGHT_BLUE: "#229793",
    GREEN_BRIGHT: "#4CAF50",
    SEEDLING: "#21A38B",
    TEXT_COLOR: "#31315C",
    TEXT_COLOR_FADED: "rgba(49, 49, 92, 0.45)",
    // TEXT_COLOR_SECONDARY: '#9898AD',
    BLOCKED_CONTENT_COLOR: "#E9EBEC",
    STATS_PROGRESS_COLOR: "#5176C9",
    COMPLETED_COLOR: "#7EA763",

    CREATED: "#9898AD",
    IN_PROGRESS: "#E1AA11",
    BLOCKED: "#DF5A49",
    ACCOMPLISHED: "#7EA763",

    TOOLTIP_BACKGROUND: "#FFFFFF",
    TOOLTIP_FONT: "#31315C",
    TOOLTIP_BORDER: "#35374A",

    FEEDBACK_STATS: {
      SENT: "#5176C9",
      READ: "#E1AA11",
      LIKED: "#C95191",
      COMMENTS: "#7EA763",
      REQUESTS: "#6E41CF",
    },
  },

  CYCLE_POLL_TIMER_INTERVAL: 60000,
  AUTOSAVE_DELAY: 1500,
  SEARCHFIELD_DEBOUNCE: 300,

  GOALS: {
    DUE_AT_HOUR: 17,
  },

  PERMISSIONS_LINK:
    "https://academy.cultureamp.com/hc/en-us/articles/360004126480",
  CHANGE_PASSWORD_LINK: "/my/password",
  CHANGE_PROFILE_PIC_LINK: "/my/profile",
  FEEDBACK_QUESTION_SELECTION_LINK:
    "https://support.cultureamp.com/hc/en-us/articles/360021579359",
  REVIEW_ACKNOWLEDGMENT_LINK:
    "https://support.cultureamp.com/hc/en-us/articles/360014491440-Launch-a-Performance-Review-Cycle-",

  NUM_MONTHS_OF_PROGRESS: 6,

  FEATURE_FLAGS: {
    ADMIN_USER_UPLOAD: true,
    UPDATE_MANAGER_REVIEWER: false,
  },

  STEADYFOOT_URL: "/performance/api/v1" as string,
  PERFORMANCE_API_V2_URL: "/performance/api/v2" as string,
  PERFORMANCE_API_GQL_URL: "/performance/api/v2/graphql" as string,
  PERFORMANCE_API_1_1_GQL_URL:
    "/performance/api/v2/one_on_ones/graphql" as string,
  PERFORMANCE_API_ADMIN_GQL_URL:
    "/performance/api/v2/admin_dashboard/graphql" as string,
  PERFORMANCE_API_WS_URL: "/performance/api/cable" as string,

  LOG_TAGS: [] as string[],

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID_DEVELOPMENT as string,
  SENTRY_ENABLED: false as boolean,
  // default to main US Sentry project (overriden by EU)
  SENTRY_URL:
    "https://be0953292126452b928c7602a5d8a3e7@sentry.io/2255089" as string,

  SENTRY_ENVIRONMENT: "production" as "development" | "testing" | "production",

  DISABLE_INTERCOM: false as boolean,
  DISABLE_UNIFIED_FLAG_SERVICE: false as boolean,
  INTERCOM_ID: "" as string,
  /** Whether or not the client SDK should fetch the flags from LD */
  LAUNCHDARKLY_STATUS: "enabled" as "enabled" | "disabled",
  RELEASE: process.env.RELEASE,
} as const

type Constants = typeof CONSTANTS

/**
 * DEVELOPMENT SETTINGS
 */
const development: Constants = {
  ...CONSTANTS,
  DISABLE_INTERCOM: false,
  DISABLE_UNIFIED_FLAG_SERVICE: true,

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  LOG_TAGS: navigator.webdriver
    ? []
    : [
        logging.REFLUX_ACTIONS,
        logging.SENTRY_EVENTS,
        logging.AUTOMATIC_REDIRECTS,
        logging.CURRENT_USER_STORE,
      ],

  SENTRY_ENVIRONMENT: "development",
}

/**
 * SANDBOX SETTINGS extends DEVELOPMENT
 */
const sandbox: Constants = {
  ...development,
  DISABLE_INTERCOM: false,
  DISABLE_UNIFIED_FLAG_SERVICE: false,

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  SENTRY_ENABLED: true,
  SENTRY_URL:
    "https://f6084a7e99d7474da7351adbc38b7a3a@o19604.ingest.sentry.io/5803340",
}

/**
 * localhost SETTINGS extends DEVELOPMENT
 */
const localhost: Constants = {
  ...development,
  LAUNCHDARKLY_STATUS: "disabled",
}

/**
 * TEST SETTINGS extends DEVELOPMENT
 */
const test: Constants = {
  ...development,
  // Don't hit actual server!
  DISABLE_INTERCOM: false,
  STEADYFOOT_URL: "http://steadyfoot_url/",
  LAUNCHDARKLY_STATUS: "disabled",
}

/**
 * PRODUCTION SETTINGS
 */
const production: Constants = {
  ...CONSTANTS,

  SENTRY_ENABLED: true,

  INTERCOM_ID: "m6btd41m",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID,
}

const environmentSettings = ((env = determineEnvironment()) => {
  // for storybook it's always development
  if (process.env.STORYBOOK_ENV === "development") {
    return development
  }
  if (process.env.ENVIRONMENT === "test") {
    return test
  }
  switch (env.realm) {
    case "production":
      return production
    case "staging":
      return sandbox
    case "localhost":
      return localhost
    case "development":
    case "unknown":
      return development
    default:
      return production
  }
})()

// Support settings overrides from env. Don't do this for the test env, though,
// because that is meant to run in a fixed environment.
const Settings: Constants = _.defaults(
  environmentSettings !== test
    ? {
        STEADYFOOT_URL:
          process.env.STEADYFOOT_EXTERNAL_URL || CONSTANTS.STEADYFOOT_URL,
        PERFORMANCE_API_V2_URL:
          process.env.PERFORMANCE_API_V2_URL ||
          CONSTANTS.PERFORMANCE_API_V2_URL,
        PERFORMANCE_API_GQL_URL:
          process.env.PERFORMANCE_API_GQL_URL ||
          CONSTANTS.PERFORMANCE_API_GQL_URL,
        PERFORMANCE_API_ADMIN_GQL_URL:
          process.env.PERFORMANCE_API_ADMIN_GQL_URL ||
          CONSTANTS.PERFORMANCE_API_ADMIN_GQL_URL,
        // Comma separated string, which gets split into an array by webpack.config.js
        // For a list of possible log tags, see src/constants/logging.ts
        LOG_TAGS: process.env.LOG_TAGS,
      }
    : {},
  environmentSettings
)

export default Settings
