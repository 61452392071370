import React, { ErrorInfo } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import * as Sentry from "@sentry/browser"
import { sendErrorToDatadog } from "@Common/utils/datadog"
import ErrorState from "../ErrorState/ErrorState"

type Props = RouteComponentProps & {
  children: React.ReactNode
  ctaText?: string
  ctaOnClick?: () => void
}

type State = {
  errorJustHappened: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { errorJustHappened: false }
  }

  static getDerivedStateFromError() {
    return { errorJustHappened: true }
  }

  componentDidUpdate(prevProps: Props) {
    const { location: previousLocation } = prevProps
    const { location } = this.props
    const locationChanged =
      previousLocation.pathname !== location.pathname ||
      previousLocation.search !== location.search

    if (locationChanged) {
      this.setState({ errorJustHappened: false })
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, info)
    Sentry.captureException(error)
    sendErrorToDatadog(error)
  }

  render() {
    const { errorJustHappened } = this.state
    const { children, ctaText, ctaOnClick } = this.props

    if (errorJustHappened) {
      return <ErrorState ctaText={ctaText} ctaOnClick={ctaOnClick} />
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
