import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react"
import { Label, TextField } from "@kaizen/draft-form"
import { Select as KaizenSelect } from "@kaizen/draft-select"
import { Text, Icon } from "@kaizen/component-library"
import { Heading } from "@kaizen/typography"
import searchIcon from "@kaizen/component-library/icons/search.icon.svg"
import organisationIcon from "@kaizen/component-library/icons/organization.icon.svg"
import { IntlShape, injectIntl } from "react-intl"
import moment from "moment"
import { useIntl } from "@Common/locale/useIntl"
import { generateGoalCycles } from "@Goals/utils"
import useDebounce from "../../hooks/timeout/useDebounce"
import styles from "./FilterContainer.scss"
import { DatePicker as DatePickerComp } from "../DatePicker"
import { DatePickerFilter as DatePickerFilterComp } from "../DatePickerFilter"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"
import { FilterSelectDropdown, DropdownOption } from "../FilterSelectDropdown"
import { GoalFilterTypes, GoalFilterKey } from "../../types/Goals"
import { GroupDropdownOption } from "../FilterSelectDropdown/GroupSelectOptions"
import { SmartDefaultOption } from "../DatePickerFilter/SmartDefaultSection"
import { Company as CompanyType } from "../../types/Company"
import { FormatMessageType, FormatDateType } from "../../types/locale"

type FilterContainerState = {
  filters: GoalFilterTypes
  updateFilter: (
    filterName: GoalFilterKey,
    filterValue: GoalFilterTypes[GoalFilterKey]
  ) => void
  intl?: IntlShape
}

export const generateGoalCycleOptions = (
  company: CompanyType,
  formatMessage: FormatMessageType,
  formatDate: FormatDateType
): SmartDefaultOption[] => {
  const goalCycles = generateGoalCycles({
    startDate: company.goal_cycle_day,
    startMonth: company.goal_cycle_month,
    cycleLength: company.goal_cycle_length,
  })

  const DATE_FORMAT = {
    day: "numeric",
    month: "short",
    year: "numeric",
  } as const
  const goalPeriodOptions = goalCycles.map((cycle) => {
    const text = formatMessage(strings.time.dateRange, {
      startDate: formatDate(cycle.startDate, DATE_FORMAT),
      endDate: formatDate(cycle.endDate, DATE_FORMAT),
    })

    return {
      label: text,
      values: [moment(cycle.startDate), moment(cycle.endDate)],
    }
  })

  return goalPeriodOptions
}

const FilterContainerContext = createContext<FilterContainerState>({
  filters: {},
  updateFilter: () => {},
})

function useFiltersContext() {
  const context = useContext(FilterContainerContext)

  if (!context) {
    throw new Error(
      "FilterContainer compound components must be render within a FilterContainer component"
    )
  }

  return context
}

const Search = ({
  name,
  placeholder,
  searchTracking,
}: {
  name: GoalFilterKey
  placeholder?: string
  searchTracking?: () => void
}) => {
  const { filters, updateFilter, intl } = useFiltersContext()
  const [value, setValue] = useState(filters[name] || "")
  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    updateFilter(name, debouncedValue)
    if (searchTracking && debouncedValue !== "") {
      searchTracking()
    }
  }, [debouncedValue, name, updateFilter, searchTracking])

  return (
    <TextField
      id={Aid.alignGoalSearchFieldInput}
      labelText={intl?.formatMessage(strings.filters.search)}
      inputType="text"
      // @ts-expect-error: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
      inputValue={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      placeholder={placeholder}
      icon={searchIcon}
    />
  )
}

type SelectOption = {
  value: string | boolean | number
  label: string
}

// fix for select z-index issue on mobile
const Select = ({
  name,
  options,
}: {
  name: GoalFilterKey
  options: SelectOption[]
}) => {
  const { filters, updateFilter, intl } = useFiltersContext()
  const selectedOption = options.find(
    (option) => option.value === filters[name]
  )
  return (
    <div className={styles.select}>
      <Text tag="p" style="label" inheritBaseline>
        {intl?.formatMessage(strings.filters.filter)}
      </Text>
      <KaizenSelect
        styles={{ menu: (styles) => ({ ...styles, zIndex: 1000 }) }}
        options={options}
        // @ts-expect-error: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        onChange={({ value }: SelectOption) => updateFilter(name, value)}
        value={selectedOption}
      />
    </div>
  )
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GroupSelectFilter = <T extends any>({
  id,
  name,
  options,
  label,
  appliedValues,
}: {
  id: string
  name: "team_ids[]" | "departments"
  label: string
  options: GroupDropdownOption<T>[]
  appliedValues: DropdownOption<T>[]
}) => {
  return (
    <FilterSelectDropdown
      mode="group"
      id={id}
      options={options}
      label={label}
      value={appliedValues}
      onChange={(option) => {}}
      onSelectAll={() => {}}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectFilter = <T extends any>({
  id,
  name,
  label,
  options,
}: {
  id: string
  name: GoalFilterKey
  label: string
  options: DropdownOption<T>[]
}) => {
  const { filters, updateFilter } = useFiltersContext()

  const defaultValues = filters[name]

  const mappedVals =
    defaultValues instanceof Array
      ? (defaultValues as string[]).reduce(
          (accumulator: DropdownOption<T>[], value: string) => {
            const option = options.find((option) => option.value === value)
            if (option) {
              accumulator.push(option)
            }
            return accumulator
          },
          []
        )
      : []

  const [appliedFilters, setAppliedFilters] =
    useState<DropdownOption<T>[]>(mappedVals)

  const handleOnChange = (options: Array<DropdownOption<T>>) => {
    const optionValues = options.map((filter) => String(filter.value))

    setAppliedFilters(options)
    updateFilter(name, optionValues)
  }

  const handleOnSelectAll = (options: DropdownOption<T>[]) => {
    setAppliedFilters(options)
  }

  return (
    <FilterSelectDropdown
      mode="basic"
      id={id}
      options={options}
      label={label}
      value={appliedFilters}
      onChange={handleOnChange}
      onSelectAll={handleOnSelectAll}
    />
  )
}

const DatePicker = ({
  name,
  allowDateRange = false,
  initialDate,
}: {
  name: GoalFilterKey | ((index: number) => GoalFilterKey)
  allowDateRange?: boolean
  initialDate?: Date[]
}) => {
  const { updateFilter, intl } = useFiltersContext()

  const onChange = (dates: Date[]) => {
    if (typeof name === "string") {
      updateFilter(name, dates)
    } else {
      ;[0, 1].map((index) => updateFilter(name(index), dates[index]))
    }
  }

  return (
    <>
      <Label
        labelText={intl?.formatMessage(strings.filters.dueDate)}
        htmlFor="date-picker"
      />
      <DatePickerComp
        id="date-picker"
        label={intl?.formatMessage(strings.filters.dueDate)}
        allowDateRange={allowDateRange}
        initialDate={initialDate}
        onChange={onChange}
      />
    </>
  )
}

const DateRangeFilter = ({
  values,
  name,
  defaultOptions,
}: {
  name: [GoalFilterKey, GoalFilterKey]
  values?: Date[]
  defaultOptions?: SmartDefaultOption[]
}) => {
  const { formatMessage } = useIntl()
  const { updateFilter } = useFiltersContext()

  const onChange = (dates: Date[]) => {
    name.forEach((val, i) => updateFilter(val as GoalFilterKey, dates[i]))
  }

  return (
    <div className={styles.filterContainer}>
      <DatePickerFilterComp
        id="date-picker"
        label={formatMessage(strings.filters.dueDate)}
        allowDateRange
        values={values}
        onChange={onChange}
        customRangeLabel={formatMessage(
          strings.filters.datePicker.customDateRange
        )}
        smartDefaultOptions={defaultOptions || []}
        smartDefaultLabel={
          <div className={styles.datepickerLabelContainer}>
            <Icon icon={organisationIcon} role="presentation" />
            <span className={styles.datepickerLabel}>
              <Heading variant="heading-6" color="dark-reduced-opacity">
                Company Goal Cycles
              </Heading>
            </span>
          </div>
        }
      />
    </div>
  )
}

const FilterContainer: React.FC<{
  defaultFilters?: GoalFilterTypes
  onFiltersChange?: (filters: GoalFilterTypes) => void
  intl: IntlShape
}> = ({ children, onFiltersChange, defaultFilters = {}, intl }) => {
  const [filters, setFilters] = useState<GoalFilterTypes>(defaultFilters)

  useEffect(() => {
    onFiltersChange && filters && onFiltersChange(filters)
  }, [filters, onFiltersChange])

  const updateFilter = useCallback(
    (
      filterName: GoalFilterKey,
      filterValue: GoalFilterTypes[GoalFilterKey]
    ) => {
      setFilters((filters) => ({
        ...filters,
        [filterName]: filterValue,
      }))
    },
    []
  )

  const value = useMemo(
    () => ({
      filters,
      updateFilter,
      intl,
    }),
    [filters, updateFilter, intl]
  )

  return (
    <FilterContainerContext.Provider value={value}>
      <div className={styles.filtersBar}>{children}</div>
    </FilterContainerContext.Provider>
  )
}

const injectedComponent = injectIntl(FilterContainer)

const InjectedFilterContainer: typeof injectedComponent & {
  Search: typeof Search
  Select: typeof Select
  DatePicker: typeof DatePicker
  DateRangeFilter: typeof DateRangeFilter
  SelectFilter: typeof SelectFilter
  GroupSelectFilter: typeof GroupSelectFilter
} = Object.assign(injectedComponent, {
  Search,
  Select,
  DatePicker,
  DateRangeFilter,
  SelectFilter,
  GroupSelectFilter,
})

export default InjectedFilterContainer
