// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiPut } from "@API/utils"
import { QuestionAnswer } from "@Calibrations/v2/queries/useGetReviewResults"
import { Calibrations } from "bff/upstreams"
import { QueryKeys } from "./keys"
import { fetchReviewResult } from "./useGetSingleReviewResult"
import { useCalibrationViewQueryParams } from "../hooks/useCalibrationViewQueryParams"

type FetchResult = Calibrations["managerReviews.ManagerReviewsDetail"]

type UpdateAnswerParams = {
  calibrationId: number
  managerReviewId: number
  answer: QuestionAnswer
}

const updateAnswer = async ({
  calibrationId,
  managerReviewId,
  answer,
}: UpdateAnswerParams) => {
  return perfApiPut<Calibrations["Success"]>(
    "/dashboard/calibrations/:calibrationId/manager_reviews/:managerReviewId/answers/:answerId",
    {
      params: {
        calibrationId,
        managerReviewId,
        answerId: answer.answerId,
      },
      body: {
        choice: answer.answer,
      },
    }
  )
}

export const updateAnswerRefetchReview = ({
  calibrationId,
  managerReviewId,
  answer,
}: UpdateAnswerParams) => {
  return updateAnswer({
    calibrationId,
    managerReviewId,
    answer,
  }).then(() =>
    fetchReviewResult({ calibrationViewId: calibrationId, managerReviewId })
  )
}

export const useUpdateAnswer = ({
  calibrationId,
}: {
  calibrationId: number
}) => {
  const queryClient = useQueryClient()

  const [searchParams] = useCalibrationViewQueryParams()
  return useMutation(
    ({ managerReviewId, answer }: UpdateAnswerParams) =>
      updateAnswerRefetchReview({
        calibrationId,
        managerReviewId,
        answer,
      }),
    {
      onMutate: async (updatedAnswer) => {
        await queryClient.cancelQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        const previousReviewResults = queryClient.getQueryData([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        queryClient.setQueryData<FetchResult | undefined>(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          (previous) => {
            if (!previous) {
              return undefined
            }
            return {
              ...previous,
              managerReviews: previous.managerReviews.map((review) =>
                updatedAnswer.managerReviewId === review.managerReview.id
                  ? {
                      ...review,
                      questionAnswers: review.questionAnswers.map((answer) =>
                        answer.questionId === updatedAnswer.answer.questionId
                          ? updatedAnswer.answer
                          : answer
                      ),
                    }
                  : review
              ),
            }
          }
        )
        return { previousReviewResults }
      },
      onError: (err, updatedReviewResult, context) => {
        queryClient.setQueryData(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          context?.previousReviewResults
        )
      },
      onSettled: () =>
        queryClient.invalidateQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ]),
    }
  )
}
