import * as React from "react"
import type { LinkProps } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSearchParams } from "@Calibrations/v2/hooks/useSearchParams"

type SortLinkProps = Omit<LinkProps, "to"> & { sortKey: string }

/**
 * When rendering tables, we want to render links that control sorting by columns.
 * The search params to control this behaviour are "sortBy" and "sortOrder", these are
 * dictated by the API (or we want them to match, at least).
 *
 * The SortLink takes a `sortKey` prop, the key we wish to assign to the `sortBy` value.
 * There are three render conditions:
 *   * If our `sortKey` does not match the current `sortBy`, we'll set the Link's `to` prop to be
 *     `sortBy={sortKey}&sortOrder={asc}`
 *   * If our `sortKey` does match the current `sortBy`, and the `sortOrder` is `asc`, we'll set the
 *     Link's `to` prop to be `sortBy={sortKey}&sortOrder={desc}`
 *   * Otherwise, we'll delete the `sortBy` and `sortKey` from the search params
 *
 * This component is built to be agnostic to the current `location.pathname`, and additive with
 * other search params that are not `sortBy` and `sortOrder`
 *
 * @returns a react router \<Link /> component with the "next" sorting pair encoded as search params
 */
export const SortLink = ({ sortKey, ...rest }: SortLinkProps) => {
  const [searchParams] = useSearchParams()
  const sortBy = searchParams.get("sortBy")
  const sortOrder = searchParams.get("sortOrder")
  // FIXME: Set an empty default key. It's mainly here to avoid an undefined type when
  // we build the new URLSearchParams, it will be deleted by the third case.
  let newSortBy = ""
  let newSortOrder = "asc"
  let shouldDelete = false
  if (sortBy !== sortKey) {
    newSortBy = sortKey
  } else if (sortBy === sortKey && sortOrder === "asc") {
    newSortBy = sortKey
    newSortOrder = "desc"
  } else {
    shouldDelete = true
  }
  const newSearchParams = new URLSearchParams({
    ...Object.fromEntries(searchParams),
    sortBy: newSortBy,
    sortOrder: newSortOrder,
  })
  if (shouldDelete) {
    newSearchParams.delete("sortBy")
    newSearchParams.delete("sortOrder")
  }
  return (
    <Link
      to={(location) => `${location.pathname}?${newSearchParams}`}
      {...rest}
    />
  )
}
