import { useEffect } from "react"

export const useKeyDownListener = (
  callback: (event: KeyboardEvent) => void,
  isEnabled = true
) => {
  useEffect(() => {
    if (!isEnabled) return undefined

    document.addEventListener("keydown", callback, true)

    return () => {
      document.removeEventListener("keydown", callback, true)
    }
  }, [callback, isEnabled])
}
