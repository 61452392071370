import {
  useQueryParams,
  DelimitedArrayParam,
  JsonParam,
  SetQuery,
  DecodedValueMap,
  StringParam,
  DelimitedNumericArrayParam,
  NumberParam,
} from "use-query-params"

export const FilterQueryParams = {
  managerReviewCycleIds: DelimitedArrayParam,
  performanceBucketInCycleIds: DelimitedArrayParam,
  departmentIds: DelimitedArrayParam,
  managerIds: DelimitedArrayParam,
  groupTypes: DelimitedArrayParam,
  jobTitleIds: DelimitedArrayParam,
  levels: DelimitedArrayParam,
  userStatus: DelimitedArrayParam,
  reviewStatus: DelimitedArrayParam,
  demographics: JsonParam,
  query: StringParam,
}

const UserSearchQueryParams = {
  userIds: DelimitedNumericArrayParam,
}

const TableQueryParams = {
  dossierNotesReviewId: NumberParam,
  dossierEmployeeId: NumberParam,
  columns: DelimitedArrayParam,
  sortBy: StringParam,
  sortOrder: StringParam,
  perPage: NumberParam,
  page: NumberParam,
  questionId: NumberParam,
}

const calibrationViewQueryParams = {
  ...FilterQueryParams,
  ...UserSearchQueryParams,
  ...TableQueryParams,
}

export type SetQueryCalibrationViewQueryParams = SetQuery<
  typeof calibrationViewQueryParams
>

export type CalibrationViewQueryParams = DecodedValueMap<
  typeof calibrationViewQueryParams
>

export const useCalibrationViewQueryParams = () => {
  return useQueryParams(calibrationViewQueryParams)
}
