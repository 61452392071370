import * as React from "react"
import { v4 as uuid } from "uuid"
import {
  createContext,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from "react"
import { memo } from "react"
import { useIntl } from "@Common/locale/useIntl"
import styles from "./Modal.scss"
import AppearanceAnim from "../../components/AppearanceAnim/AppearanceAnim"
import { keyCode } from "../../constants/keyCodes"
import { useKeyDownListener } from "../../hooks/dom/useKeyDownListener"
import { useCloseModal } from "../../hooks/modals/modals"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import strings from "../../locale/strings"

type Props = {
  children: React.ReactNode
  isOpen: boolean
  params: { [key: string]: string }
}

export const ModalContext = createContext<{
  // The titleId that must be added to the modals `h1` tag, as so it can
  // be announced by the screen reader.
  titleId: string
  params: { [key: string]: string }
  // See the `useModalEscapeLock` hook for more info
  modalEscapeLock: MutableRefObject<boolean>
}>({ params: {}, titleId: "", modalEscapeLock: { current: false } })

/**
 * A very raw implementation of a modal. It handles the following
 * responsibilities:
 *  - Overlay the content over the entire screen
 *  - Animate the modal in and out
 *  - Declare itself as a modal for screen readers, with a dialog title. For this
 *    to work, the user must use the `useContext(ModalContext)` (or the
 *    `useModalContext()` helper found under src/hooks/modals.ts), and apply
 *    the supplied `labeledById` property to the title.
 *  - Use an error boundary to catch errors. Hopefully an end user will never
 *    see this.
 */
const Modal = ({ children, isOpen, params }: Props) => {
  const closeModal = useCloseModal()
  const { formatMessage } = useIntl()
  // Using refs here instead of state, as to prevent an unnecessary rerender
  const modalEscapeLock = useRef<boolean>(false)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === keyCode.escape && !modalEscapeLock.current) {
        closeModal()
      }
    },
    [closeModal]
  )
  useKeyDownListener(handleKeyDown, isOpen)

  const titleId = useMemo(() => uuid(), [])

  const modalContext = useMemo(
    () => ({
      titleId,
      params,
      modalEscapeLock,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(params), titleId, modalEscapeLock]
  )

  const handleCloseModal = useCloseModal()

  return (
    <AppearanceAnim
      isVisible={isOpen}
      className={styles.modal}
      // @ts-expect-error: This is correct https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role
      role="dialog"
      aria-labelledby={titleId}
    >
      <ErrorBoundary
        ctaText={formatMessage(strings.general.close)}
        ctaOnClick={handleCloseModal}
      >
        <ModalContext.Provider value={modalContext}>
          {children}
        </ModalContext.Provider>
      </ErrorBoundary>
    </AppearanceAnim>
  )
}

export default memo(Modal)
