import React, { useCallback, useState } from "react"
import { GoalTreeNode } from "@Goals/types"
// @ts-expect-error - We are importing a .js file that doesn't have a declaration
import { MapInteractionCSS } from "react-map-interaction"
import useGoalDossier from "@Domain/goals/useGoalDossier"
import { useGoalTreeState } from "@Goals/context/goalTreeState"
import zoomIn from "@kaizen/component-library/icons/zoom-in.icon.svg"
import zoomOut from "@kaizen/component-library/icons/zoom-out.icon.svg"
import { Icon } from "@kaizen/component-library"
import { Tooltip } from "@Components/Tooltip"
import { useIntl } from "@Common/locale/useIntl"
import { logGoalTreeExpanded } from "@Goals/analytics"
import { NavigationTreeNode } from "./NavigationTreeNode"
import { NavigationTreeNodePlaceholder } from "./NavigationTreeNodePlaceholder"
import styles from "./NavigationTree.scss"
import { RootTreeNode } from "./RootTreeNode"
import strings from "../../../locale/strings"

export type NavigationTreeProps = {
  goalTreeNodes: GoalTreeNode[]
  companyName: string
  logoUrl: string
  isLoading: boolean
}

type MapParams = {
  scale: number
  translation: {
    x: number
    y: number
  }
}

const defaultMapParams = {
  scale: 1,
  translation: { x: 0, y: 0 },
}

export const NavigationTree = ({
  goalTreeNodes,
  companyName,
  logoUrl,
  isLoading,
}: NavigationTreeProps) => {
  const { expandedNodes, updateExpandedNodes } = useGoalTreeState()
  const { formatMessage } = useIntl()
  const [mapParams, setMapParams] = useState<MapParams>(defaultMapParams)
  const { open: openGoalDrawer } = useGoalDossier()

  const handleNodeOnCollapse = (nodeId: string) =>
    updateExpandedNodes(expandedNodes.filter((id) => id !== nodeId))

  const handleNodeOnExpand = (nodeId: string) => {
    updateExpandedNodes([...expandedNodes, nodeId])

    logGoalTreeExpanded()
  }

  const handleNodeOnClick = (nodeId: number) => {
    openGoalDrawer(nodeId.toString())
  }

  const handleMapParamsOnChange = (value: MapParams) => setMapParams(value)

  const treeScrollContainerRef = useCallback(
    (node) => {
      if (node) {
        const { width } = node.getBoundingClientRect()

        setMapParams((preMapParams) => ({
          ...preMapParams,
          ...defaultMapParams,
          translation: {
            ...defaultMapParams.translation,
            x: (window.innerWidth - width) / 2,
            y: 0,
          },
        }))
      }
    },
    // We need this as deps. as goalTreeNodes filled async and we need this to calculate the width
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalTreeNodes]
  )

  return (
    <MapInteractionCSS
      value={mapParams}
      onChange={handleMapParamsOnChange}
      showControls
      controlsClass={styles.controls}
      btnClass={styles.btnClass}
      plusBtnClass={styles.plusBtn}
      minusBtnClass={styles.minusBtn}
      plusBtnContents={
        <Tooltip text={formatMessage(strings.goals.zoomToolTips.zoomIn)}>
          <div className={styles.toolTip}>
            <Icon icon={zoomIn} role={"presentation"} />
          </div>
        </Tooltip>
      }
      minusBtnContents={
        <Tooltip text={formatMessage(strings.goals.zoomToolTips.zoomOut)}>
          <div className={styles.toolTip}>
            <Icon icon={zoomOut} role={"presentation"} />
          </div>
        </Tooltip>
      }
    >
      <div className={styles.treeScrollContainer} ref={treeScrollContainerRef}>
        <div className={styles.tree}>
          <div className={styles.rootNodeContainer}>
            <RootTreeNode logoUrl={logoUrl} companyName={companyName} />
          </div>
          <div className={styles.rootChildRow}>
            {isLoading ? (
              <>
                <NavigationTreeNodePlaceholder isFirstLevel />
                <NavigationTreeNodePlaceholder isFirstLevel />
                <NavigationTreeNodePlaceholder isFirstLevel />
                <NavigationTreeNodePlaceholder isFirstLevel />
              </>
            ) : (
              goalTreeNodes.map((node) => (
                <NavigationTreeNode
                  key={node.id}
                  isFirstLevel
                  node={node}
                  onClick={handleNodeOnClick}
                  onCollapse={handleNodeOnCollapse}
                  onExpand={handleNodeOnExpand}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </MapInteractionCSS>
  )
}
