import { Tooltip } from "@Components/Tooltip"
import * as React from "react"
import styles from "./QuestionCell.scss"

export const OpenEndedQuestionCell = ({
  answerText,
}: {
  answerText: string
}) => {
  return (
    <Tooltip text={answerText}>
      <div className={styles.answerTextCell}>{answerText}</div>
    </Tooltip>
  )
}
