import { useCallback, useEffect, useRef, useState } from "react"
import { useIsMounted } from "../react/useIsMounted"

// See docs/apiFetching.md, for some related documentation.

/**
 * Wrapper that takes any promise, and supplies the loading, error, and data
 * values in an object array.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncCallback = <T, Args extends any[]>(
  cb: (...arg: Args) => Promise<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[]
): {
  isLoading: boolean
  data: T | undefined
  error: Error | undefined
  fire: (...args: Args) => Promise<T>
  refire: (...args: Args) => Promise<T>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const isMountedRef = useIsMounted()
  // Used for cancellation. As in, a call is made, and before it finishes, we
  // make the next one.
  const callCount = useRef<number>(0)

  // Same as `fire`, but does not clear the data while loading
  const refire = useCallback(
    async (...args: Args) => {
      if (isMountedRef.current) {
        setIsLoading(true)
        setError(undefined)
        // We don't want to clear the existing data value, since
        // we might be calling a refresh. If you'd like to hide
        // the data while content is loading, make sure you
      }
      const callId = (callCount.current += 1)
      try {
        const data: T = await cb(...args)
        if (isMountedRef.current && callId === callCount.current) {
          setData(data)
        }
        return data
      } catch (ex) {
        if (isMountedRef.current && callId === callCount.current) {
          setData(undefined)
          if (ex instanceof Error) {
            setError(ex)
          }
        }
        throw ex
      } finally {
        if (isMountedRef.current && callId === callCount.current) {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  const fire = useCallback(
    async (...args: Args) => {
      if (isMountedRef.current) {
        setIsLoading(true)
      }
      return refire(...args)
    },
    [isMountedRef, refire]
  )

  return {
    isLoading,
    data,
    error,
    refire,
    fire,
  }
}

// Wrapper around the `useAsyncCallback` hook. Convenient for when you just
// want to fetch some data and display it on a page.
// Unlike the regular `useEffect` hook, this version of the callback does not
// take in a cleanup return. Therefore you can pass promises directly into the
// function.
export const useAsyncEffect = <T, Args extends unknown[]>(
  cb: () => Promise<T>,
  deps: unknown[]
): {
  isLoading: boolean
  data: T | undefined
  error: Error | undefined
  fire: (...args: Args) => Promise<T>
  refire: (...args: Args) => Promise<T>
} => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { isLoading, data, error, fire, refire } = useAsyncCallback(cb, deps)

  useEffect(() => {
    fire()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return {
    isLoading,
    data,
    error,
    fire,
    refire,
  }
}
