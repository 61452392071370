import * as React from "react"
import { useCarousel } from "@Calibrations/v2/hooks/useCarousel"
import { StatusHeroStat } from "@Calibrations/v2/queries/useGetHeroStats"
import { IconButton } from "@kaizen/button"
import { useIntl } from "react-intl"
import arrowLeftIcon from "@kaizen/component-library/icons/arrow-left.icon.svg"
import arrowRightIcon from "@kaizen/component-library/icons/arrow-right.icon.svg"
import strings from "@Calibrations/locale/strings"
import { Stat, StatEmployeeCount, StatPercentage, StatTitle } from ".."

export const StatusStatsRow = ({ data }: { data: Array<StatusHeroStat> }) => {
  const { formatMessage } = useIntl()
  const { refCallback, visibleItems, nextPage, prevPage, hasNext, hasPrev } =
    useCarousel({ items: data })

  return (
    <div className="inline-flex items-center w-full">
      <IconButton
        label=""
        icon={arrowLeftIcon}
        onClick={() => prevPage()}
        disabled={!hasPrev}
      />
      <div className="flex grow w-full" ref={refCallback}>
        {visibleItems.map((item) => {
          return (
            <Stat key={item.status}>
              <StatTitle
                title={formatMessage(
                  strings.components.CalibrationHeroStats.managerReviewStatuses[
                    item.status
                  ]
                )}
                description={null}
              />
              <StatPercentage percentage={item.percentage} />
              <StatEmployeeCount employeeCount={item.total} />
            </Stat>
          )
        })}
      </div>
      <IconButton
        label=""
        icon={arrowRightIcon}
        onClick={() => nextPage()}
        disabled={!hasNext}
      />
    </div>
  )
}
