import * as React from "react"
import cx from "classnames"

type Props = {
  isDisabled?: boolean
  isActive: boolean
  onClick: () => void
  children: React.ReactNode
}
export const StatsTab: React.FC<Props> = ({
  children,
  isDisabled,
  isActive,
  onClick,
}: Props) => {
  if (isDisabled) {
    return (
      <div
        className="text-center border-none bg-white h-full w-full py-8 text-gray-400 cursor-not-allowed"
        aria-disabled
        role="tab"
      >
        {children}
      </div>
    )
  }
  return (
    <button
      className={cx(
        "text-center cursor-pointer border-none bg-white h-full w-full py-8",
        { "font-weight-data": isActive }
      )}
      role="tab"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
