import * as React from "react"
import { Select } from "@kaizen/select"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { Paragraph } from "@kaizen/typography"
import { TruncatableText } from "@Calibrations/components/TruncatableText/TruncatableText"
import Avo from "@Analytics/avo/Avo"
import {
  AssessmentGroup,
  AssessmentRating,
  ReviewResult,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { useUpdateAssessmentRating } from "@Calibrations/v2/queries/useUpdateAssessmentRating"

type AssessmentGroupCellProps = {
  review: ReviewResult
  assessmentGroup: AssessmentGroup
  assessmentRating: AssessmentRating
  calibrationId: number
}

export const AssessmentGroupCell: React.FC<AssessmentGroupCellProps> = ({
  review,
  assessmentGroup,
  assessmentRating,
  calibrationId,
}) => {
  const { formatMessage } = useIntl()
  const mutation = useUpdateAssessmentRating({
    calibrationId: calibrationId,
  })

  const options = assessmentGroup
    ? assessmentGroup.choices.map(({ id, title }) => ({
        id,
        value: id,
        label: title,
      }))
    : []

  const currentRating = assessmentGroup.choices.find(
    (choice) => choice.id === assessmentRating.currentRatingId
  )
  const pendingRating = assessmentGroup.choices.find(
    (choice) => choice.id === assessmentRating.pendingRatingId
  )

  if (!review || !review.managerReview.managerCompletedAt) {
    return <>-</>
  }
  return (
    <div className="w-full">
      <Select
        items={options}
        aria-label={"Selected performance rating"}
        id="selectedBucket"
        onSelectionChange={(key) => {
          mutation
            .mutateAsync({
              calibrationId,
              managerReviewId: review.managerReview.id,
              pendingRatingId: Number(key),
            })
            .then(() =>
              Avo.calibrationViewManagerRatingChanged({
                calibrationViewId: calibrationId,
                performanceQuestionType: "Assessment group",
                managerReviewCycleId: review.managerReviewCycle.id,
                managerReviewId: review.managerReview.id,
              })
            )
        }}
        selectedKey={
          pendingRating
            ? pendingRating.id.toString()
            : currentRating?.id.toString()
        }
      />

      {pendingRating && pendingRating.id !== currentRating?.id && (
        <Paragraph variant="small" classNameOverride="w-full mt-4" tag={"div"}>
          <TruncatableText>
            <span className="opacity-70 text-purple-800">
              {formatMessage(
                strings.pages.AdminCalibrationViewShowPage.AssessmentGroupCell
                  .preCalibrationRating
              )}
            </span>{" "}
            {currentRating?.title}
          </TruncatableText>
        </Paragraph>
      )}
    </div>
  )
}
