import * as React from "react"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { NavigationTab } from "@kaizen/draft-title-block-zen"
import { useIntl } from "@Common/locale/useIntl"
import { createRouterClickCallback } from "@Utils/createRouterLink"
import { prependBasePath } from "@Utils/routing"
import { TitleBlock } from "@Components/layout/TitleBlock/TitleBlock"
import Loading from "@Components/Loading/Loading"
import { RoutePaths } from "@Goals/constants"
import { parse } from "query-string"
import useGoalDossier from "@Domain/goals/useGoalDossier"
import useGoalDetails from "@Domain/goals/useGoalDetails"
import useCurrentUser from "@Domain/auth/useCurrentUser"
import useTeams from "@Domain/teams/useTeams"
import GoalDetails from "@Containers/GoalDossier/GoalDetails"
import strings from "@Locale/strings"
import { SkirtPageLayout } from "./SkirtPageLayout/SkirtPageLayout"
import { Goals, Goal } from "./GoalsConfiguration"
import styles from "./GoalsApp.scss"

type GoalsApp = React.FunctionComponent

enum GOAL_ACTION {
  EMPTY = "",
  CREATE = "create",
  EDIT = "edit",
  COPY = "copy",
}

export const GoalsApp: GoalsApp = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const {
    isOpen: dossierIsOpen,
    close: closeDossier,
    goalId = "",
    effectiveOwnerId = null,
  } = useGoalDossier()
  const { goal } = useGoalDetails(Number(goalId))
  const currentUser = useCurrentUser()
  const { formatMessage } = useIntl()
  const { teams } = useTeams({ all_teams: false })

  const [activePage, setActivePage] = useState(Goals.personal.type)
  const [goalStatus, setGoalStatus] = useState(GOAL_ACTION.EMPTY)

  const resetGoal = () => {
    setGoalStatus(GOAL_ACTION.EMPTY)
  }

  useEffect(() => {
    // Set context from url for edit and create modes.
    const url = location.pathname.replace("new_goals", "goals")

    Object.keys(Goals).forEach((k) => {
      // @ts-expect-error - This should be typed
      if (url.includes(k)) setActivePage(Goals[k].type)

      if (url.includes("edit") && url.includes(k)) {
        setGoalStatus(GOAL_ACTION.EDIT)
      }

      if (url.includes("copy") && url.includes(k)) {
        setGoalStatus(GOAL_ACTION.COPY)
      }
      if (url.includes("new") && url.includes(k)) {
        setGoalStatus(GOAL_ACTION.CREATE)
      }
    })

    if (!url.includes("new") && !url.includes("edit") && !url.includes("copy"))
      resetGoal()
  }, [location])

  const getGoal = (type: string) => {
    // @ts-expect-error - This should be typed
    return Goals[type]
  }

  const constructGoalTabUrl = (goal: Goal) => {
    if (goal.type === "company") {
      return RoutePaths.goalsTree
    }

    return goal.path
  }

  const handleNavigationTabPageChange = (e: React.MouseEvent, goal: Goal) => {
    setActivePage(goal.type)
    const routerUrl = constructGoalTabUrl(goal)
    createRouterClickCallback(history, routerUrl)(e)
  }

  const handleBackToPreviousPage = React.useCallback(
    (e: React.MouseEvent, goal: Goal) => {
      const { returnUrl } = parse(location.search)
      const routerUrl = (returnUrl as string) ?? goal.path
      createRouterClickCallback(history, routerUrl)(e)
    },
    [location, history]
  )

  const constructTitleBlockBackUrl = React.useCallback(
    (goal: Goal) => {
      const { returnUrl } = parse(location.search)
      const url = prependBasePath((returnUrl as string) ?? goal.path)

      return url
    },
    [location]
  )

  const handleCreateButtonClick = (goal: Goal) => {
    setActivePage(goal.type)
    setGoalStatus(GOAL_ACTION.CREATE)

    history.replace(goal.create.path)
  }

  const renderNavigationTabs = () => {
    return Object.keys(Goals)
      .filter((k: string) => {
        if (k === "directReport") {
          return currentUser.is_a_manager
        }
        return true
      })
      .map((k: string) => {
        // @ts-expect-error - This should be typed
        const goal = Goals[k]

        if (goal.tab.visible(currentUser)) {
          return (
            <NavigationTab
              key={goal.type}
              text={formatMessage(goal.tab.label)}
              href={prependBasePath(constructGoalTabUrl(goal))}
              handleClick={(e) => handleNavigationTabPageChange(e, goal)}
              active={activePage === goal.type}
            />
          )
        }
        return undefined
      })
      .filter((tab): tab is React.ReactElement => !!tab)
  }

  const teamGoalCreationEnabled = teams.length > 0

  const getGoalButtonMenuItems = () => {
    return Array.from(
      Object.keys(Goals)
        .filter((key) => key !== "directReport")
        .map((k: string) => {
          // @ts-expect-error - This should be typed
          const goal = Goals[k]

          return {
            label: formatMessage(goal.create.label),
            action: () => handleCreateButtonClick(goal),
            disabled: goal.create.disabled(
              currentUser,
              teamGoalCreationEnabled
            ),
          }
        })
    )
  }

  const isGoalsTreePage = location.pathname.includes("tree")

  const pageLayoutProps = {
    dossierSettings: {
      isOpen: dossierIsOpen,
      content: goal ? (
        <GoalDetails
          goal={goal}
          effectiveOwnerId={effectiveOwnerId as string}
        />
      ) : (
        <Loading />
      ),
      onClose: closeDossier,
    },
    headingContent:
      goalStatus !== GOAL_ACTION.EMPTY ? (
        <TitleBlock
          title={getTitleBlock(goalStatus, activePage)}
          breadcrumb={{
            path: constructTitleBlockBackUrl(getGoal(activePage)),
            text: formatMessage(strings.goalsPage.backToGoalsSection, {
              section: formatMessage(getGoal(activePage).section),
            }),
            handleClick: (e) => {
              handleBackToPreviousPage(e, getGoal(activePage))
            },
          }}
        />
      ) : (
        <TitleBlock
          title={formatMessage(strings.goalsPage.header)}
          navigationTabs={renderNavigationTabs()}
          primaryAction={{
            label: formatMessage(strings.goalsPage.createGoalButton),
            menuItems: getGoalButtonMenuItems(),
          }}
        />
      ),
    noHorizontalMargins: isGoalsTreePage,
  }

  return (
    <>
      <SkirtPageLayout {...pageLayoutProps}>
        {goalStatus !== GOAL_ACTION.EMPTY ? (
          <div className={styles.container}>{children}</div>
        ) : (
          <>{children}</>
        )}
      </SkirtPageLayout>
    </>
  )

  function getTitleBlock(goalStatus: GOAL_ACTION, goalType: string): string {
    switch (goalStatus) {
      case GOAL_ACTION.EMPTY:
        return formatMessage(strings.goalsPage.header)
      case GOAL_ACTION.CREATE:
        return formatMessage(getGoal(goalType).create.title)
      case GOAL_ACTION.EDIT:
        return formatMessage(getGoal(goalType).edit.title)
      case GOAL_ACTION.COPY:
        return formatMessage(strings.copyGoal.title)
    }
  }
}
