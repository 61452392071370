import { useParams } from "react-router-dom"
import { useUpdateFlag } from "@Calibrations/v2/queries/useUpdateFlag"
import Avo from "@Avo/Avo"
import * as React from "react"
import { IconButton } from "@kaizen/button"
import { useIntl } from "@Common/locale/useIntl"
import flagOffIcon from "@kaizen/component-library/icons/flag-off-white.icon.svg"
import flagOnIcon from "@kaizen/component-library/icons/flag-on.icon.svg"
import strings from "@Calibrations/locale/strings"
import { addToastNotification } from "@kaizen/notification"
import { Icon } from "@kaizen/component-library"
import { ReviewResult } from "@Calibrations/v2/queries/useGetReviewResults"

const { FlagCell: FlagCellStrings } = strings.components.TableCells
export const FlagCell = ({
  reviewResult,
  editable,
}: {
  reviewResult: ReviewResult
  editable: boolean
}) => {
  const { calibrationViewId } = useParams<{ calibrationViewId: string }>()
  const { formatMessage } = useIntl()
  const mutation = useUpdateFlag({
    calibrationId: Number(calibrationViewId),
  })
  const isCurrentlyFlagged = reviewResult.managerReview.flag === "flagged"
  const newFlagState = isCurrentlyFlagged ? "not_flagged" : "flagged"
  const icon = isCurrentlyFlagged ? flagOnIcon : flagOffIcon

  if (!editable) {
    return (
      <Icon
        icon={icon}
        title={formatMessage(
          isCurrentlyFlagged
            ? FlagCellStrings.iconTitleFlagged
            : FlagCellStrings.iconTitleUnflagged
        )}
        role="img"
      />
    )
  }

  const handleOnClick = () => {
    mutation
      .mutateAsync({
        isFlagged: newFlagState,
        managerReviewId: reviewResult.managerReview.id,
        calibrationId: Number(calibrationViewId),
      })
      .then(() => {
        Avo.calibrationFlagToggled({
          calibrationViewId: Number(calibrationViewId),
          flagToggleState: newFlagState,
        })
        addToastNotification({
          type: "positive",
          title: formatMessage(FlagCellStrings.toggleFlagToastSuccess.title),
          message: formatMessage(
            FlagCellStrings.toggleFlagToastSuccess.success
          ),
        })
      })
      .catch(() => {
        addToastNotification({
          type: "negative",
          title: formatMessage(FlagCellStrings.toggleFlagToastError.title),
          message: formatMessage(FlagCellStrings.toggleFlagToastError.success),
        })
      })
  }

  return (
    <IconButton
      icon={icon}
      onClick={handleOnClick}
      label={formatMessage(
        isCurrentlyFlagged
          ? FlagCellStrings.iconButtonFlagged
          : FlagCellStrings.iconButtonUnflagged
      )}
    />
  )
}
