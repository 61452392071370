import snakeCase from "lodash/snakeCase"

export function convertKeysCamelToSnakeCase<T>(obj: {
  [key: string]: unknown
}): T {
  return Object.entries(obj).reduce(
    (options: T, [key, value]: [string, unknown]) => {
      return { ...options, [snakeCase(key)]: value }
    },
    {} as T
  )
}
