import { useReducer, useCallback } from "react"
import { perfApiFetch } from "@API/utils"
import Settings from "@Settings"

/**
 * This file is a copy of `useDataAPI`. We want to change the API URL for fetching
 * teams resources while keeping the interface and changing as little code as possible.
 * Instead of modifying the `useDataAPI` function to accept a different path,
 * and scattering a Settings.New_Endpoint in each teams hook that needs it, we are
 * duplicating the hook wholesale in the hope it's more _obviously_ different.
 *
 * Code that relies on Teams is in the process of being removed from performance-ui,
 * hence the... laziness.
 */

type DataFetchState = {
  loading: boolean
  error?: Error
  data?: JSON
}

type DataFetchAction = SuccessAction | ErrorAction | FetchAction

type FetchAction = {
  type: "FETCH"
}

type SuccessAction = {
  type: "SUCCESS"
  payload: JSON
}

type ErrorAction = {
  type: "ERROR"
  payload: Error
}

type DataAPIMethod = "POST" | "GET" | "PUT" | "DELETE"

const INITIAL_STATE: DataFetchState = {
  loading: false,
}

const dataFetchReducer = (
  state: DataFetchState,
  action: DataFetchAction
): DataFetchState => {
  switch (action.type) {
    case "FETCH":
      return {
        ...INITIAL_STATE,
        data: undefined,
        error: undefined,
        loading: true,
      }
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        data: undefined,
        loading: false,
      }
    case "SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: undefined,
        loading: false,
      }
  }
}

const httpMethods = ["POST", "PUT", "PATCH"]

/**
 * This hook has now been deprecated. See docs/apiFetching.md for more info.
 */
export const useTeamAPI = (url: string, method: DataAPIMethod) => {
  const [state, dispatch] = useReducer(dataFetchReducer, INITIAL_STATE)

  const fire = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (body?: any) => {
      dispatch({ type: "FETCH" })

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = await perfApiFetch<any>(
          url,
          {
            method,
            body: httpMethods.includes(method) ? body : undefined,
            params: method === "GET" ? body : undefined,
          },
          Settings.PERFORMANCE_API_V2_URL
        )
        dispatch({ type: "SUCCESS", payload: result.data })

        return result
      } catch (error) {
        if (error instanceof Error) {
          dispatch({ type: "ERROR", payload: error })
        }
        throw error
      }
    },
    [method, url]
  )

  return { ...state, fire }
}
