import { Heading } from "@kaizen/typography"
import * as React from "react"
import { injectIntl, IntlShape } from "react-intl"
import AvatarDetails from "../../components/AvatarDetails/AvatarDetails"
import strings from "../../locale/strings"
import styles from "./GoalOwnersDetails.scss"
import { GoalOwner } from "../../types/Goals"

type GoalOwnersDetailsProps = {
  owners: GoalOwner[]
  intl: IntlShape
}

const GoalOwnersDetails = ({ owners, intl }: GoalOwnersDetailsProps) => {
  const { formatMessage } = intl
  const itemsPerRow = 2
  const numberOfRows = Math.round(owners.length / itemsPerRow + 0.5)
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Heading variant="heading-5">
          {formatMessage(strings.goalsPage.goalsDossier.owners)}
        </Heading>
      </div>
      {[...Array(numberOfRows).keys()].map((row) => {
        return (
          <div className={styles.row} key={`row-${row}`}>
            {[...Array(itemsPerRow).keys()].map((item) => {
              const owner = owners[itemsPerRow * row + item]
              return (
                owner && (
                  <div className={styles.item} key={`row-${row}-item-${item}`}>
                    <AvatarDetails
                      size="medium"
                      bestName={owner.name ? owner.name : ""}
                      avatarURL={owner.profileImage ? owner.profileImage : ""}
                      jobTitle={owner.role}
                    />
                  </div>
                )
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default injectIntl(GoalOwnersDetails)
