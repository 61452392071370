// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiPut } from "@API/utils"
import { Calibrations } from "bff/upstreams"
import { QueryKeys } from "./keys"

type FetchResult = Calibrations["calibrations.CalibrationDetail"]

type UpdateCalibrationViewParams = {
  calibrationId: number
  name: string
}

const updateCalibrationViewName = async ({
  calibrationId,
  name,
}: UpdateCalibrationViewParams) => {
  return perfApiPut<Calibrations["Success"]>(
    "/dashboard/calibrations/:calibrationId",
    {
      params: {
        calibrationId,
      },
      body: {
        name,
      },
    }
  )
}

export const useUpdateCalibrationViewName = ({
  calibrationId,
}: {
  calibrationId: number
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (name: string) =>
      updateCalibrationViewName({
        calibrationId,
        name,
      }),
    {
      onSuccess: (res, name) => {
        queryClient.setQueryData<FetchResult | undefined>(
          [QueryKeys.AdminCalibrationViewsDetails, Number(calibrationId)],
          (previous) => {
            if (!previous) {
              return undefined
            }
            return {
              ...previous,
              calibration: { ...previous.calibration, name: name },
            }
          }
        )
      },
    }
  )
}
