// A wrapper for "JSON.parse()"" to support "undefined" value
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "")
  } catch {
    return undefined
  }
}

export type GoalCyclePeriods = {
  startDate: Date
  endDate: Date
}

type GenerateGoalCyclesArgs = {
  startDate: number
  startMonth: number
  cycleLength: number
}

export function generateGoalCycles({
  startDate,
  startMonth,
  cycleLength,
}: GenerateGoalCyclesArgs) {
  const cycles: GoalCyclePeriods[] = []
  const currentDate = new Date()

  const cycleStart = new Date(
    currentDate.getFullYear(),
    startMonth - 1,
    startDate
  )

  const monthsFromStart =
    currentDate.getFullYear() * 12 +
    currentDate.getMonth() -
    (cycleStart.getFullYear() * 12 + cycleStart.getMonth())

  const periodOffset = Math.floor(monthsFromStart / cycleLength)

  const cycleStartMonth = startMonth - 1 + periodOffset * cycleLength

  const currentStartDate = new Date(
    currentDate.getFullYear(),
    cycleStartMonth,
    startDate
  )

  // Calculate the end date of the current cycle
  const currentEndDate = new Date(
    currentDate.getFullYear(),
    cycleStartMonth + cycleLength,
    0,
    23,
    59,
    59,
    999
  )

  // Push the current cycle to the array
  cycles.push({
    startDate: currentStartDate,
    endDate: currentEndDate,
  })

  let prevStartDate = new Date(
    currentStartDate.getFullYear(),
    currentStartDate.getMonth() - cycleLength,
    startDate
  )

  let prevEndDate = new Date(
    currentEndDate.getFullYear(),
    currentEndDate.getMonth() - cycleLength + 1,
    0,
    23,
    59,
    59,
    999
  )

  // Calculate the date range for the previous 3 cycles
  for (let i = 1; i <= 3; i++) {
    cycles.push({
      startDate: prevStartDate,
      endDate: prevEndDate,
    })

    prevStartDate = new Date(
      prevStartDate.getFullYear(),
      prevStartDate.getMonth() - cycleLength,
      startDate
    )

    prevEndDate = new Date(
      prevEndDate.getFullYear(),
      prevEndDate.getMonth() - cycleLength + 1,
      0,
      23,
      59,
      59,
      999
    )
  }

  return cycles
}
