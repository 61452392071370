import { Calibrations } from "@BFF/upstreams/index"

const getAllAssessmentGroupChoiceTitles = (
  choices: Calibrations["calibrations.AssessmentGroupChoice"][] | undefined
) => (choices ? choices.map((choice) => choice.title).join("") : undefined)

export const shouldDisplayAssessmentGroupColumn = (
  assessmentGroups: Calibrations["calibrations.ManagerReviewCycle"]["assessmentGroup"][]
): boolean => {
  if (assessmentGroups.every((group) => group === undefined)) {
    return false
  }
  const allAssessmentGroupChoicesEqual = assessmentGroups.every(
    (group) =>
      getAllAssessmentGroupChoiceTitles(group?.choices) ===
      getAllAssessmentGroupChoiceTitles(assessmentGroups[0]?.choices)
  )
  return allAssessmentGroupChoicesEqual
}
