import { ConfirmationModal } from "@kaizen/draft-modal"
import { calibrationsPaths } from "@Calibrations/paths"
import { addToastNotification } from "@kaizen/notification"
import { Paragraph } from "@kaizen/typography"
import * as React from "react"
import { useIntl } from "@Common/locale/useIntl"
import { useDeleteCalibrationView } from "@Calibrations/v2/queries/useDeleteCalibrationView"
import { useHistory, useParams } from "react-router-dom"
import strings from "@Calibrations/locale/strings"
const {
  pages: { AdminCalibrationViewsDetail: PageStrings },
} = strings

export const DeleteCalibrationModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (e: boolean) => void
}) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { calibrationViewId } = useParams<{ calibrationViewId?: string }>()

  const deleteMutation = useDeleteCalibrationView(Number(calibrationViewId))

  // TODO: This is duplicate code. Maybe refactor
  function isError(error: unknown): error is Error {
    return error !== null && typeof error === "object" && "message" in error
  }
  const handleDeleteError = (error: unknown, title: string) => {
    if (isError(error)) {
      addToastNotification({
        type: "negative",
        title: title,
        message: "",
      })
    }
  }

  return (
    <ConfirmationModal
      mood="negative"
      isOpen={isOpen}
      title={formatMessage(PageStrings.DeleteModal.title)}
      onDismiss={() => setIsOpen(false)}
      onConfirm={() =>
        deleteMutation.mutate(undefined, {
          onSuccess: () => {
            setIsOpen(false)
            history.replace(calibrationsPaths.admin.views.index({}))
            addToastNotification({
              type: "positive",
              title: formatMessage(PageStrings.Toasts.delete.success),
              message: "",
            })
          },
          onError: (error) =>
            handleDeleteError(
              error,
              formatMessage(PageStrings.Toasts.delete.error)
            ),
        })
      }
      confirmLabel={formatMessage(PageStrings.DeleteModal.primaryButton)}
      dismissLabel={formatMessage(PageStrings.DeleteModal.secondaryButton)}
    >
      <Paragraph variant="body">
        {formatMessage(PageStrings.DeleteModal.body)}
      </Paragraph>
    </ConfirmationModal>
  )
}
