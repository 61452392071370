import { useCallback, useContext, useEffect, useRef } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { parseUrl } from "query-string"
import {
  BrowserHistoryStack,
  useBrowserHistoryStack,
} from "../../reduxReducers/browserHistory"
import {
  addOrReplaceModalParams,
  getModalCloseHref,
  getModalHref,
} from "../../utils/modal"
import { modalQsKey, Modals } from "../../constants/modals"
import { ModalContext } from "../../containers/ModalRouter/Modal"
import { QueryStringParams } from "../../types/url"
import { getPathQueryHash } from "../../utils/routing"

/**
 * More information about the modals system found under docs/modals.md
 */

const getPreviousLocationInStack = (
  browserHistoryStack: BrowserHistoryStack,
  currentIndex: number
) => {
  return currentIndex > 0 ? browserHistoryStack[currentIndex - 1] : null
}

const getPreviousPathQueryHashInStack = (
  browserHistoryStack: BrowserHistoryStack,
  currentIndex: number
) => {
  const location = getPreviousLocationInStack(browserHistoryStack, currentIndex)
  return (
    location &&
    [location.pathname, location.search, location.hash].filter(Boolean).join("")
  )
}

export const useCloseModalHref = () => {
  const location = useLocation()

  return getModalCloseHref(location.pathname + location.search + location.hash)
}

export const useCloseModal = () => {
  const location = useLocation()
  const history = useHistory()

  const { browserHistoryStack, currentIndex } = useBrowserHistoryStack()
  getPreviousPathQueryHashInStack(browserHistoryStack, currentIndex)

  const closedHref = getModalCloseHref(
    location.pathname + location.search + location.hash
  )

  return useCallback(() => {
    const previousHref = getPreviousPathQueryHashInStack(
      browserHistoryStack,
      currentIndex
    )

    if (previousHref === closedHref) {
      history.goBack()
    } else {
      // The user has likely either deep linked to the modal, or pressed
      // refresh while the modal was open. In which case, use a history.replace,
      // as to not add a second entry to the browser history, and to prevent
      // the user from going "back" into the open modal.
      history.replace(closedHref)
    }
  }, [browserHistoryStack, closedHref, currentIndex, history])
}

export const useModalHref = (modalKey: Modals, params?: QueryStringParams) => {
  const location = useLocation()

  return getModalHref(
    modalKey,
    params,
    location.pathname + location.search + location.hash
  )
}

export const useModalContext = () => useContext(ModalContext)

const useCurrentModalKey = (): string | undefined => {
  const location = useLocation()
  return parseUrl(getPathQueryHash(location)).query[modalQsKey] as
    | string
    | undefined
}

// Effect that gets called automatically when any modal has closed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEffectOnModalClose = (
  callback: ((lastOpenModalKey: string) => void) | (() => () => void),
  // Optional: only calls the callback, when one of the supplied modals in the
  // array is called.
  modalKeyWhitelist?: Modals[]
) => {
  const modalKey = useCurrentModalKey()
  const oldModalKeyRef = useRef<string>()
  const initialCallRef = useRef(false)
  useEffect(() => {
    if (
      !modalKey &&
      initialCallRef.current // Don't run on the initial render
    ) {
      if (
        !modalKeyWhitelist ||
        (modalKeyWhitelist as string[]).includes(
          oldModalKeyRef.current as string
        )
      ) {
        callback(oldModalKeyRef.current as string)
      }
    }
    oldModalKeyRef.current = modalKey
    initialCallRef.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalKey])
}

/**
 * Returns a function that allows you to add new modal params to the query string.
 * If the modal param already exists, it will be replaced instead.
 */
export const useAddOrReplaceModalParams = () => {
  const history = useHistory()
  const location = useLocation()
  return useCallback(
    (additionalParams: QueryStringParams) => {
      const currentUrl = location.pathname + location.search + location.hash
      const newHref = addOrReplaceModalParams(currentUrl, additionalParams)
      history.replace(newHref)
    },
    [history, location]
  )
}

/**
 * This hook was designed specifically for the AsyncSelect component, but it
 * could potentially have other use cases.
 * If that component is inside a modal, and the menu is open, we want the ESC
 * key to close the menu, not close the modal. I wasn't able to figure out
 * how to do e.stopImmediatePropagation(), so this hook allows us to talk to the
 * modal context, to tell it when we're done using the escape button.
 *
 * If this hook is not used inside a modal (via ModalContext), this function
 * will not fail, it will simply not do anything. This is due to the inability
 * to conditionally render react hooks.
 */
export const useModalEscapeLock = () => {
  const { modalEscapeLock } = useModalContext()
  // I'm intentionally using imperative callbacks, instead of state updates,
  // since it will prevent a second render.
  const enableModalLock = useCallback(() => {
    if (!modalEscapeLock) return // This means the hook is not inside a ModalContext
    modalEscapeLock.current = true
  }, [modalEscapeLock])
  const releaseModalLock = useCallback(() => {
    if (!modalEscapeLock) return // This means the hook is not inside a ModalContext
    modalEscapeLock.current = false
  }, [modalEscapeLock])

  return {
    enableModalLock,
    releaseModalLock,
  }
}
