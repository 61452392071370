import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiPut } from "@API/utils"
import { Calibrations } from "bff/upstreams"
import { QueryKeys } from "./keys"
import { fetchReviewResult } from "./useGetSingleReviewResult"
import { useCalibrationViewQueryParams } from "../hooks/useCalibrationViewQueryParams"

type FetchResult = Calibrations["managerReviews.ManagerReviewsDetail"]

type UpdateFlagParams = {
  calibrationId: number
  managerReviewId: number
  isFlagged: "flagged" | "not_flagged"
}

const updateFlag = async ({
  calibrationId,
  managerReviewId,
  isFlagged,
}: UpdateFlagParams) => {
  return perfApiPut<Calibrations["Success"]>(
    "dashboard/calibrations/:calibrationId/manager_reviews/:id",
    {
      params: { calibrationId: calibrationId, id: managerReviewId },
      body: { flag: isFlagged },
    }
  )
}

export const updateFlagRefetchReview = ({
  calibrationId,
  managerReviewId,
  isFlagged,
}: UpdateFlagParams) => {
  return updateFlag({
    calibrationId,
    managerReviewId,
    isFlagged,
  }).then(() =>
    fetchReviewResult({ calibrationViewId: calibrationId, managerReviewId })
  )
}

export const useUpdateFlag = ({ calibrationId }: { calibrationId: number }) => {
  const queryClient = useQueryClient()

  const [searchParams] = useCalibrationViewQueryParams()
  return useMutation(
    ({ managerReviewId, isFlagged }: UpdateFlagParams) =>
      updateFlagRefetchReview({
        calibrationId,
        managerReviewId,
        isFlagged,
      }),
    {
      onMutate: async (updatedFlag) => {
        await queryClient.cancelQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        const previousReviewResults = queryClient.getQueryData([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
        queryClient.setQueryData<FetchResult | undefined>(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          (previous) => {
            if (!previous) {
              return undefined
            }
            return {
              ...previous,
              managerReviews: previous.managerReviews.map((review) =>
                updatedFlag.managerReviewId === review.managerReview.id
                  ? {
                      ...review,
                      managerReview: {
                        ...review.managerReview,
                        flag: updatedFlag.isFlagged,
                      },
                    }
                  : review
              ),
            }
          }
        )
        return { previousReviewResults }
      },
      onError: (err, updatedReviewResult, context) => {
        queryClient.setQueryData(
          [QueryKeys.AdminReviewResults, calibrationId, searchParams],
          context?.previousReviewResults
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          QueryKeys.AdminReviewResults,
          calibrationId,
          searchParams,
        ])
      },
    }
  )
}
