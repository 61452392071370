import Reflux from "../ActionsInitializer"
import SteadyfootAgent from "./SteadyfootAgent"
import {
  addLoadingState,
  extractResponseKey,
  addToasts,
} from "./apiActionHelpers"
import SurveyResponse from "../../models/SurveyResponse"
import strings from "../../locale/strings"

const agent = SteadyfootAgent.defaultInstance

export default function createIncompleteSurveyResponseActions({
  surveyResponsesUrl,
  surveyItemsUrl,
}) {
  const actions = Reflux.createActions({
    getIncompleteSurveyResponse: { asyncResult: true },
    acceptIncompleteSurveyResponse: { asyncResult: true },
    rejectIncompleteSurveyResponse: { asyncResult: true },
    updateSurveyItemRemotely: { asyncResult: true },
    updateSurveyItemLocally: { sync: true },
    submitSurveyResponse: { asyncResult: true },
  })

  actions.getIncompleteSurveyResponse.listenAndPromise((id) =>
    addLoadingState(
      true,
      extractResponseKey(
        "survey_response",
        SurveyResponse.of,
        agent.get(`${surveyResponsesUrl}/${id}`)
      )
    )
  )

  actions.acceptIncompleteSurveyResponse.listenAndPromise((invitationToken) =>
    addLoadingState(
      true,
      extractResponseKey(
        "survey_response",
        SurveyResponse.of,
        agent.get(`${surveyResponsesUrl}/${invitationToken}`)
      )
    )
  )

  actions.rejectIncompleteSurveyResponse.listenAndPromise(({ id, reason }) =>
    addLoadingState(
      true,
      extractResponseKey(
        "survey_response",
        SurveyResponse.of,
        agent.put(`${surveyResponsesUrl}/${id}`).send({
          survey_response: {
            reject: reason,
          },
        })
      )
    )
  )

  actions.updateSurveyItemRemotely.listenAndPromise(({ id, answerObject }) =>
    extractResponseKey(
      "survey_item",
      agent.put(`${surveyItemsUrl}/${id}`).send(answerObject)
    ).catch(() => Promise.reject(id))
  )

  const getToast = (type, subjectName) => {
    switch (type) {
      case "SelfReflection":
        return { success: strings.review.selfReflectionSuccessMessage }
      case "Feedback":
        return {
          success: strings.review.individualSuccessMessage,
          values: { subjectName },
        }
      default:
        return { success: strings.review.successMessage }
    }
  }

  actions.submitSurveyResponse.listenAndPromise(
    (surveyResponse, type = null, subjectName = null) =>
      addToasts(
        getToast(type, subjectName),
        extractResponseKey(
          "survey_response",
          SurveyResponse.of,
          agent
            .put(`${surveyResponsesUrl}/${surveyResponse.id}`)
            .send({ survey_response: surveyResponse })
        )
      )
  )

  return actions
}
