import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiPut } from "@API/utils"
import { QueryKeys } from "@Calibrations/v2/queries/keys"
import { CalibrationView } from "@Calibrations/v2/queries/useGetCalibrationView"

const publishCalibrationView = async (
  calibrationViewId: number,
  shouldSendNotifications: boolean
) => {
  return perfApiPut<void>("/dashboard/saved_views/:savedViewId/publish", {
    params: {
      savedViewId: calibrationViewId,
    },
    body: {
      send_notifications: shouldSendNotifications,
    },
  })
}
export const usePublishCalibrationView = (calibrationViewId: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    (shouldSendNotifications: boolean) =>
      publishCalibrationView(calibrationViewId, shouldSendNotifications),
    {
      onSuccess: () => {
        queryClient.setQueryData<{ calibration: CalibrationView } | undefined>(
          [QueryKeys.AdminCalibrationViewsDetails, calibrationViewId],
          (calibration) => {
            if (!calibration) {
              return undefined
            }

            return {
              calibration: { ...calibration.calibration, state: "published" },
            }
          }
        )
      },
    }
  )
}
