import { KeyResult } from "@Goals/types"
import { v4 as uuid } from "uuid"
import { BaseGoalPreview } from "@Types/Goals"

const getCompletion = ({
  start,
  current,
  target,
}: Pick<KeyResult, "start" | "current" | "target">) => {
  return (current - start) / (target - start)
}

const withUpdatedCompletion = (keyResult: KeyResult): KeyResult => ({
  ...keyResult,
  completion: getCompletion(keyResult),
})

export const createKeyResult = (): KeyResult => ({
  id: uuid(),
  title: "",
  start: 0,
  target: 100,
  current: 0,
  metricType: "percentage",
  completion: 0,
})

export const updateKeyResultCompletion = (
  keyResult: KeyResult,
  completion: number
): KeyResult => {
  return {
    ...keyResult,
    completion,
    current: Math.round((keyResult.target - keyResult.start) * completion),
  }
}

export const updateKeyResult = (
  keyResult: KeyResult,
  params: Partial<Omit<KeyResult, "id" | "completion">>
): KeyResult => {
  return withUpdatedCompletion({
    ...keyResult,
    ...params,
  })
}

export const resetKeyResult = (keyResult: KeyResult): KeyResult => {
  return { ...keyResult, current: 0, completion: 0 }
}

export const getOverallGoalProgress = (
  goals: BaseGoalPreview[],
  goalsCount: number
) => {
  goalsCount = goalsCount || (goals && goals.length)

  if (goalsCount === 0) {
    return 0
  }

  const accumulatedProgress = goals.reduce(
    (progress, goal) => progress + goal.completion,
    0
  )
  const overallProgress = (accumulatedProgress / goalsCount) * 100

  return Math.floor(overallProgress)
}
