import { Card } from "@kaizen/draft-card"
import * as React from "react"
import { useIntl } from "react-intl"
import arrowLeftIcon from "@kaizen/component-library/icons/arrow-left.icon.svg"
import arrowRightIcon from "@kaizen/component-library/icons/arrow-right.icon.svg"
import { IconButton } from "@kaizen/button"
import strings from "@Calibrations/locale/strings"
import { Tab, TabList, Tabs } from "@kaizen/tabs"
import { LoadingStat } from ".."

export const Loading = () => {
  const { formatMessage } = useIntl()

  return (
    <Card
      data-automation-id="loading-skeleton"
      classNameOverride="overflow-hidden"
    >
      <div className="flex flex-col mb-16 border-none">
        <Tabs>
          <TabList
            aria-label={formatMessage(
              strings.components.CalibrationHeroStats.loadingDescription
            )}
          >
            <Tab disabled>
              {formatMessage(
                strings.components.CalibrationHeroStats.performanceRating
              )}
            </Tab>
            <Tab disabled>
              {formatMessage(strings.components.CalibrationHeroStats.status)}
            </Tab>
          </TabList>
        </Tabs>
        <div className="inline-flex items-center pt-12">
          <IconButton
            label=""
            icon={arrowLeftIcon}
            onClick={() => null}
            disabled={true}
          />
          <div className="flex grow">
            <LoadingStat />
            <LoadingStat />
            <LoadingStat />
            <LoadingStat />
          </div>
          <IconButton
            label=""
            icon={arrowRightIcon}
            onClick={() => null}
            disabled={true}
          />
        </div>
      </div>
    </Card>
  )
}
