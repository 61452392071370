/**
 * -- THIS FILE IS DEPRECATED --
 * This is the legacy endpoints configuration file for the reflux actions.
 * It is deprecated in favour of using api wrapper functions
 * (see ./evaluationCycles.ts for an example). Since the api
 * routes are to be referenced nowhere else, there's no longer any need to store
 * them into a constants file.
 */
export default {
  ADMIN_FEEDBACK_STATS_URLS: {
    REVIEW_STATS_URL: "/dashboard/review_stats",
    REVIEW_PERIODS_URL: "/dashboard/review_periods",
    INACTIVE_STATS_URL: "/dashboard/inactive_review_user_export_requests",
    REVIEW_EXPORT_URL: "/dashboard/review_export_requests",
  },
  ADMIN_FEEDBACK_URLS: {
    ADMIN_FEEDBACK_URL: "/dashboard/feedbacks",
  },
  ADMIN_GENERAL_URLS: {
    ADMIN_BASE_URL: "/dashboard",
    ADMIN_JOB_TITLES_URL: "/dashboard/job_titles",
    ADMIN_COMPANIES_URL: "/dashboard/companies/me",
    ADMIN_CSV_TO_USER_ID_URL: "/dashboard/csv_to_user_ids",
    ADMIN_HRIS_INTEGRATIONS_URL: "/dashboard/hris_integrations",
  },
  ADMIN_GOALS_URLS: {
    ADMIN_GOALS_URL: "/dashboard/goals",
    ADMIN_GOALS_STATS_EXPORT_REQUESTS_URL:
      "/dashboard/goal_stat_export_requests",
    ADMIN_GOAL_EXPORT_KEY_REQUEST_URL:
      "/dashboard/goal_key_results_export_requests",
    ADMIN_GOAL_EXPORT_ALIGN_REQUEST_URL:
      "dashboard/aligned_goals_export_requests",
  },
  ADMIN_PERF_REVIEW_FILTER_URLS: {
    MANAGERS: "/dashboard/performance_managers",
    JOB_TITLES: "/dashboard/job_titles?job_title_type=job_title",
    DEPARTMENTS: "/dashboard/job_titles?job_title_type=department",
    LEVELS: "/dashboard/job_titles/levels",
    USERS: "/dashboard/performance_reviews/users",
    MORE: "/dashboard/employees/filters",
    DEMOGRAPHIC_VALUES: "/dashboard/employees/filter_options",
  },
  ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT: {
    MANAGERS: `/dashboard/saved_views/:savedViewId/performance_reviews/managers`,
    JOB_TITLES: `/dashboard/saved_views/:savedViewId/performance_reviews/job_titles`,
    LEVELS: `/dashboard/saved_views/:savedViewId/performance_reviews/levels`,
    DEPARTMENTS: `/dashboard/saved_views/:savedViewId/performance_reviews/departments`,
    USERS: `/dashboard/saved_views/:savedViewId/performance_reviews/users`,
    DEMOGRAPHIC_VALUES: `/dashboard/saved_views/:savedViewId/performance_reviews/filter_options`,
    MORE: "/dashboard/employees/filters",
  },
  ADMIN_PERF_REVIEWS_URLS: {
    ADMIN_PERF_REVIEW_URL: "/dashboard/performance_reviews",
    ADMIN_PERF_CYCLES_URL: "/dashboard/performance_cycles",
    ADMIN_PERF_ANSWERS_URL: "/dashboard/performance_answers",
    ADMIN_PERF_BUCKETS_ASSIGNMENT_URL:
      "/dashboard/performance_bucket_assignments",
    ADMIN_PERF_EXPORT_REQUESTS_URL: "/dashboard/performance_export_requests",
    ADMIN_PERF_MANAGERS_URL: "/dashboard/performance_managers",
    ADMIN_PERF_DEFAULTS_URL: "/dashboard/performance_cycles/defaults",
    ADMIN_PERF_ADD_COLLABORATOR: "/dashboard/access_permissions",
    NEW_URL: "/dashboard/performance_cycles/new",
  },
  ADMIN_REVIEWS_URLS: {
    ADMIN_REVIEWS_URL: "/dashboard/reviews",
  },
  ADMIN_SAVED_VIEW_URLS: {
    ADMIN_SAVED_VIEW_URL: "/dashboard/saved_views",
    ADMIN_PERF_REVIEW_FOR_SNAPSHOT:
      "dashboard/saved_views/performance_reviews_from_live_query",
    ADMIN_PERF_REVIEW_FROM_SNAPSHOT:
      "dashboard/saved_views/:savedViewId/performance_reviews",
  },
  ADMIN_SKILLS_URLS: {
    ADMIN_SKILLS_URL: "/dashboard/skills",
    ADMIN_SKILLS_DEFAULT_URL: "/dashboard/skill_defaults",
  },
  ADMIN_SURVEY_RESPONSE_FILTER_URLS: {
    MANAGERS: "/dashboard/managers",
    JOB_TITLES: "/dashboard/survey_responses/job_titles",
    LEVELS: "/dashboard/job_titles/levels",
    DEPARTMENTS: "/dashboard/survey_responses/departments",
    USERS: "/dashboard/survey_responses/users",
    MORE: "/dashboard/employees/filters",
    DEMOGRAPHIC_VALUES: "/dashboard/employees/filter_options",
  },
  ADMIN_SURVEYS_URLS: {
    ADMIN_SURVEYS_URL: "/dashboard/surveys",
    ADMIN_SURVEY_TEMPLATES_URL: "/dashboard/templates",
    ADMIN_SURVEY_AUDIENCES_URL: "/dashboard/survey_audiences",
    ADMIN_SURVEY_EXPORT_REQUESTS_URL: "/dashboard/survey_export_requests",
    ADMIN_SURVEY_RESPONSES_URL: "/dashboard/survey_responses",
    ADMIN_SURVEY_ITEMS_URL: "/dashboard/survey_items",
    ADMIN_SURVEY_MRF_STATS: "/dashboard/survey_response_stats",
    ADMIN_SURVEY_MRF_INVITATION_STATS:
      "/dashboard/survey_response_stats/invitation_stats",
    ADMIN_SURVEY_MRF_MANAGER_STATS:
      "/dashboard/survey_response_stats/manager_invitation_stats",
    ADMIN_SURVEY_MRF_REQUEST_ABOUT_URL: "/users/request_mrf_about",
    ADMIN_SURVEY_MRF_ON_BEHALF_OF: "/users/request_mrf_on_behalf_of",
    ADMIN_SURVEY_MRF_EXPORT_INACTIVE_MANAGERS:
      "/dashboard/inactive_managers_in_mrf_export_requests",
    ADMIN_SURVEY_EXPORT_URL:
      "/dashboard/manager_requested_feedback_export_requests",
    ADMIN_SURVEY_MRF_URL: "/dashboard/manager_requested_feedbacks",
  },
  ADMIN_TEMPLATES_URLS: {
    ADMIN_POLICIES_URL: "/dashboard/user_policies",
    ADMIN_TEMPLATES_URL: "/dashboard/templates",
  },
  ADMIN_USERS_URLS: {
    ADMIN_USERS_URL: "/dashboard/users",
    ADMIN_USER_IDS_URL: "/dashboard/users/user_ids",
  },
  GENERAL_URLS: {
    BASE_URL: "",
    COMMENTS_URL: "/comments",
    DRAFTS_URL: "/drafts",
    JOB_TITLES_URL: "/job_titles",
    DEPARTMENTS_URL: "/departments",
    COMPANIES_URL: "/companies",
    CONTACTS_URL: "/contacts",
    QUEUE_URL: "/reviewee_queue",
    DEVELOPMENT_RESOURCES_URL: "/development_resources",
    EMAIL_COLLECTIONS_URL: "/email_collections",
    ONTOLOGY_URL: "/skills/ontology",
    SUMMARY_SHARES_URL: "/summary_shares",
    AUTH_PASSWORDS_URL: "/auth/passwords",
    STATS_URL: "/stats",
    TAGS_URL: "/tags",
    FEEDBACK_URL: "/feedbacks",
    FEEDBACK_INIVITATIONS_URL: "/feedback_invitations",
    NOTES_URL: "/notes",
  },
  GOALS_URLS: {
    GOALS_URL: "/goals",
  },
  NOTIFICATIONS_URLS: {
    NOTIFICATION_URL: "/notifications",
    PENDING_NOTIFICATION_URL: "/notifications/pending_notifications_count",
    TO_DO_URL: "/to_do_tasks",
  },
  PEER_FEEDBACK_URLS: {
    PEER_FB_FEEDBACK_FROM_URL: "/users/request_feedback_from",
  },
  PERF_REVIEW_FILTER_URLS: {
    MANAGERS: "/performance_managers",
    JOB_TITLES: "/job_titles?job_title_type=job_title",
    LEVELS: "/job_titles/levels",
    DEPARTMENTS: "/job_titles?job_title_type=department",
    USERS: "/performance_reviews/users",
  },
  PERF_REVIEWS_URLS: {
    PERF_REVIEW_URL: "/performance_reviews",
    PERF_ANSWERS_URL: "/performance_answers",
    PERF_BUCKET_URL: "/performance_bucket_assignments",
    PERF_CYCLES_URL: "/performance_cycles",
    PAST_REVIEWS_URL: "/performance_reviews/past",
    SHARED_REVIEWS_URL: "/shared_performance_reviews",
    CREATE_COLLABORATOR_COMMENTS_URL: "/comments/bulk_create",
    ADD_COLLABORATOR_URL: "/access_permissions",
  },
  REVIEWS_URLS: {
    REVIEWS_URL: "/reviews",
    CURRENT_OR_CREATE_URL: "/reviews/current_or_create",
    INVITATIONS_URL: "/review_invitations",
    REVIEW_SHARES_URL: "/review_shares",
  },
  SAVED_VIEW_URLS: {
    SAVED_VIEW_URL: "/saved_views",
  },
  SELF_REFLECTIONS_URLS: {
    SELF_REFLECTIONS_URL: "/self_reflections",
  },
  SESSION_URLS: {
    ONE_ON_ONES_SESSION_VALID_URL: "/one_on_ones/sessions/valid",
    SESSION_VALID_URL: "/sessions/valid",
  },
  SKILLS_URLS: {
    SKILLS_DEFAULT_URL: "/skills/default",
    SKILL_SCORES_URL: "/skill_scores",
  },
  SURVEY_RESPONSE_FILTER_URLS: {
    MANAGERS: "/managers",
    JOB_TITLES: "/job_titles?job_title_type=job_title",
    LEVELS: "/job_titles/levels",
    DEPARTMENTS: "/job_titles?job_title_type=department",
    USERS: "/survey_responses/users",
  },
  SURVEYS_URLS: {
    SURVEYS_URL: "/surveys",
    SURVEY_RESPONSES_URL: "/survey_responses",
    SURVEY_ITEMS_URL: "/survey_items",
  },
  TEAMS_URLS: {
    TEAMS_URL: "/users/team",
    DIRECTS_URL: "/users/directs",
    MRF_SUGGESTIONS_URL: "/users/manager_requested_feedback_suggestions",
    INDIRECTS_URL: "/users/indirects",
    TEAM_GOALS_URL: "/goals/team",
    TEAMS_SKILLS_URL: "/team_scored_skills",
    TEAM_SURVEY_RESPONSE_STATS_URL: "/team_survey_response_stats",
    TEAM_SURVEY_MRF_URL: "/manager_requested_feedbacks",
    SURVEY_RESPONSES_URL: "/survey_responses",
  },
  TEMPLATES_URLS: {
    TEMPLATES_URL: "/templates",
  },
  USERS_URLS: {
    USER_BY_EMAIL_URL: "/users/exists",
    USERS_URL: "/users",
    SIGNUP_REQUEST_URL: "/users/signup_request",
    SIGNUP_INVITE_URL: "/signup_invites",
    CURRENT_USER_URL: "/users/me",
  },
}
