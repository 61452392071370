// Generated by Avo VERSION 116.238.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "m9O4mDUeamu53RNxTXXF";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "LVGLP1AYXNm1fl6J39xo",
          "br": "CTRB01xYK",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "PMHjuFTxE",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "LVGLP1AYXNm1fl6J39xo",
          "br": "CTRB01xYK",
          "en": env,
          "ty": type,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "PMHjuFTxE",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "SyotC3NRBiaKYbijsQS9",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const PerformanceQuestionType = {
  'ASSESSMENT_GROUP': 'Assessment group',
  'RATING': 'Rating',
  'MULTIPLE_CHOICE': 'Multiple-choice',
  'FREE_TEXT': 'Free text',
} as const;
export type PerformanceQuestionTypeType = typeof PerformanceQuestionType;
export type PerformanceQuestionTypeValueType = PerformanceQuestionTypeType[keyof PerformanceQuestionTypeType];

export const GoalPriority = {
  'HIGH': 'High',
  'LOW': 'Low',
  'MEDIUM': 'Medium',
} as const;
export type GoalPriorityType = typeof GoalPriority;
export type GoalPriorityValueType = GoalPriorityType[keyof GoalPriorityType];

export const ExperimentVariations1On1AgendaCheckBoxAndReorder = {
  'DEFAULT': 'DEFAULT',
  'REORDER_IN_RIGHT': 'REORDER_IN_RIGHT',
  'REORDER_IN_LEFT': 'REORDER_IN_LEFT',
} as const;
export type ExperimentVariations1On1AgendaCheckBoxAndReorderType = typeof ExperimentVariations1On1AgendaCheckBoxAndReorder;
export type ExperimentVariations1On1AgendaCheckBoxAndReorderValueType = ExperimentVariations1On1AgendaCheckBoxAndReorderType[keyof ExperimentVariations1On1AgendaCheckBoxAndReorderType];

export const FeedbackType = {
  'LEGACY': 'Legacy',
  'MANAGER_EVALUATION': 'Manager Evaluation',
  'PEER_FEEDBACK': 'Peer Feedback',
  'UPWARD_FEEDBACK': 'Upward Feedback',
} as const;
export type FeedbackTypeType = typeof FeedbackType;
export type FeedbackTypeValueType = FeedbackTypeType[keyof FeedbackTypeType];

export const Medium = {
  'EMAIL': 'Email',
  'SLACK': 'Slack',
  'MS_TEAMS': 'MS Teams',
  'IN_APP': 'In-app',
} as const;
export type MediumType = typeof Medium;
export type MediumValueType = MediumType[keyof MediumType];

export const OneOn1Role = {
  'MANAGER': 'Manager',
  'SUBJECT': 'Subject',
} as const;
export type OneOn1RoleType = typeof OneOn1Role;
export type OneOn1RoleValueType = OneOn1RoleType[keyof OneOn1RoleType];

export const Visibility = {
  'EVERYONE': 'Everyone',
  'ONLY_ME': 'Only me',
  'ONLY_MY_MANAGER': 'Only my manager',
  'SPECIFY_USERS': 'Specify users',
} as const;
export type VisibilityType = typeof Visibility;
export type VisibilityValueType = VisibilityType[keyof VisibilityType];

export const GoalType = {
  'COMPANY': 'Company',
  'DEPARTMENT': 'Department',
  'INDIVIDUAL': 'Individual',
  'TEAM': 'Team',
} as const;
export type GoalTypeType = typeof GoalType;
export type GoalTypeValueType = GoalTypeType[keyof GoalTypeType];

export const UpdateAction = {
  'ADDED': 'Added',
  'UPDATED': 'Updated',
  'DELETED': 'Deleted',
  'REORDERED': 'Reordered',
  'COLLAPSED': 'Collapsed',
  'EXPANDED': 'Expanded',
  'MARKED_COMPLETE': 'Marked complete',
} as const;
export type UpdateActionType = typeof UpdateAction;
export type UpdateActionValueType = UpdateActionType[keyof UpdateActionType];

export const Role = {
  'MANAGER': 'Manager',
  'SUBJECT': 'Subject',
  'ADMIN': 'Admin',
} as const;
export type RoleType = typeof Role;
export type RoleValueType = RoleType[keyof RoleType];

export const IsAlignedTo = {
  'COMPANY': 'Company',
  'DEPARTMENT': 'Department',
  'TEAM': 'Team',
} as const;
export type IsAlignedToType = typeof IsAlignedTo;
export type IsAlignedToValueType = IsAlignedToType[keyof IsAlignedToType];

let AmplitudeCustom: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  AmplitudeCustomDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    AmplitudeCustom = AmplitudeCustomDestination;
    if (__AVO_ENV__ === 'prod') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "6cfe80b109d58db414efa2dbae828525");
    } else if (__AVO_ENV__ === 'dev') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "ebe34b05f57a2a074057e1c18183de28");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Amplitude (custom). Head to destination settings in Avo to set a staging key.");
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface CalibrationViewEditedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Edited: An admin edits a calibration view and saves the changes.
 *
 * When to trigger this event:
 * 1. A user edits an existing calibration view and clicks on the 'Save changes' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/aRdD7T5ZACCi/trigger/FL3z1D3-B
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/aRdD7T5ZACCi}
 */
export function calibrationViewEdited(
  properties: CalibrationViewEditedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "aRdD7T5ZACCi", "7d5e68019b4d87472faff644ca64e4912ea10c5468fdd289fbec1397afd04f7f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Edited", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("aRdD7T5ZACCi", "Calibration View Edited", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Edited", eventProperties, "aRdD7T5ZACCi", "7d5e68019b4d87472faff644ca64e4912ea10c5468fdd289fbec1397afd04f7f");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Edited", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewSharingUpdatedProperties {
  calibrationViewId: number;
  numberOfInvitations: number | null | undefined;
}
/**
 * Calibration View Sharing Updated: An admin updates the list of users that can access a particular calibration view.
 *
 * When to trigger this event:
 * 1. A user selects the 'Share calibration view' button in a calibration view, enters the users to share with in the modal, and selects the 'Share' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/_hyFpljfOOUf/trigger/ocAmV48wp
 * 2. An admin clicks on 'Edit sharing' in a calibration view, adds or removes users to the lists, and clicks on 'Update'.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/_hyFpljfOOUf/trigger/K2nW2XVaV
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.numberOfInvitations: The number of users that are invited to fill out a survey, view a calibration, view a report, etc. after this event.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/_hyFpljfOOUf}
 */
export function calibrationViewSharingUpdated(
  properties: CalibrationViewSharingUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  properties.numberOfInvitations !== undefined && properties.numberOfInvitations !== null ?
    eventPropertiesArray.push({id: "i9i7oisOqqe8", name: "Number of invitations", value: properties.numberOfInvitations}) :
    eventPropertiesArray.push({id: "i9i7oisOqqe8", name: "Number of invitations", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_hyFpljfOOUf", "fc5d567999f197f48479ee4da39d2392210118a11e27a82bbe1541766ce82bc5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Sharing Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_hyFpljfOOUf", "Calibration View Sharing Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Sharing Updated", eventProperties, "_hyFpljfOOUf", "fc5d567999f197f48479ee4da39d2392210118a11e27a82bbe1541766ce82bc5");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Sharing Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Viewed: A user views a calibration view, from either the admin view or a shared calibration link.
 *
 * When to trigger this event:
 * 1. A user views a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DwZO5TZ_XUe1/trigger/hI2x3r-vR
 * 2. An admin views a saved calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DwZO5TZ_XUe1/trigger/fxpgKhxmL
 * 3. An admin clicks the full-screen toggle on a saved calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DwZO5TZ_XUe1/trigger/LIHlKOdHg
 * 4. A user clicks the full-screen toggle on a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DwZO5TZ_XUe1/trigger/81xEYvdDX
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DwZO5TZ_XUe1}
 */
export function calibrationViewViewed(
  properties: CalibrationViewViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DwZO5TZ_XUe1", "bbf4190f4e439f87b226e1dd092efcdeb58de2fdf9c94031c39799c566530b5c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DwZO5TZ_XUe1", "Calibration View Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Viewed", eventProperties, "DwZO5TZ_XUe1", "bbf4190f4e439f87b226e1dd092efcdeb58de2fdf9c94031c39799c566530b5c");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewSubmittedProperties {
  countOfWordsWritten: number;
  numberOfDaysUntilDueDate: number;
  numberOfFreeTextQuestions: number;
  context: string | null | undefined;
  numberOfRatingScaleQuestions: number | null | undefined;
  subjectUserAggregateId: string;
  managerReviewId: number;
  performanceReviewCycleId: number | null | undefined;
  numberOfAssessmentGroupQuestions: number;
  managerReviewCycleId: number;
}
/**
 * Manager Review Submitted: Formal evaluation of a direct report by their manager (or admin) is submitted.
 *
 * When to trigger this event:
 * 1. A manager completes one review for their direct reports.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/0_UaRBEGv_el/trigger/cywUa6pAK
 *
 * @param properties the properties associatied with this event
 * @param properties.countOfWordsWritten: Total number of words written across all questions for submission - set to zero if none
 * @param properties.numberOfDaysUntilDueDate: Difference between submitted date and due date (negative number indicates days past due)
 * @param properties.numberOfFreeTextQuestions: Number of free text questions
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.numberOfRatingScaleQuestions: Number of rating scale questions
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.numberOfAssessmentGroupQuestions: Number of assessment group questions. It can be 0 or 1. The assessment group questions are also known as performance buckets and are used to evaluate the performance of an individual overall.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/0_UaRBEGv_el}
 */
export function managerReviewSubmitted(
  properties: ManagerReviewSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "_2mx7sUH5b23", name: "Count of words written", value: properties.countOfWordsWritten});
  eventPropertiesArray.push({id: "Homm1MS-uRo6", name: "Number of days until due date", value: properties.numberOfDaysUntilDueDate});
  eventPropertiesArray.push({id: "mPB2a7i1jtGp", name: "Number of free text questions", value: properties.numberOfFreeTextQuestions});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.numberOfRatingScaleQuestions !== undefined && properties.numberOfRatingScaleQuestions !== null ?
    eventPropertiesArray.push({id: "M23QJ-PjtIPl", name: "Number of rating scale questions", value: properties.numberOfRatingScaleQuestions}) :
    eventPropertiesArray.push({id: "M23QJ-PjtIPl", name: "Number of rating scale questions", value: null});
  eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  properties.performanceReviewCycleId !== undefined && properties.performanceReviewCycleId !== null ?
    eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId}) :
    eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: null});
  eventPropertiesArray.push({id: "Ev14V5R6F", name: "Number of assessment group questions", value: properties.numberOfAssessmentGroupQuestions});
  eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0_UaRBEGv_el", "d09e6846c0cf736b5a622a408e825cf1e6deb3e2bdd4b1415f99945234171d19", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0_UaRBEGv_el", "Manager Review Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {...eventProperties};
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  delete eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {...userProperties};
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Submitted", eventProperties, "0_UaRBEGv_el", "d09e6846c0cf736b5a622a408e825cf1e6deb3e2bdd4b1415f99945234171d19");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Evaluation Submitted", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface CompanyGoalsIndexViewedProperties {
  numberOfGoals: number | null | undefined;
  goalId: string[] | null | undefined;
  filterDateFrom: string | null | undefined;
  filterDateTo: string | null | undefined;
  navigationSource: string | null | undefined;
  filtersApplied: string[] | null | undefined;
  viewMode: string;
}
/**
 * Company Goals Index Viewed: Company Goals Index Viewed
 *
 * When to trigger this event:
 * 1. On page view (Tree)
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Acxhyq57Di1N/trigger/Gf4H6rFpq
 * 2. On page view (List)
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Acxhyq57Di1N/trigger/dXG4D-qTO
 *
 * @param properties the properties associatied with this event
 * @param properties.numberOfGoals: Total of goals on page
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.filterDateFrom: The first date in the goal due date range selected.
 * @param properties.filterDateTo: The last date in the goal due date range selected.
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.filtersApplied: A list of all filters that were applied to the search. This should be the names of the filters, not the values, as filter values can contain PII, and they are also too numerous to search within Amplitude.
 * @param properties.viewMode: Which mode was the user viewing the page in?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Acxhyq57Di1N}
 */
export function companyGoalsIndexViewed(
  properties: CompanyGoalsIndexViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.numberOfGoals !== undefined && properties.numberOfGoals !== null ?
    eventPropertiesArray.push({id: "YxV_WX8VCPW_", name: "Number of goals", value: properties.numberOfGoals}) :
    eventPropertiesArray.push({id: "YxV_WX8VCPW_", name: "Number of goals", value: null});
  properties.goalId !== undefined && properties.goalId !== null ?
    eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId}) :
    eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: null});
  properties.filterDateFrom !== undefined && properties.filterDateFrom !== null ?
    eventPropertiesArray.push({id: "8_HE0gSR_", name: "Filter date from", value: properties.filterDateFrom}) :
    eventPropertiesArray.push({id: "8_HE0gSR_", name: "Filter date from", value: null});
  properties.filterDateTo !== undefined && properties.filterDateTo !== null ?
    eventPropertiesArray.push({id: "ykLc24KCa_", name: "Filter date to", value: properties.filterDateTo}) :
    eventPropertiesArray.push({id: "ykLc24KCa_", name: "Filter date to", value: null});
  properties.navigationSource !== undefined && properties.navigationSource !== null ?
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource}) :
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: null});
  properties.filtersApplied !== undefined && properties.filtersApplied !== null ?
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: properties.filtersApplied}) :
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: null});
  eventPropertiesArray.push({id: "Nnuji-llRD", name: "View mode", value: properties.viewMode});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Acxhyq57Di1N", "60b45199b9e93b8134108ab9d77fa4122e14ecd673577ea9cda46d249addc4fc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Company Goals Index Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Acxhyq57Di1N", "Company Goals Index Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Company Goals Index Viewed", eventProperties, "Acxhyq57Di1N", "60b45199b9e93b8134108ab9d77fa4122e14ecd673577ea9cda46d249addc4fc");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Company Goals Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IndividualGoalsIndexViewedProperties {
  numberOfGoals: number | null | undefined;
  goalId: string[] | null | undefined;
  filterDateFrom: string | null | undefined;
  filterDateTo: string | null | undefined;
  navigationSource: string | null | undefined;
  filtersApplied: string[] | null | undefined;
}
/**
 * Individual Goals Index Viewed: A user views the index of their individual goals
 *
 * @param properties the properties associatied with this event
 * @param properties.numberOfGoals: Total of goals on page
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.filterDateFrom: The first date in the goal due date range selected.
 * @param properties.filterDateTo: The last date in the goal due date range selected.
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.filtersApplied: A list of all filters that were applied to the search. This should be the names of the filters, not the values, as filter values can contain PII, and they are also too numerous to search within Amplitude.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/vywC_-sJz-Ih}
 */
export function individualGoalsIndexViewed(
  properties: IndividualGoalsIndexViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.numberOfGoals !== undefined && properties.numberOfGoals !== null ?
    eventPropertiesArray.push({id: "YxV_WX8VCPW_", name: "Number of goals", value: properties.numberOfGoals}) :
    eventPropertiesArray.push({id: "YxV_WX8VCPW_", name: "Number of goals", value: null});
  properties.goalId !== undefined && properties.goalId !== null ?
    eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId}) :
    eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: null});
  properties.filterDateFrom !== undefined && properties.filterDateFrom !== null ?
    eventPropertiesArray.push({id: "8_HE0gSR_", name: "Filter date from", value: properties.filterDateFrom}) :
    eventPropertiesArray.push({id: "8_HE0gSR_", name: "Filter date from", value: null});
  properties.filterDateTo !== undefined && properties.filterDateTo !== null ?
    eventPropertiesArray.push({id: "ykLc24KCa_", name: "Filter date to", value: properties.filterDateTo}) :
    eventPropertiesArray.push({id: "ykLc24KCa_", name: "Filter date to", value: null});
  properties.navigationSource !== undefined && properties.navigationSource !== null ?
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource}) :
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: null});
  properties.filtersApplied !== undefined && properties.filtersApplied !== null ?
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: properties.filtersApplied}) :
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vywC_-sJz-Ih", "7d4845b4ee293424979c40fbba06ce5cffc5f9d5fea0dfeeb614da375121cb92", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Individual Goals Index Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vywC_-sJz-Ih", "Individual Goals Index Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Individual Goals Index Viewed", eventProperties, "vywC_-sJz-Ih", "7d4845b4ee293424979c40fbba06ce5cffc5f9d5fea0dfeeb614da375121cb92");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Individual Goals Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalCreatedProperties {
  goalDueDate: string | null | undefined;
  goalPriority: GoalPriorityValueType | null | undefined;
  goalType: GoalTypeValueType;
  isAligned: any | null | undefined;
  isAlignedTo: IsAlignedToValueType | null | undefined;
  numberOfGoalOwners: number | null | undefined;
  numberOfKeyResultsSet: number | null | undefined;
  visibility: VisibilityValueType | null | undefined;
  goalId: string[];
  context: string | null | undefined;
  navigationSource: string | null | undefined;
  method: string | null | undefined;
  baseGoalId: string | null | undefined;
}
/**
 * Performance Goal Created: A user creates a performance goal (either from scratch or from duplication of an existing goal).
 *
 * @param properties the properties associatied with this event
 * @param properties.goalDueDate: Due date as set by the user
 * @param properties.goalPriority: Priority that the goal was set as
 * @param properties.goalType: Type of goal set e.g. company level goal
 * @param properties.isAligned: Is the goal aligned?
 * @param properties.isAlignedTo: Is the goal aligned to Team, Department, Company
 * @param properties.numberOfGoalOwners: How many people own this goal
 * @param properties.numberOfKeyResultsSet: The number of key results set with this goal
 * @param properties.visibility: Who can see the goal set by user
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.method: What method was used to complete the action? This property is applicable wherever there's multiple ways to perform an action (e.g. creating a survey from scratch or by duplicating another)
 * @param properties.baseGoalId: The system ID of the original goal that the new goal was copied from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/1u98xWIRvdNJ}
 */
export function performanceGoalCreated(
  properties: PerformanceGoalCreatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.goalDueDate !== undefined && properties.goalDueDate !== null ?
    eventPropertiesArray.push({id: "Nk82k048I3sA", name: "Goal due date", value: properties.goalDueDate}) :
    eventPropertiesArray.push({id: "Nk82k048I3sA", name: "Goal due date", value: null});
  properties.goalPriority !== undefined && properties.goalPriority !== null ?
    eventPropertiesArray.push({id: "3SiTGGj79M72", name: "Goal priority", value: properties.goalPriority}) :
    eventPropertiesArray.push({id: "3SiTGGj79M72", name: "Goal priority", value: null});
  eventPropertiesArray.push({id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType});
  properties.isAligned !== undefined && properties.isAligned !== null ?
    eventPropertiesArray.push({id: "z9VLThrAxUCY", name: "Is aligned", value: properties.isAligned}) :
    eventPropertiesArray.push({id: "z9VLThrAxUCY", name: "Is aligned", value: null});
  properties.isAlignedTo !== undefined && properties.isAlignedTo !== null ?
    eventPropertiesArray.push({id: "uSsZ1M_dFpuA", name: "Is aligned to", value: properties.isAlignedTo}) :
    eventPropertiesArray.push({id: "uSsZ1M_dFpuA", name: "Is aligned to", value: null});
  properties.numberOfGoalOwners !== undefined && properties.numberOfGoalOwners !== null ?
    eventPropertiesArray.push({id: "9_GqeUOn9vJU", name: "Number of goal owners", value: properties.numberOfGoalOwners}) :
    eventPropertiesArray.push({id: "9_GqeUOn9vJU", name: "Number of goal owners", value: null});
  properties.numberOfKeyResultsSet !== undefined && properties.numberOfKeyResultsSet !== null ?
    eventPropertiesArray.push({id: "9B7CQem8XSM6", name: "Number of key results set", value: properties.numberOfKeyResultsSet}) :
    eventPropertiesArray.push({id: "9B7CQem8XSM6", name: "Number of key results set", value: null});
  properties.visibility !== undefined && properties.visibility !== null ?
    eventPropertiesArray.push({id: "Pw8EP7vfF71X", name: "Visibility", value: properties.visibility}) :
    eventPropertiesArray.push({id: "Pw8EP7vfF71X", name: "Visibility", value: null});
  eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.navigationSource !== undefined && properties.navigationSource !== null ?
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource}) :
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: null});
  properties.method !== undefined && properties.method !== null ?
    eventPropertiesArray.push({id: "z0iUtIO_p7", name: "Method", value: properties.method}) :
    eventPropertiesArray.push({id: "z0iUtIO_p7", name: "Method", value: null});
  properties.baseGoalId !== undefined && properties.baseGoalId !== null ?
    eventPropertiesArray.push({id: "ETzfLMS9XI", name: "Base goal ID", value: properties.baseGoalId}) :
    eventPropertiesArray.push({id: "ETzfLMS9XI", name: "Base goal ID", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1u98xWIRvdNJ", "e4bebb0260d98b42e18f07a9da30f6eaa8c5de5f60c3116005e917aa3cacd6e9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Created", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1u98xWIRvdNJ", "Performance Goal Created", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Created", eventProperties, "1u98xWIRvdNJ", "e4bebb0260d98b42e18f07a9da30f6eaa8c5de5f60c3116005e917aa3cacd6e9");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Created", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalUpdatedProperties {
  elementUpdated: string | null | undefined;
  elementValueChangedFrom: string | null | undefined;
  elementValueChangedTo: any | null | undefined;
  goalId: string[];
  goalType: GoalTypeValueType;
}
/**
 * Performance Goal Updated: A goal is updated (be it progress, a comment added etc)
 *
 * @param properties the properties associatied with this event
 * @param properties.elementUpdated: What the user interacted with e.g. progress bar, comment, slider, free text, agenda item
 * @param properties.elementValueChangedFrom: What the element value was before the change (e.g. 50%, In progress)
 * @param properties.elementValueChangedTo: What the element value was after the change (e.g. 70%, Blocked)
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.goalType: Type of goal set e.g. company level goal
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/G2mqIceLKpyR}
 */
export function performanceGoalUpdated(
  properties: PerformanceGoalUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.elementUpdated !== undefined && properties.elementUpdated !== null ?
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: properties.elementUpdated}) :
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: null});
  properties.elementValueChangedFrom !== undefined && properties.elementValueChangedFrom !== null ?
    eventPropertiesArray.push({id: "Sb3r6Ca9o-3u", name: "Element value changed from", value: properties.elementValueChangedFrom}) :
    eventPropertiesArray.push({id: "Sb3r6Ca9o-3u", name: "Element value changed from", value: null});
  properties.elementValueChangedTo !== undefined && properties.elementValueChangedTo !== null ?
    eventPropertiesArray.push({id: "Q5SrQTREiexD", name: "Element value changed to", value: properties.elementValueChangedTo}) :
    eventPropertiesArray.push({id: "Q5SrQTREiexD", name: "Element value changed to", value: null});
  eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId});
  eventPropertiesArray.push({id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "G2mqIceLKpyR", "aaa37b3cf96b1c3568a257be8eccd404a9fc5b1a7fb20f40ab288503e0cf4755", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("G2mqIceLKpyR", "Performance Goal Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Updated", eventProperties, "G2mqIceLKpyR", "aaa37b3cf96b1c3568a257be8eccd404a9fc5b1a7fb20f40ab288503e0cf4755");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OneOn1CheckMinusinUpdatedProperties {
  oneOn1Id: string;
  oneOn1Role: OneOn1RoleValueType | null | undefined;
  managersUserAggregateId: string;
  scheduleId: string | null | undefined;
  subjectsUserAggregateId: string;
  elementUpdated: string | null | undefined;
  updateAction: UpdateActionValueType | null | undefined;
  formatsApplied: boolean | null | undefined;
}
/**
 * 1 on 1 Check-in Updated: A user interacts with an element on a 1 on 1 check-in
 *
 * When to trigger this event:
 * 1. When the user updates an element in the 1 on 1 check in
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2qrvX-2fveF9/trigger/tAzALltrm
 *
 * @param properties the properties associatied with this event
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.oneOn1Role: Was this user a manager or subject in this 1 on 1?
 * @param properties.managersUserAggregateId: The user aggregate ID of the manager involved in the 1 on 1
 * @param properties.scheduleId: The identifier for the group of 1 on 1 meetings that are scheduled between the manager and IC.
 * @param properties.subjectsUserAggregateId: The user aggregate ID of the subject/direct report involved in the 1 on 1
 * @param properties.elementUpdated: What the user interacted with e.g. progress bar, comment, slider, free text, agenda item
 * @param properties.updateAction: Was the element added, updated, deleted (etc)?
 * @param properties.formatsApplied: Were any formats applied to the 1 on 1 Checkin via the rich-text editor?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2qrvX-2fveF9}
 */
export function oneOn1CheckInUpdated(
  properties: OneOn1CheckMinusinUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id});
  properties.oneOn1Role !== undefined && properties.oneOn1Role !== null ?
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: properties.oneOn1Role}) :
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: null});
  eventPropertiesArray.push({id: "4P_acBqHwWKq", name: "Manager's user aggregate ID", value: properties.managersUserAggregateId});
  properties.scheduleId !== undefined && properties.scheduleId !== null ?
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId}) :
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: null});
  eventPropertiesArray.push({id: "fa7n-RfCjkz7", name: "Subject's user aggregate ID", value: properties.subjectsUserAggregateId});
  properties.elementUpdated !== undefined && properties.elementUpdated !== null ?
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: properties.elementUpdated}) :
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: null});
  properties.updateAction !== undefined && properties.updateAction !== null ?
    eventPropertiesArray.push({id: "csgsy7OIlQ", name: "Update action", value: properties.updateAction}) :
    eventPropertiesArray.push({id: "csgsy7OIlQ", name: "Update action", value: null});
  properties.formatsApplied !== undefined && properties.formatsApplied !== null ?
    eventPropertiesArray.push({id: "il__CF4jB", name: "Formats applied", value: properties.formatsApplied}) :
    eventPropertiesArray.push({id: "il__CF4jB", name: "Formats applied", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2qrvX-2fveF9", "b6c5c85ec16cd43db8da5d4bff4aed1a803ffb1647932db60aac241a5bc4354a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 Check-in Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2qrvX-2fveF9", "1 on 1 Check-in Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 Check-in Updated", eventProperties, "2qrvX-2fveF9", "b6c5c85ec16cd43db8da5d4bff4aed1a803ffb1647932db60aac241a5bc4354a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 Check-in Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OneOn1SpaceViewedProperties {
  userId_: string;
  managersUserAggregateId: string;
  role: RoleValueType;
  subjectsUserAggregateId: string;
  numberOf1On1sLoaded: number;
  conversationId: string;
  experimentVariations1On1AgendaCheckBoxAndReorder: ExperimentVariations1On1AgendaCheckBoxAndReorderValueType | null | undefined;
}
/**
 * 1 on 1 Space Viewed: A user views the 1 on 1 check-in space. The event is sent once for the page view, not one event per check-in.
 *
 * When to trigger this event:
 * 1. Triggered when a user views the 1 on 1 check-in space.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/lWYJXEoSqixy/trigger/Ot8a1er4-
 * 2. Triggered when a user loads more historical 1 on 1s.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/lWYJXEoSqixy/trigger/fsL09wILw
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.managersUserAggregateId: The user aggregate ID of the manager involved in the 1 on 1
 * @param properties.role: The role of the user viewing the page.
 * @param properties.subjectsUserAggregateId: The user aggregate ID of the subject/direct report involved in the 1 on 1
 * @param properties.numberOf1On1sLoaded: The number of 1 on 1s loaded in the 1 on 1s space.
 * @param properties.conversationId: The unique ID of the 1 on 1. This is unique between each subject-manager combination. i.e. if a subject has multiple managers, then the Conversation ID will be different between the two managers.
 * @param properties.experimentVariations1On1AgendaCheckBoxAndReorder: January 2023 - Users will see a check box to tick off items that have been discussed in the 1:1. The hypothesis is the check box will increase usage of the agenda feature. The check box will be on the left with the drag handle on the left or right depending on the variation.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/lWYJXEoSqixy}
 */
export function oneOn1SpaceViewed(properties: OneOn1SpaceViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "4P_acBqHwWKq", name: "Manager's user aggregate ID", value: properties.managersUserAggregateId});
  eventPropertiesArray.push({id: "qGMJj2-Bu4y9", name: "Role", value: properties.role});
  eventPropertiesArray.push({id: "fa7n-RfCjkz7", name: "Subject's user aggregate ID", value: properties.subjectsUserAggregateId});
  eventPropertiesArray.push({id: "Zsdnw_YVpL", name: "Number of 1 on 1s loaded", value: properties.numberOf1On1sLoaded});
  eventPropertiesArray.push({id: "INz91iyeo", name: "Conversation ID", value: properties.conversationId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  properties.experimentVariations1On1AgendaCheckBoxAndReorder !== undefined && properties.experimentVariations1On1AgendaCheckBoxAndReorder !== null ?
    userPropertiesArray.push({id: "3qxXbHjowH", name: "Experiment variations: 1 on 1 agenda check box and reorder", value: properties.experimentVariations1On1AgendaCheckBoxAndReorder}) :
    userPropertiesArray.push({id: "3qxXbHjowH", name: "Experiment variations: 1 on 1 agenda check box and reorder", value: null});
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lWYJXEoSqixy", "9129ba27f43dafc1a8cd7a50a018997abaf814139f743e862c8f086735ae98f5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 Space Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lWYJXEoSqixy", "1 on 1 Space Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 Space Viewed", eventProperties, "lWYJXEoSqixy", "9129ba27f43dafc1a8cd7a50a018997abaf814139f743e862c8f086735ae98f5");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    AmplitudeCustom.logEvent("1 on 1 Space Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface QuickCommentFormViewedProperties {
  subjectUserAggregateId: string | null | undefined;
  context: string | null | undefined;
}
/**
 * Quick Comment Form Viewed: A user views the quick comment feedback form.
 *
 * When to trigger this event:
 * 1. User views the Quick Comment/Give Feedback page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/yVqZqej5aWbO/trigger/y2nHAypPH
 *
 * @param properties the properties associatied with this event
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/yVqZqej5aWbO}
 */
export function quickCommentFormViewed(
  properties: QuickCommentFormViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.subjectUserAggregateId !== undefined && properties.subjectUserAggregateId !== null ?
    eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId}) :
    eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: null});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yVqZqej5aWbO", "b535bd54af0f9af5032804f70efdf0e6aa7f323d71c9f0228e34df782fc8aad4", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Quick Comment Form Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yVqZqej5aWbO", "Quick Comment Form Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Quick Comment Form Viewed", eventProperties, "yVqZqej5aWbO", "b535bd54af0f9af5032804f70efdf0e6aa7f323d71c9f0228e34df782fc8aad4");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Quick Comment Form Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Direct Reports Self Reflections Index Viewed: A user views the index page of their direct reports' Self Reflections.
 *
 * When to trigger this event:
 * 1. User views the index page of their direct reports' Self Reflections.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/1fGus8fkkjyE/trigger/0fuUgfOpL
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/1fGus8fkkjyE}
 */
export function directReportsSelfReflectionsIndexViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1fGus8fkkjyE", "ae2dc9364dd1601fcf5ec892107064fc813e7fa94421398f6cca5ee2b9bdc2ae", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Direct Reports Self Reflections Index Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1fGus8fkkjyE", "Direct Reports Self Reflections Index Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Direct Reports Self Reflections Index Viewed", eventProperties, "1fGus8fkkjyE", "ae2dc9364dd1601fcf5ec892107064fc813e7fa94421398f6cca5ee2b9bdc2ae");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Direct Reports Self Reflections Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Self-reflections Index Viewed: A user views the index page of their Self Reflections.
 *
 * When to trigger this event:
 * 1. User views the index page of their Self Reflections.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6x8uQC2zW3HI/trigger/bl-7nOFQB
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6x8uQC2zW3HI}
 */
export function selfReflectionsIndexViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6x8uQC2zW3HI", "fb50881653a55e97bdc53e0b79aae4240dfbcc56f062952e4b7d95e96f661349", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Self-reflections Index Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6x8uQC2zW3HI", "Self-reflections Index Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Self-reflections Index Viewed", eventProperties, "6x8uQC2zW3HI", "fb50881653a55e97bdc53e0b79aae4240dfbcc56f062952e4b7d95e96f661349");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Self-reflections Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OutboundDestinationVisitedProperties {
  destinationDomain: string;
  elementClickedName: string;
  customContentEnabled: string[] | null | undefined;
  context: string | null | undefined;
  siteHierarchyL1: string | null | undefined;
}
/**
 * Outbound Destination Visited: This generic event is triggered when users select links within Culture Amp that direct to external locations, including support articles.
 *
 * When to trigger this event:
 * 1. Sent throughout the product when a "Learn more" link (or similar) is clicked, which will redirect to a support article.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/juGA3prPVOqP/trigger/0oxO-qK_x
 * 2. Sent throughout the product when a link to an external calendar provider, integrated app, or other product is clicked.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/juGA3prPVOqP/trigger/WxwE9ITYl
 *
 * @param properties the properties associatied with this event
 * @param properties.destinationDomain: The domain of the destination they visited e.g. skilljar.com
 * @param properties.elementClickedName: The name of the element clicked. If it's a hyper link this will be the link text, for buttons, the button name.
 * @param properties.customContentEnabled: Is there any custom content rendered in context of the event? For example a guidance popover, a temporary call to action banner etc
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.siteHierarchyL1: Capture top level of where the page sits in the site hierarchy (i.e. according to the top menu bar). This was historically used to track usage of navigation in the UI.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/juGA3prPVOqP}
 */
export function outboundDestinationVisited(
  properties: OutboundDestinationVisitedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "65PBZlyk3osx", name: "Destination domain", value: properties.destinationDomain});
  eventPropertiesArray.push({id: "ZnfX_VrsAxb3", name: "Element clicked name", value: properties.elementClickedName});
  properties.customContentEnabled !== undefined && properties.customContentEnabled !== null ?
    eventPropertiesArray.push({id: "pdymTj8lYxE7", name: "Custom content enabled", value: properties.customContentEnabled}) :
    eventPropertiesArray.push({id: "pdymTj8lYxE7", name: "Custom content enabled", value: null});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.siteHierarchyL1 !== undefined && properties.siteHierarchyL1 !== null ?
    eventPropertiesArray.push({id: "PfE0lLcKEPpi", name: "Site hierarchy - L1", value: properties.siteHierarchyL1}) :
    eventPropertiesArray.push({id: "PfE0lLcKEPpi", name: "Site hierarchy - L1", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "juGA3prPVOqP", "c302af2c913d47be184cacadeb3e57d40c1bf48f959a05866373f38392030848", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Outbound Destination Visited", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("juGA3prPVOqP", "Outbound Destination Visited", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Outbound Destination Visited", eventProperties, "juGA3prPVOqP", "c302af2c913d47be184cacadeb3e57d40c1bf48f959a05866373f38392030848");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Outbound Destination Visited", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationFlagToggledProperties {
  calibrationViewId: number;
  flagToggleState: string;
}
/**
 * Calibration Flag Toggled: The flag next to a manager review in a calibration view is toggled.
 *
 * When to trigger this event:
 * 1. When the user clicks the flag icon to the left of the table, it toggles on or off. This event tracks the toggling of the flag.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/nu-RphKTs/trigger/9X362RKSF
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.flagToggleState: Whether the flag was toggled on or off
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/nu-RphKTs}
 */
export function calibrationFlagToggled(
  properties: CalibrationFlagToggledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "9Vnjjp7FFk", name: "Flag toggle state", value: properties.flagToggleState});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nu-RphKTs", "1b0a46b50cba3d53e86628eac6ee50a1d1083ee28a3cb0ea1848e22f83f82d72", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration Flag Toggled", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nu-RphKTs", "Calibration Flag Toggled", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration Flag Toggled", eventProperties, "nu-RphKTs", "1b0a46b50cba3d53e86628eac6ee50a1d1083ee28a3cb0ea1848e22f83f82d72");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration Flag Toggled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewPaginationSizeChangedProperties {
  calibrationViewId: number;
  previousPaginationSize: number;
  paginationSize: number;
}
/**
 * Calibration View Pagination Size Changed: A user changes the number of Manager Reviews shown per page in a calibration.
 *
 * When to trigger this event:
 * 1. A user chooses one of the values in the pagination dropdown (between 5 and 100). Opening the dropdown and selecting a value returns the selected number of Manager Reviews per page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/M52LR3eJv_/trigger/owwsdiDWT
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.previousPaginationSize: The number of records displayed per page before the change.
 * @param properties.paginationSize: The number of records displayed per page after the change.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/M52LR3eJv_}
 */
export function calibrationViewPaginationSizeChanged(
  properties: CalibrationViewPaginationSizeChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "VQPMo_SyLH", name: "Previous pagination size", value: properties.previousPaginationSize});
  eventPropertiesArray.push({id: "AaY_oFPjpj", name: "Pagination size", value: properties.paginationSize});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "M52LR3eJv_", "a162e616727553b9da41c3a0e1db30dd25b711c9fb0a8884ab7ad7bd1d51f9cf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Pagination Size Changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("M52LR3eJv_", "Calibration View Pagination Size Changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Pagination Size Changed", eventProperties, "M52LR3eJv_", "a162e616727553b9da41c3a0e1db30dd25b711c9fb0a8884ab7ad7bd1d51f9cf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Pagination Size Changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number | null | undefined;
  performanceReviewCycleId: number | null | undefined;
  managerReviewId: number;
  managerReviewStatus: string;
  managerReviewCycleId: number;
  isCalibrated: boolean;
  hasPeerAndUpwardFeedback: boolean;
  selfReflectionsCollectedDuringCycle: boolean;
}
/**
 * Manager Review Viewed: A user views a manager review. A manager review can be accessed from the admin view, the manager view, the individual contributor view, the admin view of a calibration or a shared calibration.
 *
 * When to trigger this event:
 * 1. An admin views an individual manager review by opening the kebab menu on the employee row in a calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ/trigger/_59umAYZb
 * 2. A user views an individual manager review from a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ/trigger/drCGPPyV4
 * 3. A user views an individual manager review after it has been shared by their manager, from either the 'Shared Performance Reviews' page, the notifications page or a platform communication (email/slack/ms teams).
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ/trigger/BCv9UVJzT
 * 4. A manager views an individual manager review for one of their direct reports from the manager menu, the task list or a platform communication.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ/trigger/irsTc6WBM
 * 5. An admin views an individual manager review from the admin menu.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ/trigger/tISR56D09
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.managerReviewStatus: The status of the individual manager review: incomplete (untouched), dirty, complete or shared.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.isCalibrated: Is the manager view included in a published calibration view?
 * @param properties.hasPeerAndUpwardFeedback: Does the cycle have peer and upward feedback?
 * @param properties.selfReflectionsCollectedDuringCycle: Are self-reflections collected during this cycle? This will be true when the self-reflection cycle start date is <=1 month before the manager review phase start date
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/WXct1Wg4sQ}
 */
export function managerReviewViewed(properties: ManagerReviewViewedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.calibrationViewId !== undefined && properties.calibrationViewId !== null ?
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId}) :
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: null});
  properties.performanceReviewCycleId !== undefined && properties.performanceReviewCycleId !== null ?
    eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId}) :
    eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: null});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "hvuLAFBuds", name: "Manager review status", value: properties.managerReviewStatus});
  eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId});
  eventPropertiesArray.push({id: "Yx800PeVVM", name: "Is calibrated", value: properties.isCalibrated});
  eventPropertiesArray.push({id: "zMuoGizrvQ", name: "Has peer and upward feedback", value: properties.hasPeerAndUpwardFeedback});
  eventPropertiesArray.push({id: "DCD4Im3KI0", name: "Self reflections collected during cycle", value: properties.selfReflectionsCollectedDuringCycle});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WXct1Wg4sQ", "c3e7c1cfad40dcf828a1fa96163d471023fcf861fee914a456dbcf5e1144a023", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WXct1Wg4sQ", "Manager Review Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {...eventProperties};
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  delete eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {...userProperties};
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Viewed", eventProperties, "WXct1Wg4sQ", "c3e7c1cfad40dcf828a1fa96163d471023fcf861fee914a456dbcf5e1144a023");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Viewed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface EmployeeProfileViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number | null | undefined;
  activeTab: string;
  numberOfItemsInTab: number | null | undefined;
  managerReviewId: number | null | undefined;
  isViewingOwnProfile: boolean;
}
/**
 * Employee Profile Viewed: A user views the Employee Profile (internally called Dossier). The Employee Profile can be accessed from multiple places - e.g. a calibration, a manager review, a self-reflection. The Employee Profile can contain the employee feedback, self-reflections, goals, past performance ratings, notes and general information. This event is sent on each tab within the Employee Profile.
 *
 * When to trigger this event:
 * 1. A user views the Employee Profile. It is sent on each tab.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8/trigger/vF2oQp7A9
 * 2. When the manager or IC views the Performance reviews tab in the employee profile
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8/trigger/jPrItoZD3
 * 3. When the manager or IC views the Goals tab in the employee profile
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8/trigger/aaYoI8OI0
 * 4. When the manager or IC views the Self-reflections tab in the employee profile
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8/trigger/whB_FAzz1
 * 5. When the manager or IC views the Private notes tab in the employee profile
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8/trigger/yPFkfYsBD
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 * @param properties.numberOfItemsInTab: The number of items in the tab. For example the number of shoutouts on the dossier/employee profile tab.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.isViewingOwnProfile: Is the user viewing their own employee profile?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6hv5E4D5t8}
 */
export function employeeProfileViewed(
  properties: EmployeeProfileViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.calibrationViewId !== undefined && properties.calibrationViewId !== null ?
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId}) :
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: null});
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  properties.numberOfItemsInTab !== undefined && properties.numberOfItemsInTab !== null ?
    eventPropertiesArray.push({id: "SiO2gNHHG", name: "Number of items in tab", value: properties.numberOfItemsInTab}) :
    eventPropertiesArray.push({id: "SiO2gNHHG", name: "Number of items in tab", value: null});
  properties.managerReviewId !== undefined && properties.managerReviewId !== null ?
    eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId}) :
    eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: null});
  eventPropertiesArray.push({id: "9fR9IT6yQ", name: "Is viewing own profile", value: properties.isViewingOwnProfile});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6hv5E4D5t8", "73a95e5fdc56f769f7df19b046cee59849ef85d32eaddd9ca2b8573b4e9c9297", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Employee Profile Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6hv5E4D5t8", "Employee Profile Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Employee Profile Viewed", eventProperties, "6hv5E4D5t8", "73a95e5fdc56f769f7df19b046cee59849ef85d32eaddd9ca2b8573b4e9c9297");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Employee Profile Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewStatisticsInteractedProperties {
  calibrationViewId: number;
  interaction: string;
  elementClickedName: string;
  context: string | null | undefined;
}
/**
 * Calibration View Statistics Interacted: A user interacts with the calibration hero statistics. They can select to show the Assessment Group (default) or the Status statistics. They can also select a particular assessment group or status to filter the manager reviews in the calibration.
 *
 * When to trigger this event:
 * 1. An admin selects the 'Status' or the 'Assessment groups' button to change the Hero Statistics at the top of the screen of a calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j2wWJnZ-cn/trigger/pu5GtH6g0
 * 2. An admin selects a particular assessment group or status to filter the manager reviews in the calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j2wWJnZ-cn/trigger/uKMM8IdME
 * 3. A user selects the 'Status' or the 'Assessment groups' button to change the Hero Statistics in a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j2wWJnZ-cn/trigger/fCbwp2ytY
 * 4. A user selects a particular assessment group or status to filter the manager reviews in a calibration that has been shared with them
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j2wWJnZ-cn/trigger/-X3hdklLc
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.interaction: The kind of interaction the user performed with the element. eg. opened, closed, skipped, selected, deselected, added, removed, changed, sorted
 * @param properties.elementClickedName: The name of the element clicked. If it's a hyper link this will be the link text, for buttons, the button name.
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j2wWJnZ-cn}
 */
export function calibrationViewStatisticsInteracted(
  properties: CalibrationViewStatisticsInteractedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "NwXyKAX00-Ye", name: "Interaction", value: properties.interaction});
  eventPropertiesArray.push({id: "ZnfX_VrsAxb3", name: "Element clicked name", value: properties.elementClickedName});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j2wWJnZ-cn", "ddf47f31a8e302088d51553f067606bc29a40015f747f68be4ff3cdd67233e5b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Statistics Interacted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j2wWJnZ-cn", "Calibration View Statistics Interacted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Statistics Interacted", eventProperties, "j2wWJnZ-cn", "ddf47f31a8e302088d51553f067606bc29a40015f747f68be4ff3cdd67233e5b");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Statistics Interacted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewExportedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Exported: An admin requests to export the data from a calibration view to a csv. The csv is received via email.
 *
 * When to trigger this event:
 * 1. An admin clicks on the 'Export CSV' button in a calibration view.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/tch7wE5hHP/trigger/HaENtHtyU
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/tch7wE5hHP}
 */
export function calibrationViewExported(
  properties: CalibrationViewExportedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "tch7wE5hHP", "c45263e4e6461e3e04a7e1b30f2d10d27b561ac5c9072b2f9c776745a673d0ae", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Exported", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("tch7wE5hHP", "Calibration View Exported", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Exported", eventProperties, "tch7wE5hHP", "c45263e4e6461e3e04a7e1b30f2d10d27b561ac5c9072b2f9c776745a673d0ae");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Exported", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewManagerRatingChangedProperties {
  calibrationViewId: number;
  performanceQuestionType: PerformanceQuestionTypeValueType;
  managerReviewCycleId: number;
  managerReviewId: number;
}
/**
 * Calibration View Manager Rating Changed: An admin updates a Manager Review rating (assessment group or rating question) from a calibration
 *
 * When to trigger this event:
 * 1. An admin changes the Rating question or Assessment Group question within the calibration via the column drop-downs.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/XJiChHpesm/trigger/YD2DVTZwo
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.performanceQuestionType: Performance has a number of question types, including Rating, Multiple-choice, Free text, and Assessment group.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/XJiChHpesm}
 */
export function calibrationViewManagerRatingChanged(
  properties: CalibrationViewManagerRatingChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "2iWw27Qsg", name: "Performance question type", value: properties.performanceQuestionType});
  eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "XJiChHpesm", "606645738bbcbc190ad969d728b71b01ba467deea3f0374e2265d486c4432de2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Manager Rating Changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("XJiChHpesm", "Calibration View Manager Rating Changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {...eventProperties};
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  delete eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {...userProperties};
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Manager Rating Changed", eventProperties, "XJiChHpesm", "606645738bbcbc190ad969d728b71b01ba467deea3f0374e2265d486c4432de2");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Manager Rating Changed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

/**
 * Create Calibration View Started: An admin starts setting up a new calibration view.
 *
 * When to trigger this event:
 * 1. An admin views the page to set up a calibration view after clicking on the 'Create calibration view' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/If9EkVKZhR/trigger/G-zKaxWr7
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/If9EkVKZhR}
 */
export function createCalibrationViewStarted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "If9EkVKZhR", "c2be04e31381280d520b4899e02da9f2e2dd6e514c063294e6f05e399f2620af", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Create Calibration View Started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("If9EkVKZhR", "Create Calibration View Started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Create Calibration View Started", eventProperties, "If9EkVKZhR", "c2be04e31381280d520b4899e02da9f2e2dd6e514c063294e6f05e399f2620af");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Create Calibration View Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewCreatedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Created: An admin creates a new calibration view.
 *
 * When to trigger this event:
 * 1. An admin creates a new calibration view by clicking on the 'Create view' button, writing a name and clicking on the 'Create' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6h0wwmAEsi/trigger/Y1J6WI9rL
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6h0wwmAEsi}
 */
export function calibrationViewCreated(
  properties: CalibrationViewCreatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6h0wwmAEsi", "1192114e6c5b4d4826705cf957467114ba54df34d546fd4eaee6d7e00046b5b3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Created", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6h0wwmAEsi", "Calibration View Created", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Created", eventProperties, "6h0wwmAEsi", "1192114e6c5b4d4826705cf957467114ba54df34d546fd4eaee6d7e00046b5b3");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Created", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewPublishedProperties {
  calibrationViewId: number;
}
/**
 * Calibration View Published: An admin confirms publish for a calibration view.

A calibration view is usually published when a calibration session has taken place and ratings have changed or notes have been taken. On publishing the calibration view the changes to assessment groups will be shared with the respective managers, and the rating values will no longer be editable in the calibration view.
 *
 * When to trigger this event:
 * 1. An admin clicks 'Publish' once seeing the 'Confirm' pop up box.

The admin triggers the 'Confirm' pop up box after clicking on the Aqua 'Publish' button on the top right of the screen.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j95Buf8BNu/trigger/y0XvR2brB
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/j95Buf8BNu}
 */
export function calibrationViewPublished(
  properties: CalibrationViewPublishedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j95Buf8BNu", "101264e29e94e5f4777acd36dc1bfee0a9107e9e2d1aec1364b13787afdd0bbf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Published", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j95Buf8BNu", "Calibration View Published", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Published", eventProperties, "j95Buf8BNu", "101264e29e94e5f4777acd36dc1bfee0a9107e9e2d1aec1364b13787afdd0bbf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Published", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PublishCalibrationModalViewedProperties {
  calibrationViewId: number;
}
/**
 * Publish Calibration Modal Viewed: An admin views the modal to confirm publishing a calibration view.
 *
 * When to trigger this event:
 * 1. An admin views the modal to confirm publishing a calibration view after hitting the green 'Publish' button
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/GLk9Zvh3Mj/trigger/KuGdPLySj
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/GLk9Zvh3Mj}
 */
export function publishCalibrationModalViewed(
  properties: PublishCalibrationModalViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "GLk9Zvh3Mj", "07408b02d04aa84153cc222e466a9c044cf577dce5b7e2cd36b7d63e7ade065e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Publish Calibration Modal Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("GLk9Zvh3Mj", "Publish Calibration Modal Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Publish Calibration Modal Viewed", eventProperties, "GLk9Zvh3Mj", "07408b02d04aa84153cc222e466a9c044cf577dce5b7e2cd36b7d63e7ade065e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Publish Calibration Modal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewDashboardColumnsChangedProperties {
  columnType: string;
  performanceReviewCycleId: number;
  managerReviewCycleId: number | null | undefined;
  interaction: string;
}
/**
 * Manager Review Dashboard Columns Changed: A manager hides or exposes a column when viewing the manager review dashboard for their direct reports.
 *
 * When to trigger this event:
 * 1. In the direct reports' manager review dashboard, a manager clicks on the 'Manage columns' dropdown and then deselects a column.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/0FVGyVUN6r/trigger/ik3aSJx1e
 * 2. In the direct reports' manager review dashboard, a manager clicks on the 'Manage columns' dropdown and then selects a column.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/0FVGyVUN6r/trigger/qz8X4E3gK
 *
 * @param properties the properties associatied with this event
 * @param properties.columnType: The type of the column added or removed from the table, or sorted. In performance: 'Job title', 'Manager', 'Status', etc. In Group Search, 'Responses', 'Index factor', 'Index factor change', 'Comparison', etc.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.interaction: The kind of interaction the user performed with the element. eg. opened, closed, skipped, selected, deselected, added, removed, changed, sorted
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/0FVGyVUN6r}
 */
export function managerReviewDashboardColumnsChanged(
  properties: ManagerReviewDashboardColumnsChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "nwjVfmn9e", name: "Column type", value: properties.columnType});
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  properties.managerReviewCycleId !== undefined && properties.managerReviewCycleId !== null ?
    eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId}) :
    eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: null});
  eventPropertiesArray.push({id: "NwXyKAX00-Ye", name: "Interaction", value: properties.interaction});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0FVGyVUN6r", "0a6ff82365acff50d15772d8c1f78071c2c1267ead8813aad8f621a8f239d437", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Dashboard Columns Changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0FVGyVUN6r", "Manager Review Dashboard Columns Changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {...eventProperties};
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  delete eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {...userProperties};
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Dashboard Columns Changed", eventProperties, "0FVGyVUN6r", "0a6ff82365acff50d15772d8c1f78071c2c1267ead8813aad8f621a8f239d437");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Dashboard Columns Changed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface DirectReportsPerformanceReviewCycleViewedProperties {
  performanceReviewCycleId: number;
  activeTab: string;
}
/**
 * Direct Reports Performance Review Cycle Viewed: A manager views the dashboard with information about their direct reports in a particular performance review cycle.
 *
 * When to trigger this event:
 * 1. A manager views the dashboard with information about their direct reports in a particular performance review cycle.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/IbH4tGYv6Y/trigger/5edakAppM
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/IbH4tGYv6Y}
 */
export function directReportsPerformanceReviewCycleViewed(
  properties: DirectReportsPerformanceReviewCycleViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IbH4tGYv6Y", "f80b4c55345c99e0596f5c2b02b70e51300ccb299b60d6886c58464c2deb0d7e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Direct Reports Performance Review Cycle Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IbH4tGYv6Y", "Direct Reports Performance Review Cycle Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Direct Reports Performance Review Cycle Viewed", eventProperties, "IbH4tGYv6Y", "f80b4c55345c99e0596f5c2b02b70e51300ccb299b60d6886c58464c2deb0d7e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Direct Reports Performance Review Cycle Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AdminCalibrationNotesOpenedProperties {
  calibrationViewId: number;
  managerReviewId: number;
  calibrationViewStatus: string;
  hasNotes: boolean;
}
/**
 * Admin Calibration Notes Opened: An admin opens the calibration notes dossier for a manager review throughout the calibration process.
Calibration notes are intended to be shared with managers once the calibration process is complete.
 *
 * When to trigger this event:
 * 1. Triggered when an admin selects Calibration notes from the ellipses menu next to a manager review in a calibration view.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/db36jFM3cH/trigger/j2hXVVNkh
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.calibrationViewStatus: The status of the calibration view: 'In progress' or 'Published'. After the calibration view is published, the ratings values and the calibration notes are no longer editable.
 * @param properties.hasNotes: Are there any calibration notes in the dossier when the user opens it?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/db36jFM3cH}
 */
export function adminCalibrationNotesOpened(
  properties: AdminCalibrationNotesOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "-I9RKw05Ng", name: "Calibration view status", value: properties.calibrationViewStatus});
  eventPropertiesArray.push({id: "87p77L1nDL", name: "Has notes", value: properties.hasNotes});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "db36jFM3cH", "c464d9fca290da4d5693a811f02d0d86012f8b82d2de91fc5710771f15e5a62d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Admin Calibration Notes Opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("db36jFM3cH", "Admin Calibration Notes Opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Admin Calibration Notes Opened", eventProperties, "db36jFM3cH", "c464d9fca290da4d5693a811f02d0d86012f8b82d2de91fc5710771f15e5a62d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Admin Calibration Notes Opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationNoteSavedProperties {
  calibrationViewId: number;
  managerReviewId: number;
  isNew: boolean;
}
/**
 * Calibration Note Saved: An admin saves a calibration note for a manager review throughout the calibration process. Calibration notes are intended to be shared with managers once the calibration process is complete.
 *
 * When to trigger this event:
 * 1. Triggered when an admin selects the Save button after creating or editing a calibration note.

Admin can open the calibration notes for a manager review by selecting Calibration notes from the ellipses menu next to it.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DGFNrT0AIX/trigger/LgfaGpj8K
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.isNew: Did the user create a new note as result of this interaction? (vs editing an existing note)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DGFNrT0AIX}
 */
export function calibrationNoteSaved(
  properties: CalibrationNoteSavedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "8huAp7dv3l", name: "Is new", value: properties.isNew});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DGFNrT0AIX", "1b70a2bbf44182d8e1ecb26777c80bf4d6a58ad1ba1925566e92a24797f0bcf2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration Note Saved", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DGFNrT0AIX", "Calibration Note Saved", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration Note Saved", eventProperties, "DGFNrT0AIX", "1b70a2bbf44182d8e1ecb26777c80bf4d6a58ad1ba1925566e92a24797f0bcf2");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration Note Saved", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerCalibrationNotesViewedProperties {
  managerReviewId: number;
  performanceReviewCycleId: number;
}
/**
 * Manager Calibration Notes Viewed: A manager views the calibration notes assigned to one of their direct reports in the manager review dashboard. The calibration notes are shared with the manager after the calibration view is published.
 *
 * When to trigger this event:
 * 1. Triggered when the calibration notes dossier is displayed for a particular employee in the manager review dashboard.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/RR1TLueSpk/trigger/jzz_WSY7Y
 *
 * @param properties the properties associatied with this event
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/RR1TLueSpk}
 */
export function managerCalibrationNotesViewed(
  properties: ManagerCalibrationNotesViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RR1TLueSpk", "3e4174b70b0b92e5da95d6c86668e2946f2663c82ed4766ef7924ac394592966", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Calibration Notes Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RR1TLueSpk", "Manager Calibration Notes Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Calibration Notes Viewed", eventProperties, "RR1TLueSpk", "3e4174b70b0b92e5da95d6c86668e2946f2663c82ed4766ef7924ac394592966");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Calibration Notes Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewSharingPermissionsUpdatedProperties {
  performanceReviewCycleId: number;
  managerReviewCycleId: number;
  sharingEnabled: boolean;
}
/**
 * Manager Review Sharing Permissions Updated: An admin enables or disables sharing the manager review for a particular performance review cycle.
 *
 * When to trigger this event:
 * 1. An admin toggles the manager review 'Allow sharing' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/L6tRGTQGwu/trigger/lnvQy0Zyh
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.sharingEnabled: Is sharing enabled after this interaction?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/L6tRGTQGwu}
 */
export function managerReviewSharingPermissionsUpdated(
  properties: ManagerReviewSharingPermissionsUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId});
  eventPropertiesArray.push({id: "c6gTY2cWWtSP", name: "Sharing enabled", value: properties.sharingEnabled});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L6tRGTQGwu", "459b78c0c8684a675b5a404353b70745440624a89baa541cba3d9bc0be3f2c24", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Sharing Permissions Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L6tRGTQGwu", "Manager Review Sharing Permissions Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {...eventProperties};
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  delete eventPropertiesAmplitudeCustom["Manager review cycle ID"]
  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {...userProperties};
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Sharing Permissions Updated", eventProperties, "L6tRGTQGwu", "459b78c0c8684a675b5a404353b70745440624a89baa541cba3d9bc0be3f2c24");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Sharing Permissions Updated", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

/**
 * Goal Tree Expanded: A user expands a company goal in the Goal Tree view.
 *
 * When to trigger this event:
 * 1. Triggered on button click
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/jvOkvDd-IX/trigger/J54_zlDRY
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/jvOkvDd-IX}
 */
export function goalTreeExpanded() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jvOkvDd-IX", "15d121c6cde869521ce599e20ee9b25fccd3b4cd57b69b0e7ec6f9b8d5432335", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Goal Tree Expanded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jvOkvDd-IX", "Goal Tree Expanded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Goal Tree Expanded", eventProperties, "jvOkvDd-IX", "15d121c6cde869521ce599e20ee9b25fccd3b4cd57b69b0e7ec6f9b8d5432335");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Goal Tree Expanded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalViewedProperties {
  goalId: string[];
  goalType: GoalTypeValueType;
  navigationSource: string | null | undefined;
}
/**
 * Performance Goal Viewed: A user views a performance goal.
 *
 * When to trigger this event:
 * 1. Any user clicks on a goal in the list of goals, which opens up a drawer where they can edit the goal.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/tAZ0yocGKN/trigger/w9UfUxOC9
 *
 * @param properties the properties associatied with this event
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.goalType: Type of goal set e.g. company level goal
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/tAZ0yocGKN}
 */
export function performanceGoalViewed(
  properties: PerformanceGoalViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId});
  eventPropertiesArray.push({id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType});
  properties.navigationSource !== undefined && properties.navigationSource !== null ?
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource}) :
    eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "tAZ0yocGKN", "b890b8b3cfdd8caeb9e5d237ee0b9cf0f5313569f6caab7ae9a51c1ea8110a05", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("tAZ0yocGKN", "Performance Goal Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Viewed", eventProperties, "tAZ0yocGKN", "b890b8b3cfdd8caeb9e5d237ee0b9cf0f5313569f6caab7ae9a51c1ea8110a05");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CopyGoalPageViewedProperties {
  baseGoalId: string;
}
/**
 * Copy Goal Page Viewed: A user views the page to copy a goal
 *
 * When to trigger this event:
 * 1. On page view
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/-Yh8EWceMk/trigger/lHJDhwmYx
 *
 * @param properties the properties associatied with this event
 * @param properties.baseGoalId: The system ID of the original goal that the new goal was copied from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/-Yh8EWceMk}
 */
export function copyGoalPageViewed(properties: CopyGoalPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "ETzfLMS9XI", name: "Base goal ID", value: properties.baseGoalId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-Yh8EWceMk", "cbf9431fc7cf8a57f57c830aad8cead0e13e9740a927baca7d0a469be979bc7d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Copy Goal Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-Yh8EWceMk", "Copy Goal Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Copy Goal Page Viewed", eventProperties, "-Yh8EWceMk", "cbf9431fc7cf8a57f57c830aad8cead0e13e9740a927baca7d0a469be979bc7d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Copy Goal Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewFullWidthToggledProperties {
  viewedInFullWidth: boolean;
  calibrationViewId: number | null | undefined;
}
/**
 * Calibration View Full Width Toggled: A user toggles the minimize/maximize icon to change the width of the table in the Calibrations pages
 *
 * When to trigger this event:
 * 1. Clicking on the "Minimize width" button reduced the width of the calibrations table
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/A5LBiD8ciV/trigger/ZTwA-lutW
 * 2. Clicking on the "Maximize width" button increases the width of the calibrations table
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/A5LBiD8ciV/trigger/q9Xh7P9Fc
 *
 * @param properties the properties associatied with this event
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/A5LBiD8ciV}
 */
export function calibrationViewFullWidthToggled(
  properties: CalibrationViewFullWidthToggledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth});
  properties.calibrationViewId !== undefined && properties.calibrationViewId !== null ?
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId}) :
    eventPropertiesArray.push({id: "HmZojgQc2T", name: "Calibration view ID", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "A5LBiD8ciV", "6946a9417e4a57c612b28eb10d01000d695f94b55fcbf487f8aa2e6565f80f5c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Full Width Toggled", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("A5LBiD8ciV", "Calibration View Full Width Toggled", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Full Width Toggled", eventProperties, "A5LBiD8ciV", "6946a9417e4a57c612b28eb10d01000d695f94b55fcbf487f8aa2e6565f80f5c");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Full Width Toggled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewAcknowledgedProperties {
  managerReviewId: number;
  managerReviewCycleId: number;
  performanceReviewCycleId: number;
  addedNotes: boolean;
}
/**
 * Manager Review Acknowledged: An employee acknowledges in the platform that their performance review discussion has happened. They can add a note to their acknowledgement.
 *
 * When to trigger this event:
 * 1. A employee clicks on the 'Submit' button after selecting the checkbox to acknowledge their manager review.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/dj_QE_qh7/trigger/34qoVGlp-
 *
 * @param properties the properties associatied with this event
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.addedNotes: Did the employee add any notes to their review acknowledgement?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/dj_QE_qh7}
 */
export function managerReviewAcknowledged(
  properties: ManagerReviewAcknowledgedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId});
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "tS8bM8oJj", name: "Added notes", value: properties.addedNotes});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dj_QE_qh7", "9a506f1b1db23c00cc6cf76109f590ab75c62fc2514aafb220e9b95e0f9f9304", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Acknowledged", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dj_QE_qh7", "Manager Review Acknowledged", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Acknowledged", eventProperties, "dj_QE_qh7", "9a506f1b1db23c00cc6cf76109f590ab75c62fc2514aafb220e9b95e0f9f9304");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Acknowledged", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Calendar Sync Page Viewed: A user views page to integrate their 1-on-1s with their calendar provider.
 *
 * When to trigger this event:
 * 1. On page view
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6QSKu-_qi/trigger/Ymp8bAh5A
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/6QSKu-_qi}
 */
export function calendarSyncPageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6QSKu-_qi", "fbf3e78395b901abee5a6e9f7ca96368963362f870cd78936e30563558e9efea", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Sync Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6QSKu-_qi", "Calendar Sync Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Sync Page Viewed", eventProperties, "6QSKu-_qi", "fbf3e78395b901abee5a6e9f7ca96368963362f870cd78936e30563558e9efea");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Sync Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Set Up 1 on 1 Calendar Integration Modal Viewed: A user views the modal to select how they would prefer to set up up 1-on-1s with their direct report/s.
User can either synchronise their 1-on-1 events to their external calendar, or opt to manually set up their 1-on-1s (via creating the event in the platform and then creating the event in their external calendar).
 *
 * When to trigger this event:
 * 1. A user views the modal to set up 1-on-1s with their direct report.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/PHhoWyi62i/trigger/X17VepF3j
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/PHhoWyi62i}
 */
export function setUp1On1CalendarIntegrationModalViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "PHhoWyi62i", "2b3599abf2783acb010ceea2f0f1bc891351ae7b68807ec8288a029ec7a6474b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Set Up 1 on 1 Calendar Integration Modal Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("PHhoWyi62i", "Set Up 1 on 1 Calendar Integration Modal Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Set Up 1 on 1 Calendar Integration Modal Viewed", eventProperties, "PHhoWyi62i", "2b3599abf2783acb010ceea2f0f1bc891351ae7b68807ec8288a029ec7a6474b");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Set Up 1 on 1 Calendar Integration Modal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Calendar Event Picker Modal Viewed: A user views the modal that displays events from their external calendar client which match their 1-on-1 event specifications.
 *
 * When to trigger this event:
 * 1. A user views the modal to select an event from their external calendar, which matches & will connect to their 1-on-1 event.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DX8LQ-1u5t/trigger/MNm3i8yfU
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DX8LQ-1u5t}
 */
export function calendarEventPickerModalViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DX8LQ-1u5t", "383a1f3a964e946e1942b8d0cad0e909b607fce87723cd4b6b888b36576a89c0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Event Picker Modal Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DX8LQ-1u5t", "Calendar Event Picker Modal Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Event Picker Modal Viewed", eventProperties, "DX8LQ-1u5t", "383a1f3a964e946e1942b8d0cad0e909b607fce87723cd4b6b888b36576a89c0");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Event Picker Modal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * 1 on 1 and Calendar Event Connected: A user has connected a 1-on-1 with an event in their external calendar.
 *
 * When to trigger this event:
 * 1. User has clicked the 'Connect to event' button, from the Event Picker Modal.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/IE-uBA9rS-/trigger/fIEfrNjYs
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/IE-uBA9rS-}
 */
export function oneOn1AndCalendarEventConnected() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IE-uBA9rS-", "0f7487d449dbdcf623b529121b816dae349fde59f5a32689ca4b1f94fdfc713f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 and Calendar Event Connected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IE-uBA9rS-", "1 on 1 and Calendar Event Connected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 and Calendar Event Connected", eventProperties, "IE-uBA9rS-", "0f7487d449dbdcf623b529121b816dae349fde59f5a32689ca4b1f94fdfc713f");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 and Calendar Event Connected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * 1 on 1 No Calendar Match Message Viewed: A user receives a message indicating that no event in their calendar matches the requirements. It prompts the user to click cancel or retry fetching events.
 *
 * When to trigger this event:
 * 1. Triggered when a user is presented with the modal to 'Retry' syncing their external calendar to an existing 1-on-1 event; within the 1-on-1s product.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/O7h9bB6b6N/trigger/y7K4y_rax
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/O7h9bB6b6N}
 */
export function oneOn1NoCalendarMatchMessageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O7h9bB6b6N", "22b9ab048bdee98294b4a80ad431370b203fd4fe5168cf08e169895187932739", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 No Calendar Match Message Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O7h9bB6b6N", "1 on 1 No Calendar Match Message Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 No Calendar Match Message Viewed", eventProperties, "O7h9bB6b6N", "22b9ab048bdee98294b4a80ad431370b203fd4fe5168cf08e169895187932739");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 No Calendar Match Message Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalendarIntegrationSetUpCompletedProperties {
  calendarProvider: string;
}
/**
 * Calendar Integration Set Up Completed: A user has successfully connected their 1 on 1 to their calendar provider.
 *
 * When to trigger this event:
 * 1. Triggered when a user has received the pop-up notification on Calendar Sync Page Viewed confirming the connection between the 1 on 1 and the calendar provider.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Z7S2QFBMcD/trigger/IEqhr0YD4
 *
 * @param properties the properties associatied with this event
 * @param properties.calendarProvider: The calendar provider eg. Outlook, Google linked to the 1-on-1
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Z7S2QFBMcD}
 */
export function calendarIntegrationSetUpCompleted(
  properties: CalendarIntegrationSetUpCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iwkO90oRc", name: "Calendar provider", value: properties.calendarProvider});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Z7S2QFBMcD", "57ba0aef13f5532d2f61c8762a313a07295bb88008053ed3b6867e0e867a0d9e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Integration Set Up Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Z7S2QFBMcD", "Calendar Integration Set Up Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Integration Set Up Completed", eventProperties, "Z7S2QFBMcD", "57ba0aef13f5532d2f61c8762a313a07295bb88008053ed3b6867e0e867a0d9e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Integration Set Up Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OneOn1AndCalendarEventDisconnectedProperties {
  context: string | null | undefined;
  oneOn1Id: string | null | undefined;
  scheduleId: string | null | undefined;
}
/**
 * 1 on 1 and Calendar Event Disconnected: The sync between the single calendar event and the 1 on 1 has been removed. The calendar integration/connection is unaffected by this event.
 *
 * When to trigger this event:
 * 1. The sync with the calendar event has been removed.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/SD_2T7PgyZ/trigger/wwOSPy-7A
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.scheduleId: The identifier for the group of 1 on 1 meetings that are scheduled between the manager and IC.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/SD_2T7PgyZ}
 */
export function oneOn1AndCalendarEventDisconnected(
  properties: OneOn1AndCalendarEventDisconnectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  properties.oneOn1Id !== undefined && properties.oneOn1Id !== null ?
    eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id}) :
    eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: null});
  properties.scheduleId !== undefined && properties.scheduleId !== null ?
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId}) :
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "SD_2T7PgyZ", "659093e74d0201e5d74eac111548fe39c0e40b39b51797e1700ddcb83d6e6597", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 and Calendar Event Disconnected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("SD_2T7PgyZ", "1 on 1 and Calendar Event Disconnected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 and Calendar Event Disconnected", eventProperties, "SD_2T7PgyZ", "659093e74d0201e5d74eac111548fe39c0e40b39b51797e1700ddcb83d6e6597");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 and Calendar Event Disconnected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ItemCopiedToClipboardProperties {
  activeTab: string;
}
/**
 * Item Copied to Clipboard: A user copies an item from the tab to their clipboard using the copy icon on the Employee Profile.
 *
 * When to trigger this event:
 * 1. A user clicks the copy icon on the Shoutouts tab of the Employee Profile
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Bq9hZGQNTW/trigger/3WZoJooZk
 *
 * @param properties the properties associatied with this event
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Bq9hZGQNTW}
 */
export function itemCopiedToClipboard(
  properties: ItemCopiedToClipboardProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Bq9hZGQNTW", "d9b332cad810e83b4acbafea6bcbcf0c4c69d0a9a4fea53c194815d03822401d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Item Copied to Clipboard", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Bq9hZGQNTW", "Item Copied to Clipboard", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Item Copied to Clipboard", eventProperties, "Bq9hZGQNTW", "d9b332cad810e83b4acbafea6bcbcf0c4c69d0a9a4fea53c194815d03822401d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Item Copied to Clipboard", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface WidgetExpandedProperties {
  activeTab: string;
  elementName: string;
}
/**
 * Widget Expanded: A user clicks to expand the expert advice widget in the Employee Profile.
 *
 * When to trigger this event:
 * 1. A user clicks on the expert advice widget in the shoutouts tab of the Employee Profile.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/BslD0-_mM1/trigger/mbXpSHFrT
 *
 * @param properties the properties associatied with this event
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 * @param properties.elementName: The name of the element that was interacted with
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/BslD0-_mM1}
 */
export function widgetExpanded(properties: WidgetExpandedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  eventPropertiesArray.push({id: "VGXZMvxtpA", name: "Element name", value: properties.elementName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "BslD0-_mM1", "0a1644919da7664d269d7ba22ed2fed1250d9b9fadc2b0061178e1ebc5dfa7fc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Widget Expanded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("BslD0-_mM1", "Widget Expanded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Widget Expanded", eventProperties, "BslD0-_mM1", "0a1644919da7664d269d7ba22ed2fed1250d9b9fadc2b0061178e1ebc5dfa7fc");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Widget Expanded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalendarIntegrationDisconnectedProperties {
  calendarProvider: string;
}
/**
 * Calendar Integration Disconnected: A user disconnects the connection between their calendar provider and the 1-on-1s product. This removes the calendar sync and scheduled 1-on-1 conversations with the users direct reports.
 *
 * When to trigger this event:
 * 1. A user has disconnected their calendar from 1-on-1s.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/TA85XJwd2k/trigger/v_XIoPKsa
 *
 * @param properties the properties associatied with this event
 * @param properties.calendarProvider: The calendar provider eg. Outlook, Google linked to the 1-on-1
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/TA85XJwd2k}
 */
export function calendarIntegrationDisconnected(
  properties: CalendarIntegrationDisconnectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "iwkO90oRc", name: "Calendar provider", value: properties.calendarProvider});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "TA85XJwd2k", "c4b6ae89d031992c3478fff618156de5bc806292f1c2e7b0e2c218c387624a2d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Integration Disconnected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("TA85XJwd2k", "Calendar Integration Disconnected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Integration Disconnected", eventProperties, "TA85XJwd2k", "c4b6ae89d031992c3478fff618156de5bc806292f1c2e7b0e2c218c387624a2d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Integration Disconnected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceRatingQuestionWarningIgnoredProperties {
  performanceReviewCycleId: number;
}
/**
 * Performance Rating Question Warning Ignored: A user chooses to ignore the warning and continue the cycle creation journey without a performance rating question.
 *
 * When to trigger this event:
 * 1. User clicks the Continue without performance rating button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/jDt0M59FuQ/trigger/OYU-v2Ppe
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/jDt0M59FuQ}
 */
export function performanceRatingQuestionWarningIgnored(
  properties: PerformanceRatingQuestionWarningIgnoredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jDt0M59FuQ", "8df46333e333b8362933eb7c6dec1878a1a8472b0a3690a1323ca3aa0092fbe8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Rating Question Warning Ignored", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jDt0M59FuQ", "Performance Rating Question Warning Ignored", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Rating Question Warning Ignored", eventProperties, "jDt0M59FuQ", "8df46333e333b8362933eb7c6dec1878a1a8472b0a3690a1323ca3aa0092fbe8");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Rating Question Warning Ignored", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceRatingQuestionWarningViewedProperties {
  performanceReviewCycleId: number;
}
/**
 * Performance Rating Question Warning Viewed: A user receives a warning when they haven't added a performance rating question to the cycle and have attempted to continue through the multi-step form.
 *
 * When to trigger this event:
 * 1. A user receives a warning when they haven't added a performance rating question to the cycle and have attempted to continue through the multi-step form.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/5hQywnh6l8/trigger/aD7ERLzC8
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/5hQywnh6l8}
 */
export function performanceRatingQuestionWarningViewed(
  properties: PerformanceRatingQuestionWarningViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5hQywnh6l8", "bcc7c829cf78c58547a892912e0a0020ee734861b2f1024aecae6eb6bb5f72b8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Rating Question Warning Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5hQywnh6l8", "Performance Rating Question Warning Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Rating Question Warning Viewed", eventProperties, "5hQywnh6l8", "bcc7c829cf78c58547a892912e0a0020ee734861b2f1024aecae6eb6bb5f72b8");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Rating Question Warning Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TabFilteredProperties {
  activeTab: string;
  filtersApplied: string[] | null | undefined;
  filterValuessApplied: string[] | null | undefined;
}
/**
 * Tab Filtered: A user filters the active tab in the Employee Profile/Dossier.
 *
 * When to trigger this event:
 * 1. A user clicks on an option from the drop down to show Shoutouts given, received or all. The default is Shoutouts recieved.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/mAepXPZHZn/trigger/loAgsgu-U
 *
 * @param properties the properties associatied with this event
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 * @param properties.filtersApplied: A list of all filters that were applied to the search. This should be the names of the filters, not the values, as filter values can contain PII, and they are also too numerous to search within Amplitude.
 * @param properties.filterValuessApplied: The value(s) of filters applied on view. (e.g. IT, Finance)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/mAepXPZHZn}
 */
export function tabFiltered(properties: TabFilteredProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  properties.filtersApplied !== undefined && properties.filtersApplied !== null ?
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: properties.filtersApplied}) :
    eventPropertiesArray.push({id: "ZjfVsZHrzKhv", name: "Filters applied", value: null});
  properties.filterValuessApplied !== undefined && properties.filterValuessApplied !== null ?
    eventPropertiesArray.push({id: "5BAhZkfK8QzS", name: "Filter Values(s) Applied", value: properties.filterValuessApplied}) :
    eventPropertiesArray.push({id: "5BAhZkfK8QzS", name: "Filter Values(s) Applied", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mAepXPZHZn", "bf7f05d88fb1e15d622158c186f010a08083ec5ce905d48d4869ffbbae66586a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Tab Filtered", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mAepXPZHZn", "Tab Filtered", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Tab Filtered", eventProperties, "mAepXPZHZn", "bf7f05d88fb1e15d622158c186f010a08083ec5ce905d48d4869ffbbae66586a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Tab Filtered", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Calendar Information Modal Viewed: The user views the information modal. There are 2 versions of this modal depending on whether the user has connected their calendar or not.
 *
 * When to trigger this event:
 * 1. When a user views the calendar information modal which advises of the calendar connection and meeting requirements.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/9Mm5E6_u4R/trigger/9f5trb5BP
 * 2. A user see the modal advising the 1-on-1 meeting requirements.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/9Mm5E6_u4R/trigger/6lkAb51vC
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/9Mm5E6_u4R}
 */
export function calendarInformationModalViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9Mm5E6_u4R", "1d476b92555f001675e84d63006ed4b24cf1799f77e18fd9e81ffc3918589b93", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Information Modal Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9Mm5E6_u4R", "Calendar Information Modal Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Information Modal Viewed", eventProperties, "9Mm5E6_u4R", "1d476b92555f001675e84d63006ed4b24cf1799f77e18fd9e81ffc3918589b93");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Information Modal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Calendar Authentication Initiated: A user is sent to an external site (Nylas) to authenticate their calendar provider.
 *
 * When to trigger this event:
 * 1. A user is sent to external site to authenticate their calendar provider after clicking 'Continue' on the Calendar information modal.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/wmztU0sfJk/trigger/bjiAWJjE1
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/wmztU0sfJk}
 */
export function calendarAuthenticationInitiated() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wmztU0sfJk", "62671376e32edbd39941b4ef41372f52360fcc5f737ca8095ce398389ce1278a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calendar Authentication Initiated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wmztU0sfJk", "Calendar Authentication Initiated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calendar Authentication Initiated", eventProperties, "wmztU0sfJk", "62671376e32edbd39941b4ef41372f52360fcc5f737ca8095ce398389ce1278a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calendar Authentication Initiated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentSavedToEmployeeProfileProperties {
  role: RoleValueType;
  contentType: string;
  context: string | null | undefined;
  contentName: string;
}
/**
 * Content Saved to Employee Profile: A user saves content to the Employee Profile.
 *
 * When to trigger this event:
 * 1. A user saves content to the Employee Profile.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/daydazB8Uf/trigger/cG9SBsQ_y
 *
 * @param properties the properties associatied with this event
 * @param properties.role: The role of the user viewing the page.
 * @param properties.contentType: The type of content that is saved to the Employee Profile or being viewed from the Employee Profile eg. 1-on-1 or Shoutouts
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.contentName: The name of content that is saved to the Employee Profile or being viewed from the Employee Profile eg. 1-on-1 highlight.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/daydazB8Uf}
 */
export function contentSavedToEmployeeProfile(
  properties: ContentSavedToEmployeeProfileProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qGMJj2-Bu4y9", name: "Role", value: properties.role});
  eventPropertiesArray.push({id: "YptaKvGuy", name: "Content type", value: properties.contentType});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  eventPropertiesArray.push({id: "lZXTciROs", name: "Content name", value: properties.contentName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "daydazB8Uf", "8e9a8390af3b78e31b51e0f0eb3e7612b2e0d2a6d49d46ccc0c1c3d66614e8df", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Content Saved to Employee Profile", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("daydazB8Uf", "Content Saved to Employee Profile", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Content Saved to Employee Profile", eventProperties, "daydazB8Uf", "8e9a8390af3b78e31b51e0f0eb3e7612b2e0d2a6d49d46ccc0c1c3d66614e8df");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Content Saved to Employee Profile", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentViewedInSourceProperties {
  medium: MediumValueType;
  activeTab: string;
  context: string | null | undefined;
  contentType: string;
  contentName: string;
}
/**
 * Content Viewed in Source: A user clicks to view the content in the original source eg. in Slack or Culture Amp website.
 *
 * When to trigger this event:
 * 1. A user clicks link to view the Shoutout in Slack.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/HpdKcvLGnp/trigger/ycMy_6fx7
 * 2. A user clicks to view the 1-on-1 content on the 1 on 1 Space page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/HpdKcvLGnp/trigger/yHo68uJng
 *
 * @param properties the properties associatied with this event
 * @param properties.medium: Medium where the communication was received (e.g. Email, Slack, MS Teams, In-app)
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.contentType: The type of content that is saved to the Employee Profile or being viewed from the Employee Profile eg. 1-on-1 or Shoutouts
 * @param properties.contentName: The name of content that is saved to the Employee Profile or being viewed from the Employee Profile eg. 1-on-1 highlight.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/HpdKcvLGnp}
 */
export function contentViewedInSource(
  properties: ContentViewedInSourceProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "AWkO_kNI_UBw", name: "Medium", value: properties.medium});
  eventPropertiesArray.push({id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  eventPropertiesArray.push({id: "YptaKvGuy", name: "Content type", value: properties.contentType});
  eventPropertiesArray.push({id: "lZXTciROs", name: "Content name", value: properties.contentName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "HpdKcvLGnp", "35aab8684dd2c9436be87e1a0975a63176bd408ace4f4fec0c5b2905ff2998a0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Content Viewed in Source", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("HpdKcvLGnp", "Content Viewed in Source", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Content Viewed in Source", eventProperties, "HpdKcvLGnp", "35aab8684dd2c9436be87e1a0975a63176bd408ace4f4fec0c5b2905ff2998a0");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Content Viewed in Source", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PeerOrUpwardFeedbackRequestWithdrawnProperties {
  performanceReviewCycleId: number;
  role: RoleValueType;
  hasAutomatedUpwardFeedbackRequests: boolean;
  feedbackType: FeedbackTypeValueType[];
}
/**
 * Peer or Upward Feedback Request Withdrawn: A manager or admin withdraws a peer or upward feedback request in a performance review cycle.
 *
 * When to trigger this event:
 * 1. A manager or admin confirms they want to withdraw a peer or upward feedback request
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Tdl6cf8lcq/trigger/cNBUo4epZ
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.role: The role of the user viewing the page.
 * @param properties.hasAutomatedUpwardFeedbackRequests: Does the cycle have automated upward feedback requests? When this option is enabled, feedback requests are sent automatically to all the direct reports of subjects included in the cycle. Managers have the ability to withdraw them before they are sent.
 * @param properties.feedbackType: What types of feedback does it include? e.g. Peer Feedback, Upward Feedback, Manager Evaluation
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/Tdl6cf8lcq}
 */
export function peerOrUpwardFeedbackRequestWithdrawn(
  properties: PeerOrUpwardFeedbackRequestWithdrawnProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "qGMJj2-Bu4y9", name: "Role", value: properties.role});
  eventPropertiesArray.push({id: "DUFgjJFC1", name: "Has automated upward feedback requests", value: properties.hasAutomatedUpwardFeedbackRequests});
  eventPropertiesArray.push({id: "893jcrpViFAi", name: "Feedback type", value: properties.feedbackType});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Tdl6cf8lcq", "6341886de472da11554ee5ac9e63b110aebb164334d8808a6a6c31546fc8c817", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Peer or Upward Feedback Request Withdrawn", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Tdl6cf8lcq", "Peer or Upward Feedback Request Withdrawn", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Peer or Upward Feedback Request Withdrawn", eventProperties, "Tdl6cf8lcq", "6341886de472da11554ee5ac9e63b110aebb164334d8808a6a6c31546fc8c817");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Peer or Upward Feedback Request Withdrawn", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceReviewsIndexViewedProperties {
  hasCurrentPerformanceReviewCycles: boolean;
  hasPastPerformanceReviewCycles: boolean;
}
/**
 * Performance Reviews Index Viewed: User views the Performance Reviews index page.
 *
 * When to trigger this event:
 * 1. User views the Performance Reviews index page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2R2QXebiX5/trigger/gDaBRvwD4
 *
 * @param properties the properties associatied with this event
 * @param properties.hasCurrentPerformanceReviewCycles: Is there an active performance review cycle?
 * @param properties.hasPastPerformanceReviewCycles: Are there past performance review cycles?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2R2QXebiX5}
 */
export function performanceReviewsIndexViewed(
  properties: PerformanceReviewsIndexViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "kxZAM8qxI", name: "Has current performance review cycles", value: properties.hasCurrentPerformanceReviewCycles});
  eventPropertiesArray.push({id: "VLTix75dI", name: "Has past performance review cycles", value: properties.hasPastPerformanceReviewCycles});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2R2QXebiX5", "18ecd63436320661680dc222025533761dace53570a77080a3c03a77c9ce4e3f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Reviews Index Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2R2QXebiX5", "Performance Reviews Index Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Reviews Index Viewed", eventProperties, "2R2QXebiX5", "18ecd63436320661680dc222025533761dace53570a77080a3c03a77c9ce4e3f");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Reviews Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceReviewHubViewedProperties {
  tilesNamesDisplayed: string[];
  performanceReviewCycleId: number;
}
/**
 * Performance Review Hub Viewed: User views the Hub page of a selected Performance Review cycle.
 *
 * When to trigger this event:
 * 1. User views the Hub page of a Performance Review Cycle.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/cZCrdwwgNH/trigger/DR0N08S74
 *
 * @param properties the properties associatied with this event
 * @param properties.tilesNamesDisplayed: The names of tiles displayed to the user
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/cZCrdwwgNH}
 */
export function performanceReviewHubViewed(
  properties: PerformanceReviewHubViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "huLYFhFh-Uy4", name: "Tiles names displayed", value: properties.tilesNamesDisplayed});
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cZCrdwwgNH", "17f7d8e5eecc1cbab0a8b04ab4a58bd4f7645ea6da8ba14583d6dbf67dc90483", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Review Hub Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cZCrdwwgNH", "Performance Review Hub Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Review Hub Viewed", eventProperties, "cZCrdwwgNH", "17f7d8e5eecc1cbab0a8b04ab4a58bd4f7645ea6da8ba14583d6dbf67dc90483");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Review Hub Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Self Reflections Hub Viewed: User views the Self Reflections Hub page.
 *
 * When to trigger this event:
 * 1. User views the Self Reflections Hub page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/QXKCSZC_Sf/trigger/AShHwHVZL
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/QXKCSZC_Sf}
 */
export function selfReflectionsHubViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QXKCSZC_Sf", "2159908451d20aa3fdcb54400339ec57f29bc049b1dde6229f4e38f223bd5044", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Self Reflections Hub Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QXKCSZC_Sf", "Self Reflections Hub Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Self Reflections Hub Viewed", eventProperties, "QXKCSZC_Sf", "2159908451d20aa3fdcb54400339ec57f29bc049b1dde6229f4e38f223bd5044");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Self Reflections Hub Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PeerAndUpwardFeedbackSharedProperties {
  performanceReviewCycleId: number;
  role: RoleValueType;
  method: string;
  context: string | null | undefined;
}
/**
 * Peer and Upward Feedback Shared: A manager or admin shares the completed feedback in a cycle with the subjects. They can share the feedback for one employee at a time or all at once.
 *
 * When to trigger this event:
 * 1. Manager, individual share, feedback table
A manager shares the feedback received for one direct report from the performance cycle page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS/trigger/iFR7ZJSqL
 * 2. Manager, individual share, feedback page
A user shares the received feedback for one direct report from the feedback page
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS/trigger/6wG3RX0R6
 * 3. Manager, bulk share, feedback table
A manager shares all the received feedback for his/her direct reports in a performance cycle.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS/trigger/3JCAOEWEU
 * 4. Admin, bulk share, sharing tab
An admin shares all the received feedback in a performance cycle.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS/trigger/bnFGjGWSk
 * 5. Admin, individual share, sharing tab
An admin shares the feedback received for one employee.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS/trigger/4mtaRdwA3
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.role: The role of the user viewing the page.
 * @param properties.method: What method was used to complete the action? This property is applicable wherever there's multiple ways to perform an action (e.g. creating a survey from scratch or by duplicating another)
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/DjFOgjFpcS}
 */
export function peerAndUpwardFeedbackShared(
  properties: PeerAndUpwardFeedbackSharedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "qGMJj2-Bu4y9", name: "Role", value: properties.role});
  eventPropertiesArray.push({id: "z0iUtIO_p7", name: "Method", value: properties.method});
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DjFOgjFpcS", "c8e4b88543a49616cd1cfe6404bb3d2a4216ac3b03d553e5d000d6cb6c9902a0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Peer and Upward Feedback Shared", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DjFOgjFpcS", "Peer and Upward Feedback Shared", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Peer and Upward Feedback Shared", eventProperties, "DjFOgjFpcS", "c8e4b88543a49616cd1cfe6404bb3d2a4216ac3b03d553e5d000d6cb6c9902a0");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Peer and Upward Feedback Shared", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FeedbackExportedProperties {
  context: string | null | undefined;
  managerReviewId: number;
  role: RoleValueType;
}
/**
 * Feedback Exported: User exports feedback from the Employee Profile.
 *
 * When to trigger this event:
 * 1. User clicks on the "Export last 10 feedback" button in the contextual helper.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/JkgYJyqoa9/trigger/VEHtswMYA
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.role: The role of the user viewing the page.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/JkgYJyqoa9}
 */
export function feedbackExported(properties: FeedbackExportedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.context !== undefined && properties.context !== null ?
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: properties.context}) :
    eventPropertiesArray.push({id: "B-_x0L8pUXgk", name: "Context", value: null});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  eventPropertiesArray.push({id: "qGMJj2-Bu4y9", name: "Role", value: properties.role});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JkgYJyqoa9", "492b91b4e3f87fe071d67ca75f02fcd298fe94e2a794f18a307c850d234d5de1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Feedback Exported", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JkgYJyqoa9", "Feedback Exported", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Feedback Exported", eventProperties, "JkgYJyqoa9", "492b91b4e3f87fe071d67ca75f02fcd298fe94e2a794f18a307c850d234d5de1");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Feedback Exported", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TopicAddedProperties {
  oneOn1Id: string;
  oneOn1Role: OneOn1RoleValueType | null | undefined;
  scheduleId: string | null | undefined;
  managerUserAggregateId: string | null | undefined;
  subjectUserAggregateId: string;
  topicId: string;
  medium: MediumValueType;
}
/**
 * Topic Added: A new topic is added to the 1 on 1.
 *
 * When to trigger this event:
 * 1. A user adds a topic to the 1 on 1
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/peIZYpt-51/trigger/52hwXWUAC
 *
 * @param properties the properties associatied with this event
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.oneOn1Role: Was this user a manager or subject in this 1 on 1?
 * @param properties.scheduleId: The identifier for the group of 1 on 1 meetings that are scheduled between the manager and IC.
 * @param properties.managerUserAggregateId: Aggregate ID of the manager
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.topicId: The identifier for the topic in the 1 on 1
 * @param properties.medium: Medium where the communication was received (e.g. Email, Slack, MS Teams, In-app)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/peIZYpt-51}
 */
export function topicAdded(properties: TopicAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id});
  properties.oneOn1Role !== undefined && properties.oneOn1Role !== null ?
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: properties.oneOn1Role}) :
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: null});
  properties.scheduleId !== undefined && properties.scheduleId !== null ?
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId}) :
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: null});
  properties.managerUserAggregateId !== undefined && properties.managerUserAggregateId !== null ?
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: properties.managerUserAggregateId}) :
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: null});
  eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId});
  eventPropertiesArray.push({id: "EBP5mptsR", name: "Topic ID", value: properties.topicId});
  eventPropertiesArray.push({id: "AWkO_kNI_UBw", name: "Medium", value: properties.medium});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "peIZYpt-51", "1eaee5970ee5ed44dd8592d79dcab4f1a1a2949c9221453aa2f3a4f7b324732b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Topic Added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("peIZYpt-51", "Topic Added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Topic Added", eventProperties, "peIZYpt-51", "1eaee5970ee5ed44dd8592d79dcab4f1a1a2949c9221453aa2f3a4f7b324732b");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Topic Added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TopicUpdatedProperties {
  oneOn1Id: string;
  oneOn1Role: OneOn1RoleValueType | null | undefined;
  scheduleId: string | null | undefined;
  managerUserAggregateId: string | null | undefined;
  subjectUserAggregateId: string;
  elementUpdated: string | null | undefined;
  updateAction: UpdateActionValueType;
  formatsApplied: boolean;
  topicId: string;
}
/**
 * Topic Updated: A topic element is updated in the 1 on 1.
 *
 * When to trigger this event:
 * 1. A user updates a topic within a 1 on 1 eg. updating a description.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/wxuTf63z7o/trigger/obz3iDCUv
 *
 * @param properties the properties associatied with this event
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.oneOn1Role: Was this user a manager or subject in this 1 on 1?
 * @param properties.scheduleId: The identifier for the group of 1 on 1 meetings that are scheduled between the manager and IC.
 * @param properties.managerUserAggregateId: Aggregate ID of the manager
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.elementUpdated: What the user interacted with e.g. progress bar, comment, slider, free text, agenda item
 * @param properties.updateAction: Was the element added, updated, deleted (etc)?
 * @param properties.formatsApplied: Were any formats applied to the 1 on 1 Checkin via the rich-text editor?
 * @param properties.topicId: The identifier for the topic in the 1 on 1
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/wxuTf63z7o}
 */
export function topicUpdated(properties: TopicUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id});
  properties.oneOn1Role !== undefined && properties.oneOn1Role !== null ?
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: properties.oneOn1Role}) :
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: null});
  properties.scheduleId !== undefined && properties.scheduleId !== null ?
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId}) :
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: null});
  properties.managerUserAggregateId !== undefined && properties.managerUserAggregateId !== null ?
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: properties.managerUserAggregateId}) :
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: null});
  eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId});
  properties.elementUpdated !== undefined && properties.elementUpdated !== null ?
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: properties.elementUpdated}) :
    eventPropertiesArray.push({id: "6BWNg4JekUxP", name: "Element updated", value: null});
  eventPropertiesArray.push({id: "csgsy7OIlQ", name: "Update action", value: properties.updateAction});
  eventPropertiesArray.push({id: "il__CF4jB", name: "Formats applied", value: properties.formatsApplied});
  eventPropertiesArray.push({id: "EBP5mptsR", name: "Topic ID", value: properties.topicId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wxuTf63z7o", "eddecf803e6fd0e4cf279b5ce35ed3ba1603e0d120afb8c9e03c2d50c1360398", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Topic Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wxuTf63z7o", "Topic Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Topic Updated", eventProperties, "wxuTf63z7o", "eddecf803e6fd0e4cf279b5ce35ed3ba1603e0d120afb8c9e03c2d50c1360398");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Topic Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TopicDeletedProperties {
  oneOn1Id: string;
  oneOn1Role: OneOn1RoleValueType | null | undefined;
  managerUserAggregateId: string | null | undefined;
  subjectUserAggregateId: string;
  scheduleId: string | null | undefined;
  topicId: string;
}
/**
 * Topic Deleted: A topic topic is deleted from a 1 on 1.
 *
 * When to trigger this event:
 * 1. A topic is deleted from a 1 on 1.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/iWCOLEjQhu/trigger/UjWsMlm5R
 *
 * @param properties the properties associatied with this event
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.oneOn1Role: Was this user a manager or subject in this 1 on 1?
 * @param properties.managerUserAggregateId: Aggregate ID of the manager
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.scheduleId: The identifier for the group of 1 on 1 meetings that are scheduled between the manager and IC.
 * @param properties.topicId: The identifier for the topic in the 1 on 1
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/iWCOLEjQhu}
 */
export function topicDeleted(properties: TopicDeletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id});
  properties.oneOn1Role !== undefined && properties.oneOn1Role !== null ?
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: properties.oneOn1Role}) :
    eventPropertiesArray.push({id: "GNy2dQYpw6x_", name: "1 on 1 role", value: null});
  properties.managerUserAggregateId !== undefined && properties.managerUserAggregateId !== null ?
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: properties.managerUserAggregateId}) :
    eventPropertiesArray.push({id: "ig7Dhpige6hK", name: "Manager user aggregate ID", value: null});
  eventPropertiesArray.push({id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId});
  properties.scheduleId !== undefined && properties.scheduleId !== null ?
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId}) :
    eventPropertiesArray.push({id: "FA6r6vYeA9s0", name: "Schedule ID", value: null});
  eventPropertiesArray.push({id: "EBP5mptsR", name: "Topic ID", value: properties.topicId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "iWCOLEjQhu", "8664331e438a40c5adee8fd8398b07967fdb6d46bc238ae26ceeb13db565c29e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Topic Deleted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("iWCOLEjQhu", "Topic Deleted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Topic Deleted", eventProperties, "iWCOLEjQhu", "8664331e438a40c5adee8fd8398b07967fdb6d46bc238ae26ceeb13db565c29e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Topic Deleted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CollaboratorManagerReviewViewedProperties {
  navigationSource: string;
  managerReviewId: number;
}
/**
 * Collaborator Manager Review Viewed: A user/collaborator views the manager review for a user and can add comments. The comments are visible to the manager but not the subject of the manager review.
 *
 * When to trigger this event:
 * 1. A user views the manager review as a collaborator.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/GLGpjq2GHP/trigger/-G77PP0yZ
 *
 * @param properties the properties associatied with this event
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/GLGpjq2GHP}
 */
export function collaboratorManagerReviewViewed(
  properties: CollaboratorManagerReviewViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "GLGpjq2GHP", "79f5a9c8778eb13b4ff51251d6cfd74a94aec146ae2eafdab4ed3317c26c46dc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Collaborator Manager Review Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("GLGpjq2GHP", "Collaborator Manager Review Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Collaborator Manager Review Viewed", eventProperties, "GLGpjq2GHP", "79f5a9c8778eb13b4ff51251d6cfd74a94aec146ae2eafdab4ed3317c26c46dc");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Collaborator Manager Review Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CollaboratorManagerReviewSubmittedProperties {
  navigationSource: string;
  managerReviewId: number;
}
/**
 * Collaborator Manager Review Submitted: A user/collaborator submits comments on the manager review.
 *
 * When to trigger this event:
 * 1. A user/collaborator shares their comments with the manager by clicking 'Share comments'.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/I8YNzrBw8O/trigger/AQQ4-UL2E
 *
 * @param properties the properties associatied with this event
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/I8YNzrBw8O}
 */
export function collaboratorManagerReviewSubmitted(
  properties: CollaboratorManagerReviewSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource});
  eventPropertiesArray.push({id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "I8YNzrBw8O", "8770119abd35a2e737a1ef04029ba23d742763d23519a8afdcd725bd606da85f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Collaborator Manager Review Submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("I8YNzrBw8O", "Collaborator Manager Review Submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Collaborator Manager Review Submitted", eventProperties, "I8YNzrBw8O", "8770119abd35a2e737a1ef04029ba23d742763d23519a8afdcd725bd606da85f");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Collaborator Manager Review Submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FeedbackSharingPermissionsUpdatedProperties {
  performanceReviewCycleId: number;
  sharingEnabled: boolean;
}
/**
 * Feedback Sharing Permissions Updated: An admin enables or disables sharing the peer and upward feedback review for a particular performance review cycle.
 *
 * When to trigger this event:
 * 1. When sharing is turned off, users click the "Turn on" button
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2DlvTWfRoT/trigger/Vb0jj3UJ-
 * 2. If sharing is turn on, user clicks the "Turn off" button
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2DlvTWfRoT/trigger/EbTDgQylv
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.sharingEnabled: Is sharing enabled after this interaction?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/branches/CTRB01xYK/events/2DlvTWfRoT}
 */
export function feedbackSharingPermissionsUpdated(
  properties: FeedbackSharingPermissionsUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId});
  eventPropertiesArray.push({id: "c6gTY2cWWtSP", name: "Sharing enabled", value: properties.sharingEnabled});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2DlvTWfRoT", "424207cbb2fe98af2679a58e07194e2c6562ea10b12cba804ba691e553e0672b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Feedback Sharing Permissions Updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2DlvTWfRoT", "Feedback Sharing Permissions Updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Feedback Sharing Permissions Updated", eventProperties, "2DlvTWfRoT", "424207cbb2fe98af2679a58e07194e2c6562ea10b12cba804ba691e553e0672b");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Feedback Sharing Permissions Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  PerformanceQuestionType,
  GoalPriority,
  ExperimentVariations1On1AgendaCheckBoxAndReorder,
  FeedbackType,
  Medium,
  OneOn1Role,
  Visibility,
  GoalType,
  UpdateAction,
  Role,
  IsAlignedTo,
  calibrationViewEdited,
  calibrationViewSharingUpdated,
  calibrationViewViewed,
  managerReviewSubmitted,
  companyGoalsIndexViewed,
  individualGoalsIndexViewed,
  performanceGoalCreated,
  performanceGoalUpdated,
  oneOn1CheckInUpdated,
  oneOn1SpaceViewed,
  quickCommentFormViewed,
  directReportsSelfReflectionsIndexViewed,
  selfReflectionsIndexViewed,
  outboundDestinationVisited,
  calibrationFlagToggled,
  calibrationViewPaginationSizeChanged,
  managerReviewViewed,
  employeeProfileViewed,
  calibrationViewStatisticsInteracted,
  calibrationViewExported,
  calibrationViewManagerRatingChanged,
  createCalibrationViewStarted,
  calibrationViewCreated,
  calibrationViewPublished,
  publishCalibrationModalViewed,
  managerReviewDashboardColumnsChanged,
  directReportsPerformanceReviewCycleViewed,
  adminCalibrationNotesOpened,
  calibrationNoteSaved,
  managerCalibrationNotesViewed,
  managerReviewSharingPermissionsUpdated,
  goalTreeExpanded,
  performanceGoalViewed,
  copyGoalPageViewed,
  calibrationViewFullWidthToggled,
  managerReviewAcknowledged,
  calendarSyncPageViewed,
  setUp1On1CalendarIntegrationModalViewed,
  calendarEventPickerModalViewed,
  oneOn1AndCalendarEventConnected,
  oneOn1NoCalendarMatchMessageViewed,
  calendarIntegrationSetUpCompleted,
  oneOn1AndCalendarEventDisconnected,
  itemCopiedToClipboard,
  widgetExpanded,
  calendarIntegrationDisconnected,
  performanceRatingQuestionWarningIgnored,
  performanceRatingQuestionWarningViewed,
  tabFiltered,
  calendarInformationModalViewed,
  calendarAuthenticationInitiated,
  contentSavedToEmployeeProfile,
  contentViewedInSource,
  peerOrUpwardFeedbackRequestWithdrawn,
  performanceReviewsIndexViewed,
  performanceReviewHubViewed,
  selfReflectionsHubViewed,
  peerAndUpwardFeedbackShared,
  feedbackExported,
  topicAdded,
  topicUpdated,
  topicDeleted,
  collaboratorManagerReviewViewed,
  collaboratorManagerReviewSubmitted,
  feedbackSharingPermissionsUpdated,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["calibrationViewEdited","calibrationViewSharingUpdated","calibrationViewViewed","managerReviewSubmitted","companyGoalsIndexViewed","individualGoalsIndexViewed","performanceGoalCreated","performanceGoalUpdated","oneOn1CheckInUpdated","oneOn1SpaceViewed","quickCommentFormViewed","directReportsSelfReflectionsIndexViewed","selfReflectionsIndexViewed","outboundDestinationVisited","calibrationFlagToggled","calibrationViewPaginationSizeChanged","managerReviewViewed","employeeProfileViewed","calibrationViewStatisticsInteracted","calibrationViewExported","calibrationViewManagerRatingChanged","createCalibrationViewStarted","calibrationViewCreated","calibrationViewPublished","publishCalibrationModalViewed","managerReviewDashboardColumnsChanged","directReportsPerformanceReviewCycleViewed","adminCalibrationNotesOpened","calibrationNoteSaved","managerCalibrationNotesViewed","managerReviewSharingPermissionsUpdated","goalTreeExpanded","performanceGoalViewed","copyGoalPageViewed","calibrationViewFullWidthToggled","managerReviewAcknowledged","calendarSyncPageViewed","setUp1On1CalendarIntegrationModalViewed","calendarEventPickerModalViewed","oneOn1AndCalendarEventConnected","oneOn1NoCalendarMatchMessageViewed","calendarIntegrationSetUpCompleted","oneOn1AndCalendarEventDisconnected","itemCopiedToClipboard","widgetExpanded","calendarIntegrationDisconnected","performanceRatingQuestionWarningIgnored","performanceRatingQuestionWarningViewed","tabFiltered","calendarInformationModalViewed","calendarAuthenticationInitiated","contentSavedToEmployeeProfile","contentViewedInSource","peerOrUpwardFeedbackRequestWithdrawn","performanceReviewsIndexViewed","performanceReviewHubViewed","selfReflectionsHubViewed","peerAndUpwardFeedbackShared","feedbackExported","topicAdded","topicUpdated","topicDeleted","collaboratorManagerReviewViewed","collaboratorManagerReviewSubmitted","feedbackSharingPermissionsUpdated"]
