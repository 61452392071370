import useCurrentUser from "@Domain/auth/useCurrentUser"
import ErrorPage from "@Entry/ErrorPage/ErrorPage"
import { EnumFlagValues } from "@Constants/launchDarklyFlags"
import { useFlags } from "@cultureamp/ca-launchdarkly/react"
import React, { useLayoutEffect } from "react"
import { RouteComponentProps } from "react-router-dom"

export const PRCAppRedirect = ({
  appBasePath,
  ...rest
}: {
  appBasePath: string
} & RouteComponentProps) => {
  const isMigrating =
    useFlags<EnumFlagValues>()["performanceSelfReflectionsRebuild"] ===
    "MIGRATING"

  const isUnavailable =
    useFlags<EnumFlagValues>()["performanceSelfReflectionsRebuild"] ===
    "UNAVAILABLE"

  const migratingOrUnavailable = isMigrating || isUnavailable

  const user = useCurrentUser()
  const appUrl = `https://${window.location.host}${appBasePath}/${user.company.account_id}${rest.location.pathname}${rest.location.search}`

  useLayoutEffect(() => {
    if (!migratingOrUnavailable) {
      window.location.replace(appUrl)
    }
  }, [appUrl, migratingOrUnavailable])

  // TODO confirm wording
  return migratingOrUnavailable ? (
    <ErrorPage
      code={503}
      title="We're working on some improvements"
      message={
        <>
          Sorry, Culture Amp is not available because we&apos;re doing some
          important maintenance work. Please try again later.
        </>
      }
      includeCTA
    />
  ) : null
}
