import React from "react"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import { Button } from "@kaizen/button"
import { Box } from "@kaizen/component-library"
import { LoadingParagraph } from "@kaizen/loading-skeleton"
import type { CalibrationColumnInstance } from "@Calibrations/v2/types/Tables"
import { ManageColumnsModal } from "@Calibrations/v2/components/ManageColumnsModal/ManageColumnsModal"
import styles from "./ManageColumns.scss"

export const ManageColumns = ({
  columns,
}: {
  columns: CalibrationColumnInstance[]
}) => {
  const { formatMessage } = useIntl()

  const [isShowingModal, setIsShowingModal] = React.useState(false)

  // The list of columns that will appear in the `manage column` dropdown
  const manageableColumns = React.useMemo(
    () => columns.filter((column) => column.canToggleVisibility),
    [columns]
  )

  if (!manageableColumns) {
    return null
  }

  return (
    <>
      <Button
        secondary
        label={formatMessage(strings.components.ManageColumns.buttonLabel)}
        onClick={() => setIsShowingModal(true)}
      />
      {isShowingModal && (
        <ManageColumnsModal
          columns={columns}
          onCancel={() => setIsShowingModal(false)}
          onSubmit={() => setIsShowingModal(false)}
        />
      )}
    </>
  )
}

export const ManageColumnsLoading = () => (
  <Box my={1}>
    <div className={styles.buttonContainer}>
      <LoadingParagraph isInline width={30} />
    </div>
  </Box>
)
