import * as React from "react"
import { FormattedNumber } from "react-intl"

type AverageRatingCellProps = {
  value: number | null
}

export const AverageRatingCell: React.FC<AverageRatingCellProps> = ({
  value,
}) => {
  return value ? (
    <FormattedNumber
      value={value}
      minimumFractionDigits={1}
      maximumFractionDigits={1}
    />
  ) : (
    <>-</>
  )
}
