import * as React from "react"
import { useLocalStorage } from "@Calibrations/hooks/useLocalStorage"
import minimize from "@Static/assets/minimize.icon.svg"
import maximize from "@Static/assets/maximize.icon.svg"
import { IconButton } from "@kaizen/button"
import { Tooltip } from "@kaizen/draft-tooltip"
import strings from "@Calibrations/locale/strings"
import { useIntl } from "react-intl"
import Avo from "@Avo/Avo"

export const CalibrationViewToolbar: React.FC<{
  calibrationViewId?: number
}> = ({ children, calibrationViewId }) => {
  const { formatMessage } = useIntl()

  const [fullWidthState, setFullWidthState] = useLocalStorage(
    "calibrations.fullWidthState",
    false
  )

  const toggleFullWidthState = (value: boolean) => {
    setFullWidthState(value)
    Avo.calibrationViewFullWidthToggled({
      viewedInFullWidth: value,
      calibrationViewId,
    })
  }

  return (
    <div className="flex flex-row">
      <div className="flex w-full justify-between items-center font-weight-paragraph-bold">
        {children}
      </div>
      <div className="items-center">
        {fullWidthState ? (
          <Tooltip
            text={formatMessage(
              strings.components.FullWidthToggle.minimizeTooltip
            )}
          >
            <IconButton
              onClick={() => toggleFullWidthState(false)}
              data-automation-id="full-width-toggle"
              icon={minimize}
              label={formatMessage(
                strings.components.FullWidthToggle.minimizeIcon
              )}
            />
          </Tooltip>
        ) : (
          <Tooltip
            text={formatMessage(
              strings.components.FullWidthToggle.maximizeTooltip
            )}
          >
            <IconButton
              onClick={() => toggleFullWidthState(true)}
              data-automation-id="full-width-toggle"
              icon={maximize}
              label={formatMessage(
                strings.components.FullWidthToggle.maximizeIcon
              )}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
}
