/* eslint-disable import/no-named-as-default-member, import/default, import/named */
import qs from "query-string"
import React, { FunctionComponent, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { buildUrl } from "@Utils/url"

type GoalDossier = {
  isOpen: boolean
  open: (goalId: string, effectiveOwnerId?: string) => void
  close: () => void
  goalId: string | string[] | null
  effectiveOwnerId?: string | string[] | null
}

export const useGoalDossier = (): GoalDossier => {
  const history = useHistory()
  const location = useLocation()
  const { goalId, effectiveOwnerId } = qs.parse(location.search)

  const open = useCallback(
    (goalId: string, effectiveOwnerId?: string) => {
      const params = qs.parse(location.search)
      history.push(
        buildUrl(location.pathname, {
          ...params,
          goalId,
          effectiveOwnerId,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  const close = useCallback(() => {
    const { goalId, effectiveOwnerId, ...restOfParams } = qs.parse(
      location.search
    )

    history.push(
      buildUrl(location.pathname, {
        ...restOfParams,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return { isOpen: !!goalId, open, close, goalId, effectiveOwnerId }
}

export function withGoalDossier<P>(
  WrappedComponent: React.ComponentType<
    P & { goalDossier: GoalDossier | null | undefined }
  >
): React.ComponentType<P> {
  const WithGoalDossier: FunctionComponent<P> = (props: P) => {
    const goalDossier = useGoalDossier()
    return <WrappedComponent goalDossier={goalDossier} {...props} />
  }
  return WithGoalDossier
}

export default useGoalDossier
