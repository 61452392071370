import { useCallback, useEffect, useMemo, useState } from "react"

export const useCarousel = <T>({
  items,
  initialPage,
}: {
  items: Array<T>
  initialPage?: number
}) => {
  const [carouselWidth, setCarouselWidth] = useState(0)
  const [node, setNode] = useState<HTMLDivElement | null>(null)

  const refCallback = useCallback((refNode) => {
    if (refNode !== null) {
      setCarouselWidth(refNode.getBoundingClientRect().width)
      setNode(refNode)
    }
    return refNode
  }, [])

  useEffect(() => {
    const measure = () => {
      if (node) {
        setCarouselWidth(node.getBoundingClientRect().width)
      }
    }
    window.addEventListener("resize", measure)

    return () => {
      window.removeEventListener("resize", measure)
    }
  }, [node])

  const carouselItemSize = 250
  const [carouselPage, setCarouselPage] = useState(
    initialPage ? initialPage - 1 : 0
  )

  const perRowCount = useMemo(() => {
    if (carouselWidth / carouselItemSize > 5) {
      return 5
    }
    return Math.round(carouselWidth / carouselItemSize)
  }, [carouselWidth])

  let start = carouselPage
  let end = perRowCount + carouselPage

  if (items.length >= perRowCount) {
    while (end > items.length) {
      start = start - 1
      end = perRowCount + start
    }
  }

  if (perRowCount > items.length) {
    start = 0
    end = items.length
  }

  const visibleItems = useMemo((): Array<T> => {
    return items.slice(start, end)
  }, [start, end, items])

  const hasNext = end < items.length
  const hasPrev = start > 0

  const setNextPage = () => {
    if (!hasNext) {
      return
    }
    setCarouselPage(carouselPage + 1)
  }

  const setPrevPage = () => {
    if (!hasPrev) {
      return
    }
    setCarouselPage(carouselPage - 1)
  }

  return {
    refCallback,
    visibleItems,
    nextPage: setNextPage,
    prevPage: setPrevPage,
    hasNext,
    hasPrev,
  }
}
