import { perfApiGet } from "@API/utils"
import { useQuery } from "@tanstack/react-query"
import { QueryKeys } from "@Calibrations/v2/queries/keys"
import { Calibrations } from "../../../../bff/upstreams"
import { CalibrationViewQueryParams } from "../hooks/useCalibrationViewQueryParams"

const fetchSharedWithUsers = async ({
  calibrationViewId,
  query,
  userIds,
  perPage,
  page,
  include_current_user = true,
  include_deactivated_users = false,
}: {
  calibrationViewId: number
  query?: string
  userIds?: number[]
  perPage?: number
  page?: number
  include_current_user?: boolean
  include_deactivated_users?: boolean
}): Promise<Calibrations["calibrations.User"][]> => {
  const res = await perfApiGet<Calibrations["calibrations.Users"]>(
    "/dashboard/calibrations/:calibrationId/shared_users",
    {
      params: {
        calibrationId: calibrationViewId,
        per_page: perPage,
        page,
        q: query,
        user_ids: userIds && userIds.join(","),
        include_current_user: include_current_user.toString(),
        include_deactivated_users: include_deactivated_users.toString(),
      },
    }
  )

  return res.data.users
}

export const useFetchSharedWithUsers = ({
  calibrationViewId,
}: {
  calibrationViewId: number
}) => {
  return useQuery(
    [
      QueryKeys.AdminCalibrationViewsDetailsSharing.SharedWithUsers,
      calibrationViewId,
    ],
    () => fetchSharedWithUsers({ calibrationViewId })
  )
}

export const fetchSharingCandidates = async (
  searchString?: string
): Promise<Calibrations["calibrations.User"][]> => {
  const res = await perfApiGet<Calibrations["calibrations.PaginatedUsers"]>(
    "/dashboard/calibration/users_in_company",
    {
      params: {
        searchString,
      },
    }
  )
  return res.data.users
}

export const useFetchSharingCandidates = () => {
  return useQuery(
    [QueryKeys.AdminCalibrationViewsDetailsSharing.SharingCandidates],
    () => fetchSharingCandidates()
  )
}

export const fetchCalibrationSnapshotUsers = async ({
  calibrationViewId,
  searchString,
  userIds,
}: {
  calibrationViewId: number
  searchString?: string
  userIds?: Array<number | null>
}): Promise<Calibrations["calibrations.User"][]> => {
  const res = await perfApiGet<Calibrations["filters.UserFilter"]>(
    `/dashboard/calibrations/${calibrationViewId}/snapshot_users`,
    {
      params: {
        searchString,
        userIds: userIds?.join(","),
      },
    }
  )
  return res.data.filterItems
}

export const fetchCalibrationUsers = async ({
  searchString,
  managerReviewCycleIds,
  userIds,
}: {
  searchString?: string
  managerReviewCycleIds?: CalibrationViewQueryParams["managerReviewCycleIds"]
  userIds?: Array<number | null>
}) => {
  const res = await perfApiGet<Calibrations["filters.UserFilter"]>(
    `/dashboard/calibrations/users`,
    {
      params: {
        searchString,
        managerReviewCycleIds: managerReviewCycleIds?.join(","),
        userIds: userIds?.join(","),
      },
    }
  )

  return res.data.filterItems
}
