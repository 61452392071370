import React, { useContext } from "react"
import { hasLaunchDarklyFlag } from "@Domain/user/user"
import LaunchDarklyFeatureFlags from "@Constants/launchDarklyFlags"
import { ErrorPage503Maintenance } from "@Calibrations/modals/ErrorPage/ErrorPage503Maintenance"
import { CurrentUserContext } from "./currentUser"

export const MaintenanceModeProvider: React.FunctionComponent = ({
  children,
}) => {
  const currentUser = useContext(CurrentUserContext)

  if (!currentUser) return <> {children} </>

  const isInMaintenance =
    hasLaunchDarklyFlag(
      currentUser,
      LaunchDarklyFeatureFlags.performanceMaintenanceWindow,
      true
    ) && !(currentUser.is_masquerading ?? false)

  return isInMaintenance ? <ErrorPage503Maintenance /> : <> {children} </>
}
