import { useIntl } from "@Common/locale/useIntl"
import { useSearchParams } from "@Calibrations/v2/hooks/useSearchParams"
import {
  useGetReviewResultsMeta,
  useGetSnapshotReviewResultsMeta,
  useGetNonAdminReviewResultsMeta,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { Pagination } from "@kaizen/pagination"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { Select } from "@kaizen/draft-select"
import { Box } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
const {
  components: { CalibrationTable: ComponentStrings },
} = strings
import * as React from "react"
import strings from "@Calibrations/locale/strings"
import { Calibrations } from "bff/upstreams"

const TablePagination = ({
  meta,
}: {
  meta: Calibrations["calibrations.Meta"]
}) => {
  const { formatMessage } = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = searchParams.get("perPage")
    ? Number(searchParams.get("perPage"))
    : 25

  return (
    <div className="mt-16 flex items-center justify-center relative">
      <Pagination
        currentPage={Number(searchParams.get("page") || 1)}
        ariaLabelNextPage={formatMessage(ComponentStrings.pagination.nextPage)}
        ariaLabelPage={formatMessage(ComponentStrings.pagination.pageLabel)}
        ariaLabelPreviousPage={formatMessage(
          ComponentStrings.pagination.previousPage
        )}
        pageCount={meta.pagination.totalPages}
        onPageChange={(newPage: number) => {
          setSearchParams(
            new URLSearchParams({
              ...Object.fromEntries(searchParams),
              page: newPage.toString(),
            })
          )
        }}
      />
      <PerPageDropdown perPage={perPage} />
    </div>
  )
}

export const CalibrationTablePagination = () => {
  const { data: meta } = useGetReviewResultsMeta()

  if (!meta) {
    return null
  }
  if (meta.pagination.totalCount === 0) {
    return null
  }
  return <TablePagination meta={meta} />
}

export const SnapshotCalibrationTablePagination = () => {
  const { data: meta } = useGetSnapshotReviewResultsMeta()

  if (!meta) {
    return null
  }
  if (meta.pagination.totalCount === 0) {
    return null
  }
  return <TablePagination meta={meta} />
}

export const NonAdminCalibrationTablePagination = () => {
  const { data: meta } = useGetNonAdminReviewResultsMeta()

  if (!meta) {
    return null
  }
  if (meta.pagination.totalCount === 0) {
    return null
  }
  return <TablePagination meta={meta} />
}

const PerPageDropdown = ({ perPage }: { perPage: number }) => {
  const [, setQueryParams] = useCalibrationViewQueryParams()
  const { formatMessage } = useIntl()

  type Options = {
    value: number
    label: string
  }

  const options: Options[] = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
  ]

  const updatePageAndPerPageURL = (newPage: number, newPerPage: number) => {
    setQueryParams({
      page: newPage,
      perPage: newPerPage,
      dossierNotesReviewId: undefined,
      dossierEmployeeId: undefined,
    })
  }

  return (
    <div className="flex justify-center items-center absolute top-1/2 right-0 -translate-y-1/2">
      <Select
        inputId="selectPerPage"
        name="selectPerPage"
        isSearchable={false}
        variant="secondary"
        options={options}
        onChange={(newPerPage: Options) =>
          updatePageAndPerPageURL(1, newPerPage.value)
        }
        value={{ value: perPage, label: `${perPage}` }}
        defaultValue={{ value: perPage, label: `${perPage}` }}
      />
      <Box ml={0.25}>
        <Paragraph variant="body">
          {formatMessage(ComponentStrings.pagination.perPage)}
        </Paragraph>
      </Box>
    </div>
  )
}
