import * as React from "react"
import { CheckboxField } from "@kaizen/draft-form"
import { FilterMenuButton } from "@kaizen/draft-filter-menu-button"
import { Divider } from "@kaizen/draft-divider"
import _ from "lodash"
import { useIntl } from "@Common/locale/useIntl"
import styles from "./FilterSelectDropdown.scss"
import strings from "../../locale/strings"
import { BasicSelectOptions } from "./BasicSelectOptions"
import { GroupSelectOptions, GroupDropdownOption } from "./GroupSelectOptions"
import { SearchBoxNotForUse as SearchBox } from "../SearchBoxNotForUse/SearchBoxNotForUse"
import FilterSelectDropdownLoading from "./FilterSelectDropdownLoading"
import {
  NestedSelectOptions,
  NestedDropdownOption,
} from "./NestedSelectOptions"

export type DropdownOption<T> = {
  label: string
  value: T
}
interface CommonProps<T> {
  id: string
  label?: string
  value: Array<DropdownOption<T>>
  onChange: (options: Array<DropdownOption<T>>) => void
  onSelectAll: (options: Array<DropdownOption<T>>) => void
  onClearButtonClick?: () => void
  loading?: boolean
  isSearchable?: boolean
  searchValue?: string
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
  mode: "basic" | "group" | "nested"
  hideAllCheckbox?: boolean
  noOptionsMessage?: string
}

interface BasicSelectProps<T> extends CommonProps<T> {
  mode: "basic"
  options: Array<DropdownOption<T>>
}

interface GroupSelectProps<T> extends CommonProps<T> {
  mode: "group"
  options: Array<GroupDropdownOption<T>>
}

interface NestedSelectProps<T> extends CommonProps<T> {
  mode: "nested"
  options: Array<NestedDropdownOption<T>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FilterSelectDropdown = <T extends any>(
  props: BasicSelectProps<T> | GroupSelectProps<T> | NestedSelectProps<T>
) => {
  const { formatMessage } = useIntl()
  const {
    id,
    label,
    value,
    onChange,
    onSelectAll,
    onClearButtonClick,
    noOptionsMessage,
  } = props
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false)
  const [filterMetadata, setFilterMetadata] = React.useState("")
  const [savedFilters, setSavedFilters] =
    React.useState<Array<DropdownOption<T>>>(value)
  const [selectedFilters, setSelectedFilters] = React.useState(value)

  React.useEffect(() => {
    let metadataText = ""
    // Only show the first 3 of the options and use number to represent the rest of the selected options
    if (selectedFilters.length > 3) {
      const joinMetadataString = selectedFilters
        .slice(0, 3)
        .map((option) => option.label)
        .join(", ")

      metadataText = formatMessage(
        strings.filters.filterDropdown.metadataText,
        {
          selectedOptions: joinMetadataString,
          nextSelectedOptionsCount: selectedFilters.length - 3,
        }
      )
    } else {
      metadataText = selectedFilters.map((option) => option.label).join(", ")
    }

    setFilterMetadata(metadataText)
  }, [selectedFilters, formatMessage])

  React.useEffect(() => {
    // Observe the value props and only update the internal state if the value become an empty array
    // This logic is used to allow the consumers to reset the value (e.g. Clear all button in the FilterBar component)
    if (value.length === 0) {
      setSelectedFilters([])
      setSavedFilters([])
    }
  }, [value])

  const hideDropdown = () => {
    // When the dropdopwn menu is closed, we store this savedFilters state so that we can render it
    // at the top of the dropdown options
    setSavedFilters(selectedFilters)
    setIsDropdownVisible(false)
  }

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const onCheckboxChange = (option: DropdownOption<T>) => {
    const [optionsToKeep, optionsToRemove] = _.partition(
      selectedFilters,
      (filter) => filter.value !== option.value
    )
    const updatedSelectedOptions =
      optionsToRemove.length > 0 ? optionsToKeep : [...optionsToKeep, option]
    setSelectedFilters(updatedSelectedOptions)
    onChange(updatedSelectedOptions)
  }

  const onNestedDropdownCheckboxOnChange = (
    options: Array<DropdownOption<T>>
  ) => {
    setSelectedFilters(options)
    onChange(options)
  }

  const handleSelectAllCheckboxOnClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedOptions = [] as DropdownOption<T>[]
    if (e.currentTarget.value === "off") {
      if (props.mode === "group") {
        // If it's group options, it needs to be flattened first
        selectedOptions = Array.prototype.concat(
          ...props.options.map((option) => [...option.options])
        )
      } else {
        selectedOptions = props.options
      }

      if (props.mode === "nested") {
        selectedOptions = props.options
          .map((option) => {
            if (option.options && option.options.length > 0) {
              return option.options.map((val) => {
                return {
                  label: `${option.label}: ${val.label}`,
                  value: val.value,
                }
              })
            }
            return option
          })
          .flat()
      }
    } else if (e.currentTarget.value === "on") {
      selectedOptions = []
    }

    setSelectedFilters(selectedOptions)
    setSavedFilters(selectedOptions)
    onSelectAll(selectedOptions)
  }

  const handleOnFilterClear = () => {
    setSelectedFilters([])
    setSavedFilters([])
    if (onClearButtonClick) onClearButtonClick()
  }

  const isAllOptionsChecked = () => {
    if (props.mode === "group") {
      return (
        props.options
          .map((option) => option.options.length)
          .reduce((a, b) => a + b, 0) === selectedFilters.length
      )
    }
    if (props.mode === "nested") {
      return (
        props.options
          .map((option) => {
            // If the option has child we need to count the child length and exclude the parent
            if (option.options && option.options.length > 0) {
              return option.options.length
            }
            return 1
          })
          .reduce((a, b) => a + b, 0) === selectedFilters.length
      )
    }
    return selectedFilters.length === props.options.length
  }

  React.useEffect(() => {
    // When options change (due to a user searching), update savedFilters to only include selected filters
    setSavedFilters(selectedFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options])

  const displaySelectAllOptions =
    (!props.isSearchable || props.searchValue === "") && !props.hideAllCheckbox

  return (
    <FilterMenuButton
      id={id}
      labelText={label || ""}
      metadata={filterMetadata}
      isDropdownVisible={isDropdownVisible}
      toggleDropdown={toggleDropdown}
      hideDropdown={hideDropdown}
      autoHide="off"
      onFilterClear={onClearButtonClick ? handleOnFilterClear : undefined}
    >
      <>
        {props.isSearchable && (
          <div className={styles.searchBox}>
            <SearchBox
              id="search"
              onChange={props.onSearch}
              inputValue={props.searchValue}
              working={props.loading}
            />
          </div>
        )}

        {props.loading ? (
          <FilterSelectDropdownLoading />
        ) : (
          <>
            {props.options.length < 1 && (
              <div className={styles.emptyState}>{noOptionsMessage}</div>
            )}
            {displaySelectAllOptions && (
              <>
                <div className={styles.selectAction}>
                  <CheckboxField
                    onCheck={handleSelectAllCheckboxOnClick}
                    id="checkbox-select-all"
                    checkedStatus={isAllOptionsChecked() ? "on" : "off"}
                    labelText={formatMessage(strings.general.all)}
                  />
                </div>
                <Divider variant="content" />
              </>
            )}
            <div className={styles.content}>
              {props.mode === "group" && (
                <GroupSelectOptions
                  groupOptions={props.options}
                  selectedFilterOption={selectedFilters}
                  savedFilterOptions={savedFilters}
                  onCheckboxChange={onCheckboxChange}
                />
              )}
              {props.mode === "nested" && (
                <NestedSelectOptions
                  selectedFilterOption={selectedFilters}
                  onCheckboxChange={onNestedDropdownCheckboxOnChange}
                  savedFilterOptions={savedFilters}
                  options={props.options}
                />
              )}
              {props.mode === "basic" && (
                <BasicSelectOptions
                  options={props.options}
                  selectedFilterOption={selectedFilters}
                  savedFilterOptions={savedFilters}
                  onCheckboxChange={onCheckboxChange}
                />
              )}
            </div>
          </>
        )}
      </>
    </FilterMenuButton>
  )
}
