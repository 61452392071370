import { useQuery } from "@tanstack/react-query"
import { perfApiGet } from "@API/utils"
import { CalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { Calibrations } from "bff/upstreams"
import { transformParams } from "@Calibrations/v2/queries/useGetReviewResults"
import { FilterQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { QueryKeys } from "./keys"

export type HeroStatsResponse = Calibrations["heroStats.StatsSummaries"]
export type AssessmentGroupHeroStat = Calibrations["heroStats.AssessmentGroup"]
export type StatusHeroStat = Calibrations["heroStats.ManagerReviewStatus"]

const fetchSnapshotCalibrationHeroStats = async ({
  calibrationViewId,
  filters,
}: {
  calibrationViewId?: number
  filters: CalibrationViewQueryParams
}) => {
  const res = await perfApiGet<HeroStatsResponse>(
    "/dashboard/calibrations/:calibrationId/snapshot_manager_reviews/stats",
    {
      params: {
        calibrationId: calibrationViewId,
        ...transformParams(filters),
      },
    }
  )
  return res.data.statistics
}

export const useGetSnapshotHeroStats = ({
  calibrationViewId,
  filters,
}: {
  calibrationViewId?: number
  filters: CalibrationViewQueryParams
}) => {
  // The only filters we want to check when deciding to refetch
  // the hero stats are filters that affect the hero stats data.
  // E.g. ignore if the sort order changes, visible columns, etc.
  const heroStatsQueryDependencyArray = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) =>
      Object.keys(FilterQueryParams).includes(key)
    )
  )

  return useQuery(
    [
      QueryKeys.AdminCalibrationViewHeroStats,
      calibrationViewId,
      heroStatsQueryDependencyArray,
    ],
    () => fetchSnapshotCalibrationHeroStats({ calibrationViewId, filters })
  )
}

const fetchCalibrationHeroStats = async ({
  filters,
}: {
  filters: CalibrationViewQueryParams
}) => {
  const res = await perfApiGet<HeroStatsResponse>(
    "/dashboard/calibrations/manager_reviews/stats",
    {
      params: {
        ...transformParams(filters),
      },
    }
  )
  return res.data.statistics
}

export const useGetHeroStats = ({
  filters,
}: {
  filters: CalibrationViewQueryParams
}) => {
  // The only filters we want to check when deciding to refetch
  // the hero stats are filters that affect the hero stats data.
  // E.g. ignore if the sort order changes, visible columns, etc.
  const heroStatsQueryDependencyArray = Object.fromEntries(
    Object.entries(filters).filter(([key, val]) =>
      Object.keys(FilterQueryParams).includes(key)
    )
  )

  return useQuery(
    [QueryKeys.AdminCalibrationCreateHeroStats, heroStatsQueryDependencyArray],
    () => fetchCalibrationHeroStats({ filters })
  )
}

const fetchNonAdminCalibrationHeroStats = async ({
  calibrationViewId,
}: {
  calibrationViewId?: number
}) => {
  const res = await perfApiGet<HeroStatsResponse>(
    "/calibrations/:calibrationId/manager_reviews/stats",
    {
      params: {
        calibrationId: calibrationViewId,
      },
    }
  )
  return res.data.statistics
}

export const useGetNonAdminHeroStats = ({
  calibrationViewId,
}: {
  calibrationViewId?: number
}) => {
  return useQuery(
    [QueryKeys.NonAdminCalibrationViewHeroStats, calibrationViewId],
    () => fetchNonAdminCalibrationHeroStats({ calibrationViewId })
  )
}
