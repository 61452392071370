import * as React from "react"
import { useIntl } from "react-intl"

import strings from "@Calibrations/locale/strings"

export const StatEmployeeCount = ({
  employeeCount,
}: {
  employeeCount: number
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="text-center text-purple-800 text-heading-6 leading-heading-6 opacity-7">
      {`${employeeCount} ${formatMessage(
        strings.components.CalibrationHeroStats.employees
      )}`}
    </div>
  )
}
