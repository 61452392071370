import { useMutation, useQueryClient } from "@tanstack/react-query"
import { perfApiDelete } from "@API/utils"
import { QueryKeys } from "./keys"

const deleteCalibrationView = async (calibrationViewId: number) => {
  const res = await perfApiDelete(
    "/dashboard/calibrations/:calibrationViewId",
    {
      params: {
        calibrationViewId,
      },
    }
  )
  return res.data
}

export const useDeleteCalibrationView = (id: number) => {
  const queryClient = useQueryClient()
  return useMutation(() => deleteCalibrationView(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.AdminCalibrationViewsIndex])
      queryClient.invalidateQueries([
        QueryKeys.AdminCalibrationViewHeroStats,
        id,
      ])
      queryClient.invalidateQueries([
        QueryKeys.AdminCalibrationViewsDetails,
        id,
      ])
      queryClient.invalidateQueries([QueryKeys.AdminReviewResults, id])
    },
  })
}
