import Settings from "@Settings"
import React, { PropsWithChildren, useContext, useEffect } from "react"
import { parse, ParsedQuery } from "query-string"
import type { ToggleIntercomLauncher } from "./types"

const getIntercomImport = () =>
  import(/* webpackChunkName: "intercom" */ "@cultureamp/intercom")

const setupIntercom = () => getIntercomImport().then((m) => m.setupIntercom())

/**
 * Open or close intercom launcher
 * @param state open | closed
 * @returns
 */
export const toggleIntercomLauncher: ToggleIntercomLauncher = (state) =>
  getIntercomImport().then((m) => m.toggleIntercomLauncher(state))

type IntercomContext = {
  toggleIntercomLauncher: ToggleIntercomLauncher
}

const IntercomUtilities = {
  toggleIntercomLauncher,
}

const IntercomContext = React.createContext<IntercomContext>(IntercomUtilities)

export const useIntercom = (): IntercomContext => useContext(IntercomContext)

export const HELP_REDIRECT_QUERY_KEY = "helpRedirect"

const isLegacyRedirect = (search: string): boolean => {
  const parsedSearchObject: ParsedQuery = parse(search)
  return (
    !!parsedSearchObject[HELP_REDIRECT_QUERY_KEY] &&
    parsedSearchObject[HELP_REDIRECT_QUERY_KEY] === "true"
  )
}

export const IntercomProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  useEffect(() => {
    async function initIntercom() {
      if (!Settings.DISABLE_INTERCOM) {
        await setupIntercom()
        if (isLegacyRedirect(window.location.search)) {
          toggleIntercomLauncher("showWidget")
        }
      }
    }
    initIntercom()
  }, [])

  return (
    <IntercomContext.Provider value={IntercomUtilities}>
      {children}
    </IntercomContext.Provider>
  )
}
