import _ from "lodash"
import { EditorContentArray } from "@kaizen/rich-text-editor"
// @ts-expect-error - We are importing a .js file that doesn't have a declaration
import lazy from "../utils/lazy"
import BaseModel from "./BaseModel"
import PerformanceCycle from "./PerformanceCycle"
import { Answer } from "../types/PerformanceReview"
import { Bucket, PerformanceCycleRaw } from "../types/PerformanceCycle"
import { User } from "../types/User"

export type Commenter = {
  comments: {
    body: string
    id: number
    created_at: string
    updated_at: string
  }[]
  full_name: string
}

type PerformanceReviewRaw = {
  id: number
  performance_cycle_id: number
  shared_at: string | null // Date string, eg "2020-01-09T05:16:08.000Z"
  manager_completed_at: string | null // Date string, eg "2020-01-09T05:16:08.000Z"
  performance_answers: Answer[]
  performance_cycle_name: string
  performance_bucket_in_cycles: null | Bucket
  performance_buckets_question_title: null | string
  performance_buckets_question_description: null | string
  performance_buckets_position: null | number
  manager: {
    id: number
    email: string
    full_name: string
    best_name: string
    manager_email: string
    manager_name: string
    profile_image_url: string
    job_title: string
    deactivated_at: string | null
    // avatar_images - deprecated, use profile_image_url
  } | null
  latest_bucket_assignment: null // ??
  // Some endpoints may include the full performance cycle, some may not
  performance_cycle?: PerformanceCycleRaw
  introduction_text?: EditorContentArray
}

export default class PerformanceReview
  extends BaseModel
  // Without performance_cycle, because in this PerformanceReview Model, the raw
  // type is converted to a PerformanceCycle Model
  implements Omit<PerformanceReviewRaw, "performance_cycle">
{
  id: number
  performance_cycle_id: number
  shared_at: string // Date string, eg "2020-01-09T05:16:08.000Z"
  manager_completed_at: string // Date string, eg "2020-01-09T05:16:08.000Z"
  performance_answers: Answer[]
  performance_cycle_name: string
  performance_bucket_in_cycles: null | Bucket
  performance_buckets_question_title: null | string
  performance_buckets_question_description: null | string
  performance_buckets_position: null | null
  manager: null // ??
  latest_bucket_assignment: null // ??
  // I'm not sure if there appear on all responses or not
  // collaborator evaluation page api results.
  commenters: Commenter[]
  // There are other fields in this "User" object, we're just including
  // what was needed to get typescript to pass. If you have time, please
  // update this type appropriately.
  user: User & {
    full_name: string | undefined
    best_name: string | undefined
  }
  status: "incomplete" | "shared" | "dirty" | "completed"

  // Additional fields
  complete: boolean
  performance_cycle: PerformanceCycle | undefined
  introduction_text?: EditorContentArray

  constructor(props: PerformanceReviewRaw) {
    super(props)
    _.assign(this, props)

    // prettier-ignore
    this.complete =
      // @ts-expect-error - This should be typed
      props.complete !== undefined
        // @ts-expect-error - This should be typed
        ? props.complete
        : !!props.manager_completed_at

    // @ts-expect-error - This should be typed
    this.performance_cycle = props.performance_cycle
      ? PerformanceCycle.of(props.performance_cycle)
      : undefined
  }

  static getAnswerValue(answer: Answer) {
    return answer && (answer.rating || answer.choice || answer.text)
  }

  static getProgress({
    questionCount,
    review,
    cycleHasBucketQuestion,
  }: {
    questionCount: number
    review: PerformanceReview
    cycleHasBucketQuestion: boolean
  }) {
    const { numAnswersGiven, complete, assignedBucket } = review

    // The questionCount and numAnswersGiven don't include buckets questions.
    // Unfortunately, the performance_review doesn't have a reference if a performance cycle has buckets questions
    // so we need to pass the prop.

    const totalQuestionsCount = questionCount + (cycleHasBucketQuestion ? 1 : 0)
    const totalAnswersGiven = numAnswersGiven + (assignedBucket ? 1 : 0)
    const completedAnswerFraction =
      totalQuestionsCount === 0 ? 1 : totalAnswersGiven / totalQuestionsCount

    // This is a totally arbitrary calculation of review progress, intended just
    // as a rough guide for the UI
    // NB: add ints and divide by 10 at the end since `0.1 + 0.2 !== 0.3`
    const answersProgress = 9 * completedAnswerFraction
    return (answersProgress + (complete ? 1 : 0)) / 10
  }

  get assignedBucket() {
    return _.get(this.latest_bucket_assignment, "performance_bucket_in_cycle")
  }

  @lazy
  get numAnswersGiven() {
    return this.performance_answers.filter((answer) => {
      const answerValue = PerformanceReview.getAnswerValue(answer)
      return (
        (answerValue || answerValue === 0) &&
        answer.performance_question_in_cycle?.required
      )
    }).length
  }

  get incomplete() {
    return !this.complete
  }
}
