import { perfApiGet } from "@API/utils"
import { useQuery } from "@tanstack/react-query"
import { Calibrations } from "bff/upstreams"
import { QueryKeys } from "./keys"

export type ManagerReviewDetails =
  Calibrations["calibrations.ManagerReviewDetails"]["managerReviews"]

export const fetchManagerReviewDetails = async ({
  managerReviewCycleIds,
}: {
  managerReviewCycleIds: number[]
}) => {
  const url = `/dashboard/calibrations/manager_review_details`
  const res = await perfApiGet<{
    managerReviews: ManagerReviewDetails
  }>(url, {
    params: {
      managerReviewCycleIds: managerReviewCycleIds.join(","),
    },
  })
  return res.data.managerReviews
}

export const useGetManagerReviewDetails = ({
  managerReviewCycleIds,
  nonAdminView,
}: {
  managerReviewCycleIds: number[]
  nonAdminView?: boolean
}) => {
  return useQuery(
    [QueryKeys.AdminCalibrationViewManagerReviewDetails, managerReviewCycleIds],
    () => fetchManagerReviewDetails({ managerReviewCycleIds }),
    { enabled: !nonAdminView && !!managerReviewCycleIds.length }
  )
}
