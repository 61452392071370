import React from "react"
import { Box } from "@kaizen/component-library"
import { BrandMoment } from "@kaizen/brand-moment"
import { BrandMomentError } from "@kaizen/draft-illustration"
import { Paragraph } from "@kaizen/typography"
import arrowRightIcon from "@kaizen/component-library/icons/arrow-right.icon.svg"
import email from "@kaizen/component-library/icons/email.icon.svg"
import {
  ThemeManager,
  ThemeProvider,
  defaultTheme,
} from "@kaizen/design-tokens"
const styles = require("./ErrorPage.scss")

type ErrorPageProps = {
  code: number
  title: string
  message: React.ReactNode | string
  includeCTA: boolean
}

const mailtoLink = (code: number) => {
  const email = "support@cultureamp.com"
  const subject = "Houston we have a problem"
  const body = `Hi there,\n\nI received a ${code} error page while I was trying to...`
  return encodeURI(`mailto:${email}?subject=${subject}&amp;body=${body}`)
}

export default (props: ErrorPageProps) => {
  const themeManager = new ThemeManager(defaultTheme)
  const WithTheme: React.FC = ({ children }) => (
    <ThemeProvider themeManager={themeManager}>{children}</ThemeProvider>
  )

  return (
    <WithTheme>
      <div className={styles}>
        <BrandMoment
          body={
            <>
              <Box mb={1.75}>
                <Paragraph variant="intro-lede">{props.message}</Paragraph>
              </Box>
              <Paragraph color="dark-reduced-opacity" variant="small">
                Error code {props.code}
              </Paragraph>
            </>
          }
          header={null}
          illustration={<BrandMomentError isAnimated loop />}
          mood="negative"
          primaryAction={
            props.includeCTA
              ? {
                  href: "/app/home",
                  icon: arrowRightIcon,
                  iconPosition: "end",
                  label: "Go to Home",
                }
              : undefined
          }
          secondaryAction={
            props.includeCTA
              ? {
                  href: mailtoLink(props.code),
                  icon: email,
                  label: "Contact support",
                }
              : undefined
          }
          text={{
            title: props.title,
          }}
        />
      </div>
    </WithTheme>
  )
}
