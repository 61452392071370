import { LoadingParagraph } from "@kaizen/loading-skeleton"
import * as React from "react"
import { Filter } from "./useCalibrationViewFilters"

type FilterLoader = Pick<
  Filter,
  | "url"
  | "queryKey"
  | "id"
  | "demographic"
  | "useFetch"
  | "initialValues"
  | "managerReviewCycleIds"
>

const CalibrationViewFiltersSkeleton = () => (
  <div
    data-automation-id="calibration-filters-loading"
    style={{
      display: "inline-grid",
      gridTemplateColumns: "1fr auto",
      justifyContent: "center",
      alignItems: "center",
      border: "solid 1px rgb(244, 244, 245)",
      padding: "10px",
    }}
    className="bg-white rounded-default shadow-sm w-full"
  >
    <LoadingParagraph style={{ height: "40px" }} />
  </div>
)

const CalibrationViewFilterLoader: React.FC<{
  filter: FilterLoader
  onLoaded: () => void
}> = ({ filter, onLoaded }) => {
  const { useFetch, initialValues } = filter
  const { data } = useFetch({
    url: filter.url,
    queryKey: filter.queryKey,
    enabled: Boolean(initialValues?.length),
    query: "",
    initialIds: initialValues,
    managerReviewCycleIds: filter.managerReviewCycleIds,
  })

  React.useEffect(() => {
    if (data) {
      onLoaded()
    }
  }, [data, onLoaded])

  return null
}

export const CalibrationViewFiltersLoader: React.FC<{
  filters: FilterLoader[]
}> = ({ filters, children }) => {
  const [loadingCountdown, setLoadingCountdown] = React.useState(
    filters.filter((f) => f.initialValues?.length).length
  )

  const decrementLoadingCountdown = React.useCallback(() => {
    setLoadingCountdown((c) => c - 1)
  }, [])

  return (
    <>
      {loadingCountdown <= 0 ? (
        <>{children}</>
      ) : (
        <>
          <CalibrationViewFiltersSkeleton />
          {filters.map((filter) => {
            return (
              <CalibrationViewFilterLoader
                onLoaded={decrementLoadingCountdown}
                key={`${filter.id}_${filter.demographic}`}
                filter={filter}
              />
            )
          })}
        </>
      )}
    </>
  )
}
