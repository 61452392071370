import { EmployeeData } from "@kaizen/draft-illustration"
import React from "react"
import { useHistory, generatePath } from "react-router-dom"
import { PageLayout } from "@Calibrations/components/layout/PageLayout/PageLayout"
import { TitleBlock } from "@Calibrations/components/layout/TitleBlock/TitleBlock"
import strings from "@Calibrations/locale/strings"
import { useIntl } from "@Common/locale/useIntl"
import { calibrationsPaths } from "@Calibrations/paths"
import { CalibrationHeroStats } from "@Calibrations/v2/components/CalibrationHeroStats/CalibrationHeroStats"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { CalibrationViewFilters } from "@Calibrations/v2/components/CalibrationViewFilters/CalibrationViewFilters"
import { GuidanceBlock } from "@kaizen/draft-guidance-block"
import { UserSearchWrapper } from "@Calibrations/v2/components/UserSearch/UserSearchWrapper/UserSearchWrapper"
import { CreateResults } from "./CreateResults"
import { CreateCalibrationViewModal } from "./components"

const {
  pages: { AdminCalibrationViewsCreate: PageStrings },
} = strings

export const AdminCalibrationViewsCreate = () => {
  const [
    showCreateCalibrationViewModal,
    setShowCreateCreateCalibrationViewModal,
  ] = React.useState(false)
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [queryParams] = useCalibrationViewQueryParams()
  const hasManagerReviewCycleIds = !!queryParams.managerReviewCycleIds

  return (
    <PageLayout
      headingContent={
        <TitleBlock
          title={formatMessage(PageStrings.title)}
          primaryAction={{
            label: formatMessage(PageStrings.ctaButton),
            disabled: !hasManagerReviewCycleIds,
            onClick: () => setShowCreateCreateCalibrationViewModal(true),
          }}
          breadcrumb={{
            text: formatMessage(PageStrings.backButton),
            path: generatePath(calibrationsPaths.admin.views.index({})),
            handleClick: (e) => {
              e.preventDefault()
              history.push(calibrationsPaths.admin.views.index({}))
            },
          }}
        />
      }
    >
      <div className="mb-12">
        <GuidanceBlock
          noMaxWidth
          illustration={<EmployeeData alt="" />}
          persistent={true}
          text={{
            title: formatMessage(PageStrings.GuidanceBlock.title),
            description: (
              <ol className="list-decimal pl-16">
                <li>{formatMessage(PageStrings.GuidanceBlock.firstPoint)}</li>
                <li>{formatMessage(PageStrings.GuidanceBlock.secondPoint)}</li>
              </ol>
            ),
          }}
        />
      </div>
      <div className="my-12">
        <CreateCalibrationViewModal
          isOpen={showCreateCalibrationViewModal}
          onDismiss={() => setShowCreateCreateCalibrationViewModal(false)}
        />
        <div style={{ paddingBottom: "10px" }}>
          <CalibrationViewFilters />
        </div>
        {hasManagerReviewCycleIds ? (
          <div className="flex flex-col gap-y-12">
            <div className="my-12">
              <UserSearchWrapper
                placeholder={
                  strings.pages.AdminCalibrationViewsDetail.UserSearch
                    .placeholder
                }
              />
            </div>
            <CalibrationHeroStats filters={queryParams} />
            <CreateResults filters={queryParams} />
          </div>
        ) : null}
      </div>
    </PageLayout>
  )
}
