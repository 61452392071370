import { Text } from "@kaizen/component-library"
import { IconButton } from "@kaizen/button"
import classnames from "classnames"
import moment, { Moment } from "moment"
import React, { useEffect, useCallback, useState, useMemo } from "react"
import arrowLeftIcon from "@kaizen/component-library/icons/arrow-left.icon.svg"
import arrowRightIcon from "@kaizen/component-library/icons/arrow-right.icon.svg"
import styles from "./Calendar.scss"
import Aid from "../../constants/automationId"

type Calendar = React.FC<{
  selectedDates?: Moment[]
  onChange: (dates: Moment[]) => void
  allowDateRange?: boolean
  minimumDate?: Date
}>

export const Calendar: Calendar = ({
  selectedDates = [],
  onChange,
  allowDateRange = false,
  minimumDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(
    selectedDates.length > 1
      ? moment(selectedDates[1]).startOf("month")
      : moment().startOf("month")
  )
  const [hoverDate, setHoverDate] = useState<Moment>()

  const months = moment.months()
  const weekdays = moment.weekdaysShort()
  // Changing to isoWeekday from weekdaysShort because isoWeekday gives weekday
  // irrespective of the locale https://cultureamp.atlassian.net/browse/COMP-36
  const firstDayOfMonth = currentMonth.startOf("month").isoWeekday()
  const rowsNumber = Math.ceil(
    (currentMonth.daysInMonth() + firstDayOfMonth) / 7
  )

  useEffect(() => {
    if (selectedDates.length > 1) {
      // Set the calendar to display the end date of the selected dates
      setCurrentMonth(moment(selectedDates[1]).startOf("month"))
    }
  }, [selectedDates])

  const handleSelect = useCallback(
    (date: Moment) => {
      if (selectedDates.length === 1 && selectedDates[0].isSame(date)) {
        onChange([])
        return
      }
      const newDates =
        allowDateRange && selectedDates.length < 2
          ? [...selectedDates, date]
          : [date]
      onChange(newDates)
    },
    [allowDateRange, selectedDates, onChange]
  )

  const isPrevDisabled = useMemo(() => {
    if (!minimumDate) {
      return false
    }
    return moment(minimumDate).isAfter(currentMonth)
  }, [minimumDate, currentMonth])

  const isDayDisabled = useCallback(
    (day: number) => {
      if (!minimumDate || !isPrevDisabled) {
        return false
      }
      const minDay = parseInt(moment(minimumDate).format("D"), 10)
      return day < minDay
    },
    [isPrevDisabled, minimumDate]
  )

  const rows: JSX.Element[] = []
  for (let row = 0; row < rowsNumber; row++) {
    rows.push(
      <div className={styles.row} key={`row-${row}`}>
        {weekdays.map((_, i) => {
          const day = row * weekdays.length + i - firstDayOfMonth + 1
          const date = moment(currentMonth).add(day - 1, "days")
          const shouldDisplayDay = day > 0 && day <= currentMonth.daysInMonth()

          return (
            <button
              type="button"
              data-automation-id={Aid.calendarDayButton}
              key={`day-${day}`}
              className={classnames({
                [styles.day]: true,
                [styles.empty]: !shouldDisplayDay,
                [styles.selected]:
                  (selectedDates.length === 1 &&
                    shouldDisplayDay &&
                    selectedDates.find((d) => d.isSame(date))) ||
                  (selectedDates.length === 2 &&
                    shouldDisplayDay &&
                    (date.isSame(selectedDates[0], "date") ||
                      date.isSame(selectedDates[1], "date"))),
                [styles.between]:
                  (shouldDisplayDay &&
                    allowDateRange &&
                    hoverDate &&
                    selectedDates.length === 1 &&
                    date.isBetween(
                      selectedDates[0],
                      hoverDate,
                      undefined,
                      "(]"
                    )) ||
                  date.isSame(hoverDate) ||
                  (selectedDates.length === 2 &&
                    shouldDisplayDay &&
                    date.isBetween(
                      selectedDates[0],
                      selectedDates[1],
                      undefined,
                      "[]"
                    )),
              })}
              disabled={isDayDisabled(day) || !shouldDisplayDay}
              onMouseEnter={() => shouldDisplayDay && setHoverDate(date)}
              onMouseLeave={() => setHoverDate(undefined)}
              onClick={(evt) => {
                evt.stopPropagation()
                handleSelect(date)
              }}
            >
              {shouldDisplayDay && day}
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <div className={styles.calendar}>
      <div className={styles.controls}>
        <IconButton
          label=""
          icon={arrowLeftIcon}
          onClick={(e) => {
            e.stopPropagation()
            setCurrentMonth((m) => moment(m).subtract(1, "month"))
          }}
          disabled={isPrevDisabled}
        />
        <Text style="heading" tag="h2" inline inheritBaseline>
          {`${months[currentMonth.month()]} ${currentMonth.year()}`}
        </Text>
        <IconButton
          label=""
          icon={arrowRightIcon}
          onClick={(e) => {
            e.stopPropagation()
            setCurrentMonth((m) => moment(m).add(1, "month"))
          }}
        />
      </div>
      <div className={styles.weekdays}>
        {weekdays.map((day) => (
          <div key={day} className={styles.dayName}>
            <Text tag="span" style="small">
              {day}
            </Text>
          </div>
        ))}
      </div>
      <div>{rows}</div>
    </div>
  )
}
