import React from "react"
import { Icon as IconCA } from "@kaizen/component-library"
import "./Icon.less"

import refresh from "@kaizen/component-library/icons/refresh.icon.svg"
import search from "@kaizen/component-library/icons/search.icon.svg"
import close from "@kaizen/component-library/icons/close.icon.svg"
import add from "@kaizen/component-library/icons/add.icon.svg"
import minus from "@kaizen/component-library/icons/minus.icon.svg"
import exportIcon from "@kaizen/component-library/icons/export.icon.svg"
import duplicate from "@kaizen/component-library/icons/duplicate.icon.svg"
import chevronLeft from "@kaizen/component-library/icons/chevron-left.icon.svg"
import chevronDown from "@kaizen/component-library/icons/chevron-down.icon.svg"
import chevronRight from "@kaizen/component-library/icons/chevron-right.icon.svg"
import chevronUp from "@kaizen/component-library/icons/chevron-up.icon.svg"
import user from "@kaizen/component-library/icons/user.icon.svg"
import support from "@kaizen/component-library/icons/support.icon.svg"
import logOut from "@kaizen/component-library/icons/log-out.icon.svg"
import comment from "@kaizen/component-library/icons/comment.icon.svg"
import commentBank from "@kaizen/component-library/icons/comment-bank.icon.svg"
import edit from "@kaizen/component-library/icons/edit.icon.svg"
import sharing from "@kaizen/component-library/icons/report-sharing.icon.svg"
import skip from "@kaizen/component-library/icons/skip.icon.svg"
import favoriteOn from "@kaizen/component-library/icons/favorite-on.icon.svg"
import favoriteOff from "@kaizen/component-library/icons/favorite-off.icon.svg"
import starOn from "@kaizen/component-library/icons/star-on.icon.svg"
import starOff from "@kaizen/component-library/icons/star-off.icon.svg"
import listView from "@kaizen/component-library/icons/list-view.icon.svg"
import trash from "@kaizen/component-library/icons/trash.icon.svg"
import dateRange from "@kaizen/component-library/icons/date-range.icon.svg"
import question from "@kaizen/component-library/icons/question.icon.svg"
import users from "@kaizen/component-library/icons/users.icon.svg"
import clear from "@kaizen/component-library/icons/clear.icon.svg"
import redo from "@kaizen/component-library/icons/redo.icon.svg"
import check from "@kaizen/component-library/icons/check.icon.svg"
import flagOn from "@kaizen/component-library/icons/flag-on.icon.svg"
import flagOff from "@kaizen/component-library/icons/flag-off.icon.svg"
import visible from "@kaizen/component-library/icons/visible.icon.svg"
import information from "@kaizen/component-library/icons/information.icon.svg"
import faceVerySatisfied from "@kaizen/component-library/icons/face-very-satisfied.icon.svg"
import closed from "@kaizen/component-library/icons/closed.icon.svg"
import draft from "@kaizen/component-library/icons/draft.icon.svg"
import exclamationOctagon from "@kaizen/component-library/icons/exclamation-octagon.icon.svg"
import exclamation from "@kaizen/component-library/icons/exclamation.icon.svg"
import empty from "@kaizen/component-library/icons/empty.icon.svg"
import full from "@kaizen/component-library/icons/full.icon.svg"
import arrowForward from "@kaizen/component-library/icons/arrow-forward.icon.svg"
import arrowBackward from "@kaizen/component-library/icons/arrow-backward.icon.svg"
import arrowUp from "@kaizen/component-library/icons/arrow-up.icon.svg"
import arrowDown from "@kaizen/component-library/icons/arrow-down.icon.svg"
import hamburger from "@kaizen/component-library/icons/hamburger.icon.svg"
import success from "@kaizen/component-library/icons/success.icon.svg"
import ellipsis from "@kaizen/component-library/icons/ellipsis.icon.svg"
import sortDescending from "@kaizen/component-library/icons/sort-descending.icon.svg"
import sortAscending from "@kaizen/component-library/icons/sort-ascending.icon.svg"
import spinner from "@kaizen/component-library/icons/spinner.icon.svg"
import feedbackClassify from "@kaizen/component-library/icons/feedback-classify.icon.svg"
import feedbackReview from "@kaizen/component-library/icons/feedback-review.icon.svg"
import processManager from "@kaizen/component-library/icons/process-manager.icon.svg"
import camera from "@kaizen/component-library/icons/camera.icon.svg"
import commentAdd from "@kaizen/component-library/icons/comment-add.icon.svg"
import guidance from "@kaizen/component-library/icons/guidance.icon.svg"
import communications from "@kaizen/component-library/icons/communications.icon.svg"
import thumbsUp from "@kaizen/component-library/icons/thumbs-up.icon.svg"
import ErrorBoundaryWithLogging from "@Common/components/ErrorBoundaryWithLogging/ErrorBoundaryWithLogging"

const icons = {
  refresh,
  search,
  close,
  add,
  minus,
  duplicate,
  chevronLeft,
  chevronDown,
  chevronRight,
  chevronUp,
  user,
  support,
  logOut,
  comment,
  commentBank,
  edit,
  sharing,
  skip,
  listView,
  trash,
  dateRange,
  question,
  users,
  clear,
  redo,
  check,
  flagOff,
  flagOn,
  visible,
  information,
  faceVerySatisfied,
  closed,
  draft,
  exclamationOctagon,
  exclamation,
  empty,
  full,
  arrowForward,
  arrowBackward,
  arrowUp,
  arrowDown,
  success,
  hamburger,
  ellipsis,
  sortDescending,
  sortAscending,
  export: exportIcon,
  communications,
  favoriteOn,
  favoriteOff,
  starOn,
  starOff,
  spinner,
  feedbackReview,
  feedbackClassify,
  processManager,
  camera,
  commentAdd,
  guidance,
  thumbsUp,
} as const

// TO USE JUST IMPORT Icon COMPONENT AND SET THE ICONNAME

// myComponent {
//   render() {
//     return (
//       <Icon iconName="myIconName" />
//     )
//   }
// }

export type IconName = keyof typeof icons

type Props = {
  iconName: IconName
  style?: object
  className?: string
  inheritSize?: boolean
  role?: "img" | "presentation"
  title?: string
}

export default class Icon extends React.Component<Props> {
  render() {
    const {
      iconName,
      style,
      className,
      inheritSize,
      role = "presentation",
      title,
    } = this.props
    return (
      <div
        className={`Icon--container ${className}`}
        style={style || undefined}
      >
        <ErrorBoundaryWithLogging fallbackRender={() => <></>}>
          <IconCA
            icon={icons[iconName]}
            role={role}
            title={title}
            inheritSize={!!inheritSize}
          />
        </ErrorBoundaryWithLogging>
      </div>
    )
  }
}
