import * as React from "react"
import {
  asyncWithProvider,
  AsyncProviderConfig,
} from "@cultureamp/ca-launchdarkly/react"
import { sendErrorToDatadog } from "@Common/utils/datadog"
import Loading from "@Components/Loading/Loading"
import Settings from "../../../settings"

let mockedFlags = {}
if (Settings.LAUNCHDARKLY_STATUS === "disabled") {
  mockedFlags = require("../../../mockedFlags.json")
}

export const providerConfig = (
  launchDarklyStatus: "enabled" | "disabled",
  bootstrapFlagSet: object,
  userId: string,
  accountId: string
): AsyncProviderConfig => {
  return {
    // Passing an empty string here will prevent the client SDK from calling LD to get the flags.
    // Passing `undefined` will cause the client SDK to fetch the ClientSideID before initializing the client SDK.
    clientSideID: launchDarklyStatus === "enabled" ? undefined : "",
    context: {
      userID: userId,
      realUserID: userId,
      accountID: accountId,
    },
    options: {
      sendEvents: launchDarklyStatus === "enabled",
      fetchGoals: launchDarklyStatus === "enabled",
      bootstrap: {
        ...bootstrapFlagSet,
        ...mockedFlags,
      },
    },
  }
}

type LaunchDarklyProviderProps = {
  bootstrapFlagSet: object
  accountId: string
  userId: string
}

export const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({
  children,
  bootstrapFlagSet,
  accountId,
  userId,
}) => {
  const [LDProvider, setLDProvider] = React.useState<
    React.FC<{ children: React.ReactNode }>
  >(() => () => <Loading />)

  React.useEffect(() => {
    asyncWithProvider(
      providerConfig(
        Settings.LAUNCHDARKLY_STATUS,
        bootstrapFlagSet,
        userId,
        accountId
      )
    )
      .then((Provider) => {
        setLDProvider(() => Provider)
      })
      .catch((error) => {
        sendErrorToDatadog(error)
      })
  }, [userId, accountId, bootstrapFlagSet])

  return <LDProvider>{children}</LDProvider>
}
