import * as React from "react"
import { FilterMultiSelect, getSelectedOptionLabels } from "@kaizen/select"
import useDebounce from "@Hooks/timeout/useDebounce"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import type { Filter } from "./useCalibrationViewFilters"

type FilterDropdownProps = {
  filter: Filter
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({ filter }) => {
  const {
    url,
    queryKey,
    label,
    triggerLabelItems,
    useFetch,
    useCache,
    demographic,
    onChange,
    onRemove,
    values,
    managerReviewCycleIds,
  } = filter

  const { formatMessage } = useIntl()
  const cachedItems = useCache(queryKey)
  const [open, setOpen] = React.useState(false)
  const [searchString, setSearchString] = React.useState("")
  const debouncedSearchString = useDebounce(searchString, 500)
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useFetch({
      url: url,
      queryKey: queryKey,
      enabled: open,
      query: debouncedSearchString,
      managerReviewCycleIds: managerReviewCycleIds,
    })

  const items = data?.pages || []

  const setOfKeys = new Set(values)

  const selectedOptionLabels =
    triggerLabelItems || getSelectedOptionLabels(setOfKeys, cachedItems)

  return (
    <FilterMultiSelect
      isLoading={isLoading}
      loadingSkeleton={<FilterMultiSelect.MenuLoadingSkeleton />}
      isOpen={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      label={label}
      onSelectionChange={onChange}
      selectedKeys={setOfKeys}
      items={items}
      onSearchInputChange={(query) => setSearchString(query)}
      trigger={() => {
        if (demographic) {
          return (
            <FilterMultiSelect.RemovableTrigger
              selectedOptionLabels={selectedOptionLabels}
              label={label}
              onRemove={onRemove}
            />
          )
        }
        return (
          <FilterMultiSelect.TriggerButton
            selectedOptionLabels={selectedOptionLabels}
            label={label}
          />
        )
      }}
    >
      {(context) => (
        <>
          <FilterMultiSelect.SearchInput />
          <FilterMultiSelect.ListBox>
            {({ allItems }) => {
              if (allItems.length === 0) {
                return context?.searchQuery ? (
                  <div className="text-center" style={{ padding: "20px" }}>
                    {formatMessage(
                      strings.components.CalibrationFilters.noResultsForSearch
                    )}
                    :{" "}
                    <span className="font-weight-data">
                      {context?.searchQuery}
                    </span>
                  </div>
                ) : (
                  <div className="text-center" style={{ padding: "20px" }}>
                    {formatMessage(
                      strings.components.CalibrationFilters.noResults
                    )}
                  </div>
                )
              }
              return allItems.map((item) => (
                <FilterMultiSelect.Option key={item.key} item={item} />
              ))
            }}
          </FilterMultiSelect.ListBox>
          {hasNextPage && (
            <FilterMultiSelect.LoadMoreButton
              label={formatMessage(
                strings.components.CalibrationFilters.loadMoreButton.label
              )}
              workingLabel={formatMessage(
                strings.components.CalibrationFilters.loadMoreButton.loading
              )}
              working={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            />
          )}
          <FilterMultiSelect.MenuFooter>
            <FilterMultiSelect.SelectAllButton />
            <FilterMultiSelect.ClearButton />
          </FilterMultiSelect.MenuFooter>
        </>
      )}
    </FilterMultiSelect>
  )
}
