import React from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import { calibrationsPaths } from "@Calibrations/paths"
import { ErrorPage403 } from "@Entry/ErrorPage/ErrorPage403"
import { ErrorPage404 } from "@Entry/ErrorPage/ErrorPage404"
import { DevelopmentResourcesRoutes } from "@DevelopmentResources/routes"
import { FeedbackRoutes } from "@Feedback/routes"
import { GoalsRoutes } from "@Goals/routes"
import { teamsManagementPaths } from "@TeamsManagement/paths"
import { ConfigurationOptions } from "@Constants/configurationOptions"
import { HELP_REDIRECT_QUERY_KEY } from "@Common/components/intercom/IntercomProvider"
import { ldFeatureToggle } from "@Containers/FeatureToggle/LDFeatureToggle"
import { PRCAppRedirect } from "@Common/routing/PRCAppRedirect"
import { AdminCalibrationViewsCreate } from "@Calibrations/v2/pages/AdminCalibrationViewsCreate/AdminCalibrationViewsCreate"
import R from "./constants/routes"
import { routes as SurveysResponsesRoutes } from "./containers/SurveysResponsesRoutes/SurveysResponsesRoutes"
import FeatureFlags from "./constants/featureFlags"
import { featureToggle } from "./containers/FeatureToggle/FeatureToggle"
import { Home } from "./entry/Home/Home"
import { PrivateRoute, ValidatedRoute } from "./common/routing"
import { OneOnOneRoutes, OneOnOneAdminRoutes } from "./one_on_ones/routes"
import { heartbeatRoute } from "./heartbeat/routes"
import { ErrorPage503Maintenance } from "./entry/ErrorPage/ErrorPage503Maintenance"

const TeamsManagementRoutes = React.lazy(
  () => import(/* webpackChunkName: "teams" */ "@TeamsManagement/routes")
)

const AdminCalibrationsRoutes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "calibrations" */ "@Calibrations/routes/AdminRoutes"
    )
)

const NonAdminCalibrationsRoutes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "calibrations" */ "@Calibrations/routes/NonAdminRoutes"
    )
)

const EcPaufRequestFeedbackPage = React.lazy(
  () =>
    import(/* webpackChunkName: "pauf" */ "@Entry/EcPaufRequestFeedbackPage")
)

// Admin pages
const AdminApp = React.lazy(
  () =>
    // @ts-expect-error No definition file
    import(/* webpackChunkName: "AdminApp" */ "./admin/entry/AdminApp/AdminApp")
)
const AdminCompletedSelfReflectionResponsePage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminCompletedSelfReflectionSurveyResponsePage/AdminCompletedSelfReflectionSurveyResponsePage"
    )
)
const AdminEcDetailsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEcDetailsPage"
    )
)
const AdminEcPeerAndUpwardFeedbackIndividualStats = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEcPeerAndUpwardFeedbackIndividualStats/index"
    )
)

const SubjectFeedbackOverview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEcPeerAndUpwardFeedbackIndividualStats/multilingual/SubjectFeedbackOverview"
    )
)

const AdminEvaluationQuestionsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationQuestionsPage/AdminEvaluationQuestionsPage"
    )
)
const AdminEvaluationRootPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationRootPage/AdminEvaluationRootPage"
    )
)
const AdminFeedbackStatsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminFeedbackStatsPage/AdminFeedbackStatsPage"
    )
)
const AdminGoalsStatsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminGoalsStatsPage/AdminGoalsStatsPage"
    )
)
const AdminAdvancedPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminAdvancedPage/AdminAdvancedPage"
    )
)
const AdminIncompleteSelfReflectionSurveyResponsePage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminIncompleteSelfReflectionSurveyResponsePage/AdminIncompleteSelfReflectionSurveyResponsePage"
    )
)
const AdminIndividualManagerRequestHistoryPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminIndividualManagerRequestHistoryPage/AdminIndividualManagerRequestHistoryPage"
    )
)
const AdminManagerRequestedFeedbackPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminManagerRequestedFeedbackPage/AdminManagerRequestedFeedbackPage"
    )
)
const AdminMRFSettingsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminMRFSettingsPage/AdminMRFSettingsPage"
    )
)
const AdminSelfReflectionListingPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminSelfReflectionListingPage/AdminSelfReflectionListingPage"
    )
)
const AdminSelfReflectionSummaryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminSelfReflectionSurveySummaryPage/AdminSelfReflectionSummaryPage"
    )
)
const AdminSkills = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminSkills/AdminSkills"
    )
)
const AdminSurveyFeedbackRequestsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminSurveyFeedbackRequestsPage/AdminSurveyFeedbackRequestsPage"
    )
)
const AdminWelcomePage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminWelcomePage/AdminWelcomePage"
    )
)
const AdminEcListingPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEcListingPage"
    )
)
const AdminSearchPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./admin/entry/AdminSearchPage/AdminSearchPage"
    )
)

// Main pages
const BrowserPluginGiveFeedback = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "browserPlugin" */ "./entry/BrowserPluginGiveFeedback/BrowserPluginGiveFeedback"
    )
)
const BrowserPluginRedirect = React.lazy(
  () =>
    import(
      /* webpackChunkName: "browserPlugin" */ "./entry/BrowserPluginGiveFeedback/BrowserPluginRedirect"
    )
)
const CollaboratorEvaluationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/CollaboratorEvaluationPage/CollaboratorEvaluationPage"
    )
)
const CompletedSelfReflectionSurveyPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/CompletedSelfReflectionSurveyPage/CompletedSelfReflectionSurveyPage"
    )
)
const EcDetailsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/EcDetailsPage/EcDetailsPage"
    )
)
const Emails = React.lazy(
  // @ts-expect-error No definition file
  () => import(/* webpackChunkName: "mainPages" */ "./entry/Emails/Emails")
)
const EvaluationQuestionsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/EvaluationQuestionsPage/EvaluationQuestionsPage"
    )
)
const ManagerEvaluationQuestionsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "@ManagerReviews/pages/EvaluationQuestionsPage"
    )
)
const EvaluationRedirectPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/EvaluationRedirectPage/EvaluationRedirectPage"
    )
)
const EvaluationRootPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/EvaluationRootPage/EvaluationRootPage"
    )
)
const FeedbackList = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/FeedbackList/FeedbackList"
    )
)
const FeedbackRequestsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/FeedbackRequestsPage/FeedbackRequestsPage"
    )
)
const IncompleteMRFSurveyPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/IncompleteMRFSurveyPage/IncompleteMRFSurveyPage"
    )
)
const IncompleteSelfReflectionSurveyPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/IncompleteSelfReflectionSurveyPage/IncompleteSelfReflectionSurveyPage"
    )
)
const MySelfReflectionSurveyResponsesPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/MySelfReflectionSurveyResponsesPage/MySelfReflectionSurveyResponsesPage"
    )
)
const MySummary = React.lazy(
  () =>
    // @ts-expect-error No definition file
    import(/* webpackChunkName: "mainPages" */ "./entry/MySummary/MySummary")
)
const Notifications = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/Notifications/Notifications"
    )
)
const PrinterFriendlyEvaluationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlyEvaluationPage/PrinterFriendlyEvaluationPage"
    )
)
const PrinterFriendlyPeerReviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlyPeerReviewPage/PrinterFriendlyPeerReviewPage"
    )
)
const PrinterFriendlySharedPeerAndUpwardFeedback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/SharedEcDetailPage/feedback/PrinterFriendlySharedPeerAndUpwardFeedback"
    )
)
const PrinterFriendlySelfReflectionPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlySelfReflectionPage/PrinterFriendlySelfReflectionPage"
    )
)
const PrinterFriendlySharedEvaluationPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlySharedEvaluationPage/PrinterFriendlySharedEvaluationPage"
    )
)
const Profile = React.lazy(
  // @ts-expect-error No definition file
  () => import(/* webpackChunkName: "mainPages" */ "./entry/Profile/Profile")
)
const ExportProfile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/ExportProfilePage/ExportProfile"
    )
)
const PublicPraise = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "publicPraise" */ "./entry/PublicPraise/PublicPraise"
    )
)
const QuickComment = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/QuickComment/QuickComment"
    )
)
const Review = React.lazy(
  // @ts-expect-error No definition file
  () => import(/* webpackChunkName: "mainPages" */ "./entry/Review/Review")
)
const PeerFeedback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/PeerFeedback/PeerFeedback"
    )
)
const PeerFeedbackRequest = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/PeerFeedbackRequest/PeerFeedbackRequest"
    )
)
const Search = React.lazy(
  // @ts-expect-error No definition file
  () => import(/* webpackChunkName: "mainPages" */ "./entry/Search/Search")
)
const SharedEcDetailPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/SharedEcDetailPage/SharedEcDetailPage"
    )
)
const SharedFeedbackPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/SharedEcDetailPage/SharedFeedbackPage"
    )
)
const SharedManagerReviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/SharedEcDetailPage/SharedManagerReviewPage"
    )
)
const EvaluationQuestionsPreviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "@ManagerReviews/pages/EvaluationQuestionsPreview/EvaluationQuestionsPreviewPage"
    )
)
const AdminEvaluationQuestionsPreviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationQuestionsPreviewPage/AdminEvaluationQuestionsPreviewPage"
    )
)
const SummaryEcDetailPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./landingPages/pages/ReviewHubPage"
    )
)
const SummaryEcListingPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./landingPages/pages/ReviewsIndexPage"
    )
)
const TeamGoalsPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "@Goals/containers/TeamGoalsPage/TeamGoalsPage"
    )
)
const TeamMembers = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/TeamMembers/TeamMembers"
    )
)
const TeamSelfReflectionListingPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/TeamSelfReflectionListingPage/TeamSelfReflectionListingPage"
    )
)
const TeamSelfReflectionSummaryPage = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/TeamSelfReflectionSummaryPage/TeamSelfReflectionSummaryPage"
    )
)
const TeamSummary = React.lazy(
  () =>
    import(
      // @ts-expect-error No definition file
      /* webpackChunkName: "mainPages" */ "./entry/TeamSummary/TeamSummary"
    )
)
const TeamUsage = React.lazy(
  () =>
    // @ts-expect-error No definition file
    import(/* webpackChunkName: "mainPages" */ "./entry/TeamUsage/TeamUsage")
)
const TeamFeedbackRequest = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/TeamFeedbackRequest/TeamFeedbackRequest"
    )
)
const SelfReflectionsOverview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./landingPages/pages/SelfReflectionsPage"
    )
)

const ManagerReviewsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/EcDetailsPage/managerReview/ManagerReviewsPage"
    )
)

const FeedbacksPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/EcDetailsPage/feedback/FeedbacksPage"
    )
)

const FeedbackManagerViewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/FeedbackManagerViewPage/FeedbackManagerViewPage"
    )
)

const NominateFeedbackPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./entry/NominateFeedbackPage/NominateFeedbackPage"
    )
)

const AppRaw = React.lazy(
  // @ts-expect-error No definition file
  () => import(/* webpackChunkName: "App" */ "./entry/App/App")
)

export const RoutesApp = (
  <Switch>
    {OneOnOneRoutes}
    {OneOnOneAdminRoutes}
    <PrivateRoute path="/admin">
      <AdminApp>
        <Switch>
          <PrivateRoute
            exact
            path="/admin"
            authorisedRoles={["admin"]}
            component={AdminWelcomePage}
          />
          <PrivateRoute
            path="/admin/skills/:categoryId?/:categoryName?"
            authorisedRoles={["admin"]}
            component={AdminSkills}
          />
          <PrivateRoute authorisedRoles={["admin", "hr_business_partner"]}>
            <Switch>
              <PrivateRoute
                exact
                path="/admin/search"
                authorisedRoles={["admin"]}
              >
                {featureToggle({
                  flag: FeatureFlags.allowAdminFeedbackDelete,
                  On: AdminSearchPage,
                  offRedirect: R.dashboard,
                })}
              </PrivateRoute>

              <Route
                path="/admin/advanced_admin"
                component={AdminAdvancedPage}
              />
              <Route
                exact
                path="/admin/evaluation"
                component={() => <Redirect to="/admin/evaluation_cycles" />}
              />
              <Route
                exact
                path="/admin/evaluation_cycles"
                component={AdminEcListingPage}
              />
              <Route
                exact
                path="/admin/evaluation_cycles/:id"
                component={AdminEcDetailsPage}
              />
              {/* TODO: nuke AdminEcPeerAndUpwardFeedbackIndividualStats once management is done. */}
              <ValidatedRoute
                exact
                path="/admin/performance_review_cycle/:evaluationCycleId/pauf_requests/:subjectId"
                component={SubjectFeedbackOverview}
                validators={{ evaluationCycleId: /^\d+$/, subjectId: /^\d+$/ }}
              />

              <Route
                path="/admin/evaluation_cycles/:id/pauf_requests/:employeeId"
                component={AdminEcPeerAndUpwardFeedbackIndividualStats}
              />

              <Route
                exact
                path="/admin/evaluation/all"
                component={AdminCalibrationViewsCreate}
              />

              <Route
                path={[
                  "/admin/evaluation/saved_view/:savedViewId/:cycleId/:cycleName?/review/:reviewId",
                  "/admin/evaluation/:cycleId/:cycleName/review/:reviewId",
                ]}
              >
                <AdminEvaluationRootPage>
                  <AdminEvaluationQuestionsPage />
                </AdminEvaluationRootPage>
              </Route>
              <PrivateRoute
                path="/admin/evaluation/preview/:cycleId/:cycleName/review/:reviewId"
                authorisedRoles={["admin", "hr_business_partner"]}
              >
                <AdminEvaluationRootPage>
                  <AdminEvaluationQuestionsPreviewPage />
                </AdminEvaluationRootPage>
              </PrivateRoute>

              <Route
                exact
                path="/admin/evaluation/saved_view/:savedViewId/edit"
                component={() => <Redirect to="/admin/calibrations/views" />}
              />

              <Route
                exact
                path="/admin/evaluation/saved_view/:savedViewId"
                component={() => <Redirect to="/admin/calibrations/views" />}
              />

              <Route
                exact
                path="/admin/evaluation/saved_view"
                component={() => <Redirect to="/admin/calibrations/views" />}
              />

              <Route exact path={"/admin/evaluation/:cycleId/:cycleName"}>
                <Redirect to={R.adminEcListing} />
              </Route>

              <PrivateRoute path="/admin/printer_friendly_evaluation">
                <AppRaw showMenu={false}>
                  <PrinterFriendlyEvaluationPage renderAdminView />
                </AppRaw>
              </PrivateRoute>

              <Route exact path="/admin/self_reflection/:cycleId/:cycleName?">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect
                      appBasePath="/app/cycles-manage"
                      {...props}
                    />
                  ),
                  Off: AdminSelfReflectionSummaryPage,
                })}
              </Route>

              <Route exact path="/admin/self_reflection">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect appBasePath="/app/cycles-plan" {...props} />
                  ),
                  Off: AdminSelfReflectionListingPage,
                })}
              </Route>

              <Route path="/admin/completed_self_reflection">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect appBasePath="/app/cycles-view" {...props} />
                  ),
                  Off: AdminCompletedSelfReflectionResponsePage,
                })}
              </Route>

              <Route path="/admin/incomplete_self_reflection">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect
                      appBasePath="/app/cycles-capture"
                      {...props}
                    />
                  ),
                  Off: AdminIncompleteSelfReflectionSurveyResponsePage,
                })}
              </Route>

              <PrivateRoute
                path="/admin/goals"
                hasConfigurationOption="allow_goals"
              >
                <Switch>
                  <Route
                    exact
                    path="/admin/goals/stats"
                    component={AdminGoalsStatsPage}
                  />

                  <Redirect to="/admin/goals/stats" />
                </Switch>
              </PrivateRoute>

              <Route
                path="/admin/team_feedback/managers/:managerId"
                component={AdminIndividualManagerRequestHistoryPage}
              />
              <Route
                exact
                path={["/admin/team_feedback", "/admin/team_feedback/history"]}
                component={AdminManagerRequestedFeedbackPage}
              />

              <PrivateRoute
                path="/admin/manager_request_feedback_settings"
                authorisedRoles={["admin"]}
                component={AdminMRFSettingsPage}
              />

              <Route
                path="/admin/request_feedback"
                component={AdminSurveyFeedbackRequestsPage}
              />

              <Route
                path="/admin/feedback/stats"
                component={AdminFeedbackStatsPage}
              />
              <Route path={calibrationsPaths.admin.root.pattern}>
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceCalibrationsUpliftEnable",
                  On: AdminCalibrationsRoutes,
                  offRedirect: R.dashboard,
                })}
              </Route>
              <Route component={ErrorPage404} />
            </Switch>
          </PrivateRoute>
        </Switch>
      </AdminApp>
    </PrivateRoute>
    <PrivateRoute path="/browser_plugin">
      <AppRaw showMenu={false}>
        <Route
          exact
          path="/browser_plugin"
          component={BrowserPluginGiveFeedback}
        />
        <Route
          path="/browser_plugin/redirect"
          component={BrowserPluginRedirect}
        />
        <Route
          path="/browser_plugin/variants/:variant"
          component={BrowserPluginGiveFeedback}
        />
      </AppRaw>
    </PrivateRoute>
    <PrivateRoute path="/printer_friendly_peer_review">
      <AppRaw showMenu={false}>
        <PrinterFriendlyPeerReviewPage />
      </AppRaw>
    </PrivateRoute>
    <PrivateRoute path="/printer_friendly_shared_peer_and_upward_feedback">
      <AppRaw showMenu={false}>
        <PrinterFriendlySharedPeerAndUpwardFeedback />
      </AppRaw>
    </PrivateRoute>
    <PrivateRoute path="/printer_friendly_self_reflection">
      {ldFeatureToggle({
        launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
        predicate: (flag) => ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
        On: (props) => (
          <PRCAppRedirect appBasePath="/app/cycles-view" {...props} />
        ),
        Off: (props) => (
          <AppRaw {...props} showMenu={false}>
            <PrinterFriendlySelfReflectionPage />
          </AppRaw>
        ),
      })}
    </PrivateRoute>
    <PrivateRoute path="/printer_friendly_evaluation">
      <AppRaw showMenu={false}>
        <PrinterFriendlyEvaluationPage renderAdminView={false} />
      </AppRaw>
    </PrivateRoute>
    <PrivateRoute path="/printer_friendly_shared_evaluation">
      <AppRaw showMenu={false}>
        <PrinterFriendlySharedEvaluationPage />
      </AppRaw>
    </PrivateRoute>
    <Route path="/403" component={ErrorPage403} />
    <Route path="/404" component={ErrorPage404} />
    <Route path="/503Maintenance" component={ErrorPage503Maintenance} />
    <PrivateRoute path="/">
      <AppRaw>
        <Switch>
          <Route path="/emails" component={Emails} />
          <Redirect
            from="/help"
            to={{ search: `${HELP_REDIRECT_QUERY_KEY}=true` }}
          />
          <PrivateRoute
            path={teamsManagementPaths.index.pattern}
            hasConfigurationOption={ConfigurationOptions.teams}
          >
            <TeamsManagementRoutes />
          </PrivateRoute>
          {DevelopmentResourcesRoutes}
          {FeedbackRoutes}
          <Route path={R.home} component={Home} />
          <Route exact path="/" component={Notifications} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/profile" component={Profile} />
          <Route path="/profile_export" component={ExportProfile} />
          <Route path="/review" component={Review} />
          <Route
            path="/manager_requested_feedback"
            component={IncompleteMRFSurveyPage}
          />
          <PrivateRoute
            path="/team_self_reflection"
            hasConfigurationOption="allow_self_reflections"
          >
            <Switch>
              <Route exact path="/team_self_reflection">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect
                      appBasePath="/app/cycles-manage"
                      {...props}
                    />
                  ),
                  Off: TeamSelfReflectionListingPage,
                })}
              </Route>
              <Route path="/team_self_reflection/:cycleId/:cycleName?">
                {ldFeatureToggle({
                  launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
                  predicate: (flag) =>
                    ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
                  On: (props) => (
                    <PRCAppRedirect
                      appBasePath="/app/cycles-manage"
                      {...props}
                    />
                  ),
                  Off: TeamSelfReflectionSummaryPage,
                })}
              </Route>
            </Switch>
          </PrivateRoute>
          <ValidatedRoute
            path="/evaluation_cycles/shared/:cycleId/feedbacks"
            component={SharedFeedbackPage}
            validators={{ cycleId: /^\d+$/ }}
          />
          <ValidatedRoute
            path="/evaluation_cycles/shared/:cycleId/manager_reviews"
            component={SharedManagerReviewPage}
            validators={{ cycleId: /^\d+$/ }}
          />
          <ValidatedRoute
            path="/evaluation_cycles/shared/:cycleId"
            component={SharedEcDetailPage}
            validators={{ cycleId: /^\d+$/ }}
          />
          <ValidatedRoute
            path="/evaluation_cycles/summary/:cycleId"
            component={SummaryEcDetailPage}
            validators={{ cycleId: /^\d+$/ }}
          />
          <Redirect
            exact
            from="/evaluation_cycles/shared"
            to={R.summaryEvaluationCyclePage}
          />
          <PrivateRoute
            path="/self_reflections/overview"
            hasConfigurationOption="allow_self_reflections"
          >
            {ldFeatureToggle({
              launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
              predicate: (flag) =>
                ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
              On: (props) => (
                <PRCAppRedirect appBasePath="/app/cycles-manage" {...props} />
              ),
              Off: SelfReflectionsOverview,
            })}
          </PrivateRoute>
          <Route
            path="/evaluation_cycles/summary"
            component={SummaryEcListingPage}
          />
          <PrivateRoute
            path={R.ecFeedbackSubject}
            authorisedRoles={["manager"]}
            component={FeedbackManagerViewPage}
          />
          <ValidatedRoute
            path="/evaluation_cycles/:id"
            exact
            component={EcDetailsPage}
            validators={{ id: /^\d+$/ }}
            authorisedRoles={["manager"]}
          />
          <ValidatedRoute
            path="/evaluation_cycles/:id/manager_reviews"
            exact
            component={ManagerReviewsPage}
            validators={{ id: /^\d+$/ }}
            authorisedRoles={["manager"]}
          />
          <ValidatedRoute
            path="/evaluation_cycles/:id/feedbacks"
            exact
            component={FeedbacksPage}
            validators={{ id: /^\d+$/ }}
            authorisedRoles={["manager"]}
          />
          <ValidatedRoute
            path="/evaluation_cycles/:evaluationCycleId/nominations/:peerNominationCycleId"
            validators={{
              evaluationCycleId: /^\d+$/,
              peerNominationCycleId: /^\d+$/,
            }}
            component={NominateFeedbackPage}
          />
          <Redirect
            exact
            from="/evaluation_cycles/"
            to={R.summaryEvaluationCyclePage}
          />
          <PrivateRoute
            path={R.ecPaufRequestFeedback}
            authorisedRoles={["manager"]}
            component={EcPaufRequestFeedbackPage}
          />
          <Redirect
            exact
            from="/evaluation"
            to={R.summaryEvaluationCyclePage}
          />

          <PrivateRoute
            path="/evaluation"
            hasConfigurationOption="allow_performance_cycles"
          >
            <Switch>
              <Route path="/evaluation/saved_views/:savedViewId/:cycleId/:cycleName?/review/:reviewId">
                <EvaluationRootPage>
                  <EvaluationQuestionsPage />
                </EvaluationRootPage>
              </Route>
              <Route
                path="/evaluation/saved_views/:savedViewId"
                component={() => <Redirect to="/calibrations/views" />}
              />
              <Route
                path="/evaluation/saved_views"
                component={() => <Redirect to="/calibrations/views" />}
              />
              <Route path="/evaluation/:cycleId/:cycleName?/redirect">
                <EvaluationRootPage>
                  <EvaluationRedirectPage />
                </EvaluationRootPage>
              </Route>
              <Route path="/evaluation/:cycleId/:cycleName?/review/:reviewId?">
                <EvaluationRootPage>
                  <ManagerEvaluationQuestionsPage />
                </EvaluationRootPage>
              </Route>
              <PrivateRoute
                path="/evaluation/preview/:cycleId/:cycleName/review/:reviewId"
                authorisedRoles={["manager", "hr_business_partner"]}
              >
                <EvaluationRootPage>
                  <EvaluationQuestionsPreviewPage />
                </EvaluationRootPage>
              </PrivateRoute>

              <Redirect
                exact
                from="/evaluation/:cycleId/:cycleName?/"
                to={R.summaryEvaluationCyclePage}
              />
            </Switch>
          </PrivateRoute>
          <Route path="/search" component={Search} />
          <Route path="/myfeedback" component={FeedbackList} />
          <PrivateRoute
            path="/self_reflections_overview"
            hasConfigurationOption="allow_self_reflections"
          >
            {ldFeatureToggle({
              launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
              predicate: (flag) =>
                ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
              On: (props) => (
                <PRCAppRedirect appBasePath="/app/cycles-view" {...props} />
              ),
              Off: MySelfReflectionSurveyResponsesPage,
            })}
          </PrivateRoute>
          <Redirect
            exact
            from="/shared_evaluations_overview"
            to={R.summaryEvaluationCyclePage}
          />
          <Redirect
            exact
            from="/shared_evaluations_overview/review/:reviewId"
            to={R.summaryEvaluationCyclePage}
          />
          <Route path="/requests" component={FeedbackRequestsPage} />
          <PrivateRoute
            path="/self_review"
            hasConfigurationOption="allow_self_reflections"
          >
            {ldFeatureToggle({
              launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
              predicate: (flag) =>
                ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
              On: (props) => (
                <PRCAppRedirect appBasePath="/app/cycles-capture" {...props} />
              ),
              Off: IncompleteSelfReflectionSurveyPage,
            })}
          </PrivateRoute>
          <PrivateRoute
            path="/completed_self_reflection"
            hasConfigurationOption="allow_self_reflections"
          >
            {ldFeatureToggle({
              launchDarklyFlagKey: "performanceSelfReflectionsRebuild",
              predicate: (flag) =>
                ["V2", "MIGRATING", "UNAVAILABLE"].includes(flag),
              On: (props) => (
                <PRCAppRedirect appBasePath="/app/cycles-view" {...props} />
              ),
              Off: CompletedSelfReflectionSurveyPage,
            })}
          </PrivateRoute>
          <Route path="/quick_comment" component={QuickComment} />
          <PrivateRoute
            path="/public_praise"
            hasConfigurationOption="allow_praise"
            component={PublicPraise}
          />
          <PrivateRoute path="/goals" hasConfigurationOption="allow_goals">
            <Switch>
              <Route path="/goals/team" component={TeamGoalsPage} />
              <Redirect from="/goals" to="/goals/team" />
            </Switch>
          </PrivateRoute>
          <Route path="/summary/:section?" component={MySummary} />
          <PrivateRoute
            path="/team_search"
            authorisedRoles={["manager"]}
            component={TeamMembers}
          />
          <PrivateRoute
            path="/team_summary/:section?"
            authorisedRoles={["manager"]}
            component={TeamSummary}
          />
          <Route path="/team_usage" component={TeamUsage} />
          <PrivateRoute
            path="/team_feedback_request"
            authorisedRoles={["manager"]}
            component={PeerFeedbackRequest}
          />
          <Route
            path="/team_feedback/:teamMemberId/:teamId"
            component={withRouter(TeamFeedbackRequest)}
            /* onEnter={Authenticate({
predicate: user => user.is_a_manager
})} - TODO - add team lead authentication*/
          />
          <PrivateRoute
            path="/team_feedback"
            authorisedRoles={["manager"]}
            component={PeerFeedback}
          />
          {SurveysResponsesRoutes}
          <PrivateRoute
            path="/collaborator_evaluation/:reviewId"
            hasConfigurationOption="allow_performance_cycles"
            component={CollaboratorEvaluationPage}
          />
          {GoalsRoutes}
          <Route
            path={calibrationsPaths.nonAdmin.root.pattern}
            component={NonAdminCalibrationsRoutes}
          />
        </Switch>
      </AppRaw>
    </PrivateRoute>
    <Route path="*" component={ErrorPage404} />
  </Switch>
)

export const PublicRoutesApp = heartbeatRoute

const LegacyEmployeeProfileContent = React.lazy(
  () =>
    import(
      // @ts-expect-error - No definition file
      /* webpackChunkName: "LegacyEmployeeProfileContent" */ "./profileContent/LegacyEmployeeProfileContent"
    )
)

/**
 * Route created to serve perform 2.0 with a way to display ProfileContent as it currently is
 * This should be removed once ProfileContent is implemented in the rebuild
 */
export const ProfileContentApp = (
  <Route
    path="/legacy_profile_content"
    exact
    component={LegacyEmployeeProfileContent}
  />
)
