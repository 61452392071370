import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { addLoadingState, extractResponseKey } from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS } = endpoints
const { COMMENTS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const CommentsAction = Reflux.createActions({
  comment: { asyncResult: true },
  delete: { asyncResult: true },
  list: { asyncResult: true },
  seen: { asyncResult: true },
})

CommentsAction.comment.listenAndPromise(
  ({
    sourceObject,
    parentSourceObject,
    body,
    type,
    collectionName,
    parentType,
    recipientId = null,
    recipientType = "user",
    parentId = null,
    isPrivate = undefined,
  }) =>
    extractResponseKey(
      "comment",
      agent.post(COMMENTS_URL).send({
        comment: {
          source_obj_id: sourceObject.id,
          source_obj_type: type,
          parent_id: parentId,
          ...(recipientId && {
            recipient_id: recipientId,
            recipient_type: recipientType,
          }),
          private: isPrivate,
          body,
        },
      })
    ).then(
      (comment) => ({
        comment,
        parentSourceObject,
        type,
        parentType,
        collectionName,
        recipientId,
        parentId,
      }),
      () => Promise.reject({ sourceObject, type })
    )
)

CommentsAction.delete.listenAndPromise((id) =>
  extractResponseKey("comment", agent.del(`${COMMENTS_URL}/${id}`))
)

CommentsAction.list.listenAndPromise(
  ({
    sourceObjectId,
    type,
    recipientId,
    recipientType = "user",
    loadingState = [true, { light: true }],
  }) =>
    addLoadingState(
      loadingState,
      extractResponseKey(
        "comments",
        agent.get(COMMENTS_URL).query({
          source_obj_id: sourceObjectId,
          source_obj_type: type,
          recipient_id: recipientId,
          recipient_type: recipientType,
        })
      ).then((comments) => ({ sourceObjectId, type, recipientId, comments }))
    )
)

CommentsAction.seen.listenAndPromise(({ id }) =>
  agent.put(`${COMMENTS_URL}/${id}`).send({
    comment: {
      seen: true,
    },
  })
)

export default CommentsAction
