import * as React from "react"
import kebabIcon from "@kaizen/component-library/icons/kebab.icon.svg"
import { IconButton } from "@kaizen/button"
import { Menu, MenuList, MenuItem } from "@Containers/Menu"
import { useIntl } from "@Common/locale/useIntl"
import strings from "@Calibrations/locale/strings"
import userIcon from "@kaizen/component-library/icons/user.icon.svg"
import surveysIcon from "@kaizen/component-library/icons/surveys.icon.svg"
import { buildUrl } from "@Utils/url"
import { useLocation, useParams, useRouteMatch } from "react-router-dom"
import R from "@Constants/routes"
import {
  ManagerReview,
  ManagerReviewCycle,
  Employee,
} from "@Calibrations/v2/queries/useGetReviewResults"
import { useCalibrationViewQueryParams } from "@Calibrations/v2/hooks/useCalibrationViewQueryParams"
import { CYCLE_SLUG } from "@Constants/evaluationCycles"

type EmployeeToggleMenuProps = {
  managerReview: ManagerReview
  managerReviewCycle: ManagerReviewCycle
  employee: Employee
}

export const EmployeeToggleMenu: React.FC<EmployeeToggleMenuProps> = ({
  managerReview,
  managerReviewCycle,
  employee,
}) => {
  const { formatMessage } = useIntl()
  const params = useParams<{ calibrationViewId: string }>()
  const location = useLocation()
  const adminRouteMatch = useRouteMatch("/admin")
  const [, setQueryParams] = useCalibrationViewQueryParams()

  const href = buildUrl(
    adminRouteMatch
      ? R.adminCalibrationSavedViewManagerReview
      : R.calibrationSavedViewManagerReview,
    {
      savedViewId: params.calibrationViewId,
      cycleId: managerReviewCycle.id,
      cycleName: CYCLE_SLUG,
      reviewId: managerReview.id,
      summaryQueryString: location.search,
    }
  )

  return (
    <>
      <Menu
        button={
          <IconButton
            label={formatMessage(
              strings.pages.AdminCalibrationViewShowPage
                .AdminCalibrationViewTable.toggleMenu.label
            )}
            icon={kebabIcon}
          />
        }
        align="right"
        renderLocation="root"
      >
        <MenuList>
          <MenuItem
            label={formatMessage(
              strings.pages.AdminCalibrationViewShowPage
                .AdminCalibrationViewTable.toggleMenu.performanceReview
            )}
            href={href}
          />
        </MenuList>
        <MenuList>
          <MenuItem
            label={formatMessage(
              strings.pages.AdminCalibrationViewShowPage
                .AdminCalibrationViewTable.toggleMenu.employeeProfile
            )}
            icon={userIcon}
            onClick={() => {
              setQueryParams({
                dossierEmployeeId: employee.id,
                dossierNotesReviewId: undefined,
              })
            }}
            target="_blank"
          />
          <MenuItem
            label={formatMessage(
              strings.pages.AdminCalibrationViewShowPage
                .AdminCalibrationViewTable.toggleMenu.calibrationNotes
            )}
            icon={surveysIcon}
            onClick={() => {
              setQueryParams({
                dossierNotesReviewId: managerReview.id,
                dossierEmployeeId: undefined,
              })
            }}
            target="_blank"
          />
        </MenuList>
      </Menu>
    </>
  )
}
