import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Menu as KaizenMenu } from "@kaizen/draft-menu"
import { MenuProps } from "@kaizen/draft-menu/KaizenDraft/Menu/Menu"
import { menuPortalId } from "../../constants/portals"

type Props = Omit<MenuProps, "portalSelector"> & {
  /**
   * Specify where the menu should be rendered.
   * "inline" will render the component exactly how you have placed it.
   * "root" will render this at the root document/body level.
   * Set this to "root", if you experience issues such as the menu getting
   * cropped.
   */
  renderLocation?: "inline" | "root"
}

/**
 * Wrapper around the kaizen menu. So far, it does nothing but add the renderLocation
 * prop, in favour of `portalSelector`.
 */
export const Menu = ({ renderLocation = "inline", ...rest }: Props) => {
  const portalSelector =
    renderLocation === "root" ? `#${menuPortalId}` : undefined

  return <KaizenMenu portalSelector={portalSelector} {...rest} />
}
