import * as React from "react"
import { LoadingParagraph } from "@kaizen/loading-skeleton"
import cx from "classnames"

export const LoadingStat = () => {
  return (
    <div className="border-none border-b-none border-t-none border-solid border-gray-300 flex flex-col p-8 basis-0 grow items-center">
      <div className="text-center w-full">
        <LoadingParagraph width={70} isCentred isAnimated />
      </div>
      <div className={cx("text-center w-full text-data-lg leading-data-lg")}>
        <LoadingParagraph width={20} isCentred isAnimated />
      </div>
      <div className={cx("text-center w-full opacity-7")}>
        <LoadingParagraph width={50} isCentred isAnimated />
      </div>
    </div>
  )
}
