export const QueryKeys = {
  AdminCalibrationViewsDetails: "admin/calibrationViews/details",
  AdminCalibrationViewsDetailsSharing: {
    SharedWithUsers: "admin/calibrationViews/details/shared_with_users",
    SharingCandidates: "admin/calibrationViews/details/sharing_candidates",
  },
  AdminReviewResults: "admin/calibrationViews/details/results",
  AdminReviewResult: "admin/calibrationViews/details/result",
  AdminCalibrationViewManagerReviewDetails:
    "admin/calibrationViews/managerReviewDetails",
  AdminCalibrationViewsIndex: "admin/calibrationViews/index",
  AdminCalibrationViewHeroStats: "admin/calibrationViews/stats",
  AdminCalibrationCreateHeroStats: "admin/CalibrationCreate/stats",
  AdminCalibrationViewReviewResult: "/admin/calibrationViews/reviewResult",
  NonAdminCalibrationViewsDetails: "calibrationViews/details",
  NonAdminCalibrationViewsIndex: "calibrationViews/index",
  NonAdminCalibrationViewHeroStats: "calibrationViews/stats",
  AdminFilters: {
    Snapshot: {
      ManagerReviewCycles: "admin/calibrationViews/filters/managerReviewCycles",
      PerformanceRatings: "admin/calibrationViews/filters/performanceRatings",
      Departments: "admin/calibrationViews/filters/snapshotDepartments",
      Managers: "admin/calibrationViews/filters/managers",
      Levels: "admin/calibrationViews/filters/level",
      JobTitles: "admin/calibrationViews/filters/jobTitles",
      Users: "admin/calibrationViews/filters/users",
      UserStatus: "admin/calibrationViews/filters/userStatus",
      ReviewStatus: "admin/calibrationViews/filters/reviewStatus",
      GroupTypes: "admin/calibrationViews/filters/groupTypes",
    },
    DemographicValues: "admin/calibrationViews/filters/demographicValues",
    PerformanceRatings: "admin/calibrationViews/filters/performanceRatings",
    More: "admin/calibrationViews/filters/more",
    ManagerReviewCycles: "admin/calibrationViews/filters/managerReviewCycles",
    Departments: "admin/calibrationViews/filters/snapshotDepartments",
    Managers: "admin/calibrationViews/filters/managers",
    Levels: "admin/calibrationViews/filters/level",
    JobTitles: "admin/calibrationViews/filters/jobTitles",
    Users: "admin/calibrationViews/filters/users",
    UserStatus: "admin/calibrationViews/filters/userStatus",
    ReviewStatus: "admin/calibrationViews/filters/reviewStatus",
    GroupTypes: "admin/calibrationViews/filters/groupTypes",
  },
}
